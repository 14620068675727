<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="vue_wrapper_detail_page_order">
    <v-app style="min-height: 25vh !important">
      <div
        class="wrapper_loader_progress-circular"
        v-show="$store.state.state_progress_circular_page"
      >
        <v-progress-circular
          indeterminate
          color="success"
          size="100"
          width="10"
        ></v-progress-circular>
      </div>

      <div
        class="wrapper_detail_page_order"
        v-if="Object.keys(arr_data_order).length !== 0"
      >
        <v-expansion-panels
          focusable
          class="wrapper_head_block"
          style="z-index: 0"
          v-model="panel"
        >
          <v-expansion-panel
            style="border-radius: 15px"
            :disabled="
              $store.state.data_access_user_group_user
                .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_DESCRIPTION
                .CHECK_VALID_USER_GROUP !== 'Y'
            "
          >
            <div class="block_main_info">
              <v-expansion-panel-header>
                <div
                  class="header_block_main_info"
                  style="grid-area: header_block_main_info"
                >
                  Описание изделия:
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <section class="main_image" style="grid-area: main_image">
                  <img
                    :src="
                      $store.state.VUE_DEV_LOCAL_SERVER +
                      arr_data_order.DETAIL_ORDER.IMG_CUR_SPEC.SRC
                    "
                    :title="arr_data_order.DETAIL_ORDER.IMG_CUR_SPEC.TITLE"
                  />
                </section>

                <!-- Компонент файлов -->
                <list-files
                  :ALL_DOC="arr_data_order.DETAIL_ORDER.ALL_DOC"
                  v-if="!!arr_data_order.DETAIL_ORDER.ALL_DOC"
                ></list-files>
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
          <v-expansion-panel
            style="border-radius: 15px"
            :disabled="
              $store.state.data_access_user_group_user
                .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_DELIVERY
                .CHECK_VALID_USER_GROUP !== 'Y'
            "
          >
            <div class="wrapper_table">
              <v-expansion-panel-header>
                <div
                  class="header_block_output_table"
                  style="color: #f7ee89 !important; font-size: 1.1em"
                >
                  Необходимо выдать комплектующие:
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="color: white" eager>
                <section
                  class="wrapper_vidat_zagotovki_ili_izdelya"
                  v-if="
                    $store.state.data_access_user_group_user
                      .ACCESS_USER_GROUP_VISIBLE_POSITIONS_ORDER
                      .CHECK_VALID_USER_GROUP === 'Y'
                  "
                >
                  <section class="wrapper_table">
                    <!-- Динамическая таблица покупных изделий-->
                    <!--
                                  <section class="table_heading">
                                      <div class="nomer_heading">№</div>
                                      <div class="naimenovanie_heading">Наименование <span class="grey_caption">(ч-н)</span></div>
                                      <div class="kolichestvo_heading">Кол-во на выдачу <span class="grey_caption">(на складе)</span></div>
                                  </section>
                                  -->
                    <section
                      class="table_row header_table_row"
                      :class="{
                        noadmin_column:
                          arr_data_order.DETAIL_ORDER
                            .ACCESS_USER_GROUP_DELETE_ORDER
                            .CHECK_VALID_USER_GROUP !== 'Y',
                      }"
                    >
                      <section></section>
                      <section class="header_table_row_name">
                        Наименование
                      </section>
                      <section
                        class="header_table_row_sklad"
                        v-if="
                          arr_data_order.OUT_INVENTORY_FLAT_ARRAY.length !== 0
                        "
                      >
                        Кол-во<br />
                        для списания <br />
                        ---- <br />
                        (кол-во <br />на
                        {{
                          arr_data_order.OUT_INVENTORY_FLAT_ARRAY[0][
                            'NOMENCLATURE_CATALOG_QUANTITY_cur_store'
                          ]['TITLE']
                        }}
                        ) <br />
                        шт. | мм.
                      </section>
                    </section>
                    <section
                      v-if="arr_data_order.OUT_INVENTORY_FLAT_ARRAY.length == 0"
                      style="display: grid; text-align: center"
                    >
                      Не найдено элементов спецификации
                    </section>
                    <section
                      v-if="
                        arr_data_order.OUT_INVENTORY_FLAT_ARRAY.length !== 0
                      "
                      class="table_row"
                      v-for="(
                        array_tree, index
                      ) in arr_data_order.OUT_INVENTORY_FLAT_ARRAY"
                      :class="[
                        { touched: touchedId === index },
                        featuresFunctional(arr_data_order, array_tree),
                      ]"
                    >
                      <div class="nomer grey_caption">{{ index + 1 }}</div>
                      <!--<img class="preview_nodes_image" :src="array_tree['IMG_THUMB']['SRC']" alt="">-->
                      <div class="naimenovanie">
                        <!--:class="{composite_node: array_tree.FLAG_CO MPOSITE_NODE === 'Y'}"-->
                        <a
                          class="nomenclature_name_section href_to_nomenclature"
                          :data-img-href="array_tree['IMG']['SRC']"
                          :data-name="array_tree['NOMENCLATURE_NAME']"
                          @click="
                            open_dialog_nomenclature(array_tree.NOMENCLATURE_ID)
                          "
                        >
                          {{ array_tree['NOMENCLATURE_NAME'] }}
                          <span
                            class="chertejniy_nomer grey_caption"
                            v-if="array_tree.ARTICUL"
                          >
                            ({{ array_tree.ARTICUL }})
                          </span>
                        </a>
                      </div>
                      <div class="kolichestvo">
                        <span
                          class="sum_quantity_nodes"
                          v-if="array_tree['SUM_QUANTITY_NODES']"
                        >
                          {{ array_tree['SUM_QUANTITY_NODES'] }}
                        </span>
                        <span class="dostupnoe_kolichestvo grey_caption">
                          ({{
                            array_tree[
                              'NOMENCLATURE_CATALOG_QUANTITY_cur_store'
                            ]['AMOUNT'] - array_tree['SUM_QUANTITY_NODES']
                          }})
                        </span>
                      </div>
                      <v-checkbox
                        v-if="
                          !array_tree.STATE__NOT_ENOUGH_CATALOG_QUANTITY ||
                          array_tree.PRODUCT_TYPE !== 'Изделие'
                        "
                        :disabled="
                          array_tree.STATE__NOT_ENOUGH_CATALOG_QUANTITY ||
                          state_disabled_buttons_conduct_order.disabled_all_checkbox
                        "
                        v-model="array_tree.STATE_MARK"
                        @change="
                          update_obj_mark(
                            arr_data_order.OUT_INVENTORY_FLAT_ARRAY,
                          )
                        "
                        class="checkbox_accept_row"
                        :data-id-prod="array_tree['NOMENCLATURE_ID']"
                        color="#00a0e3"
                      >
                      </v-checkbox>
                      <div v-else class="element-create">
                        <v-btn
                          @click.prevent="
                            addOrderToProduction(
                              array_tree['NOMENCLATURE_ID'],
                              index,
                            )
                          "
                          style="width: 34px; height: 34px; border-radius: 20px"
                        >
                          <v-icon style="font-size: 32px"> mdi-plus </v-icon>
                        </v-btn>
                      </div>
                    </section>
                  </section>

                  <v-btn
                    id="fill_all_checkbox"
                    elevation="2"
                    text
                    :disabled="
                      state_disabled_buttons_conduct_order.disabled_all_checkbox
                    "
                    @click="fill_all_checkbox()"
                    class=""
                    >Отметить все / Сбросить все</v-btn
                  >
                </section>

                <!-- Виолла блоки отгрузки -->
                <div>
                  <div class="wrapper_block_commentary">
                    <header>Комментарии к заказ-наряду:</header>
                    <textarea
                      id="commentary_to_purchase_order"
                      @change="
                        update_variable_property(
                          arr_data_order['DETAIL_ORDER']['ID'],
                          $event.target.value,
                        )
                      "
                      rows="3"
                      >{{
                        arr_data_order['DETAIL_ORDER']['COMMENTARY']
                      }}</textarea
                    >
                  </div>
                  <div class="wrapper_block_conduct_document">
                    <section class="text">
                      Блок списания/формирования заказ-наряда
                    </section>

                    <v-btn
                      color="secondary"
                      id="izdelie_prinyato"
                      class=""
                      :loading="state_disabled_buttons_conduct_order.loading"
                      :disabled="
                        state_disabled_buttons_conduct_order.first_button_one_action ||
                        state_disabled_buttons_conduct_order.first_button_two_action
                      "
                      @click="
                        stage_navigation_logic(
                          data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
                            .FIRST_BTN_ACTION,
                        )
                      "
                    >
                      Взял в работу
                    </v-btn>
                    <v-btn
                      color="secondary"
                      id="izdelie_sobrano"
                      class=""
                      :loading="state_disabled_buttons_conduct_order.loading"
                      :disabled="
                        state_disabled_buttons_conduct_order.second_button_action
                      "
                      @click="
                        stage_navigation_logic(
                          data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
                            .SECOND_BTN_ACTION,
                        )
                      "
                    >
                      Готово
                    </v-btn>

                    <v-btn
                      outlined
                      v-if="
                        arr_data_order.DETAIL_ORDER
                          .ACCESS_USER_GROUP_DELETE_ORDER
                          .CHECK_VALID_USER_GROUP === 'Y'
                      "
                      id="delete_purchase_order"
                      :disabled="
                        state_disabled_buttons_conduct_order.delete_order
                      "
                      @click="delete_document_order"
                    >
                      Удалить ЗН, пока не поздно
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
          <v-expansion-panel
            style="border-radius: 15px"
            :disabled="
              $store.state.data_access_user_group_user
                .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_STATISTICS
                .CHECK_VALID_USER_GROUP !== 'Y'
            "
          >
            <div
              class="block_status"
              v-if="
                $store.state.data_access_user_group_user
                  .ACCESS_USER_GROUP_VISIBLE_STATISTIC
                  .CHECK_VALID_USER_GROUP === 'Y'
              "
            >
              <v-expansion-panel-header>
                <div class="header_block_status_block">
                  Статистика по стадиям ЗН:
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="wrapper_position_fixed">
                  <div
                    class="
                      wrapper_stage_of_production_status wrapper_main_date_order
                    "
                  >
                    <div class="wrapper_name_date_block">
                      <section class="text">Дата создания заказа:</section>
                    </div>
                    <time
                      class="date_create_new_order"
                      :datetime="
                        arr_data_order['DETAIL_ORDER']['DATE_CREATE_NEW_ORDER']
                      "
                    >
                      {{
                        arr_data_order['DETAIL_ORDER']['DATE_CREATE_NEW_ORDER']
                      }}
                    </time>
                  </div>

                  <div
                    class="wrapper_stage_of_production_status"
                    v-for="(arr, key) in order.FULL_DATA_STAGE_OF_PRODUCTION"
                  >
                    <div
                      class="wrapper_name_date_block"
                      style="grid-area: wrapper_name_date_block"
                    >
                      <section
                        :class="'name_status color_status_' + arr.NAME_STAGE"
                      >
                        {{ arr.NAME_STAGE }}
                      </section>
                      <section class="text_executor">Исполнитель:</section>
                      <section class="fio">
                        {{ arr._LOG_FOR_STAGE_DATA.FULL_NAME_PERFORMER }}
                      </section>
                    </div>
                    <div
                      class="wrapper_info_status"
                      style="grid-area: wrapper_info_status"
                    >
                      <section class="plan">
                        <section>План:</section>
                        <time datetime="">{{
                          arr._LOG_FOR_STAGE_DATA.PLANNED_TIME_FOR_WORK
                        }}</time>
                      </section>
                      <section class="fact">
                        <section>Факт:</section>
                        <time datetime="">{{
                          arr._LOG_FOR_STAGE_DATA.PLANNED_TIME_FOR_WORK
                        }}</time>
                      </section>
                      <section class="overdue_payment"></section>
                    </div>
                    <div
                      class="wrapper_date_appointment_executor"
                      style="grid-area: wrapper_date_appointment_executor"
                    >
                      <section class="text_date">Дата назначения:</section>
                      <time
                        class="date_appointment_executor"
                        :datetime="
                          arr._LOG_FOR_STAGE_DATA.DATE_APPOINTMENT_EXECUTOR
                        "
                      >
                        {{ arr._LOG_FOR_STAGE_DATA.DATE_APPOINTMENT_EXECUTOR }}
                      </time>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>

        <!--    <div class="empty_block"></div>-->
      </div>
    </v-app>

    <!-- Диалоговое окно с номенклатурой -->
    <v-dialog
      v-model="dialog_nomenclature_window.state"
      max-width="600px"
      content-class="dialog_nomenclature"
    >
      <v-card id="v_dialog_nomenclature_window">
        <v-card-title>
          <span class="headline">Номенклатура</span>
          <v-icon
            class="close_dialog_cross"
            color="primary"
            elevation="2"
            @click="dialog_nomenclature_window.state = false"
            >fas fa-times
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <nomenclature
                :dialog_id_order="dialog_nomenclature_window.id_order"
              ></nomenclature>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListFiles from '../components/ListFiles'
import Nomenclature from './nomenclature/Nomenclature'

export default {
  name: 'DetailPageOrder',
  components: {
    ListFiles,
    Nomenclature,
  },
  props: ['prop_id_order', 'order'],
  data: () => ({
    toggle_state: false,
    loading_page: false,
    arr_data_order: {},
    message_status_order: [],
    queryProduct: {},
    panel: 1,
    state_disabled_buttons_conduct_order: {
      loading: false,
      first_button_one_action: true,
      first_button_two_action: true,
      second_button_action: true,
      delete_order: true,
      disabled_all_checkbox: true,
    },
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    touchedId: null,
  }),
  mounted() {
    this.get_order()
  },
  created() {},
  watch: {
    data_cur_stage: {
      handler: function watch() {
        this.state_buttons_for_main_status
      },
      deep: true,
    },
    '$store.state.data_take_product_on_store.counter_call': {
      handler: function watch() {
        this.get_order()
      },
    },
  },
  computed: {
    /* Возвращаем в watch массив STATE_MARK всех чекбоксов */
    state_arr_mark_checkbox() {
      console.log('state_arr_mark_checkbox')

      const _this = this
      if (!!_this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY) {
        _this.state_disabled_buttons_conduct_order.first_button_two_action = false

        _this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY.forEach(function (elem) {
          /* Если хотя бы один чекбокс не активен - кнопка "принять" неактивна */
          if (elem.STATE_MARK === false || elem.STATE_MARK === undefined) {
            _this.$data.state_disabled_buttons_conduct_order.first_button_two_action = true
          }
        })
      }

      console.log('finish checkbox')

      _this.$nextTick(() => {
        _this.state_buttons_for_main_status
      })
    },
    data_cur_stage() {
      const cur_stage = this.order.FULL_DATA_STAGE_OF_PRODUCTION.filter(
        (elem) => elem.STAGE_STATUS === 'cur_stage',
      )[0]
      return !!cur_stage ? cur_stage : null
    },
    data_next_stage() {
      const next_stage = this.order.FULL_DATA_STAGE_OF_PRODUCTION.filter(
        (elem) => elem.STAGE_STATUS === 'next_stage',
      )[0]
      return !!next_stage ? next_stage : null
    },
    obj_data_stage() {
      return {
        warehouse_request: {
          ID_STORE_1:
            this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE.ID_STORE_1,
          ID_STORE_2:
            this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE.ID_STORE_2,
          ID_STORE_STAGE:
            this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
              .ID_STORE_STAGE,
          state_conversion:
            this.data_cur_stage._LOG_FOR_STAGE_DATA
              ._DATA_PURCHASE_ORDER_STATE_CONVERSION,
          number_purchase_order: this.arr_data_order.DETAIL_ORDER.ID,
          id_create_employee: this.arr_data_order.DETAIL_ORDER.ID_CUR_EMPLOYEE,
          id_change_employee: this.arr_data_order.DETAIL_ORDER.ID_CUR_EMPLOYEE,
          price_cur_specification:
            this.arr_data_order.DETAIL_ORDER.PRICE_CUR_SPECIFICATION,
          price_sum_cur_order:
            this.arr_data_order.DETAIL_ORDER.PRICE_SUM_CUR_ORDER,
          into_inventory: {
            number_of_positions_for_execution:
              this.arr_data_order.DETAIL_ORDER
                .NUMBER_OF_POSITIONS_FOR_EXECUTION,
            id_prod: this.arr_data_order.DETAIL_ORDER.ID_PROD,
          },
          out_inventory: {
            flat_array_tree: this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY,
          },
        },
        login: this.$store.state.login,
        token: this.$store.state.token,
      }
    },
    url_movement_stage_store() {
      return (
        this.$store.state.VUE_DEV_LOCAL_SERVER +
        '/local/modules/nk.production/lib/conductdocuments/conductdocument.php'
      )
    },
    get_header_store() {
      if (Object.keys(this.arr_data_order).length !== 0) {
        if (this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY.length !== 0) {
          return this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY[0]
        }
      }
    },
    state_buttons_for_main_status() {
      this.state_disabled_buttons_conduct_order.first_button_one_action = true
      this.state_disabled_buttons_conduct_order.second_button_action = true
      this.state_disabled_buttons_conduct_order.disabled_all_checkbox = true
      this.state_disabled_buttons_conduct_order.delete_order = true

      /* Проверяем доступ по роли (группе пользователя) */
      if (!!this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE) {
        if (
          this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
            .ACCESS_STATE_FOR_ROLE &&
          this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
            .CHECK_ASSIGN_USER_TO_STAGE
        ) {
          /* 1. Основная логика по состоянию работы на стадии */
          switch (
            this.data_cur_stage.A_STATE_WORK_ASSIGNED_ROLE_FOR_STAGE
              .STATE_WORK_ON_STAGE
          ) {
            case 'awaiting':
              this.state_disabled_buttons_conduct_order.first_button_one_action = false
              this.state_disabled_buttons_conduct_order.second_button_action = true
              break
            case 'took_to_work':
              this.state_disabled_buttons_conduct_order.first_button_one_action = true
              this.state_disabled_buttons_conduct_order.second_button_action = false
              break
            case 'completed_work':
              this.state_disabled_buttons_conduct_order.first_button_one_action = true
              this.state_disabled_buttons_conduct_order.second_button_action = true
              break
          }

          //Если aсtion - преобразование (а такое только на стадии заготовка) то можно пользоваться чекбоксами, можем удалить ЗН
          if (
            (this.data_cur_stage.NAME_STAGE === 'Заготовка' ||
              this.data_cur_stage.NAME_STAGE === 'Изделие') &&
            this.data_cur_stage._STATE_WORK_ON_STAGE_DATA
              ._STATE_ACTION_ON_STAGE_CODE === 'awaiting'
          ) {
            this.state_disabled_buttons_conduct_order.disabled_all_checkbox = false
            this.state_disabled_buttons_conduct_order.delete_order = false
          }
        }
      }
    },
  },
  methods: {
    // При нажатии на плюс изменяем объект с query параметрами
    addOrderToProduction(data, index) {
      if (!!index || index === 0) {
        this.touchedId = index
      }
      this.$store.dispatch('getNomenclatureById', data).then(() => {
        this.$store.commit('changeShouldUpdateUrl', true)
        this.$store.state.data_nomenclature_from_plus = Object.assign(
          {},
          this.$store.state.data_nomenclature.filter(
            (index) => index.ID === data,
          )[0],
        )
        this.$store.state.data_new_order.state_v_show = true
      })
    },
    // Изменяем URL
    routerPush() {
      this.$store.commit('changeShouldUpdateUrl', true)
      this.$store.dispatch('getUpdateURL_components', this.queryProduct)
    },
    // Проверяем на логин группы пользователя, чтобы открыть определенную вкладку
    checkAccessUser() {
      if (Object.keys(this.$store.state.data_access_user_group_user).length) {
        if (
          this.$store.state.data_access_user_group_user
            .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_DESCRIPTION
            .CHECK_VALID_USER_GROUP === 'Y' &&
          this.$store.state.data_access_user_group_user
            .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_DELIVERY
            .CHECK_VALID_USER_GROUP === 'Y' &&
          this.$store.state.data_access_user_group_user
            .ACCESS_USER_GROUP_DETAIL_ORDER_TAB_STATISTICS
            .CHECK_VALID_USER_GROUP === 'Y'
        ) {
          this.panel = 1
        } else {
          this.panel = 0
        }
      }
    },
    featuresFunctional(arr_data_order, array_tree) {
      return {
        noadmin_column:
          arr_data_order.DETAIL_ORDER.ACCESS_USER_GROUP_DELETE_ORDER
            .CHECK_VALID_USER_GROUP !== 'Y',
        not_enough_quantity: !!array_tree.STATE__NOT_ENOUGH_CATALOG_QUANTITY,
      }
    },
    open_dialog_nomenclature(order_id) {
      // console.log('order_id');
      // console.log(order_id);
      this.dialog_nomenclature_window.id_order = order_id
      this.dialog_nomenclature_window.state = true
    },
    fill_all_checkbox() {
      const _this = this
      /* Изменим булево если доступен чекбокс  */
      if (
        !_this.$data.state_disabled_buttons_conduct_order.disabled_all_checkbox
      ) {
        this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY.forEach(function (elem) {
          if (!elem['STATE__NOT_ENOUGH_CATALOG_QUANTITY']) {
            elem.STATE_MARK = !_this.$data.toggle_state
          }
        })
        /* сменим булево (переключаем)*/
        this.toggle_state = !this.toggle_state

        /* Обновим на бэке марки */
        this.update_obj_mark(this.arr_data_order.OUT_INVENTORY_FLAT_ARRAY)
      }
    },
    async update_obj_mark(nomenclature) {
      const _this = this
      // console.log(nomenclature);

      const modify_obj_perfomance_mark = nomenclature.map(function (elem) {
        return {
          PERFOMANCE_MARK: elem.STATE_MARK,
          NOMENCLATURE_ID: elem.NOMENCLATURE_ID,
          NOMENCLATURE_NAME: elem.NOMENCLATURE_NAME,
        }
      })
      this.arr_data_order.DETAIL_ORDER.OBJ_PERFORMANCE_MARK =
        modify_obj_perfomance_mark
      /* Переместили обновление отметок из ответа сервера для реакции на фронте */
      _this.state_arr_mark_checkbox

      /* Создаем новую запись  */
      BX.ajax
        .runComponentAction(
          'nk:production.detail_purchase_order',
          'executeUpdate_obj_performance_mark',
          {
            // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_detail_order: this.arr_data_order.DETAIL_ORDER.ID,
                obj_performance_mark:
                  this.arr_data_order.DETAIL_ORDER.OBJ_PERFORMANCE_MARK,
              },
              login: _this.$store.state.login,
              token: _this.$store.state.token,
            },
          },
        )
        .then(function (response) {
          if (response.status === 'success') {
            response.data.obj_performance_mark = JSON.parse(
              response.data.obj_performance_mark,
            )
          }
        })
        .catch((response) => {
          console.log(response)
        })
    },
    async get_order() {
      const _this = this
      // console.log(_this.prop_id_order);
      _this.$store.state.state_progress_circular_page = true

      /* Битриксовый AJAX */
      BX.ajax
        .runComponentAction(
          'nk:production.detail_purchase_order',
          'executeGetOrder',
          {
            // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_order: _this.prop_id_order,
                data_cur_stage: _this.data_cur_stage,
              },
              login: _this.$store.state.login,
              token: _this.$store.state.token,
            },
          },
        )
        .then(function (response) {
          if (response.status === 'success') {
            // Если форма успешно отправилась
            console.log(response.data)
            _this.$data.arr_data_order = response.data

            _this.checkAccessUser()

            _this.$store.state.state_progress_circular_page = false
            _this.state_arr_mark_checkbox
          }
        })
        .catch((response) => {
          console.log(response)
          _this.$store.dispatch('setSystemNotificationResult', response)
          _this.state_arr_mark_checkbox
        })
    },

    stage_navigation_logic(action) {
      this.state_disabled_buttons_conduct_order.loading = true

      if (action === 'Преобразование') {
        this.conductDocument()
      } else if (action === 'Перемещение') {
        this.distributeToStores()
      } else if (action === '-') {
        this.executeUpdate_status_on_table()
      }
    },
    /* Перемещения заказ-нарядов */
    async distributeToStores() {
      this.obj_data_stage.warehouse_request.type_doc = 'M'
      const data = this.obj_data_stage
      console.log('distributeToStores')
      console.log(data)

      fetch(this.url_movement_stage_store, {
        // mode: 'no-cors',
        method: 'POST',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data, null, 2),
      })
        .then((response) => {
          console.log('YAT distributeToStores response')
          console.log(response)
          return response.json()
        })
        .then((response) => {
          console.log('AAAA distributeToStores')
          console.log(response)

          if (response.state === true) {
            this.executeUpdate_status_on_table(response)
          } else {
            this.$store.dispatch('setSystemNotificationResult', response)
          }
        })
        .catch((error) => {
          console.log('catch')
          console.log(error)
          this.$store.dispatch('setSystemNotificationResult', error)
        })
        .finally((result) => {
          this.state_disabled_buttons_conduct_order.loading = false
        })
    },
    /* Проводка / Списание */
    async conductDocument() {
      this.obj_data_stage.warehouse_request.type_doc = 'A&D'
      const data = this.obj_data_stage
      console.log('conductDocument')
      console.log(data)
      fetch(this.url_movement_stage_store, {
        // mode: 'no-cors',
        method: 'POST',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization': axios.defaults.headers.common['Authorization'],
        },
        body: JSON.stringify(data, null, 2),
      })
        .then((response) => {
          console.log('YAY conductDocument')
          console.log(response)
          return response.json()
        })
        .then((response) => {
          console.log(response)
          if (response.state === true) {
            return this.executeUpdate_status_on_table(response)
          } else {
            this.$store.dispatch('setSystemNotificationResult', response)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch('setSystemNotificationResult', error)
        })
        .finally((result) => {
          this.state_disabled_buttons_conduct_order.loading = false
        })
    },
    async executeUpdate_status_on_table(
      response_called_func = null,
      reload = null,
    ) {
      const _this = this

      /* Битриксовый AJAX */
      BX.ajax
        .runComponentAction(
          'nk:production.detail_purchase_order',
          'executeUpdate_status_on_table',
          {
            // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_detail_order: this.arr_data_order.DETAIL_ORDER.ID,
                obj_cur_stage: this.data_cur_stage,
                obj_next_stage: this.data_next_stage,
              },
              login: _this.$store.state.login,
              token: _this.$store.state.token,
            },
          },
        )
        .then(function (response) {
          if (response.status === 'success') {
            console.log('executeUpdate_status_on_table')
            console.log(response)

            /* Установим для VUE данные */
            _this.get_order()
            // const data_for_vue = response.data['$result->getData()'];
            // _this.$data.arr_data_list_order.DETAIL_ORDER.MAIN_STATUS = data_for_vue.MAIN_STATUS;
            // _this.$data.arr_data_list_order.DETAIL_ORDER.STATE_ORDER = data_for_vue.STATE_ORDER;
            /* После этого vue watcher перерисует нужные состояния активности кнопок действий */

            /* Дополняем ответ от другого запроса */
            response.data.sub_message = !!response_called_func
              ? response_called_func.message
              : ''
            _this.$store.dispatch('setSystemNotificationResult', response.data)
            _this.$emit('listen-add-update-order')

            _this.state_disabled_buttons_conduct_order.loading = false
          }
        })
        .catch((response) => {
          console.log(response)
          _this.$store.dispatch('setSystemNotificationResult', response)
          _this.$emit('listen-add-update-order')

          _this.state_disabled_buttons_conduct_order.loading = false
        })
    },
    async delete_document_order() {
      const _this = this

      BX.ajax
        .runComponentAction(
          'nk:production.detail_purchase_order',
          'executeDelete_purchase_order',
          {
            // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_detail_order: _this.arr_data_order.DETAIL_ORDER.ID,
              },
              login: _this.$store.state.login,
              token: _this.$store.state.token,
            },
          },
        )
        .then(function (response) {
          console.log(response)
          if (response.data.status === 'success') {
            _this.$emit('listen-remove-order', response.data.message)
          } else {
            _this.$store.dispatch('setSystemNotificationResult', response.data)
          }
        })
        .catch((response) => {
          console.log(response)
          _this.$store.dispatch('setSystemNotificationResult', response)
        })
    },
    async update_variable_property(id_detail_order, value) {
      const _this = this
      // console.log(obj_data);
      BX.ajax
        .runComponentAction(
          'nk:production.detail_purchase_order',
          'executeUpdate_any_property',
          {
            // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_detail_order: id_detail_order,
                commentary: value,
              },
              login: _this.$store.state.login,
              token: _this.$store.state.token,
            },
          },
        )
        .then(function (response) {
          if (response.status === 'success') {
            // response.data.obj = JSON.parse(response.data.obj);
            console.table(response.data)
          }
        })
        .catch((response) => {
          console.log(response)
        })
    },
  },
  beforeDestroy() {
    this.touchedId = null
  },
}
</script>

<style lang="scss">
@import '../assets/sass/main';

/* Детальная страница ЗН новые стили */
#vue_wrapper_detail_page_order {
  background: $background_main_order_block;
  .wrapper_detail_page_order {
    color: white;
    @extend %round_block_detail_page;
    margin: 10px;

    display: grid;
    grid-row-gap: 10px;

    .block_main_info,
    .block_status,
    .wrapper_table,
    .wrapper_block_buttons_access_table {
      background: $background_detail_page;
      border-radius: 10px;
    }

    /* Новый макет */
    .wrapper_head_block {
      position: relative;
      grid-row-gap: 10px;

      .block_status {
        display: grid;
        grid-template-columns: auto;
        align-items: center;

        font-size: 18px;
        @media (max-width: 768px) {
          font-size: 15px;
        }

        .header_block_status_block {
          @extend %caption_section;
          font-size: 1.1em;
          padding: 0;
        }

        .wrapper_position_fixed {
          display: grid;
          grid-template-columns: auto;
          grid-row-gap: 10px;
          width: 100%;
          height: 100%;
          color: white;
          align-items: center;

          time {
            font-size: 11px;
          }
          .wrapper_stage_of_production_status {
            &.wrapper_main_date_order {
              align-items: center;
              align-content: center;
              margin-bottom: 20px;
              justify-items: baseline;

              time {
                font-size: inherit;
                color: white;
              }
            }
            &:last-child {
              border-bottom: unset;
            }
            border-bottom: 1px solid grey;
            height: 100%;
            display: grid;
            grid-template-columns: 1.6fr 1fr;
            grid-template-areas: 'wrapper_name_date_block           wrapper_info_status' 'wrapper_date_appointment_executor wrapper_date_appointment_executor';
            align-content: baseline;
            align-items: baseline;
            padding: 1px 10px;

            .fio,
            time {
            }
            .text_executor,
            .text_date {
              color: darkgrey;
            }
            .fio,
            .text_executor,
            .text_date,
            time {
              font-size: 0.8em;
            }

            .wrapper_date_appointment_executor {
              margin: 5px 0;
              display: grid;
              grid-gap: 2px;
              grid-template-columns: auto;
              justify-items: baseline;
            }
            .wrapper_name_date_block {
              display: grid;
              grid-row-gap: 2px;
              .text {
                font-site: 1em;
              }
              .name_status {
                margin-bottom: 10px;
                &.color_status {
                  @each $status, $color in $status-colors {
                    &_#{$status} {
                      color: $color;
                    }
                  }
                }
              }
            }
            .wrapper_info_status {
              display: grid;
              grid-template-rows: repeat(3, auto);
              grid-row-gap: 2px;
              justify-content: flex-start;
              font-size: 0.8em;

              .plan,
              .fact,
              .overdue_payment {
                display: grid;
                grid-template-columns: 1fr 2fr;
                justify-content: center;
                justify-items: end;
                grid-column-gap: 10px;
              }
            }
          }

          img.icon_checkbox {
            display: none;
            height: 18px;
            width: auto;
          }
        }
      }

      .block_main_info {
        grid-template-columns: auto 13%;
        grid-template-areas: 'header_block_main_info header_block_main_info' 'main_image block_icons';
        grid-column-gap: 20px;

        .header_block_main_info {
          color: $caption_yellow_color;
          text-align: left;
          font-size: 1.1em;
        }
        section {
          display: flex;
          justify-content: center;

          &.main_image {
            padding: 12px 0 28px 0;
            img {
              height: auto;
              max-height: 19vh;
              max-width: 100%;
              border-radius: 7px;
            }
          }
        }
      }
    }

    .empty_block {
      height: 60px;
    }

    div.wrapper_table {
      .wrapper_vidat_zagotovki_ili_izdelya {
        display: grid;
        p {
          text-align: center;
          font-weight: bold;
        }

        .wrapper_table {
          display: grid;
          grid-row-gap: 0.6em;
          div {
          }

          .grey_caption {
            width: 100%;
            display: block;
            color: gray;
          }
          .table_heading {
            display: grid;
            grid-template-columns: 10% auto 32%;
          }

          .header_block_output_table {
            @extend %caption_section;
          }
          .header_table_row {
            color: darkgrey;
            .header_table_row_name {
              justify-self: baseline;
            }
            .header_table_row_sklad {
              text-align: center;
              font-size: 0.9em;
            }
          }
          .table_row {
            display: grid;
            grid-template-columns: auto 1fr 18% 12%;
            grid-column-gap: 1em;
            align-items: center;
            font-size: 15px;
            padding: 5px;

            &.not_enough_quantity {
              background: rgb(205 92 92 / 68%);
              border-radius: 10px;
              .kolichestvo {
                .sum_quantity_nodes {
                  color: darkred;
                }
                .dostupnoe_kolichestvo {
                  color: white;
                  font-size: 1.1em;
                }
              }
            }
            &.noadmin_column {
              grid-template-columns: auto 1fr 29% 9%;
              justify-items: center;
            }

            .naimenovanie {
              display: grid;
              justify-self: baseline;
            }
            img.preview_nodes_image {
              max-height: 4em;
              max-width: 100%;
              height: fit-content;
              margin: auto;
              object-fit: contain;
            }
            .nomenclature_name_section {
              cursor: pointer;

              &.composite_node {
                color: yellow;
              }
              @at-root .fancybox-container {
                .fancybox-content {
                }
              }
            }
            .kolichestvo.not_enough_quantity span {
              &.grey_caption {
                color: red;
              }
            }

            .kolichestvo {
              justify-content: center;
              text-align: center;
            }
            .kolichestvo_2_sklad {
              justify-content: center;
              text-align: center;
              align-self: center;
            }
            .nomer {
              align-content: baseline;
            }
            .checkbox_accept_row {
              margin: unset;
              padding: unset;
              display: grid;
              justify-content: center;
              align-self: center;
              &.v-input--is-label-active {
                &.v-input--is-disabled {
                  background: unset;
                }
                .v-input--selection-controls__input {
                  background: white;
                }
              }
              .v-input__slot {
                margin: unset;
              }
              .v-icon {
                color: #00a0e3;
                font-size: 36px;
              }
              .v-messages {
                display: none;
              }
            }
            .element-create {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-right: 9px;

              .v-btn:not(.v-btn--round).v-size--default {
                padding: 0;
                min-width: 0;
              }
            }
            .wpapper_preview_node {
              grid-area: wpapper_preview_node;
              max-height: fit-content;
              display: grid;
              grid-row-gap: 15px;
              justify-content: center;

              img {
                width: 100%;
                height: auto;
              }
              .preview_node_btn_ok {
                display: flex;
                justify-content: center;
              }
            }
          }
          .touched {
            border: 1px solid lightyellow;
          }
        }
        #fill_all_checkbox {
          display: grid;
          margin-left: auto;
          color: white;
          margin-top: 2em;
          text-transform: capitalize;
          letter-spacing: normal;
        }
      }
    }

    .wrapper_block_buttons_access_table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin: 20px auto 20px auto;

      .clear_checkbox_output_table {
        color: white;
      }
    }

    .wrapper_block_commentary {
      display: grid;
      grid-row-gap: 15px;
      margin-top: 2rem;
      header {
        margin: auto;
      }
      textarea {
        font-family: 'Roboto';
        font-size: 17px;
        padding: 25px;
        border-radius: 10px;
        border: 1px solid white;
        color: white;
      }
    }

    .wrapper_block_conduct_document {
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 20px;
      margin: 50px 0;
      .text {
        display: grid;
        text-align: center;
      }
      button {
        margin-left: unset;
        &#delete_purchase_order {
          margin-top: 30px;
          color: white;
        }
      }
    }
  }
}

.v-expansion-panel-content__wrap {
  padding: 0 10px 10px !important;
}

#v_dialog_files {
  .headline {
    margin: auto;
  }
}
</style>
