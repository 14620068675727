var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper_relative_menu_footer",attrs:{"id":"vue_wrapper_relative_menu_footer"}},[(_vm.$route.name == 'SpecificationCreate')?_c('div',{staticClass:"menu_footer_not"},[_c('StatusLogger'),_c('div',{staticClass:"btns-create"},[_c('div',{staticClass:"btn-create"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.backToView}},[_vm._v("Отмена")])],1),_c('div',{staticClass:"btn-create"},[_c('v-btn',{attrs:{"color":"green darken-1","disabled":_vm.validationСheckEdit},on:{"click":_vm.postNewElement}},[_vm._v(" Создать ")])],1)])],1):_vm._e(),(_vm.$route.name == 'SpecificationView')?_c('div',{staticClass:"menu_footer_not"},[_c('StatusLogger')],1):_vm._e(),(_vm.$route.name == 'SpecificationEdit')?_c('div',{staticClass:"menu_footer_not",staticStyle:{"height":"auto"}},[_c('StatusLogger'),_c('div',{staticClass:"btns-create"},[_c('div',{staticClass:"btn-create"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.backToViewFromEdit}},[_vm._v("Отмена")])],1),_c('div',{staticClass:"btn-create"},[_c('v-btn',{attrs:{"color":"green darken-1","disabled":_vm.validationСheckEdit},on:{"click":_vm.updateNomenclature}},[_vm._v(" Обновить данные ")])],1)])],1):_vm._e(),(
      _vm.$route.name !== 'SpecificationEdit' &&
      _vm.$route.name !== 'SpecificationView' &&
      _vm.$route.name !== 'SpecificationCreate'
    )?_c('div',{staticClass:"menu_footer"},[_c('v-btn',{attrs:{"id":"btn_filter","text":"","color":"primary"},on:{"click":_vm.open_close_block_filter}},[_c('v-icon',{attrs:{"color":"blue","large":""}},[_vm._v("mdi-"+_vm._s(this.icons_state ? 'filter-off' : 'filter'))])],1),(Object.keys(_vm.$store.getters.getDataDeniedFunctional).length !== 0)?_c('v-btn',{attrs:{"id":"btn_add","color":"primary","text":"","disabled":_vm.$store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_ADD_NEW_ORDER
          .CHECK_VALID_USER_GROUP !== 'Y' ||
        _vm.$route.name === 'Receipt_overhead' ||
        _vm.$route.name === 'NomenclatureList'},on:{"click":_vm.open_frame_add_new_order}},[_c('v-icon',{attrs:{"color":"blue","large":""}},[_vm._v("mdi-plus-circle")])],1):_vm._e(),(Object.keys(_vm.$store.getters.getDataDeniedFunctional).length !== 0)?_c('v-btn',{attrs:{"id":"btn_find_nomenclature","text":""},on:{"click":function($event){return _vm.$router.push('/qr_code')}}},[_c('v-icon',{attrs:{"color":"blue","large":""}},[_vm._v("mdi-qrcode-scan")])],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }