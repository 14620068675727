<template>
    <v-app class="order_for_nomenclature">
        <v-dialog
                v-model="modal_detail_order.state"
                max-width="600px"
        >
            <div class="wrapper_loader_progress-circular" v-show="$store.state.state_progress_circular_page">
                <v-progress-circular
                        indeterminate
                        color="success"
                        size="100"
                        width="10"
                ></v-progress-circular>
            </div>

            <div id="wrapper_icon_main_info">
                <div class="row_main_info wrap_row">
                    <div class="progress_stage_of_production" style="grid-area: progress_stage_of_production"
                         v-for="(value, index) in order.FULL_DATA_STAGE_OF_PRODUCTION" :key="index">
                          <span v-if="!value.STAGE_STATUS"
                                class="span_progress"
                                :class="'progress_'+value.NAME_SECTOR"
                          ></span>
                    </div>
                    <div class="wrap_row_onclick_area" style="grid-area: wrap_row_onclick_area">
                        <section class="id_order"><span>ЗН</span> <span>{{ order.ID_ORDER }}</span></section>
                        <section class="date_create_new_order">от &nbsp; {{ order.DATE_CREATE_NEW_ORDER }}</section>
                        <section class="name_production" :data-id-prod="order.ID">{{  order.NAME_PROD }}</section>
                        <section class="quantity">{{ order.NUMBER_OF_POSITIONS_FOR_EXECUTION }}</section>
                        <section class="arrow_open_close" :class="{'collapse': order.boolean_state_iframe}"></section>
                    </div>
                </div>

                <div class="wrapper_icon">
                    <v-icon
                            class="close_dialog_cross"
                            color="primary"
                            elevation="2"
                            @click="modal_detail_order.state = false"
                    >fas fa-times
                    </v-icon>
                </div>
            </div>
        </v-dialog>
    </v-app>
</template>

<script>
    // import DetailPageOrder2 from "../components/DetailPageOrder";

    export default {
        name: "OrderForNomenclature",
        components: {
            // 'detail-page-order33' : DetailPageOrder2,
        },
        props:['order', 'event'],
        data: () => ({
            modal_detail_order: {
                state: false,
            }
        }),
        watch:{
          event:{
              handler: function watch() {
                  this.open_modal();
              },
          }
        },
        methods:{
            open_modal(){
                if (!!this.order){
                    this.modal_detail_order.state = true;
                }
            }
        }

    }
</script>

<style lang="scss">
    #wrapper_icon_main_info{
        position: sticky;
        top: 0;
        z-index: 1;
        display: grid;
        grid-template-columns: auto auto;
        .wrapper_icon{
            background: #454444;
            border-bottom: 2px solid #212121;
            align-items: baseline;
            .close_dialog_cross{
                background: #454444;
                padding-top: 10px;
            }
        }
    }
</style>