<template>
  <div class="show_block">
    <div
      class="specification_card-block"
      @click="isShowBlock"
      style="cursor: pointer; user-select: none"
    >
      <span class="standard_title" v-if="nameBlock"
        >{{ nameBlock }}
        <span v-if="numberBlock.length">[ {{ numberBlock.length }} ]</span>
        <span v-if="numberBlockChild.length"
          >[ {{ getElementTypeDetail.length }} +
          {{ getElementTypeOperation.length }} ]</span
        ></span
      >

      <v-icon large color="grey lighten-1" :class="{ upDownChevron: !is_show }">
        mdi-chevron-up
      </v-icon>
    </div>
    <div v-show="is_show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    nameBlock: {
      type: String,
    },
    showBlock: {
      type: Boolean,
      default: false,
    },
    numberBlock: {
      type: Array,
      default() {
        return []
      },
    },
    numberBlockChild: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      is_show: false,
    }
  },
  computed: {
    ...mapGetters('specificationStore', [
      'getElementTypeOperation',
      'getElementTypeDetail',
    ]),
  },
  methods: {
    isShowBlock() {
      this.is_show = !this.is_show
      this.$emit('renderDrawing')
    },
  },
  watch: {
    showBlock: function (value) {
      this.is_show = value
    },
  },
  mounted() {
    this.is_show = this.showBlock
  },
}
</script>

<style lang="scss" scoped>
.specification_card-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #3b3b3b;
    border-radius: 4px;
  }
}
</style>
