<template>
  <v-dialog v-model="$store.state.simpleModal.state" max-width="600">
    <v-card>
      <v-card-title
        style="display: flex; justify-content: center; align-items: center"
      >
        <span class="text-h7" style="word-break: break-word">
          <p>{{ $store.state.simpleModal.message.text }}</p>
          <p>{{ $store.state.simpleModal.message.name }}</p>
        </span>
        <v-icon
            class="close_dialog_cross"
            color="primary"
            elevation="2"
            @click="hide_simple_modal"
        >fas fa-times
        </v-icon>
      </v-card-title>
      <v-card-actions>
        <v-btn block color="grey" @click="hide_simple_modal"> Закрыть </v-btn>
        <v-btn block color="error" @click="accept_event_simple_modal">Ок</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<!--

-->

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'SimpleModal',
  methods: {
    ...mapActions([]),
    ...mapMutations(['hide_simple_modal', 'accept_event_simple_modal']),
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/main';

.v-card{
  background: $background_active_tabs;
  color: white;
}
.v-card__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  padding: 16px 24px !important;
  .v-btn {
    max-width: 50px;
  }
}
</style>
