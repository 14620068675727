<template>
  <div class="specification_card__tags_zone">
    <div class="specification_card__tags_zone__header">
      <span class="standard_title">Хештеги:</span>
    </div>
    <div class="specification_card__tags_zone__wrapper"></div>
    <div class="specification_card-chips">
      <v-combobox
        chips
        v-model="elementValueLocal"
        :items="elementChips"
        :clearable="closeChip"
        label="Хештеги"
        multiple
        solo
        :readonly="elementReadonly"
      >
        <template v-slot:prepend-item>
          <section style="margin-left: 55px">Хэштеги</section>
          <hr style="margin: 10px 0" />
        </template>
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            color
            :close="closeChip"
            @click="select"
            @click:close="remove(item)"
          >
            <strong>{{ item }}</strong
            >&nbsp;
          </v-chip>
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    elementValue: { type: Array, default: () => [] },
    elementReadonly: { type: Boolean, default: false },
    closeChip: { type: Boolean, default: true },
    elementChips: { type: Array },
  },
  data() {
    return {
      chips: [],
      // colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    }
  },
  computed: {
    elementValueLocal: {
      get: function () {
        return this.elementValue
      },
      set: function (value) {
        this.createNewElement({
          name: 'HASHTAGS',
          event: value,
        })
      },
    },
  },
  methods: {
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
      removeChip: 'REMOVE_CHIP',
    }),

    remove(item) {
      this.removeChip(item)
    },
  },
}
</script>

<style lang="scss">
.v-autocomplete__content {
  border-radius: 4px !important;
}

.v-list-item .v-list-item__content .v-list-item__title {
  text-align: left;
}

.specification_card-chips {
  .v-input__slot {
    margin: 0;
  }
}
</style>
