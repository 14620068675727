<template>
  <div>
    <div id="ListRemains">
        <div class="wrapper_filters" v-show="$store.state.state_block_filters">
            <v-text-field class="filter_name_prod"
                    v-model="$store.state.remains_on_store.data_filters.name_product"
                    label="Фильтр по наименованию"
                    solo dense hide-details
            ></v-text-field>
        </div>

        <div class="wrapper_list_remains">
            <div class="row_remains"
                 v-if="$store.state.remains_on_store.list_remains_on_store"
                 v-for="product in $store.state.remains_on_store.list_remains_on_store"
                 :key="product.ID"
            >
                <img class="image" :src="$store.state.VUE_DEV_LOCAL_SERVER+product.PICTURE.SRC" :title="product.PICTURE.TITLE">
                <section class="wrapper_name_articul">
                    <a @click="open_dialog_nomenclature(product.ID)" class="href_to_nomenclature">
                        <section class="name_prod" :data-id-prod="product.ID">{{  product.NAME.SHORT_NAME }}</section>
                    </a>
                    <section class="articul">
                        Артикул: <br> {{ product.ARTICUL }}
                    </section>
                </section>
                <v-text-field
                        class="item EDITABLE_QUANTITY"
                        v-model.number="product.QUANTITY_PRODUCT_MANUALLY__GIDRUSS"
                        @input="update_value_quantity(product)"
                        type="number"
                        :rules="rules.number"
                        :color="product.DATA_MESSAGE_NOTIFICATION.color"
                        :messages="product.DATA_MESSAGE_NOTIFICATION.message"
                >
                </v-text-field>
            </div>

<!--            <v-skeleton-loader-->
<!--                width="100%"-->
<!--                class="mx-auto"-->
<!--                type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"-->
<!--                v-if="loading_order"-->
<!--            ></v-skeleton-loader>-->
            <v-progress-circular
                style="margin: auto;"
                width="3"
                id="progress_circular_list_order"
                color="green"
                :indeterminate="true"
                v-if="$store.state.state_progress_circular_page"
            ></v-progress-circular>
        </div>

        <v-btn
            class="show_more"
            block
            v-if="!$store.state.state_progress_circular_page"
            @click='() => { $store.state.remains_on_store.count_data.NUM_PAGE++; $store.dispatch("getListRemains") }'
            :disabled="$store.state.remains_on_store.count_data.RECORDS_TO_END <= 0"
        >
          Показать ещё {{ $store.state.remains_on_store.count_data.RECORDS_TO_END }}
        </v-btn>

        <!-- Диалоговое окно с номенклатурой -->
        <v-dialog
                v-model="dialog_nomenclature_window.state"
                max-width="600px"
                content-class="dialog_nomenclature"
        >
            <v-card id="v_dialog_nomenclature_window">
                <v-card-title>
                    <span class="headline">Номенклатура</span>
                    <v-icon
                            class="close_dialog_cross"
                            color="primary"
                            elevation="2"
                            @click="dialog_nomenclature_window.state = false"
                    >fas fa-times
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <nomenclature :dialog_id_order="dialog_nomenclature_window.id_order"></nomenclature>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
  </div>
</template>

<script>
    import Nomenclature from "./nomenclature/Nomenclature";

    export default {
        components: {
          Nomenclature,
        },
        name: "ListRemains",
        data: () => ({
            dialog_nomenclature_window: {
                state: false,
                id_order: null,
            },
            rules: {
                number: [
                    value => (parseInt(value) >= 0) || 'Должно быть положительным числом'
                ],
            },
        }),
        watch:{
            /* Смотрим за фильтрами чтобы отфильтровать отпагинировать */
            '$store.state.remains_on_store.data_filters.name_product': {
              handler: function watch() {
                if (this.$store.state.debounceTimeout) clearTimeout(this.$store.state.debounceTimeout);
                this.$store.state.debounceTimeout = setTimeout(() => {
                  this.$store.state.remains_on_store.count_data.NUM_PAGE = 1;
                  this.$store.dispatch("getListRemains");
                }, 1000);
              },
            },
        },
        computed:{},
        mounted(){
          this.$store.dispatch("getListRemains");
        },
        methods: {
            async update_value_quantity(product){
                // console.log('product');
                // console.log(product);
              if (this.$store.state.debounceTimeout) clearTimeout(this.$store.state.debounceTimeout);
              this.$store.state.debounceTimeout = setTimeout(() => {

                if (product.QUANTITY_PRODUCT_MANUALLY__GIDRUSS < 0 || product.QUANTITY_PRODUCT_MANUALLY__GIDRUSS === '') {
                  product.QUANTITY_PRODUCT_MANUALLY__GIDRUSS = 0
                }
                else {
                  const _this = this;
                  /* Создаем новую запись  */
                  BX.ajax.runComponentAction('nk:production.block_nomenclature',
                      'executeUpdateQuantityProducts', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                          post: {
                            id_product: product.ID,
                            quantity_product: product.QUANTITY_PRODUCT_MANUALLY__GIDRUSS
                          },
                          'login': _this.$store.state.login,
                          'token': _this.$store.state.token,
                        },
                      })
                      .then(function (response) {
                        console.log('update_value_quantity');
                        console.log(response);
                        if (response.status === 'success') {
                          product.DATA_MESSAGE_NOTIFICATION = _this.$store.getters.getObjectMessageColorForCode('success');
                        }
                        else{
                          product.DATA_MESSAGE_NOTIFICATION = _this.$store.getters.getObjectMessageColorForCode('error');
                        }
                        _this.$store.dispatch('setSystemNotificationResult', response.data);
                      })
                      .catch((response) => {
                        console.log(response);
                        product.DATA_MESSAGE_NOTIFICATION = _this.$store.getters.getObjectMessageColorForCode('error');
                        _this.$store.dispatch('setSystemNotificationResult', response);
                      });
                }

              }, 1000);

            },
            open_dialog_nomenclature(order_id){
                this.dialog_nomenclature_window.id_order = order_id;
                this.dialog_nomenclature_window.state = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .v-application {
        font-family: inherit;
        a{
            color: unset !important;
        }
    }
    #ListRemains{
      margin-bottom: 20px;
        .wrapper_filters{
            top: 0;
            padding: 15px 15px;
            background: darkgrey;
            z-index: 1;
            .v-text-field__details{
                display: none;
            }
        }
        .wrapper_list_remains{
            /*padding: 0 15px;*/
            display: grid;
            grid-row-gap: 1em;
            .row_remains{
                display: grid;
                grid-template-columns: 1fr 2fr 20%;
                grid-column-gap: 1em;
                align-items: center;
                background: #454444;
                padding: 10px 15px;
                .wrapper_name_articul{
                    text-align: left;
                    display: grid;
                    grid-row-gap: 10px;
                    align-content: baseline;
                    color: white;
                    .name_prod{
                        color: lightgrey;
                        cursor: pointer;
                    }
                    .articul{
                        color: white;
                        font-style: italic;
                        font-weight: lighter;
                        justify-content: flex-start;
                    }
                }
                img{
                    width: 100%;
                    height: auto;
                    max-width: 150px;
                    max-height: 150px;
                    margin: auto;
                    object-fit: contain;
                    border-radius: 10px;
                }
            }
        }
    }

</style>
