<template>
  <div class="preview_child">
    <div class="preview_child__img">
      <img
        :src="$store.state.VUE_DEV_LOCAL_SERVER+imgSRC"
        :title="title"
        @error="replaceByDefault"
      >
    </div>
    <div class="preview_child__info">
      <span class="preview_child__info__name">
        {{ data_elem.NAME }}
      </span>
      <span class="preview_child__info__kip">
        {{ data_elem.DRAWING_NUMBER }}
      </span>
    </div>
    <div class="preview_child__btn">
      <v-btn
          color="primary"
          class="text-capitalize"
          small
          @click="setData()"
          :disabled="!$store.state.edit_fields"
      >Выбрать элемент</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewChildSpecification",
  props: ['data_elem'],
  data: () => ({

  }),
  computed: {
    title() {
      return this.data_elem?.NAME ? this.data_elem.NAME : ''
    },
    imgSRC() {
      return this.data_elem?.PREVIEW_PICTURE_PATH?.SRC ? this.data_elem.PREVIEW_PICTURE_PATH.SRC : ''
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    setData() {
      this.$emit('setData', this.data_elem)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/previewSpecification";
</style>
