<template>
  <div class="status">
    <v-expansion-panels v-model="state_content">
      <v-expansion-panel>
        <v-expansion-panel-content
          id="expansion-panel-content"
          eager
          v-if="status_nomenclature.length"
        >
          <div class="status__wrapper_header">
            <section class="status__wrapper_header__header">Журнал текущей работы</section>
            <v-icon
                class="status__wrapper_header__close"
                color="grey lighten-1"
                @click="state_content = 1"
            >
              mdi-close-circle
            </v-icon>
          </div>
          <hr>
          <v-timeline class="status__wrapper__timeline">
            <v-timeline-item
              small
              v-for="(obj, key) in [...status_nomenclature].reverse()"
              :key="key"
              :color="obj.color"
              :left="obj.left"
              :right="obj.right"
              fill-dot
              class="status__timeline__item"
              :class="{ last_item: obj.last_item }"
            >
              <v-card :color="obj.color" dark>
                <v-card-title class="status__timeline__item__card_title">
                  <span>{{ obj.short_text }}</span>
                  <span>{{ obj.detail_text }}</span>
                  <div v-if="obj.arr_errors.length" v-for="error in obj.arr_errors"
                       class="status__timeline__item__card_title__wrapper_errors"
                  >
                    <span>-</span>
                    <span class="status__timeline__item__card_title__wrapper_errors__error">
                      {{ error }}
                    </span>
                  </div>
                  <span class="status__timeline__item__card_title__time">{{ obj.time }}</span>
                </v-card-title>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>

        <v-expansion-panel-header>
          <section v-if="status_nomenclature.length" class="status__timeline__section_wrapper">
            <span :class="getLastItem.color + '--text'" v-html="getLastItem.short_text"></span>
            <span :class="getLastItem.color + '--text'" v-html="getLastItem.detail_text"></span>
          </section>
          <span v-else style="text-align: center">Журнал текущей работы</span>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'StatusLogger',
  data: () => ({
    state_content: 1,
  }),
  computed: {
    ...mapState('specificationStore', ['status_nomenclature']),
    ...mapGetters('specificationStore', []),
    getLastItem() {
      return this.status_nomenclature[this.status_nomenclature.length - 1]
    },
  },
  methods: {
    changeScrollToExpansionPanel() {
      var d = document.getElementById('expansion-panel-content')
      if (d) {
        d.scrollTop = d.scrollHeight
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  &__wrapper {
    display: grid;
    grid-row-gap: 5px;

    &__row {
      border-radius: 5px;
      &.error,
      &.critical {
        background-color: rgb(255 82 82 / 50%) !important;
        border-color: rgb(255 82 82 / 50%) !important;
      }
      &.info {
        background-color: rgba(0, 0, 255, 50%) !important;
        border-color: rgba(0, 0, 255, 50%) !important;
      }
      &.warning {
        background-color: rgba(255, 255, 0, 0.99) !important;
        border-color: rgba(255, 255, 0, 0.99) !important;
      }
    }
  }

  .v-expansion-panel-header{
    padding: 9px 24px;
    min-height: 48px;
    font-size: 13px!important;
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    //min-height: unset !important;
  }
  .v-expansion-panel-content {
    position: absolute;
    bottom: 50px;
    background: white;
    width: 100%;
    padding: 10px;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 4px;
  }

  &__timeline {
    padding-bottom: 15px;
    margin-top: 5px;
    &__item {
      padding: 5px;
      &.last_item {
        background: lightgrey;
      }
      &__card_title {
        font-size: 0.8em;
        padding: 5px;
        display: grid;
        line-height: unset;
        word-break: break-word;
        &__time{
          margin-top: 5px;
          border-top: 1px solid beige;
          padding-top: 5px;
        }
        &__wrapper_errors{
          display: inline-flex;
          align-items: baseline;
          padding: 5px;
          grid-column-gap: 5px;
          &__error{
            text-align: left;
            padding: 5px;
            word-break: break-word;
            color: beige;
          }
        }
      }

      &__card_text {
        font-size: 0.8em;
        padding: 5px;
      }
    }
    &__section_wrapper{
      display: grid;
      //justify-content: center;
    }
  }
  &__wrapper_header {
    width: 100%;
    position: relative;
    &__header {
      margin: 5px;
      font-size: 0.9em;
    }
    &__close{
      position: absolute!important;
      top: 0;
      right: 0;
    }
  }
}
</style>
