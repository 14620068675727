<template>
  <div class="specification_card_block">
    <div class="par_search">
      <div class="search_nom search_result">
        <DrawingNumber
          :elementView="'input'"
          :elementValue="newElement.DRAWING_NUMBER"
          :elementPlaceholder="'Чертёжный номер нового элемента'"
          @eventChange="(data) => eventDataFunction(data, 'DRAWING_NUMBER')"
        />
        <NameNomenclature
          :elementView="'input'"
          :elementValue="newElement.NAME"
          :elementTitle="false"
          @eventChange="(data) => eventDataFunction(data, 'NAME')"
        />
      </div>
    </div>
    <div class="margin_blocks">
      <show-block :nameBlock="'Чертежи'" :showBlock="true">
        <Drawings
          @eventChange="(data) => eventDataFunction(data, 'DRAWINGS')"
          :drawings="newElement.DRAWINGS"
      /></show-block>

      <show-block :nameBlock="'Спецификация'" :showBlock="true">
        <div class="show_block-content">
          <ChildElement />
        </div>
      </show-block>

      <show-block :nameBlock="'Номенклатура'">
        <div class="show_block-content">
          <RealNomenclature />
        </div>
      </show-block>

      <show-block :nameBlock="'Параметры'">
        <div class="show_block-content show_block-content_params">
          <TypeSpecification
            @eventChange="(data) => eventDataFunction(data, 'TYPE')"
          />
          <CommodityItem />
          <Note @eventChange="(data) => eventDataFunction(data, 'NOTE')" />

          <Chips
            @eventChange="(data) => eventDataFunction(data, 'HASHTAGS')"
            :elementChips="allHashtags"
          />
        </div>
      </show-block>
    </div>

    <v-dialog dark v-model="modalCreate" max-width="600">
      <v-card>
        <v-card-title
          style="display: flex; justify-content: center; align-items: center"
        >
          <span class="text-h7" style="word-break: break-word"
            >Покинуть текущую страницу?
            <p>Все текущие изменения будут утеряны</p></span
          >
        </v-card-title>
        <v-card-actions>
          <v-btn block color="grey" @click="modalCreate = false">
            Отмена
          </v-btn>
          <v-btn block color="primary" @click="confirmationTransition()"
            >Ок</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

import DrawingNumber from '@/components/specification/DrawingNumber.vue'
import NameNomenclature from '@/components/specification/NameNomenclature.vue'
import Drawings from '@/components/specification/Drawings.vue'
import Note from '@/components/specification/Note.vue'
import RealNomenclature from '@/components/specification/RealNomenclature.vue'
import Chips from '../../components/specification/Chips.vue'
import TypeSpecification from '../../components/specification/TypeSpecification.vue'
import ChildElement from '../../components/specification/ChildElement.vue'
import ShowBlock from '../../components/specification/ShowBlock.vue'
import CommodityItem from '../../components/specification/CommodityItem.vue'

export default {
  name: 'SpecificationCreate',
  beforeRouteLeave(to, from, next) {
    if (this.confirmActive) {
      next()
      return
    }
    if (this.checkValueComp) {
      this.nextPage = next
      this.modalCreate = true
    } else {
      next()
      return
    }
    console.log(this.checkValueComp)
  },
  components: {
    DrawingNumber,
    NameNomenclature,
    Drawings,
    Note,
    RealNomenclature,
    Chips,
    TypeSpecification,
    ChildElement,
    ShowBlock,
    CommodityItem,
  },
  data() {
    return {
      modalCreate: false,
      nextPage: '',
    }
  },
  computed: {
    ...mapGetters('specificationStore', []),
    ...mapState('specificationStore', [
      'allHashtags',
      'newElement',
      'confirmActive',
    ]),

    checkValueComp() {
      let obj = {}
      for (let key in this.newElement) {
        obj[key] = this.newElement[key]
      }
      delete obj.TYPE

      for (let key in JSON.parse(JSON.stringify(obj))) {
        console.log(typeof obj[key], key)
        if (obj[key] !== null && obj[key].length) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions('specificationStore', ['getAllHashtags']),
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
      confirmActivated: 'CONFIRM_ACTIVE',
    }),

    eventDataFunction(data, name) {
      this.createNewElement({
        name: name,
        event: data,
      })
    },

    confirmationTransition() {
      this.modalCreate = false
      this.nextPage()
    },

    // checkValue() {
    //   let obj = this.newElement
    //   delete obj

    //   for (let key in obj) {
    //     // console.log(this.newElement[key], key)
    //     if (obj[key].length || obj[key].trim() !== '') {
    //       console.log(key, 'keyeyeyeeyyeyyyyyeeyeyeyyey')
    //     }
    //   }
    // },
  },
  mounted() {
    this.getAllHashtags()
    this.confirmActivated()
    // this.checkValue()
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/main';
@import 'src/assets/sass/specification';
@import 'src/assets/sass/detailSpecification';

.par_search {
  position: fixed !important;
  top: 50px;
  z-index: 995;
  padding-top: 8px;
  background: #363636;
  height: 64px;
  z-index: 997;
}
</style>
