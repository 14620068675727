<template>
  <div class="specification_card__header__title">
    <span class="standard_title"> {{ elementTitle }} </span>
    <div class="specification_card__header__title__wrapper">
      <v-text-field
        v-if="elementView === 'input'"
        label="Чертёжный номер нового элемента"
        :placeholder="elementPlaceholder"
        :name="elementName"
        ref="drawingNumberInput"
        @input="handleChange($event)"
        hide-details="auto"
        solo
        dense
        clearable
        :disabled="elementDisabled"
        :value="elementValue"
        :class="elementValue ? 'drawing_hint_success' : 'drawing_hint_error'"
        :hint="elementValue ? 'Чертежный номер' : 'Заполните чертежный номер'"
        :persistent-hint="elementValue && $route.name !== 'SpecificationView'"
      ></v-text-field>
      <v-combobox
        :loading="loadingItems"
        v-if="elementView === 'select'"
        :items="itemsSelect"
        :value="elementValue"
        :item-text="(item) => item.NAME"
        :search-input.sync="search"
        :class="'drawing_hint_error'"
        :hint="hintShow ? 'Ничего не выбрано' : ''"
        :persistent-hint="hintShow"
        solo
        dense
        clearable
        ref="comboboxNumberInput"
        @click:clear="clearFunc()"
        :placeholder="elementPlaceholder"
        aria-autocomplete="both"
        :disabled="elementDisabled"
        @change="handleChangeSelect($event)"
        :error-messages="elementErrMessage"
        :menu-props="{ top: false, maxHeight: 494, maxWidth: 742 }"
      >
        <template v-slot:no-data v-if="!btnCreate">
          <button @click="goToCreate">
            &lt;&lt; Создать новую виртуальную номенклатуру "{{ search }}"
            &gt;&gt;
          </button></template
        >

        <template v-slot:item="data">
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid lightgrey;
              padding: 5px 0;
            "
          >
            <div
              style="
                width: 60%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
              "
            >
              {{ data.item.NAME }}
            </div>
            <div
              style="
                width: 40%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
              "
            >
              {{ data.item.DRAWING_NUMBER ||
            (data.item.INTERNAL_ARTICLE == data.item.ARTICUL
                ? data.item.INTERNAL_ARTICLE
                : (data.item.INTERNAL_ARTICLE
                    ? data.item.INTERNAL_ARTICLE
                    : (data.item.ARTICUL ? data.item.ARTICUL :'')))
              }} {{ data.item.DRAWING_NUMBER ||
                data.item.INTERNAL_ARTICLE == data.item.ARTICUL
                    ? ''
                    :   (data.item.INTERNAL_ARTICLE ? ' (' + data.item.ARTICUL  + ")": '')
              }}
            </div>
          </div>
        </template>
      </v-combobox>
      <header-icons v-if="is_in_header"></header-icons>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import HeaderIcons from '@/components/specification/HeaderIcons'
export default {
  name: 'DrawingNumber',
  components: { HeaderIcons },
  props: {
    elementView: { type: String },
    elementDisabled: { type: Boolean },
    elementValue: { type: String },
    elementTitle: { type: String },
    elementPlaceholder: { type: String },
    itemsSelect: { type: Array },
    elementName: {
      type: String,
      default: 'DRAWING_NUMBER_NEW',
    },
    is_in_header: {
      type: Boolean,
    },
    elementErrMessage: { type: String },
    btnCreate: { type: Boolean, default: true },
    hintShow: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingItems: false,
      itemsActive: '',
      searchText: '',
      debounce: null,
      search: this.elementValue ? this.elementValue : '',
      rules: [
        (value) =>
          /\S/.test(value) && value !== null && !!value
            ? (this.validate = true)
            : (this.validate = false) || 'Заполните строку.',
      ],
      validate: false,
    }
  },
  methods: {
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
      handleSelectedRealNomenclature: 'HANDLE_SELECTED_REAL_NOMENCLATUE',
      clearForm: 'CLEAR_FORM',
      clearConcrete: 'CLEAR_CONCRETE',
    }),

    clearFunc() {
      // "clearableCallback" - Vuetify метод очистки поля text-field combobox etc
      // https://stackoverflow.com/questions/52573962/vuetify-how-to-trigger-method-when-clear-a-v-text-field
      this.$refs.comboboxNumberInput.clearableCallback()
      this.$emit('eventClear')
    },

    handleChange(event) {
      this.$emit('eventChange', event)
    },

    handleChangeSelect(event) {
      this.$emit('eventData', event)
    },

    goToCreate() {
      this.$router.push({ name: 'SpecificationCreate' }).catch((error) => {})
    },
  },
  watch: {
    search: function (val) {
      this.loadingItems = true
      this.searchText = null
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchText = val
        this.$emit('eventChange', this.searchText)
        this.loadingItems = false
      }, 500)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/main';
@import 'src/assets/sass/specification';
@import 'src/assets/sass/detailSpecification';
</style>
