<template>
  <div class="specification_card__dropzone">
    <template>
      <div class="text-center show_block-content">
        <div v-if="!drawings.length" style="color: darkgrey">Чертежей нет</div>
        <v-dialog
          v-else
          v-model="dialog"
          width="900"
          style="z-index: 997"
          content-class="drawing-card_dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-item-group class="items_group" v-model="onboarding">
              <v-item
                v-for="(drawing, index) in drawings"
                :key="drawing.ID ? drawing.ID : drawing.UUID"
                v-slot="{ active, toggle }"
                style="
                  width: 200px;
                  height: 282px;
                  position: relative;
                  overflow: hidden;
                "
              >
                <div v-bind="attrs" v-on="on" @click="toggle">
                  <pdf
                    v-for="i in 2"
                    :key="i"
                    :page="i"
                    :src="
                      drawing.DATA_URL
                        ? drawing.DATA_URL
                        : $store.state.VUE_DEV_LOCAL_SERVER + drawing.SRC
                    "
                  ></pdf>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="pdf-name" v-bind="attrs" v-on="on">
                        {{ drawing.ORIGINAL_NAME }}
                      </div>
                    </template>
                    <span>{{ drawing.ORIGINAL_NAME }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!is_enabled">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="pdf-basket"
                        @click.stop="removedFile(drawing)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="#ff6666">mdi-trash-can</v-icon>
                      </div>
                    </template>
                    <span>Удалить чертеж</span>
                  </v-tooltip>
                </div>
              </v-item>
            </v-item-group>
          </template>

          <v-card>
            <v-carousel
              :continuous="false"
              :cycle="cycle"
              :show-arrows="false"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
              class="carousel-dialog_pdf"
              style="max-width: 900px"
              v-model="onboarding"
            >
              <v-carousel-item
                class="carousel-dialog"
                v-for="(drawing, index) in drawings"
                :key="index"
              >
                <v-card-title class="dialog-pdf_title">
                  <div class="dialog-pdf_title-name">
                    {{ drawing.ORIGINAL_NAME }}
                  </div>
                  <v-icon
                    class="drawing-icon_close"
                    @click="dialog = false"
                    color="black"
                    large
                    >mdi-close-circle</v-icon
                  >
                </v-card-title>
                <div
                  v-dragscroll="true"
                  @dragscrollmove="dragPdf = true"
                  @dragscrollend="dragPdf = false"
                  style="height: 100%; overflow: scroll; padding-bottom: 104px"
                  :style="{
                    cursor: dragPdf ? 'grabbing' : 'grab',
                  }"
                >
                  <pdf
                    style="margin-top: 48px; transform-origin: 0 0"
                    v-for="i in drawing.PAGE_COUNT"
                    :key="i"
                    :page="i"
                    :style="{
                      transform: `scale(${drawingScale})`,
                    }"
                    :src="
                      drawing.DATA_URL
                        ? drawing.DATA_URL
                        : $store.state.VUE_DEV_LOCAL_SERVER + drawing.SRC
                    "
                  ></pdf>
                </div>
              </v-carousel-item>
            </v-carousel>
            <v-card-actions class="justify-center" style="margin-bottom: 28px">
              <v-btn @click="prev">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn @click="next">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn @click="zoomIn">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn @click="zoomOut">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div
        class="specification_card__dropzone__wrapper"
        v-if="!is_enabled"
        style="margin-top: 12px"
      >
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :class="{ dz_height: drawings.length }"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-file-added="uploadedData"
          @vdropzone-success="vsuccess"
        >
          <h3 class="dropzone-custom-title">
            <v-icon
              size="150"
              color="grey lighten-1"
              style="transform: rotate(45deg)"
            >
              mdi-paperclip
            </v-icon>
          </h3>
          <div class="subtitle" style="color: darkgrey">
            Для вставки чертежа перетащите файл в зону или нажмите на скрепку
          </div>
        </vue-dropzone>
      </div>
    </template>

    <span v-show="error" class="error_drop">Неправильный формат чертежа</span>
    <span v-show="success" class="info_drop">Успешно загружено</span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'Drawings',
  directives: {
    dragscroll,
  },
  props: {
    drawings: {
      type: Array,
      default() {
        return []
      },
    },
    is_enabled: Boolean,
    elementTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    pdf,
  },
  data: function () {
    return {
      dialog: false,
      dragPdf: false,
      drawingScale: 1,
      isLoading: true,
      onboarding: 0,
      numPages: undefined,
      dropzoneOptions: {
        url:
          this.$store.state.VUE_DEV_LOCAL_SERVER +
          '/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.FileController.GetBase64FromFiles',
        thumbnailWidth: 150,
        maxFilesize: 50,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        destroyDropzone: false,
        thumbnailHeight: 289,
      },
      // is_show: true,
      error: false,
      success: false,
      format: ['png', 'jpg', 'jpeg', 'pdf'],
    }
  },
  methods: {
    ...mapMutations('specificationStore', {
      removedDrawing: 'REMOVED_DRAWING',
      // getPages: 'GET_PAGES',
    }),

    loadedPdf(pagePdf) {
      let loadingTask = pdf.createLoadingTask(
        this.$store.state.VUE_DEV_LOCAL_SERVER + pagePdf,
      )
      loadingTask.promise.then((pdf) => {
        console.log(pdf)
        this.numPages = pdf.numPages
      })
      console.log('loadadadadaddada', pagePdf)
    },

    vmounted() {
      this.$refs.myVueDropzone.removeAllFiles()
    },

    vsuccess(file, response) {
      console.log(response)
      this.$emit('eventChange', {
        ORIGINAL_NAME: response.data.data.file.name,
        DATA_URL: response.data.data.file.dataURL,
        UUID: file.upload.uuid,
      })
      this.$refs.myVueDropzone.removeFile(file)

      if (response.status === 'success') {
        this.success = true
        setTimeout(() => {
          this.success = false
        }, 1500)
      }
    },

    removedFile(file) {
      this.removedDrawing(file)
    },

    uploadedData(file) {
      if (file.type !== undefined) {
        let formatFile = file.type.split('/')[1]
        if (!this.format.includes(formatFile)) {
          this.error = true
          this.$refs.myVueDropzone.removeFile(file)

          setTimeout(() => {
            this.error = false
          }, 1500)
          return
        }
        this.error = false
        return
      }
      return
    },

    setDropzone() {
      this.drawings.forEach((d) => {
        let file = {
          name: d.ORIGINAL_NAME,
          type: d.CONTENT_TYPE,
          size: d.FILE_SIZE,
        }
        let url = this.$store.state.VUE_DEV_LOCAL_SERVER + d.SRC
        this.$refs.myVueDropzone.manuallyAddFile(file, url)
      })
    },

    next() {
      this.onboarding =
        this.onboarding + 1 === this.drawings.length ? 0 : this.onboarding + 1
      this.drawingScale = 1
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.drawings.length - 1 : this.onboarding - 1
      this.drawingScale = 1
    },

    progressPdf(load) {
      console.log(load)
    },

    isLoadingDrawings() {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },

    zoomIn() {
      this.drawingScale = this.drawingScale + 0.3
    },
    zoomOut() {
      this.drawingScale === 1
        ? this.drawingScale
        : (this.drawingScale = this.drawingScale - 0.3)
    },

    checkKey(e) {
      e = e || window.event
      // up arrow
      if (e.keyCode == '38') {
      }
      // down arrow
      else if (e.keyCode == '40') {
      }
      // left arrow
      else if (e.keyCode == '37') {
        e.preventDefault()
        this.prev()
      }
      // right arrow
      else if (e.keyCode == '39') {
        e.preventDefault()
        this.next()
      }
      //esc
      else if (e.keyCode === 27) {
        this.dialog = false
      }
    },

    // getNumPages() {
    //   for (let i = 0; i < this.drawings.length; i++) {
    //     let loadingTask = pdf.createLoadingTask(
    //       this.$store.state.VUE_DEV_LOCAL_SERVER + this.drawings[i].SRC,
    //     )
    //     loadingTask.promise.then((pdf) => {
    //       let infoPdf = {
    //         index: i,
    //         countPages: pdf.numPages,
    //       }
    //       this.getPages(infoPdf)
    //     })
    //   }
    // },
  },
  watch: {
    drawings: {
      handler: function () {
        this.isLoadingDrawings()
        this.getNumPages()
      },

      immediate: true,
      deep: true,
    },
    dialog: {
      handler: function (value) {
        this.drawingScale = 1
        if (value == false) {
          this.onboarding = 0
        }
      },
    },
  },
  computed: {
    isDrawings() {
      return this.drawings
    },
  },
  mounted() {
    document.onkeydown = this.checkKey
  },
}
</script>

<style scoped lang="scss">
.viewer,
#dropzone {
  justify-content: center;
  padding: 10px 0 10px 0;
  &.dz-drag-hover {
    background: white;
  }
}
.dropzone__header {
  justify-content: flex-end;
}
.disable_dropzone {
  pointer-events: none;
  cursor: default;
}
.error_drop {
  color: #ff5252;
  font-size: 16px;
}
.info_drop {
  color: green;
  font-size: 16px;
}

.dz_height {
  height: 200px;
}

.pdf-name {
  color: white;
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0, 0.7);
  width: 100%;
  height: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}

.pdf-basket {
  width: 30px;
  height: 30px;
  color: white;
  position: absolute;
  top: 0;
  background: rgb(0, 0, 0, 0.7);
  right: 0;
}

.items_group {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: center;
  margin-bottom: 10px;
  max-height: 580px;
  overflow: scroll;
}

.v-card__actions {
  display: block;
}

.drawing-icon_close {
  display: flex;
  justify-content: flex-end;
}
.dialog-pdf_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 6px;
  background: #d1d1d1;
  color: #000000;
  position: fixed;
  width: 100%;
  max-width: inherit;
  z-index: 1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  &-name {
    margin: auto;
    font-size: 18px;
  }
}

.v-carousel__controls {
  display: none;
}

.v-responsive__content {
  overflow: scroll !important;
}

.v-card__actions {
  border-top: 1px solid;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .drawing-icon_close {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
  }
}
</style>
