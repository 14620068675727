<template>
  <div class="specification_card">

    <div class="specification_card__header">
      <div class="specification_card__header__title">
        <span class="standard_title">
          Обозначение:
        </span>
        <div class="specification_card__header__title__wrapper">
          <v-combobox
              solo
              dense
              clearable
              :hide-details="!isErrorDN"
              placeholder="Обозначение нового элемента"
              :class="[this.virtual_nomenclature.name !==null && this.virtual_nomenclature.name.DRAWING_NUMBER===data_instance.drawing_numberSearch  ?
                'specification_card__header__title__wrapper__input drawing_number__header__input__bold_green' :
                'specification_card__header__title__wrapper__input']"
              v-model="virtual_nomenclature.drawing_number"
              :loading="loading_list"
              :items="data_by_drawing_number"
              item-text="DRAWING_NUMBER"
              :search-input.sync="data_instance.drawing_numberSearch"
              @update:search-input="getItemsFromDrawingNumber()"
              @click:clear="clearSearchInHeader()"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
              @change="onSelect('drawing_number')"
              :error="isErrorDN"
              :error-messages="isErrorDN ? 'Не заполнены пустые поля' : []"
              aria-autocomplete="inline"
          >
            <template v-slot:prepend-item >
              <v-list-item
                  aria-selected="false"
                  tabindex="0"
                  role="option"
              >
                <v-list-item-content>
                  <v-list-item>
                    <v-list-item-title @click="prepareToCreate()" v-if="data_instance.drawing_numberSearch">
                      <pre><i>&lt;&lt; Создать &#34;{{ data_instance.drawing_numberSearch }}&#34;... &gt;&gt;</i></pre>
                    </v-list-item-title>
                    <v-list-item-title @click="prepareToCreate()" v-else>
                      <pre><i>&lt;&lt; Создать новый элемент... &gt;&gt;</i></pre>
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <div class="specification_card__header__title__wrapper__icons">
            <v-icon large color="grey lighten-1" :disabled="loading_list || !currentId" @click="goToTree()">
              mdi-pine-tree
            </v-icon>
<!--            <v-tooltip bottom>-->
<!--&lt;!&ndash;              <dialog-question v-model="this.$store.state.dialog_question.state_dialog" ></dialog-question>&ndash;&gt;-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-icon-->
<!--                    large-->
<!--                    color="grey lighten-1"-->
<!--                    :disabled="loading_list || !currentId"-->
<!--                    @click="show_dialog_enable_edit_fields = true"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                >-->
<!--                  mdi-plus-thick-->
<!--                </v-icon>-->
<!--              </template>-->
<!--              <span>Создать новый элемент</span>-->
<!--&lt;!&ndash;              <v-btn @click="dialog = true">Open</v-btn>&ndash;&gt;-->

<!--&lt;!&ndash;              <button @click="dialog=true">Open Dialog</button>&ndash;&gt;-->

<!--            </v-tooltip>-->
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    large
                    :color="!$store.state.edit_fields ? 'grey lighten-1' : 'green lighten-1'"
                    :disabled="loading_list || !currentId"
                    @click="setEdit()"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Редактировать спецификацию</span>
            </v-tooltip>
            <v-icon large color="grey lighten-1" :disabled="loading_list">
              fa-solid fa-file-pdf
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    large color="grey lighten-1"
                    :disabled="!currentId || loading_list"
                    @click="print_layout_nomenclature = true"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-printer
                </v-icon>
              </template>
              <span>Отправить на печать</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="specification_card__header__name">
        <span class="standard_title">Наименование:</span>
        <!--      @update:search-input="getItemsFromName()"-->
        <!-- Отключим поиск, так как ломает логику создания-->
        <v-combobox
            solo
            dense
            ref="myComboBox"
            :hide-details="!isErrorName"
            clearable
            placeholder="Наименование нового элемента"
            class="specification_card__header__name__input"
            v-model="virtual_nomenclature.name"
            :loading="loading_list"
            :items="data_by_name"
            item-text="NAME"
            :search-input.sync="data_instance.nameSearch"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
            @change="onSelect('name')"
            @click:clear="clearSearchInHeader()"
            :error="isErrorName"
            :error-messages="isErrorName ? 'Не заполнены пустые поля' : []"
        ></v-combobox>
      </div>
      <div class="specification_card__header__name" v-if="newItemNotification">
        <span class="standard_title">Тип:</span>
        <div class="radio_group">
          <v-radio-group
              dense
              dark
              v-model="selected_type"
              class="radio-buttons"
              :hide-details="!isErrorType"
              :error="isErrorType"
              :error-messages="isErrorType ? 'Не заполнены пустые поля' : []"
          >
            <v-radio
                v-for="(radio, index) in radio_types"
                :key="index"
                :label="radio.text"
                :value="radio.value"
                :loading="loading_list"
                :disabled="loading_list"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
    </div>

    <div class="specification_card__warning" v-if="newItemNotification">
      <v-alert
        type="warning"
      >
        <span style="padding-right: 15px">Не найден элемент, создадим новый?</span>
        <v-btn
            small
            color="primary"
            :loading="loading_list"
            @click="createNew()"
        >
          Создать
        </v-btn>
      </v-alert>
    </div>

    <div class="specification_card__dropzone">
        <div class="specification_card__dropzone__header">
        <span class="standard_title">
          Чертежи:
        </span>
          <v-icon large color="grey lighten-1" @click="onShow('drop_zone')" :class="{upDownChevron: !isShow.drop_zone}">
            mdi-chevron-up
          </v-icon>
        </div>
        <div class="specification_card__dropzone__wrapper" v-show="isShow.drop_zone">
          <template v-if="!loading_list && currentId">
            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="options" :useCustomSlot=true v-if="!loading_dropzone" @vdropzone-file-added="uploadedData" @vdropzone-sending="sendingData" @vdropzone-mounted="setDropzone">
              <h3 class="dropzone-custom-title">
                <v-icon size="150" color="grey lighten-1" style="transform: rotate(45deg)">
                  mdi-paperclip
                </v-icon>
              </h3>
              <div class="subtitle" style="color: darkgrey">Для вставки чертежа перетащите файл в зону или нажмите на скрепку</div>
            </vue-dropzone>
            <div v-if="dropzone_uploaded.length && $store.state.edit_fields" @click="triggerUpload()" class="specification_card__dropzone__wrapper__upload">
              <v-icon color="grey lighten-1" size="60" style="transform: rotate(45deg)">mdi-paperclip</v-icon>
            </div>
          </template>
          <template v-else-if="loading_list">
            <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; position: absolute;">
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>
          </template>
          <template v-else-if="!loading_list && !currentId">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                    style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute;"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon
                      size="150"
                      color="grey lighten-1"
                      style="transform: rotate(45deg)"
                  >
                    mdi-paperclip
                  </v-icon>
                  <div style="color: darkgrey">Для вставки чертежа перетащите файл в зону или нажмите на скрепку</div>
                </div>
              </template>
              <span>Сначала необходимо выбрать элемент!</span>
            </v-tooltip>
          </template>
        </div>
    </div>

    <div class="specification_card__comment_zone">
      <div class="specification_card__comment_zone__header">
        <span class="standard_title">
          Примечание (мотив разработки):
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('comment_zone')" :class="{upDownChevron: !isShow.comment_zone}">
          mdi-chevron-up
        </v-icon>
      </div>
      <div class="specification_card__comment_zone__content" v-show="isShow.comment_zone">
        <v-textarea
            solo
            dense
            hide-details
            clearable
            auto-grow
            rows="3"
            row-height="25"
            v-model="virtual_nomenclature.commentary"
            :disabled="!$store.state.edit_fields"
        >
        </v-textarea>
      </div>
    </div>

    <div class="specification_card__real_nomenclature">
      <div class="specification_card__real_nomenclature__header">
        <span class="red_title">
          Добавить реальную номенклатуру
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('real_nomenclature')" :class="{upDownChevron: !isShow.real_nomenclature}">
          mdi-chevron-up
        </v-icon>
      </div>
    </div>
    <div class="specification_card__real_nomenclature_content" v-show="isShow.real_nomenclature">
      <div class="specification_card__real_nomenclature_content__search">
        <v-combobox
            :solo="$store.state.edit_fields"
            dense
            hide-details
            clearable
            hide-no-data
            return-object
            placeholder="Выберите реальную номенклатуру"
            class="specification_card__create_zone__head__inputs__input"
            v-model="selectedRealNomenclature"
            :loading="loading_list"
            :items="realSelectorList"
            item-text="NAME"
            :search-input.sync="realSelectorValue"
            @update:search-input="getRealItems()"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
            :disabled="!virtual_nomenclature.name || !Object.keys(virtual_nomenclature.name).length || !$store.state.edit_fields"
            :readonly="!$store.state.edit_fields"
        ></v-combobox>
        <v-btn
            color="primary"
            :loading="loading_list"
            :disabled="!selectedRealNomenclature || !Object.keys(selectedRealNomenclature).length || typeof selectedRealNomenclature === 'string'"
            @click="addRealToVirtual()"
        >
          Добавить
        </v-btn>
      </div>
      <div class="specification_card__real_nomenclature_content__title" v-if="realNomenclatureList.length">
        <span class="specification_card__real_nomenclature_content__title__text">Список привязанной реальной номенклатуры к виртуальной:</span>
      </div>
      <div class="specification_card__real_nomenclature_content__items" :id="`cards${currentId}`" v-if="currentId">
        <detail-nomenclature-card
            v-for="(item, indexItem) in realNomenclatureList"
            :key="indexItem"
            :nomenclature_data="item"
            :showChilds="isShow.real_nomenclature"
            :index="currentId"
            :specification_child="true"
            :nomenclature_parent="currentId"
            :disableAbsolute="true"
            style="font-size: 18px"
        />
      </div>
    </div>

    <div class="specification_card__specification_zone">
      <div class="specification_card__specification_zone__header">
        <span class="red_title">
          Спецификация {{ [subDetails.length] }}
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('specification_zone')" :class="{upDownChevron: !isShow.specification_zone}">
          mdi-chevron-up
        </v-icon>
      </div>
      <div class="specification_card__specification_zone__content" v-show="isShow.specification_zone">
        <transition-group name="list" class="specification_card__specification_zone__content">
          <SubDetailCard
              v-for="(item, index) in subDetails"
              :key="index"
              :index="index"
              :data_elem="item"
              @openModal="openModal"
              @setCount="setCount"
              @deleteChild="deleteFromParent"
          />
        </transition-group>
      </div>
    </div>

    <div class="specification_card__operation_zone">
      <div class="specification_card__operation_zone__header">
        <span class="red_title">
          Операции {{ [subOperations.length] }}
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('operation_zone')" :class="{upDownChevron: !isShow.operation_zone}">
          mdi-chevron-up
        </v-icon>
      </div>
      <div class="specification_card__operation_zone__content" v-show="isShow.operation_zone">
        <transition-group name="list">
          <SubDetailCard
              v-for="(item, index) in subOperations"
              :key="index"
              :index="index"
              :data_elem="item"
              @openModal="openModal"
              @setCount="setCount"
              @deleteChild="deleteFromParent"
          />
        </transition-group>
      </div>
    </div>

    <div class="specification_card__operation_zone">
      <div class="specification_card__operation_zone__header">
        <span class="red_title">
          Добавление вложенных виртуальных элементов
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('create_zone')" :class="{upDownChevron: !isShow.create_zone}">
          mdi-chevron-up
        </v-icon>
      </div>
    </div>
    <div class="specification_card__create_zone" v-show="isShow.create_zone" :class="{hideCard: hideCard, moveCardUp: moveCardUp, moveCardBot: moveCardBot}">
      <div class="specification_card__create_zone__head">
        <div class="specification_card__create_zone__head__plus">
          <v-icon size="74" color="grey darken-1" v-if="!indexNewItem">
            mdi-plus-circle-outline
          </v-icon>
          <div class="operation_card__number" v-else>
            <span class="operation_card__number__round">{{ indexNewItem }}</span>
          </div>
        </div>
        <div class="specification_card__create_zone__head__inputs">
          <v-combobox
              :solo="$store.state.edit_fields"
              dense
              hide-details
              clearable
              placeholder="Наименование"
              class="specification_card__create_zone__head__inputs__input"
              v-model="child_virtual_nomenclature.name"
              :loading="loading_list"
              :items="list_child"
              item-text="NAME"
              :search-input.sync="child_data_instance.nameSearch"
              @update:search-input="getItemsFromName('child')"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
              @change="onChildSelect('name')"
              :disabled="!virtual_nomenclature.name || !Object.keys(virtual_nomenclature.name).length || !$store.state.edit_fields"
          ></v-combobox>
          <v-combobox
              :solo="$store.state.edit_fields"
              dense
              hide-details
              clearable
              placeholder="КИП"
              class="specification_card__create_zone__head__inputs__input"
              v-model="child_virtual_nomenclature.kip"
              :loading="loading_list"
              :items="list_child"
              item-text="DRAWING_NUMBER"
              :search-input.sync="child_data_instance.drawing_numberSearch"
              @update:search-input="getItemsFromDrawingNumber('child')"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
              @change="onChildSelect('drawing_number')"
              :disabled="!virtual_nomenclature.name || !Object.keys(virtual_nomenclature.name).length || !$store.state.edit_fields"
              ref="KIP"
          ></v-combobox>
        </div>
        <div class="specification_card__create_zone__head__dropzone" :class="{disabledDZ: (!childId || !$store.state.edit_fields)}">
          <template v-if="childId">
            <vue-dropzone ref="myVueDropzoneSmall" id="dropzoneSmall" :options="optionsSmall" :useCustomSlot=true v-if="!loading_dropzone" @vdropzone-file-added="uploadedDataSmall" :class="{hideMessageDZ: dropzone_uploadedSmall.length}" @vdropzone-sending="sendingDataSmall">
              <h3 class="dropzone-custom-title">
                <v-icon size="78" color="grey darken-1" style="transform: rotate(45deg)">mdi-paperclip</v-icon>
              </h3>
            </vue-dropzone>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    size="60"
                    color="grey darken-1"
                    style="transform: rotate(45deg)"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-paperclip
                </v-icon>
              </template>
              <span>Сначала необходимо выбрать элемент!</span>
            </v-tooltip>
          </template>
        </div>
        <div class="specification_card__create_zone__head__count">
          <v-text-field
              :solo="$store.state.edit_fields"
              dense
              hide-details
              clearable
              placeholder="0"
              class="specification_card__create_zone__head__count__input"
              v-model="child_virtual_nomenclature.count"
              :loading="loading_list"
              ref="countSub"
              type="number"
              :readonly="!$store.state.edit_fields"
          ></v-text-field>
        </div>
      </div>
      <v-alert
        type="warning"
        v-if="!currentId"
        style="margin-top: 20px"
      >
        <span>Сначала нужно выбрать родительский элемент!</span>
      </v-alert>
      <div class="specification_card__create_zone__wrapper">
        <div class="specification_card__create_zone__wrapper__founded" v-if="list_child.length && currentId">
          <div class="specification_card__create_zone__wrapper__founded__header">
            <span class="specification_card__create_zone__wrapper__founded__header__pre">
              В системе имеется
              <span class="specification_card__create_zone__wrapper__founded__header__pre__count">{{ list_child.length }}</span>
              подходящих элемента:
            </span>
            <v-btn @click="onShow('founded_list')" text color="grey lighten-1">
              Показать
              <v-icon large color="grey lighten-1"  :class="{upDownChevron: !isShow.founded_list}">
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </div>
          <template v-if="isShow.founded_list">
            <PreviewChildSpecification
                v-for="(item, index) in list_child"
                :key="index"
                :data_elem="item"
                @setData="setData"
            ></PreviewChildSpecification>
          </template>
        </div>
        <div class="specification_card__create_zone__wrapper__notFounded" v-else>
          <span class="standard_title">
            Ничего не найдено
          </span>
          <div class="specification_card__create_zone__wrapper__notFounded__content">
            <h3 class="specification_card__create_zone__wrapper__notFounded__content__title">
              Создадим дочерний элемент?
            </h3>
            <div class="specification_card__create_zone__wrapper__notFounded__content__name">
              <span class="small_title">
                Наименование:
              </span>
              <v-text-field
                  solo
                  dense
                  :hide-details="!isChildErrorName"
                  clearable
                  placeholder="Наименование"
                  class="specification_card__create_zone__wrapper__notFounded__content__name__input"
                  v-model="new_child_virtual_nomenclature.name"
                  :loading="loading_list"
                  :disabled="loading_list || !currentId"
                  :error="isChildErrorName"
                  :error-messages="isChildErrorName ? 'Не заполнены пустые поля' : []"
              ></v-text-field>
            </div>
            <div class="specification_card__create_zone__wrapper__notFounded__content__name">
              <span class="small_title">
                Чертежный номер:
              </span>
              <v-text-field
                  solo
                  dense
                  :hide-details="!isChildErrorDN"
                  clearable
                  placeholder="Чертежный номер"
                  class="specification_card__create_zone__wrapper__notFounded__content__name__input"
                  v-model="new_child_virtual_nomenclature.drawing_number"
                  :loading="loading_list"
                  :disabled="loading_list || !currentId"
                  :error="isChildErrorDN"
                  :error-messages="isChildErrorDN ? 'Не заполнены пустые поля' : []"
              ></v-text-field>
            </div>
            <div class="specification_card__create_zone__wrapper__notFounded__content__types">
              <span class="small_title">
                Тип:
              </span>
              <div class="radio_group">
                <v-radio-group
                    hide-details
                    dense
                    dark
                    v-model="new_child_virtual_nomenclature.type"
                    class="radio-buttons"
                    :hide-details="!isChildErrorType"
                    :error="isChildErrorType"
                    :error-messages="isChildErrorType ? 'Не заполнены пустые поля' : []"
                >
                  <v-radio
                      v-for="(radio, index) in radio_types"
                      :key="index"
                      :label="radio.text"
                      :value="radio.value"
                      :loading="loading_list"
                      :disabled="loading_list || !currentId"
                  ></v-radio>
                </v-radio-group>
              </div>
              <span class="small_title">Примечание:</span>
              <v-textarea
                  solo
                  dense
                  hide-details
                  clearable
                  auto-grow
                  rows="3"
                  row-height="25"
                  v-model="new_child_virtual_nomenclature.commentary"
                  :loading="loading_list"
                  :disabled="loading_list || !currentId"
              >
              </v-textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="specification_card__create_zone__button">
        <v-btn
            color="primary"
            :disabled="addBtnStatus"
            @click="addChild()"
        >
          Добавить в спецификацию
        </v-btn>
      </div>
    </div>

    <div class="specification_card__planing_zone">
      <div class="specification_card__planing_zone__header">
        <span class="standard_title">
          Планирование
        </span>
        <v-icon large color="grey lighten-1" @click="onShow('planing_zone')" :class="{upDownChevron: !isShow.planing_zone}">
          mdi-chevron-up
        </v-icon>
      </div>
      <div class="specification_card__planing_zone__content" v-show="isShow.planing_zone">
        <div class="specification_card__planing_zone__content__block"  v-for="(item,index) in specificationSignatures">
          <section class="specification_card__planing_zone__content__block__name">{{ index }}:</section>
          <section class="specification_card__planing_zone__content__block__date">{{ item.DATE }}</section>
          <section class="specification_card__planing_zone__content__block__executor">{{ item.USER_NAME }}</section>
          <section class="specification_card__planing_zone__content__block__signed">
              <v-checkbox disabled
                          v-model:value="item.SIGNED"
                          :label="item.SIGNED ? 'Согласовано': ''"

              ></v-checkbox>
            </section>

        </div>
      </div>
    </div>

    <div class="specification_card__tags_zone">
      <div class="specification_card__tags_zone__header">
        <span class="standard_title">Хештеги:</span>
      </div>
      <div class="specification_card__tags_zone__wrapper">
        <v-chip-group
            column
            v-if="curTag.length"
        >
          <v-chip
              close
              active-class="active_tag"
              :disabled="loading_list || !$store.state.edit_fields"
              v-for="(item, index) in curTag"
              :key="index"
          >
            {{ item.NAME_HASHTAG }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="specification_card__tags_zone__adding">
        <v-chip
            @click="showTagSelection()"
            :loading="loading_list"
            :disabled="loading_list || !currentId || !$store.state.edit_fields"
        >...новый</v-chip>
      </div>
    </div>

    <div class="specification_card__bottom">
      <v-btn
          text
          color="red darken-1"
          class="specification_card__bottom__btn text-capitalize"
          :disabled="loading_list"
      >
        Удалить
      </v-btn>
<!--      @click="$store.dispatch('addAlertSpecification', {time: $data.currentTime, text: 'warning!!', type: $store.state.alert_type.warning})"-->

      <v-btn
          text
          color="green darken-1"
          class="specification_card__bottom__btn text-capitalize"
          :disabled="loading_list"
          v-if="currentId!==undefined"
          @click="$router.push (`/specification/view/${currentId}/`)"
      >
<!--        $store.state.alerts.push({text: 'success!!', type: $store.state.alert_type.info})-->
       роутер
      </v-btn>
      <v-btn
          color="green darken-1"
          class="specification_card__bottom__btn text-capitalize"
          :loading="loading_list"
          :disabled="loading_list || !currentId"
          @click="show_dialog_sign_specification=true"
      >
        Согласовано
      </v-btn>
    </div>

    <!-- MODALS -->
    <v-dialog
        v-model="showTagSelector"
        max-width="600"
    >
      <v-card class="tag_wrapper">
        <v-card-title style="color: #FFFFFF">
          Выберите тег
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              solo
              dense
              hide-details
              clearable
              return-object
              v-model="tag.selected_tag"
              :items="listTags"
              :item-text="(item) => {
                return item.NAME
              }"
              :loading="loading_list"
              :search-input.sync="tag.tag_search"
              label="Введите тэг"
              :disabled="!tag.list_tags.length"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-between">
          <v-btn
              text
              color="red darken-1"
              class="specification_card__bottom__btn text-capitalize"
              @click="closeTagSection"
          >
            Отмена
          </v-btn>
          <v-btn
              text
              color="blue darken-1"
              class="specification_card__bottom__btn text-capitalize"
              @click="addNewTag"
          >
            Выбрать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="modalSpecification"
        max-width="1000"
    >
      <v-card class="specification_modal_wrapper">
        <v-card-title></v-card-title>
        <v-card-text>
          <SpecificationCard v-if="modalSpecification" :nomenclature_data="modalData" :index="`${currentId}${modalData.ID}`" :force="true"/>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-between">
          <v-btn
              text
              color="red darken-1"
              class="specification_card__bottom__btn text-capitalize"
              @click="closeModal"
          >
            Отмена
          </v-btn>
          <v-btn
              text
              color="blue darken-1"
              class="specification_card__bottom__btn text-capitalize"
              @click="closeModal"
          >
            Выбрать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!-- Включить режим редактирования?   -->
    <v-dialog
        v-model="show_dialog_enable_edit_fields"
        persistent
        max-width="460"
    >
      <v-card>
        <v-card-title class="text-h5" align="center">
         Перейти в режим редактирования?
        </v-card-title>
        <v-card-text>Это может быть опасным!!11</v-card-text>
        <v-card-actions>
          <v-btn
              color="blue darken-1"
              text
              @click="show_dialog_enable_edit_fields=false"
          >
            Нет
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="show_dialog_enable_edit_fields=false; $store.state.edit_fields=true"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- Согласовать?   -->

    <v-dialog
        v-model="show_dialog_sign_specification"
        persistent
        max-width="270"
    >
      <v-card>
        <v-card-title class="text-h5">
         Согласовать?
        </v-card-title>
        <v-card-text>Согласовать спецификацию как<br><b>{{this.$store.state.userFio}}</b>?</v-card-text>
        <v-card-actions>
          <v-btn
              color="blue darken-1"
              text
              @click="show_dialog_sign_specification = false"
          >
            Нет
          </v-btn>
          <v-spacer></v-spacer>
<!--          @click="show_dialog_sign_specification = false; singSpecificationAsync()"-->
          <v-btn
              color="red darken-1"
              text
              @click="show_dialog_sign_specification = false; "
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <LayoutNomenclature
        v-show="false"
        v-if="print_layout_nomenclature && !!currentId"
        :id_prod_from_parent="currentId"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import vue2Dropzone from 'vue2-dropzone'
import router from "../router";

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import PreviewTemplate from "../components/PreviewTemplate";
import layoutSmallDropzone from "../components/specification/layoutSmallDropzone";

import SubDetailCard from "../components/specification/SubDetailCard";
import PreviewChildSpecification from "../components/specification/PreviewChildSpecification";
import SpecificationCard from "../components/specification/SpecificationCard";
import LayoutNomenclature from "../components/nomenclature/LayoutNomenclature";
import DetailNomenclatureCard from "../components/nomenclature/DetailNomenclatureCard";
import PlanningCard from "../components/specification/PlanningCard";
import Request from "@/services/request";

export default {
  name: "Specification",
  components: {
    DetailNomenclatureCard,
    LayoutNomenclature,
    SpecificationCard,
    PreviewChildSpecification,
    SubDetailCard,
    PreviewTemplate,
    layoutSmallDropzone,
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    virtual_nomenclature: {
      name: null,
      drawing_number: null,
      commentary: '',
      search: '',
    },
    child_virtual_nomenclature: {
      name: null,
      kip: null,
      count: null
    },
    hideCard: false,
    moveCardUp: false,
    moveCardBot: false,
    indexNewItem: null,
    new_child_virtual_nomenclature: {
      name: '',
      drawing_number: '',
      type: 'detail', // Деталь по умолчанию, null если не нужно
      commentary: '',
    },
    initialiedData: false,
    onCheck: false,
    onCheckChild: false,
    debounceTimeout: null,
    drawingDebounce: null,
    nameDebounce: null,

    data_instance: {
      drawing_numberSearch: '',
      nameSearch: '',
    },

    loading_list: false,
    gettedFromUrl: false,
    data_by_name: [],
    data_by_drawing_number: [],
    data_children: [],

    child_data_by_drawing_number: [],
    child_data_by_name: [],
    child_data_instance: {
      drawing_numberSearch: '',
      nameSearch: '',
    },

    realNomenclatureList: [],
    selectedRealNomenclature: null,
    realSelectorList: [],
    realSelectorValue: '',

    /* RADIO_BTN */
    selected_type: 'detail', // Деталь по умолчанию, null если не нужно
    radio_types: [
      {
        text: 'Деталь',
        value: 'detail',
      },
      {
        text: 'Операция',
        value: 'operation',
      },
    ],

    /* TAGS */
    showTagSelector: false,
    tag: {
      list_tags: [],
      selected_tag: {},
      tag_search: '',
    },
    curTag: [],

    /* MODAL SPECIFICATION */
    modalSpecification: false,
    modalData: {},

    /* PRINT_TICKET */
    print_layout_nomenclature: null,

    /* CHEVRONS MENU */
    isShow: {
      drop_zone: true,
      specification_zone: true,
      operation_zone: true,
      planing_zone: false, //cкроем нереализованное
      comment_zone: true,
      create_zone: true,
      founded_list: false,
      real_nomenclature: true,
    },

    /* DROPZONE */
    index_uploaded: 1,
    dropzone_uploaded: [],
    loading_dropzone: true,
    previewHtml: null,
    index_uploadedSmall: 1,
    dropzone_uploadedSmall: [],
    previewHtmlSmall: null,
    specificationSignatures:[],
    show_dialog_enable_edit_fields: false,
    show_dialog_sign_specification: false,
    allow_sign_specification: false
  }),
  created() {
    const ComponentClass = Vue.extend(PreviewTemplate);
    const instance = new ComponentClass();
    instance.$mount();
    this.previewHtml = instance.$el.outerHTML;
    const ComponentClassSmall = Vue.extend(layoutSmallDropzone);
    const instanceSmall = new ComponentClassSmall();
    instanceSmall.$mount();
    this.previewHtmlSmall = instanceSmall.$el.outerHTML;
    this.loading_dropzone = false;
  },
  mounted() {
    this.$router.push(`/specification/view`)

    this.initializeQuery()
    this.getItemsFromDrawingNumber('child')
    this.getItemsFromName('child')
    this.getListTags()
    this.getSpecificationSignatures()
  },
  computed: {
    filteredCurTag() {
      if (!this.curTag.length) return []
      return this.curTag.map(elem => elem.NAME_HASHTAG)
    },
    listTags() {
      if (!this.tag.list_tags.length) return []
      return this.tag.list_tags.filter(tag => {
        return !this.filteredCurTag.includes(tag.PREVIEW_TEXT ? tag.PREVIEW_TEXT : tag.NAME)
      })
    },
    commentaryVN() {
      return this.currentId ? this.virtual_nomenclature.name?.PREVIEW_TEXT : ''
    },
    list_child() {
      if (!this.child_data_by_drawing_number.length || !this.child_data_by_drawing_number.length) {
        return this.child_data_by_name.length ? this.child_data_by_name : this.child_data_by_drawing_number.length ? this.child_data_by_drawing_number : []
      }
      return this.child_data_by_name.length > this.child_data_by_drawing_number.length ? this.child_data_by_drawing_number : this.child_data_by_name
      // return [...new Set([...this.child_data_by_name ,...this.child_data_by_drawing_number])];
    },
    subDetails() {
      return this.data_children.filter(elem => {
        return elem.TYPE === 'detail'
      }).sort((a, b) => {
        if (a.QUANTITY_BIND_NOMENCLATURE > b.QUANTITY_BIND_NOMENCLATURE) return 1
        else if (a.QUANTITY_BIND_NOMENCLATURE < b.QUANTITY_BIND_NOMENCLATURE) return -1
        else return 0
      })
    },
    subOperations() {
      return this.data_children.filter(elem => {
        return elem.TYPE === 'operation'
      })
    },
    childId() {
      return this.child_virtual_nomenclature.name?.ID && this.child_virtual_nomenclature.kip?.ID
    },
    currentId() {
      return this.virtual_nomenclature.name?.ID && this.virtual_nomenclature.drawing_number?.ID
    },
    validate() {
      return !!this.virtual_nomenclature.drawing_number && !!this.virtual_nomenclature.name && !!this.selected_type
    },
    isErrorDN() {
      return this.newItemNotification && !this.virtual_nomenclature.drawing_number && this.onCheck
    },
    isErrorName() {
      return this.newItemNotification && !this.virtual_nomenclature.name && this.onCheck
    },
    isErrorType() {
      return this.newItemNotification && !this.selected_type && this.onCheck
    },
    newItemNotification() {
      return (!this.data_by_drawing_number.length || !this.data_by_name.length) && this.initialiedData && !this.currentId
    },
    validateChild() {
      return !!this.new_child_virtual_nomenclature.drawing_number && !!this.new_child_virtual_nomenclature.name && !!this.new_child_virtual_nomenclature.type
    },
    isChildErrorName() {
      return !this.new_child_virtual_nomenclature.name && this.onCheckChild
    },
    isChildErrorDN() {
      return !this.new_child_virtual_nomenclature.drawing_number && this.onCheckChild
    },
    isChildErrorType() {
      return !this.new_child_virtual_nomenclature.type && this.onCheckChild
    },
    addBtnStatus() {
      return this.loading_list || (!!this.list_child.length && (((this.child_virtual_nomenclature.name !== null && typeof this.child_virtual_nomenclature.name !== 'object') || this.child_virtual_nomenclature.name === null) || (this.child_virtual_nomenclature.kip === null || typeof this.child_virtual_nomenclature.kip !== 'object')))
    },
    options() {
      return {
        url: `${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SetPictureToVirtualElement&id_virtual_nomenclature=${this.currentId}`,
        previewTemplate: this.previewHtml,
        destroyDropzone: false,
        duplicateCheck: true,
        method: 'POST',
        headers: {
          // "accept": "*/*",
          // "accept-language": "ru,en;q=0.9,zh;q=0.8",
          // "content-type": "multipart/form-data",
        },
      }
    },
    optionsSmall() {
      return {
        url: `${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SetPictureToVirtualElement&id_virtual_nomenclature=${this.childId}`,
        previewTemplate: this.previewHtmlSmall,
        destroyDropzone: false,
        duplicateCheck: true,
        method: 'POST',
        headers: {
          // "accept": "*/*",
          // "accept-language": "ru,en;q=0.9,zh;q=0.8",
          // "content-type": "multipart/form-data",
          // "content-type": "application/x-www-form-urlencoded",
        },
      }
    },
    myHeaders() {
      let myHeaders = new Headers();
      myHeaders.append("accept", "*/*");
      myHeaders.append("accept-language", "ru,en;q=0.9,zh;q=0.8");
      myHeaders.append("content-type", "application/x-www-form-urlencoded");
      myHeaders.append("origin", this.$store.state.VUE_DEV_LOCAL_SERVER);
      myHeaders.append("referer", this.$store.state.VUE_DEV_LOCAL_SERVER);
      myHeaders.append("sec-ch-ua", "\" Not A;Brand\";v=\"99\", \"Chromium\";v=\"102\", \"Google Chrome\";v=\"102\"");
      myHeaders.append("sec-ch-ua-mobile", "?0");
      myHeaders.append("sec-ch-ua-platform", "\"Windows\"");
      myHeaders.append("sec-fetch-dest", "empty");
      myHeaders.append("sec-fetch-mode", "cors");
      myHeaders.append("sec-fetch-site", "same-site");
      myHeaders.append("user-agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36");
      myHeaders.append("Cookie", "PHPSESSID=Dt2P9vLm9wxDWy3jzj62IGSw6tPLNoEj");
      return myHeaders
    },
    myURLEncoded() {
      let urlencoded = new URLSearchParams();
      urlencoded.append("token", this.$store.state.token);
      urlencoded.append("sessid", this.$store.state.sessid);
      urlencoded.append("login", this.$store.state.login);
      urlencoded.append("post[num_page]", "1");
      return urlencoded
    }
  },
  watch: {
    'currentId': {
      handler(v) {
        if (v) {
          this.getChildren()
          this.getBindedNomenclature(v)
          this.getSpecificationSignatures()
        } else {
          this.data_children = []
          this.specificationSignatures = []
        }
      },
    },
    'commentaryVN': {
      handler(v) {
        this.virtual_nomenclature.commentary = v
      }
    },
  },
  methods: {
    goToTree() {
      let routeData = this.$router.resolve({name: 'TreeVirtualSpecification', params: {id_v_prod: this.currentId}});
      window.open(routeData.href, '_blank');
    },
    /* Create NEW */
    prepareToCreate() {
      this.virtual_nomenclature.drawing_number = null
      this.virtual_nomenclature.name = null
      this.virtual_nomenclature.commentary = ''
      this.virtual_nomenclature.search = ''

      this.child_virtual_nomenclature.name = null
      this.child_virtual_nomenclature.kip = null
      this.child_virtual_nomenclature.count = null

      this.addQuery()
    },
    /* EDIT */
    setEdit() {
      // Если режим редактирования полей false
      if (!this.$store.state.edit_fields)
      {
        // отобразить диалог с вопросом
        this.show_dialog_enable_edit_fields=true
        // this.$store.state.edit_fields = false
      }
      else this.$store.state.edit_fields = false // иначе просто выключеам редактирование полей

      // this.$store.state.edit_fields = !this.$store.state.edit_fields
    },

    /* ONLOAD ITEMS */
    initializeQuery() {
      if (Object.keys(this.$route.query).length && Object.keys(this.$route.query).includes('id')) {
        this.gettedFromUrl = true
        this.getConcreteNomenclature(this.$route.query.id)
        this.getElemTags()
      } else {
        this.getItemsFromName()
        this.getItemsFromDrawingNumber()
      }
    },

    /* MODAL DETAIL */
    openModal(data) {
      this.modalData = data
      this.modalSpecification = true
    },
    deleteFromParent(id) {
      this.loading_list = true

      const params = new URLSearchParams();
      params.append("id_parent_virtual_nomenclature", this.currentId);
      params.append("id_children_virtual_nomenclature", id);

      let requestOptions = {
        method: 'POST',
        headers: this.myHeaders,
        body:  Object.assign(params, this.myURLEncoded),
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.DeleteNestedElementsSpecification`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            console.log(refactored)
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            this.loading_list = false
          })
    },
    setCount(data) {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {

        this.loading_list = true

        const params = new URLSearchParams();
        params.append("id_children_virtual_nomenclature", data.id);
        params.append("quantity", data.count);

        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body:  Object.assign(params, this.myURLEncoded),
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SetQuantityElementsSpecification`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              console.log(refactored)
            })
            .catch(error => {
              console.log('error', error)
            })
            .finally(() => {
              this.loading_list = false
            })
      }, 500)
    },
    closeModal() {
      this.modalSpecification = false
    },

    addRealToVirtual() {
      this.loading_list = true

      this.myURLEncoded.append("id_virtual_nomenclature", this.currentId);
      this.myURLEncoded.append("id_real_nomenclature", this.selectedRealNomenclature.ID);

      let requestOptions = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.setBindRealNomenclatureToVirtualNomenclature`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            this.realSelectorList = refactored.data.data
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            this.myURLEncoded.delete("id_virtual_nomenclature")
            this.myURLEncoded.delete("id_real_nomenclature")
            this.loading_list = false
            this.getBindedNomenclature(this.currentId)
          })
    },
    getRealItems() {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.loading_list = true

        this.myURLEncoded.append("name_part", this.realSelectorValue);

        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body: this.myURLEncoded,
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SearchNomenclatureByPartName`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              this.realSelectorList = refactored.data.data
            })
            .catch(error => {
              console.log('error', error)
            })
            .finally(() => {
              this.myURLEncoded.delete("name_part")
              this.loading_list = false
            })
      }, 400)
    },
    getBindedNomenclature(id) {
      this.loading_list = true

      this.myURLEncoded.append("post[id_virtual_nomenclature]", id);

      let requestOptions = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?mode=class&c=nk%3Aproduction.list_nomenclature&action=executeGetBindedNomenclatureByVirtualNomenclature`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            this.realNomenclatureList = refactored.data.log_data.DATA
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            this.myURLEncoded.delete("post[id_virtual_nomenclature]")
            this.loading_list = false
          })
    },

    addToParentRequest() {
      return new Promise((resolve, reject) => {
        this.loading_list = true

        const params = new URLSearchParams();
        params.append("id_parent_virtual_nomenclature", this.currentId);
        params.append("id_children_virtual_nomenclature", this.child_virtual_nomenclature.name.ID);
        params.append('quantity', this.child_virtual_nomenclature.count ? this.child_virtual_nomenclature.count : '')

        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body:  Object.assign(params, this.myURLEncoded),
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.CreateNestedElementsSpecification`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              console.log(refactored)
              resolve()
            })
            .catch(error => {
              reject(error)
              console.log('error', error)
            });
      })
    },
    addChild() {
      if (this.list_child.length) {
        this.addToParentRequest().then(() => {
          console.log(this.$refs.myVueDropzoneSmall)
          this.dropzone_uploadedSmall = []
          this.$refs.myVueDropzoneSmall.removeAllFiles(true)
          this.indexNewItem = this.data_children.length+1
          this.moveCardUp = true;
          this.hideCard = true;
          this.getChildren().then(() => {
            /* Анимация добавления в список */
            // setTimeout(() => {
            //   this.data_children.push(this.data_children[0])
            // }, 1000)
            /* Анимация появления формы снова */
            setTimeout(() => {
              this.moveCardUp = false
              this.moveCardBot = true
              this.indexNewItem = null
              this.child_virtual_nomenclature.kip = this.child_virtual_nomenclature.name = this.child_virtual_nomenclature.count = null
              this.new_child_virtual_nomenclature.drawing_number = this.new_child_virtual_nomenclature.name = this.new_child_virtual_nomenclature.type = this.new_child_virtual_nomenclature.commentary = null
              setTimeout(() => {
                this.moveCardBot = false
                this.hideCard = false
                this.$refs.KIP.focus()
              }, 1500)
            }, 1600)
          })
        })
      } else {
        this.createNewChild()
      }
    },
    onChildSelect(type) {
      if (type === 'drawing_number') {
        if (this.child_virtual_nomenclature.kip && typeof this.child_virtual_nomenclature.kip === 'object') {
          this.child_virtual_nomenclature.name = this.child_virtual_nomenclature.kip
        }
      } else {
        if (this.child_virtual_nomenclature.name && typeof this.child_virtual_nomenclature.name === 'object') {
          this.child_virtual_nomenclature.kip = this.child_virtual_nomenclature.name
        }
      }
    },
    createNewChild() {
      if (this.validateChild) {
        this.loading_list = true

        const params = new URLSearchParams();
        params.append("name", this.new_child_virtual_nomenclature.name);
        params.append("drawing_number", this.new_child_virtual_nomenclature.drawing_number);
        params.append('type', this.new_child_virtual_nomenclature.type)
        params.append('preview_text', this.new_child_virtual_nomenclature.commentary ? this.new_child_virtual_nomenclature.commentary : '')

        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body: Object.assign(params, this.myURLEncoded),
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.CreateVirtualNomenclature`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              let id = refactored.data.data.id_virtual_nomenclature
              this.gettedFromUrl = true
              this.getConcreteNomenclature(id, 'child')
            })
            .catch(error => console.log('error', error));
      } else {
        this.onCheckChild = true
      }
    },
    createNew() {
      if (this.validate) {
        this.loading_list = true

        const params = new URLSearchParams();
        params.append("name", this.data_instance.nameSearch);
        params.append("drawing_number", this.data_instance.drawing_numberSearch);
        params.append('type', this.selected_type);

        // console.debug(this.date.virtual_nomenclature);
        // console.debug(typeof this.date.virtual_nomenclature);
        // if ( typeof this.date.virtual_nomenclature != undefined ) {
        //   if (typeof this.date.virtual_nomenclature.commentary!= undefined) params.append('preview_text', this.date.virtual_nomenclature.commentary);
        //
        // }


        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body: Object.assign(params, this.myURLEncoded),
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.CreateVirtualNomenclature`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              let id = refactored.data.data.id_virtual_nomenclature
              this.getConcreteNomenclature(id)
              this.addQuery(id)
              this.selected_type = null
              this.$store.state.edit_fields = true
            })
            .catch(error => console.log('error', error));
      } else {
        this.onCheck = true
      }
    },
    onSelect(type) {
      if (type === 'drawing_number') {
        if (this.virtual_nomenclature.drawing_number && typeof this.virtual_nomenclature.drawing_number === 'object') {
          this.virtual_nomenclature.name = this.virtual_nomenclature.drawing_number
        }
      } else {
        if (this.virtual_nomenclature.name && typeof this.virtual_nomenclature.name === 'object') {
          this.virtual_nomenclature.drawing_number = this.virtual_nomenclature.name
        }
      }
      this.addQuery()
      this.dropzone_uploaded = []
      this.setDropzone()
      this.initialiedData = true
    },
    getCommentary() {

    },
    addQuery(id) {
      if (this.currentId || id) {
        this.$router.push({
          path: router.path,
          query: {id: id ? id : this.currentId},
        })
      } else {
        if (this.$route.query && Object.keys(this.$route.query).length) {
          this.$router.push({
            path: router.path,
          })
        }
      }
    },
    getConcreteNomenclature(id, type) {
      this.loading_list = true

      let requestOptions = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.GetBasicDataVirtualElement&id_virtual_nomenclature=${id}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            if (type === 'child') {
              this.child_data_by_name = [refactored.data.data]
              this.child_data_by_drawing_number = [refactored.data.data]
              this.child_virtual_nomenclature.name = this.child_virtual_nomenclature.kip = refactored.data.data
            } else {
              this.data_by_name = [refactored.data.data]
              this.data_by_drawing_number = [refactored.data.data]
              this.virtual_nomenclature.name = this.virtual_nomenclature.drawing_number = refactored.data.data
              // this.setDropzone()
            }
            this.loading_list = false
          })
          .catch(error => console.log('error', error));

      setTimeout(() => {
        this.gettedFromUrl = false
      }, 200)
    },
    getItemsFromDrawingNumber(type) {
      if (!this.gettedFromUrl || type) {

        if (this.drawingDebounce) clearTimeout(this.drawingDebounce);
        this.drawingDebounce = setTimeout(() => {
          this.loading_list = true

          let requestOptions = {
            method: 'POST',
            headers: this.myHeaders,
            body: this.myURLEncoded,
            redirect: 'follow',
            credentials: 'include'
          };

          if (type === 'child') {
            const value = this.child_data_instance.drawing_numberSearch ? encodeURIComponent(this.child_data_instance.drawing_numberSearch) : ''

            fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.SearchVirtualNomenclatureByPartDrawingNumber&drawing_number_part=${value}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                  const refactored = JSON.parse(result)
                  this.child_data_by_drawing_number = refactored.data.data
                  this.loading_list = false
                })
                .catch(error => console.log('error', error));
          } else {
            const value = this.data_instance.drawing_numberSearch ? encodeURIComponent(this.data_instance.drawing_numberSearch) : ''

            fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.SearchVirtualNomenclatureByPartDrawingNumber&drawing_number_part=${value}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                  const refactored = JSON.parse(result)
                  this.data_by_drawing_number = refactored.data.data
                  this.loading_list = false
                })
                .catch(error => console.log('error', error));
          }
        }, 700)
      }
    },
    getItemsFromName(type) {
      if (!this.gettedFromUrl || type) {
        if (this.nameDebounce) clearTimeout(this.nameDebounce);
        this.nameDebounce = setTimeout(() => {

          this.loading_list = true

          let requestOptions = {
            method: 'POST',
            headers: this.myHeaders,
            body: this.myURLEncoded,
            redirect: 'follow',
            credentials: 'include'
          };

          if (type === 'child') {
            const value = this.child_data_instance.nameSearch ? encodeURIComponent(this.child_data_instance.nameSearch) : ''

            fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.SearchVirtualNomenclatureByPartName&name_part=${value}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                  const refactored = JSON.parse(result)
                  this.child_data_by_name = refactored.data.data
                  this.loading_list = false
                })
                .catch(error => console.log('error', error));
          } else {
            const value = this.data_instance.nameSearch ? encodeURIComponent(this.data_instance.nameSearch) : ''

            fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.SearchVirtualNomenclatureByPartName&name_part=${value}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                  const refactored = JSON.parse(result)
                  this.data_by_name = refactored.data.data
                  this.loading_list = false
                })
                .catch(error => console.log('error', error));
          }
        }, 700)
      }
    },
    getChildren() {
      return new Promise((resolve, reject) => {
        this.loading_list = true

        let requestOptions = {
          method: 'POST',
          headers: this.myHeaders,
          body: this.myURLEncoded,
          redirect: 'follow',
          credentials: 'include'
        };

        fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.GetNestedElementsSpecification&id_virtual_nomenclature=${this.currentId}`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const refactored = JSON.parse(result)
              this.data_children = refactored.data.data
              this.loading_list = false
              resolve()
            })
            .catch(error => {
              console.log('error', error)
              reject(error)
            });
      })
    },
    setData(data) {
      this.child_virtual_nomenclature.name = data
      this.onChildSelect('name')
      this.$refs.countSub.focus()
    },

    /* TAGS */
    closeTagSection() {
      this.showTagSelector = false
    },
    addNewTag() {
      this.loading_list = true

      let requestOptions = {
        method: 'GET',
        headers: this.myHeaders,
        // body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.AddHashtagToVirtualNomenclature&id_virtual_nomenclature=${this.currentId}&name_hashtag=${this.tag.selected_tag.PREVIEW_TEXT}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            this.curTag = refactored.data.data
            this.loading_list = false
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            this.getElemTags()
            this.tag.selected_tag = {}
            this.showTagSelector = false
          })
    },
    getElemTags() {
      this.loading_list = true

      let requestOptions = {
        method: 'GET',
        headers: this.myHeaders,
        // body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.GetHashtagsByIdVirtualNomenclature&id_virtual_nomenclature=${this.$route.query.id}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            this.curTag = refactored.data.data
            this.loading_list = false
          })
          .catch(error => {
            console.log('error', error)
          });
    },
    getListTags() {
      this.loading_list = true

      let requestOptions = {
        method: 'GET',
        headers: this.myHeaders,
        // body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.GetAllDataHashtags`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            console.log(refactored)
            this.tag.list_tags = refactored.data.data
            this.loading_list = false
          })
          .catch(error => {
            console.log('error', error)
          });
    },
    showTagSelection() {
      this.showTagSelector = true
    },

    /* CHEVRON SHOW/CLOSE */
    onShow(name) {
      this.isShow[name] = !this.isShow[name]
    },

    /* DROP_ZONE */
    /* ЧТОБЫ ЗАПИСАТЬ В ДАТУ ЗАГРУЖЕННЫЙ ФАЙЛ */
    uploadedDataSmall(file) {
      const formatedObj = {}

      for (let key in file) {
        Object.assign(formatedObj, {[key]: file[key]})
      }
      Object.assign(formatedObj, {id: this.index_uploadedSmall})
      this.index_uploadedSmall++
      this.dropzone_uploadedSmall.push(formatedObj)

      this.$nextTick(() => {
        const deletedElems = document.getElementsByClassName('closeSmall')
        let count = 1;
        for (let item of deletedElems) {
          item.setAttribute('id', `close-Small-${count}`);
          const id = count
          item.onclick = () => {
            this.removedFileSmall(id)
          }
          count++
        }
      })
    },
    removedFileSmall(id) {
      const index = this.dropzone_uploadedSmall.findIndex(elem => {
        return elem.id === id
      })
      if (index !== -1) {
        this.dropzone_uploadedSmall.splice(index, 1)
      }
    },
    setDropzone() {
      this.$nextTick(() => {
        if (Object.keys(this.$route.query).length && Object.keys(this.$route.query).includes('id') && this.currentId) {
          // if (!this.currentId) return
          const {ALT, DESCRIPTION, TITLE, SRC, SIZE, HEIGHT, WIDTH} = this.virtual_nomenclature.name.PREVIEW_PICTURE_PATH
          const endPath = SRC.split('.')[1]
          const type = endPath === 'png' ? 'image/png' : endPath === 'jpg' ? 'image/jpg' : 'application/pdf'
          const url = this.$store.state.VUE_DEV_LOCAL_SERVER+SRC;
          this.$refs.myVueDropzone.manuallyAddFile({
            name: DESCRIPTION, type: type, size: SIZE ? SIZE : 123, width: WIDTH, height: HEIGHT
          }, url)
        }
      })
    },
    uploadedData(file) {
      const formatedObj = {}

      for (let key in file) {
        Object.assign(formatedObj, {[key]: file[key]})
      }
      Object.assign(formatedObj, {id: this.index_uploaded})
      // this.index_uploaded++
      this.dropzone_uploaded.push(formatedObj)

      this.$nextTick(() => {
        const deletedElems = document.getElementsByClassName('close')
        let count = 1;
        for (let item of deletedElems) {
          item.setAttribute('id', `close-${count}`);
          const id = count
          item.onclick = () => {
            this.removedFile(id)
          }
          if (count === 1 && this.dropzone_uploaded.length>1) {
            item.click()
          }
          count++
        }
      })
    },
    sendingData(file, xhr, formData) {
      formData.append('size', file.size)
      formData.append('alt', file.name)
      formData.append('title', file.name)
      formData.append('description', file.name)
    },
    sendingDataSmall(file, xhr, formData) {
      formData.append('size', file.size)
      formData.append('alt', file.name)
      formData.append('title', file.name)
      formData.append('description', file.name)
    },
    /* onClick on small area */
    triggerUpload() {
      document.getElementById('dropzone').click()
    },
    /* for Remove file */
    removedFile(id) {
      if (this.$store.state.edit_fields) {
        const index = this.dropzone_uploaded.findIndex(elem => {
          return elem.id === id
        })
        if (index !== -1) {
          this.dropzone_uploaded.splice(index, 1)
          this.$refs.myVueDropzone.removeAllFiles()
        }
      }
    },

    // TODO переписать запрос как async
    getSpecificationSignatures() {
      this.loading_list = true

      let requestOptions = {
        method: 'GET',
        headers: this.myHeaders,
        // body: this.myURLEncoded,
        redirect: 'follow',
        credentials: 'include'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.GetSpecificationSignatures&id_virtual_nomenclature=${this.currentId}`, requestOptions)
          // .then(response => response.text())
          // .then(result => console.log(result))
          // .catch(error => console.log('error', error));
      .then(response => response.text())
          .then(result => {
            const refactored = JSON.parse(result)
            this.specificationSignatures = refactored.data.data
            this.loading_list = false
            console.log('getSpecificationSignatures', refactored)
          })
          .catch(error => {
            console.log('error', error)
          });
    },

    /** TODO Не могу получить асинхронные данные, они приходят позже*/
    async singSpecificationAsync(){
      //console.log('singSpecification currentId', this.currentId)

      if (this.currentId) {
        const response = await Request.post(
            this.$store.state.VUE_DEV_LOCAL_SERVER + '/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.SignSpecification',
            {
              'id_virtual_nomenclature': this.currentId,
              'position': 'AGREED_LOGISTICS',
              'date': "31.12.2026"
            }
        )
        console.log('singSpecification response', await response)
       if (typeof await response !== undefined || typeof await response != "undefined") {
          // console.log('singSpecification', response)
          const refactored = await response  // JSON.parse(response)
          if (refactored.status!=='error' & refactored.data.data.length > 0  ) {
            this.specificationSignatures = refactored.data.data
          }
          else
          {
            console.error(refactored.errors);
          }
        }

      }
      this.allow_sign_specification=false
      this.show_dialog_sign_specification=false

    },
    clearSearchInHeader(){
      this.data_by_drawing_number=[]
      this.data_instance.drawing_numberSearch=''
      this.data_by_name=[]
      this.data_instance.nameSearch=''
    },

  },
}
</script>

<style scoped lang="scss">
.drawing_number_txt_color_green{
  color: green; /*changes the text color*/
  background-color: whitesmoke; /*changes the text background color*/
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s ease-in-out;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

@import "src/assets/sass/main";
@import "src/assets/sass/specification";
@import "src/assets/sass/detailSpecification";
</style>
<style lang="scss">
.drawing_number__header__input__bold_green {
  .v-select__slot {
    input {
      color: #43A047;
      font-weight: bold;
    }
  }
}
</style>