<template>
<!--  <div >-->
    <table id="wrapper_layout_prod" style="page-break-after:always;">
      <tbody class="LayoutProd"
             v-if="$store.state.smallSize"
      >
      <tr>
        <td v-for="(row, index) in arr_data_order" :key="index">
          <!--        v-for="nomenclature in arr_data_order" v-if="arr_data_order.length !== 0"-->
          <div
              class="LayoutProd-wrapper"
              style="page-break-inside: avoid;"
          >
            <div class="LayoutProd_Block">
              <section class="LayoutProd_Block_InternalArticle">{{ INTERNAL_ARTICLE(row) }}</section>
              <div class="LayoutProd_Block_Info">
                <div class="LayoutProd_Block_Info_DrawingNumber">
                  <section class="LayoutProd_Block_Info_DrawingNumber_Header" style="word-break: break-word;">{{ NAME_DATA(row) }}</section>
                  <!--              <section class="LayoutProd_Block_Info_DrawingNumber_Header">Чертежный номер</section>-->
                  <!--              <section class="LayoutProd_Block_Info_DrawingNumber_Data" v-if="nomenclature['DRAWING_NUMBER']">{{ nomenclature['DRAWING_NUMBER'] }}</section>-->
                  <!--              <section class="LayoutProd_Block_Info_DrawingNumber_Data" v-else>000000</section>-->
                </div>
<!--                <section class="LayoutProd_Block_WrapperQrCode_CurDate">{{ column['CUR_DATE'] }}</section>-->
              </div>
            </div>
            <div class="LayoutProd_Block_WrapperQrCode">
              <img class="LayoutProd_Block_WrapperQrCode_QrCode"
                   :src="'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chld=L|0&chl='+row['CORRECT_URL_QR_CODE']">
            </div>
          </div>
        </td>
      </tr>
      </tbody>
      <!--    <div class="LayoutProd"-->
      <!--         v-else-->
      <!--         style="grid-template-columns: 50% auto; font-size: 18px"-->
      <!--    >-->
      <!--      <div v-for="nomenclature in arr_data_order" v-if="arr_data_order.length !== 0"-->
      <!--           class="LayoutProd-wrapper" style="padding: 5px 5px 3px 5px;">-->
      <!--        <div class="LayoutProd_Block">-->
      <!--          <section style="text-align: left; font-size: 2.5em; font-weight: bold; margin-bottom: 3px">{{ nomenclature.INTERNAL_ARTICLE }}</section>-->
      <!--          <div class="LayoutProd_Block_Info">-->
      <!--            <div class="LayoutProd_Block_Info_DrawingNumber">-->
      <!--              <section style="text-align: left; font-size: 1em; font-weight: bold; margin-bottom: 3px">{{nomenclature.NAME}}</section>-->
      <!--&lt;!&ndash;              <section class="LayoutProd_Block_Info_DrawingNumber_Header">Чертежный номер:</section>&ndash;&gt;-->
      <!--&lt;!&ndash;              <section class="LayoutProd_Block_Info_DrawingNumber_Data" v-if="nomenclature['DRAWING_NUMBER']">{{ nomenclature['DRAWING_NUMBER'] }}</section>&ndash;&gt;-->
      <!--&lt;!&ndash;              <section class="LayoutProd_Block_Info_DrawingNumber_Data" v-else>Отсутствует</section>&ndash;&gt;-->
      <!--            </div>-->
      <!--            <section class="LayoutProd_Block_WrapperQrCode_CurDate">{{ nomenclature['CUR_DATE'] }}</section>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="LayoutProd_Block_WrapperQrCode">-->
      <!--          <img class="LayoutProd_Block_WrapperQrCode_QrCode"-->
      <!--               :src="'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chld=L|0&chl='+nomenclature['CORRECT_URL_QR_CODE']">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->
    </table>
<!--  </div>-->
</template>

<script>
    export default {
        name: "LayoutNomenclature",
        props: ['id_prod_from_parent', 'detailNomenclature'],
        data: () => ({
            arr_data_order: []
        }),
        mounted() {
          if (this.detailNomenclature && Object.keys(this.detailNomenclature).length) {
            this.arr_data_order.push(this.detailNomenclature)
            setTimeout(() => {
              this.print()
            }, 500)
          } else {
            this.loadData()
          }
        },
        computed: {
        },
        methods:{
          INTERNAL_ARTICLE(data) {
            return data?.INTERNAL_ARTICLE ? data.INTERNAL_ARTICLE : ''
          },
          NAME_DATA(data) {
            return data?.NAME ? data.NAME : ''
          },
          refactorDataToTables(data) {
            const arr = [[[],[]],[[],[]]]
            const refactorArr = []
            let row = []
            const column = []

            for (let i = 0; i < data.length; i++) {
              if ((i+1) % 2 !== 0) {
                row.push(data[i])
              } else {
                row.push(data[i])
                this.arr_data_order.push(row)
                row = []
              }
            }
            this.print()
          },
          loadData(){
            const _this = this;
            console.log(this.id_prod_from_parent)
            BX.ajax.runComponentAction('nk:production.print_layout',
                'executeGetDataProd', { // Вызывается без постфикса Action
                  mode: 'class',
                  data: {
                    'post':  {
                      'id_prod': (!!_this.id_prod_from_parent) ? _this.id_prod_from_parent : _this.$route.query.id_prod,
                    },
                    'login': _this.$store.state.login,
                    'token': _this.$store.state.token,
                  },
                })
                .then(function(response) {
                  console.log('then loadData response');
                  console.log(response);
                  if (response.status === 'success') {
                    _this.refactorDataToTables(response.data)
                    // _this.arr_data_order = response.data;
                    // console.log(_this.arr_data_order)
                    // _this.print();
                  }
                })
                .catch((response) => {
                  console.log(response);
                });
          },
          print(){
            this.$nextTick(() => {

              const main_vue_component = this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
              main_vue_component.printed_setTimeout(this, '#wrapper_layout_prod');

              // w.close();
              //     // console.log(this.arr_data_order);
              //     this.$htmlToPaper('print_layout_prod', null, () => {
              //         console.log('Printing completed or was cancelled!');
              //         this.$emit('printing-completed')
              //     });
            })
          }
        }
    }
</script>

<style lang="scss">
    //@import "../../public/print_layout.css";

    /* TODO стиди неактуальны смотри в файле Print_layout.css*/
    /* Стили для печатной формы Этикеток продукции */
    @media print {
      @page {
        size: auto;
        margin: 0;
        padding: 0;
      }
      header {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      .LayoutProd {
        display: table;
        grid-template-columns: 30% auto;
        font-size: 16px;
        margin-left: 10px;
      }
      .LayoutProd-wrapper {
        display: flex;
        flex-direction: column;
        border: 1px solid;
        grid-column-gap: 10px;
        padding: 5px 5px 3px 5px;
      }
      .LayoutProd_Block {
        background: white;
        display: grid;
        grid-template-columns: 1fr 50%;
        grid-column-gap: 1em;
        font-size: 1em;
        position: relative;
      }
      .LayoutProd_Block_PreviewProd, .LayoutProd_Block_WrapperQrCode_QrCode {
        width: 100%;
        height: auto;
      }
      .LayoutProd_Block_Info {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .LayoutProd_Block_Info_DrawingNumber {
        font-size: 0.8em;
        text-align: justify;
        display: flex;
        flex-direction: column;
        padding-bottom: 3px;
      }
      .LayoutProd_Block_Info_DrawingNumber_Data {
        font-weight: bold;
      }
      .LayoutProd_Block_Info_DrawingNumber_Header {
        font-size: 11px;
        font-style: italic;
        word-break: break-word;
      }
      .LayoutProd_Block_WrapperQrCode_CurDate {
        //margin-top: auto;
        align-self: start;
        font-size: 1em;
      }
    }
</style>
