<template>
    <v-app class="container">

        <v-form v-model="valid" class="login container" @submit.prevent="login">
            <v-container>
                <v-row>
                    <h1>Авторизация</h1>
                </v-row>
            </v-container>
            <v-container>
                <v-row class="wrapper_input">
                    <v-text-field
                            type="text"
                            name="login_user"
                            v-model="login_user"
                            label="Введите логин"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules"
                            single-line
                    ></v-text-field>
                    <v-text-field
                            type="password"
                            name="password_user"
                            v-model="password_user"
                            label="Введите пароль"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules"
                            single-line
                    ></v-text-field>
                </v-row>
            </v-container>
            <v-container>
                <v-row>
                    <v-alert v-if="$store.state.status === 'error'"
                             dense
                             dismissible
                             outlined
                             prominent
                             text
                             type="error"
                    >
                        {{ $store.state.error_msg }}
                    </v-alert>
                </v-row>
            </v-container>
            <v-container>
                <v-row>
                    <v-btn type="submit"
                           color="primary"
                           elevation="2"
                           large
                           rounded
                           block
                    >
                        Войти
                    </v-btn>
                </v-row>
            </v-container>
        </v-form>
    </v-app>
</template>

<script>
    export default {
        name: "Login",
        data(){
            return {
                valid: false,
                rules: [
                    value => !!value || 'Обязательное для заполнение поле',
                ],
                login_user: "",
                password_user: ""
            }
        },
        computed : {},
        methods: {
            login: function () {
                let login_user = this.login_user;
                let password_user = this.password_user;
                this.$store.dispatch('login', { login_user, password_user })
                    .then(() => {
                        console.log('AUTH SUCCESS');
                        this.$router.push(this.$router.history._startLocation);
                    })
                    .catch(err => console.log(err))
            },
        }
    }
</script>

<style lang="scss" scoped>
    form.login{
        display: grid;
        justify-content: center;
        color: whitesmoke;
        background: dimgrey;
        border-radius: 10px;
        max-width: 600px;
        h1{
            margin: auto;
        }
        .wrapper_input{
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 3em;
            .v-label{
                color: whitesmoke!important;
            }
        }
        @media (max-width:768px) {
            &{
                justify-content: normal;
                .wrapper_input {
                    grid-template-columns: auto;
                }
            }
        }
    }
</style>