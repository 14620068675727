var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mobile_menu"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('div',{staticClass:"\n          specification_card__header__title__wrapper__icons\n          mobile_menu__icons\n        "},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":_vm.$route.name === 'SpecificationCreate'
                  ? 'green lighten-1'
                  : 'grey lighten-1'},on:{"click":function($event){return _vm.goToPageViewOfCreate()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle-outline ")])]}}])},[_c('span',[_vm._v("Создать новую спецификацию")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":'grey lighten-1',"disabled":_vm.activeIconEdit},on:{"click":function($event){return _vm.goToTree()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pine-tree ")])]}}])},[_c('span',[_vm._v("Дерево элементов")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":_vm.$route.name === 'SpecificationEdit'
                  ? 'green lighten-1'
                  : 'grey lighten-1',"disabled":_vm.activeIconEdit},on:{"click":function($event){return _vm.goToPageViewOfEdit('SpecificationEdit', _vm.$route.params.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.textEditTooltip))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" fa-solid fa-file-pdf ")])]}}])},[_c('span',[_vm._v("Сохранить как документ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":"grey lighten-1"},on:{"click":function($event){_vm.print_layout_nomenclature = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-printer ")])]}}])},[_c('span',[_vm._v("Отправить на печать")])])],1)])],1),_c('div',{staticClass:"specification_card__header__title__wrapper__icons desktop_menu"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":_vm.$route.name === 'SpecificationCreate'
              ? 'green lighten-1'
              : 'grey lighten-1'},on:{"click":function($event){return _vm.goToPageViewOfCreate()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle-outline ")])]}}])},[(_vm.$route.name !== 'SpecificationCreate')?_c('span',[_vm._v("Создать новую спецификацию")]):_c('span',[_vm._v("Отменить создание нового элемента")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":'grey lighten-1',"disabled":_vm.activeIconEdit},on:{"click":function($event){return _vm.goToTree()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pine-tree ")])]}}])},[_c('span',[_vm._v("Дерево элементов")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":_vm.$route.name === 'SpecificationEdit'
              ? 'green lighten-1'
              : 'grey lighten-1',"disabled":_vm.activeIconEdit},on:{"click":function($event){return _vm.goToPageViewOfEdit('SpecificationEdit', _vm.$route.params.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.textEditTooltip))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":"grey lighten-1","disabled":_vm.activeIconEdit}},'v-icon',attrs,false),on),[_vm._v(" fa-solid fa-file-pdf ")])]}}])},[_c('span',[_vm._v("Сохранить как документ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"yellow_icon_active",attrs:{"large":"","color":"grey lighten-1","disabled":_vm.activeIconEdit},on:{"click":function($event){_vm.print_layout_nomenclature = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-printer ")])]}}])},[_c('span',[_vm._v("Отправить на печать")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }