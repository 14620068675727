<template>
  <div>
    <qrcode-stream
        style="width: 100vw; height: 100vh;"
        @decode="onDecode" @init="onInit"
    ></qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'


export default {
  name: "QrCodeViewer",
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  data: () => ({
  }),
  methods:{
    onDecode(url) {
      const query = new URL(url).search;
      this.$router.push('/nomenklatura/'+query);
    },
    onInit(promise) {
      promise
          .then(console.log)
          .catch(console.error)
    }
  }
}
</script>

<style scoped>

</style>