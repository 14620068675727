<template>
  <div>
    <div id="ListProductOnStore">
      <!-- Блок фильтров -->
      <div class="invisible block_filters_universal" v-show="$store.state.state_block_filters || contractorView">
        <div class="block_filter">
          <live-search
              @listen-data-prod="(data) => { this.nomenclature_data = (!!data) ? data : {} }"
              :disableProdInput="disableProdInput"
          />
          <v-autocomplete solo dense
                          :loading="!$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION"
                          :items="$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION"
                          item-value="CODE" item-text="DESCRIPTION"
                          return-object
                          clearable
                          @click:clear="$nextTick(() => { $store.state.products_on_store.data_filters.stage_of_production = null})"
                          label="Вcе склады"
                          placeholder="Все склады"
                          :menu-props="{closeOnContentClick: true}"
                          v-model="$store.state.products_on_store.data_filters.stage_of_production"
          >
          </v-autocomplete>
        </div>
      </div>


      <div class="wrapper_list_remains">
        <div class="row_remains"
             v-if="$store.state.products_on_store.list_products_on_store"
             v-for="(product, key) in $store.state.products_on_store.list_products_on_store"
             :key="product.ID"
             :class="{row_remains_modal: contractorView}"
        >
          <section class="display">
            <img class="image"
                 :src="$store.state.VUE_DEV_LOCAL_SERVER+product.PICTURE.SRC"
                 :title="product.PICTURE.TITLE"
                 :width="product.PICTURE.WIDTH"
                 @error="replaceByDefault"
            >
          </section>
          <section class="wrapper_name_articul">
            <a @click="open_dialog_nomenclature(product.PRODUCT_ID)" class="href_to_nomenclature">
              <section class="name_prod" :data-id-prod="product.PRODUCT_ID">{{ product.NAME }}</section>
            </a>
            <section class="drawing_number">
              ч.н: <span>{{ product.DRAWING_NUMBER }}</span>
            </section>
            <section class="articul">
              Арт: <span>{{ product.ARTICUL }}</span>
            </section>
          </section>
          <div class="wrapper_quantity">
            <div class="wrapper_quantity__titles" :class="{wrapper_quantity__titles_modal: contractorView}">
              <p class="store_name">
                {{ product.STORE_NAME }}
              </p>
              <p class="store_name">
                Кол-во в упаковке
              </p>
              <p class="store_name" v-if="contractorView">
                Общая стоимость /<br> Стоимость за шт.
              </p>
            </div>
            <div class="wrapper_quantity__items" :class="{wrapper_quantity__items_modal: contractorView}">
              <div>
                <v-text-field
                    hide-details
                    class="EDITABLE_QUANTITY"
                    v-model="product.AMOUNT"
                    :placeholder="product.DEFAULT_AMOUNT"
                    min="0"
                    type="number"
                    @input="sendEditableAmount(product)"
                    :loading="((!!product.data_update_commentary) && product.data_update_commentary.code === 'send')"
                >
                  <template slot="prepend-inner">
                    <v-icon small class="EDITABLE_QUANTITY__prepend" @click="onChangeQuantity('minus', product)">mdi-minus</v-icon>
                  </template>
                  <template slot="append">
                    <v-icon small class="EDITABLE_QUANTITY__append" @click="onChangeQuantity('plus', product)">mdi-plus</v-icon>
                  </template>
                </v-text-field>
                <section class="item MINIMUM_QUANTITY_GIDRUSS" v-if="product.MINIMUM_QUANTITY_GIDRUSS">НО: {{ product.MINIMUM_QUANTITY_GIDRUSS }} шт.</section>
                <section class="item MINIMUM_QUANTITY_GIDRUSS" v-else>НО: - </section>
              </div>
              <div>
                <section class="item MINIMUM_QUANTITY_GIDRUSS" v-if="product.HOW_MANY_PIECES_IN_PACKAGE">{{ product.HOW_MANY_PIECES_IN_PACKAGE }} шт.</section>
                <section class="item MINIMUM_QUANTITY_GIDRUSS" v-else> - </section>
              </div>
              <div class="wrapper-price-bloc" v-if="contractorView">
                <span class="totalPrice">{{ getTotal(product) }} руб.</span>
                <div class="priceDivider"></div>
                <span class="pricePerItem">{{ product.perProd }} руб./шт.</span>
              </div>
            </div>
          </div>
        </div>

        <v-progress-circular
            style="margin: auto;"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
            v-if="$store.state.state_progress_circular_page"
        ></v-progress-circular>
      </div>

      <v-alert
          v-if="!$store.state.products_on_store.list_products_on_store.length && !$store.state.state_progress_circular_page"
          type="error"
          text
          class="err-msg"
      >
        Ничего не найдено
      </v-alert>
      <v-btn
          block
          v-if="!$store.state.state_progress_circular_page"
          @click='() => { $store.state.products_on_store.count_data.NUM_PAGE++; $store.dispatch("getListProducts") }'
          :disabled="$store.state.products_on_store.count_data.RECORDS_TO_END <= 0"
          style="margin: 1em 0"
      >
        Показать ещё {{ $store.state.products_on_store.count_data.RECORDS_TO_END }}
      </v-btn>

      <!-- Диалоговое окно с номенклатурой -->
      <v-dialog
          v-model="dialog_nomenclature_window.state"
          max-width="600px"
          content-class="dialog_nomenclature"
      >
        <v-card id="v_dialog_nomenclature_window">
          <v-card-title>
            <span class="headline">Номенклатура</span>
            <v-icon
                class="close_dialog_cross"
                color="primary"
                elevation="2"
                @click="dialog_nomenclature_window.state = false"
            >fas fa-times
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <nomenclature :dialog_id_order="dialog_nomenclature_window.id_order"></nomenclature>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Nomenclature from "./nomenclature/Nomenclature";
import LiveSearch from "../components/LiveSearch";

export default {
  components: {
    Nomenclature,
    LiveSearch,
  },
  name: "ListProductionOnStore",
  props: ['contractorView'],
  data: () => ({
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    disableProdInput: true,
  }),
  mounted() {
    this.initializeQuery()
    if (Object.keys(this.$route.query).includes('subtype_prod')) {
      this.$store.state.state_block_filters = true;
    }
    this.disableProdInput = !this.contractorView
  },
  watch: {
    /* Смотрим за фильтрами чтобы отфильтровать отпагинировать */
    '$store.state.products_on_store.data_filters.stage_of_production': {
        handler: function watch() {
          this.updateQuery()
          this.$store.state.products_on_store.count_data.NUM_PAGE = 1;
          this.$store.dispatch("getListProducts");
        },
    },
    '$store.state.data_nomenclature': {
      handler: function watch() {
        this.$store.state.products_on_store.count_data.NUM_PAGE = 1;
        this.$store.dispatch("getListProducts");
      },
    },
    '$store.state.data_take_product_on_store.counter_call':{
      handler: function watch() {
        this.$store.state.products_on_store.count_data.NUM_PAGE = 1;
        this.$store.dispatch("getListProducts");
      }
    }
  },
  methods: {
    initializeQuery() {
      if (Object.keys(this.$route.query).includes('stage')) {
        if (this.$store.state.data_production?.ARR_FILTER_STAGE_OF_PRODUCTION?.length) {
          this.$nextTick(() => {
            this.$store.state.products_on_store.data_filters.stage_of_production = this.$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION.filter((elem) => this.$route.query['stage'] === elem.ID)[0]
          })
        } else {
          this.$store.dispatch("SetDataProduction").then(() => {
            this.$nextTick(() => {
              this.$store.state.products_on_store.data_filters.stage_of_production = this.$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION.filter((elem) => this.$route.query['stage'] === elem.ID)[0]
            })
          })
        }
      } else if (Object.keys(this.$route.query).length === 0) {
        this.$store.dispatch("getListProducts");
      }
    },
    updateQuery() {
      let queryObj = {}
      if (Object.keys(this.$route.query).length) {
        for (let key in this.$route.query) {
          Object.assign(queryObj, {[key]: this.$route.query[key]})
        }
      }
      if (!!this.$store.state.products_on_store?.data_filters.stage_of_production?.ID) {
        Object.assign(queryObj, {'stage': this.$store.state.products_on_store.data_filters.stage_of_production.ID})
      } else {
        delete queryObj.stage
      }
      console.log(queryObj)
      this.pushRouter(queryObj)
    },
    pushRouter(object) {
      this.$store.commit('updateURL_components', object)
    },
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    onChangeQuantity(operation, product) {
      const planned_quantity = (!!product.AMOUNT) ? product.AMOUNT : 0;
      const pieces_in_package = (!!product.HOW_MANY_PIECES_IN_PACKAGE) ? product.HOW_MANY_PIECES_IN_PACKAGE : 0;

      if (!!pieces_in_package){
        /* Остаток */
        const aliquot = planned_quantity % pieces_in_package;
        /* Вычисляем логику декремента / инкремента от остатков / упаковки */
        const decrement_aliquout = parseInt((!!aliquot) ? aliquot : pieces_in_package);
        const increment_aliquout = pieces_in_package - aliquot;

        if (operation === 'minus' && !!planned_quantity) {
          product.AMOUNT = parseInt(planned_quantity) - parseInt(decrement_aliquout);
          this.sendEditableAmount(product)
        }
        if (operation === 'plus') {
          product.AMOUNT = parseInt(planned_quantity) + parseInt(increment_aliquout);
          this.sendEditableAmount(product)
        }
      }
    },
    sendEditableAmount(product){
      const _this = this;

      if (_this.$store.state.debounceTimeout)
        clearTimeout(_this.$store.state.debounceTimeout);

      _this.$store.state.debounceTimeout = setTimeout(() => {
        console.log('sendEditableAmount');

        product.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('send');

        console.log(product.data_update_commentary)
        console.log(product)

        if (product.AMOUNT.length > 1 && product.AMOUNT.charAt(0) === '0') {
          _this.$nextTick(() => {product.AMOUNT = parseInt(product.AMOUNT.slice(1))})
        } else if (parseInt(product.AMOUNT) <= 0 || Number.isNaN(parseInt(product.AMOUNT))) {
          _this.$nextTick(() => {product.AMOUNT = 0})
        }
        // console.log(obj_data);
        BX.ajax.runComponentAction('nk:production.list_product_on_store',
            'executeSetAmountOnStore', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                post: {
                  id: product.ID,
                  id_store: product.STORE_ID,
                  product_amount: product.AMOUNT,
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function (response) {
              if (response.status === 'success') {
                console.log(response);

                if (response.data.state) {
                  setTimeout(() => {
                    _this.$nextTick(() => {
                      product.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('success');
                      // console.log('TIMEOUT');
                      _this.$store.dispatch('setSystemNotificationResult', response.data);
                      _this.$store.state.products_on_store.count_data.NUM_PAGE = 1;
                      _this.$store.dispatch("getListProducts");
                    })
                  }, 1000);
                }
              }
            })
            .catch((response) => {
              console.log(response);
              product.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('error');
              _this.$store.dispatch('setSystemNotificationResult', response);
            });

      }, 1000);
    },
    open_dialog_nomenclature(order_id) {
      this.dialog_nomenclature_window.id_order = order_id;
      this.dialog_nomenclature_window.state = true;
    },
    getTotal(product) {
      return product.total = parseInt(product.perProd) * parseInt(product.AMOUNT)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/main";
.v-application {
  font-family: inherit;

  a {
    color: unset !important;
  }
}

#ListProductOnStore {
  color: white;

  .wrapper_filters {
    position: sticky;
    top: 0px;
    padding: 15px 15px;
    background: darkgrey;
    z-index: 1;

    .v-text-field__details {
      display: none;
    }
  }

  .wrapper_list_remains {
    display: grid;
    margin-top: 1em;

    @media (max-width: 768px) {
      .row_remains {
        grid-template-columns: 25% 30% auto !important;

        .display {
          background: #FFFFFF !important;
          border-radius: 5px !important;
          height: 90px !important;
        }
        .image {
          width: 100% !important;
          border-radius: 5px !important;
        }
      }
    }

    .row_remains {
      display: grid;
      grid-template-columns: 30% 30% auto;
      grid-column-gap: 1em;
      align-items: center;
      background: #454444;
      @extend %padding-row-position;
      @extend %margin-row-position;
      @extend %border-universal;
      border-color: map-get($border-universal-color, 'желтый');
      font-size: 0.9em;

      .image {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
        border-radius: 5px;
        background-color: #FFFFFF;
      }

      .display {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 130px;
        max-width: 100%;
      }

      .wrapper_name_articul {
        align-self: start;
        text-align: left;
        display: grid;
        height: 100%;
        align-content: space-around;
        grid-row-gap: 5px;
        color: lightgrey;
        .name_prod {
          font-size: 1.1em;
          cursor: pointer;
        }
        .articul, .drawing_number{
          font-size: 0.9em;
          color: grey;
          span{
            color: lightgrey;
            font-size: 1em;
            opacity: 0.9;
          }
        }
      }

      .wrapper_quantity {
        align-self: start;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding-right: 20px;
        word-break: break-word;

        &__titles {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 5px;
          .store_name {
            font-size: 0.9em;
            color: lightyellow;
            margin-bottom: 0;
          }
        }

        .wrapper_quantity__titles_modal {
          grid-template-columns: 1fr 1fr 1fr;
        }

        &__items {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          column-gap: 5px;
          .EDITABLE_QUANTITY {
            -moz-appearance:textfield !important;
            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            &__append {
              cursor: pointer !important;
              color: #FFFFFF;
            }
            &__prepend {
              cursor: pointer !important;
              color: #FFFFFF;
            }
          }
          .MINIMUM_QUANTITY_GIDRUSS{
            color: grey;
          }
          .wrapper-price-bloc {
            display: flex;
            flex-direction: column;
            .priceDivider {
              width: 100%;
              height: 1px;
              background-color: #FFFFFF;
            }
            .totalPrice {
              font-size: 1.2em;
              padding: 6px 0;
            }
          }
        }
        .wrapper_quantity__items_modal {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
    .row_remains_modal {
      grid-template-columns: 20% 30% auto;
      .display {
        height: 110px;
      }
    }
  }

  ::v-deep .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    ::v-deep .v-text-field__slot {
      font-size: 0.75em !important;
      input {
        padding: 0 !important;
      }
    }
    .row_remains {
      grid-column-gap: 0.7em !important;
      .wrapper_name_articul {
        .articul, .drawing_number{
          font-size: 0.8em !important;;
          color: grey;
          span{
            color: lightgrey;
            font-size: 0.9em !important;
            opacity: 0.9;
          }
        }
        .name_prod {
          font-size: 0.85em !important;
        }
      }
      .wrapper_quantity {
        padding-right: 0 !important;
        word-break: break-all;
        &__titles {
          grid-template-columns: auto 35% !important;
        }
        &__items {
          grid-template-columns: auto 35% !important;;
        }
        .MINIMUM_QUANTITY_GIDRUSS {
          padding-top: 2px;
          font-size: 0.65em !important;
        }
        .store_name {
          font-size: 0.7em !important;
        }
      }
    }
  }
}
</style>
