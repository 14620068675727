<template>
  <MetaMaket :class="!!quantity ? setColor : ''" :checkCounts="checkCounts">
    <template v-slot:info>
      <div
        style="cursor: pointer"
        @click="openChild"
        class="operation_card-info"
      >
        <div class="operation_card__number">
          <div class="operation_card__number__round">
            {{ index + 1 }}
          </div>
        </div>
        <div class="operation_card__info">
          <div class="operation_card__info__block">
            <span class="operation_card__info__block__name">
              {{ data_elem.NAME }}
            </span>
          </div>
          <span class="operation_card__info__drawing_number">
            {{ data_elem.DRAWING_NUMBER }}
          </span>
        </div>
      </div>
    </template>

    <template v-slot:related>
      <div class="operation_card__right">
        <div class="operation_card__right_items">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="operation_card__right_nom" v-on="on" @click.stop>
                <span @click="openDetail">
                  {{
                    data_elem.QUANTITY_BIND_NOMENCLATURE
                      ? data_elem.QUANTITY_BIND_NOMENCLATURE
                      : 0
                  }}
                </span>
              </div> </template
            ><span>Количество привязанной номенклатуры</span></v-tooltip
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="operation_card__right_nom" v-on="on">
                <span>{{
                  data_elem.QUANTITY_PARENTS ? data_elem.QUANTITY_PARENTS : 0
                }}</span>
              </div> </template
            ><span>Количество родительских элементов</span></v-tooltip
          >
        </div>
      </div></template
    >

    <template v-slot:edit>
      <div
        class="operation_card__edit"
        :class="{ check_color_counts: checkCounts }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div
              class="operation_card__right_counts"
              v-if="
                getActionPage($route) === 'edit' ||
                getActionPage($route) === 'create'
              "
              v-on="on"
              @click.stop
            >
              <v-text-field
                justify-right
                class="operation_card__counts-input"
                style="width: 80px; text-align: center"
                type="number"
                min="0"
                @focus="deactivateTooltip"
                solo
                v-model.number="data_elem.QUANTITY_NODES"
                hide-details
                placeholder="0"
              ></v-text-field>
            </div>

            <div
              v-if="quantity && getActionPage($route) === 'view'"
              class="operation_card__right_counts"
              v-on="on"
            >
              <span>{{
                data_elem.QUANTITY_NODES ? data_elem.QUANTITY_NODES : 0
              }}</span>
            </div>
          </template>
          <span v-if="$route.name === 'SpecificationView'">Количество</span>
          <span v-else>Изменить количество</span>
        </v-tooltip>

        <v-hover v-slot="{ hover }">
          <div
            class="operation_card__buttons"
            v-if="
              getActionPage($route) === 'edit' ||
              getActionPage($route) === 'create'
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="hover ? 'grey lighten-5' : 'grey lighten-1 '"
                  @click.stop="
                    setSimpleModal({
                      message: {
                        text: 'Удалить номенклатуру из спецификации?',
                        name: data_elem.NAME,
                      },
                      data_obj: data_elem,
                      component_name: $options.name,
                    })
                  "
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>Удалить номенклатуру из спецификации</span>
            </v-tooltip>
          </div>
        </v-hover>
      </div>
    </template>
  </MetaMaket>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MetaMaket from '../nomenclature/MetaMaket.vue'

export default {
  name: 'SubDetailCard',
  props: {
    index: {
      type: Number,
    },
    data_elem: {
      type: Object,
    },
    quantity: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    canEdit: false,
    activeTooltip: true,
    checkCounts: false,
  }),
  computed: {
    ...mapGetters('specificationStore', ['getActionPage']),
    setColor() {
      if (parseInt(this.data_elem.QUANTITY_BIND_NOMENCLATURE) < 1) {
        return 'red_color'
      } else if (parseInt(this.data_elem.QUANTITY_BIND_NOMENCLATURE) === 1) {
        return 'yellow_color'
      } else {
        return 'green_color'
      }
    },
  },
  methods: {
    ...mapActions('specificationStore', ['deleteChildNomenclature']),
    ...mapActions(['setSimpleModal', 'hideSimpleModal']),
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    openDetail() {
      this.$emit('openModal', this.data_elem)
    },
    setToEditMode() {
      this.canEdit = !this.canEdit
    },
    setCount() {
      this.$emit('setCount', {
        count: this.data_elem.QUANTITY_NODES,
        id: this.data_elem.ID,
      })
    },
    openChild() {
      if (!this.data_elem.ID) return false
      let childLink = this.$router.resolve({
        name: 'SpecificationView',
        params: { id: this.data_elem.ID },
      })
      window.open(childLink.href, '_blank')
    },
    deactivateTooltip() {
      this.checkCounts = true
      // console.log(this.$refs.reference.isActive)
      this.$refs.reference.isActive = false
    },
  },
  watch: {
    '$store.state.simpleModal': {
      handler: function (obj) {
        if (
          obj.component_name === this.$options.name &&
          obj.state_accept_event
        ) {
          this.deleteChildNomenclature(obj.data_obj)
          this.hideSimpleModal()
        }
      },
      deep: true,
    },
  },
  components: { MetaMaket },
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/detailSpecification';
@import '../../assets/sass/main';

.operation_card {
  &:hover {
    border-color: $border_yellow_color !important;
  }
  &__edit {
    display: flex;
  }
  &__buttons {
    display: flex;
    align-items: center;
    height: 100%;
    // border-left: 1px solid #8f8f8f40;
    // padding-left: 10px;

    & button {
      height: 100%;
      padding-left: 10px;
      // padding-right: 10px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }

  &__right {
    // border-left: 1px solid #8f8f8f40;
    // padding-left: 14px;

    &_items {
      display: flex;
      gap: 6px;
      flex-direction: column;
    }

    &_counts {
      span {
        color: rgb(220, 220, 220);
        border: 1px solid #bdbdbd;
        display: flex;
        border-radius: 3px;
        font-size: 24px;
        width: 68px;
        min-width: 40px;
        min-height: 40px;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
      }
      display: flex;
      align-items: center;
      font-weight: 600;
      gap: 14px;
      padding-left: 10px;
      &-input {
        width: 100px;
        text-align: right;
      }
    }
    &_nom {
      color: rgb(220, 220, 220);
      border: 1px solid #bdbdbd;
      border-radius: 3px;
      min-width: 29px;
      min-height: 29px;
      font-weight: 600;
    }
  }
}
.viewBorder {
  border-left: none;
}

@media screen and (max-width: 600px) {
  .operation_card__buttons {
    padding: 0 10px;
    & button {
      padding-top: 10px;
      width: 100%;
    }
  }

  .operation_card__right {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    padding-right: 10px;
    // margin-top: 18px;
    border: none;
    align-items: center;
    &_counts {
      padding: 0;
    }
    &_items {
      flex-direction: row;
    }
  }

  .sub_detail {
    display: block;
  }

  .operation_card__number {
    width: 60px;
    height: 60px;
  }

  .operation_card__info__block__name {
    display: -webkit-box;
    -webkit-line-clamp: 2; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .operation_card__edit {
    align-items: baseline;
  }
}
</style>
