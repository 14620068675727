<template>
  <div :class="{onMobile: isMobile, wrapper_block_contractor: !isMobile}">
    <div class="wrapper_block_contractor__producer">
      <!-- <p>Производитель:</p> -->
      <v-autocomplete
          label="Производитель"
          hide-details
          dense
          :solo="isMobile"
          :dark="!isMobile"
          :items="listContractors.DATA"
          :loading="$store.state.isLoading"
          :disabled="(PRODUCER !== null && !!Object.keys(PRODUCER).length) || $store.state.isLoading"
          clearable
          item-text="PERSON_NAME"
          v-model="PRODUCER"
          return-object
          :search-input.sync="contractor_search_producer"
          @update:search-input="updateContractor('producer')"
          @change="() => {changeContractorBlock({
            'id_elem': $store.state.NomenclatureModal.ID, 'code_prop':'PRODUCER', 'value_prop': PRODUCER ? PRODUCER.CUSTOM_CONTRACTOR_ID : null
            })}"
          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersect" />
        </template>
      </v-autocomplete>
    </div>
    <div class="wrapper_block_contractor__default">
      <!-- <p>Поставщик по умолчанию:</p> -->
      <v-autocomplete
          label="Поставщик по умолчанию"
          hide-details
          dense
          :solo="isMobile"
          :dark="!isMobile"
          :items="listContractors.DATA"
          :loading="$store.state.isLoading"
          :disabled="(CUSTOM_CONTRACTOR !== null && !!Object.keys(CUSTOM_CONTRACTOR).length) || $store.state.isLoading"
          clearable
          item-text="PERSON_NAME"
          return-object
          v-model="CUSTOM_CONTRACTOR"
          :search-input.sync="contractor_search_custom_contractor"
          @update:search-input="updateContractor('default')"
          @change="() => {changeContractorBlock({
            'id_elem': $store.state.NomenclatureModal.ID, 'code_prop':'CONTRACTORS', 'value_prop': CUSTOM_CONTRACTOR ? CUSTOM_CONTRACTOR.CUSTOM_CONTRACTOR_ID : null
            })}"
          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersect" />
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailContractorsSelectors",
  props: ['isMobile'],
  data: () => ({
    listContractors: {
      DATA: [],
      COUNT: {
        NUM_PAGE: 1,
      },
    },
    debounceTimeout: null,
    debounceTimeoutSearch: null,
    CUSTOM_CONTRACTOR: null,
    PRODUCER:null,
    contractor_search: null,
    contractor_search_producer: null,
    contractor_search_custom_contractor: null,
    shouldUpdateSearch: true,
    activatedWatch: false,
    oldValues: {
      PRODUCER: null,
      CUSTOM_CONTRACTOR: null,
    },
    contractorByRequest: {},
  }),
  mounted() {
    this.activatedWatch = false
    this.getListContractors().then(() => {
      this.initialiseContractors()
      this.activatedWatch = true
    })
    this.$store.state.NomenclatureModal = this.nomenclature_data
  },
  watch: {
    '$store.state.NomenclatureModal': {
      handler() {
        if (this.activatedWatch) {
          this.initialiseContractors()
        }
      },
    },
    'CUSTOM_CONTRACTOR': {
      handler() {
        this.$store.state.contractorByDefault = Object.assign({}, this.CUSTOM_CONTRACTOR)
      }
    },
  },
  methods: {
    getOldValues() {
      this.oldValues.CUSTOM_CONTRACTOR = this.CUSTOM_CONTRACTOR
      this.oldValues.PRODUCER = this.PRODUCER
    },
    initialiseContractors() {
      if (this.$store.state.NomenclatureModal && Object.keys(this.$store.state.NomenclatureModal).length) {
        if (this.$store.state.NomenclatureModal.PRODUCER_ID) {
          console.log('PRODUCER_ID')
          this.getContractorById(this.$store.state.NomenclatureModal.PRODUCER_ID).then(() => {
            this.PRODUCER = Object.assign({}, this.contractorByRequest)
            this.getOldValues()
          })
        }
        if (this.$store.state.NomenclatureModal.CONTRACTOR_BY_DEFAULT_ID) {
          console.log('CONTRACTOR_BY_DEFAULT_ID')
          this.getContractorById(this.$store.state.NomenclatureModal.CONTRACTOR_BY_DEFAULT_ID).then(() => {
            this.CUSTOM_CONTRACTOR = Object.assign({}, this.contractorByRequest)
            this.getOldValues()
          })
        }
      }
    },
    getContractorById(id) {
      return new Promise((resolve, reject) => {
        this.$store.state.isLoading = true
        const _this = this;

        console.log(id)

        /* Запрашиваем список поставщиков  */
        BX.ajax.runComponentAction('nk:production.list_contractors',
            'executeGetListContractors', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                post: {
                  get_list_contractor: 'Y',
                  num_page: 1,
                  name: '',
                  id_contractor: id ? id : ''
                  // type_contractor: (!!_this.type_contractor) ? _this.type_contractor?.UF_XML_ID : null,
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function (response) {
              if (response.status === 'success') {
                _this.$store.state.isLoading = false
                _this.contractorByRequest = response.data.log_data.DATA ? response.data.log_data.DATA[0] : null
                if (response.data.log_data.DATA) {
                  _this.listContractors.DATA.push(response.data.log_data.DATA[0])
                }
                resolve()
              }
            })
            .catch((response) => {
              console.log(response);
              _this.$store.state.isLoading = false
              reject()
            })
      })
    },
    getListContractors(action) {
      return new Promise((resolve, reject) => {
        if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {

          this.$store.state.isLoading = true
          const _this = this;

          /* Запрашиваем список поставщиков  */
          BX.ajax.runComponentAction('nk:production.list_contractors',
              'executeGetListContractors', { // Вызывается без постфикса Action
                mode: 'class',
                data: {
                  post: {
                    get_list_contractor: 'Y',
                    num_page: _this.listContractors.COUNT.NUM_PAGE,
                    name: _this.contractor_search ? _this.contractor_search : '',
                    // type_contractor: (!!_this.type_contractor) ? _this.type_contractor?.UF_XML_ID : null,
                  },
                  'login': _this.$store.state.login,
                  'token': _this.$store.state.token,
                },
              })
              .then(function (response) {
                if (response.status === 'success') {
                  if (action === 'next_page') {
                    if (response.data.log_data !== null) {
                      if (response.data.log_data.DATA) {
                        _this.listContractors.DATA.push(...response.data.log_data.DATA)
                      }
                      _this.listContractors.COUNT = response.data.log_data.COUNT;
                    }
                  } else {
                    // Если форма успешно отправилась
                    console.log('executeGetListContractor');
                    console.log(response.data);
                    if (response.data.log_data.DATA !== null) {
                      _this.listContractors = response.data.log_data;
                    } else _this.listContractors.DATA = []
                  }
                  _this.$store.state.isLoading = false
                  resolve()
                  console.log(_this.PRODUCER)
                  console.log(_this.CUSTOM_CONTRACTOR)
                }
              })
              .catch((response) => {
                console.log(response);
                _this.$store.state.isLoading = false
                reject()
              })
        }, 500);
      })
    },
    updateContractor(action) {
      if (this.debounceTimeoutSearch) clearTimeout(this.debounceTimeoutSearch);
      this.debounceTimeoutSearch = setTimeout(() => {
        if (this.shouldUpdateSearch) {
          if (action === 'producer') {
            // this.shouldUpdateSearch = false
            console.log('producer changed')
            this.contractor_search = this.contractor_search_producer
            this.listContractors.COUNT.NUM_PAGE = 1
            this.getListContractors('next_page')
          } else if (action === 'default') {
            // this.shouldUpdateSearch = false
            this.contractor_search = this.contractor_search_custom_contractor
            this.listContractors.COUNT.NUM_PAGE = 1
            console.log('default changed')
            this.getListContractors('next_page')
          }
        }
      }, 500)
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.listContractors.COUNT.NUM_PAGE++
        this.getListContractors('next_page')
      }
    },
    changeContractorBlock(block) {
      this.$store.state.isLoading = true
      this.shouldUpdateSearch = false
      console.log(block)
      this.$store.dispatch('setPropertyItemAnyIblock', block).then(() => {
        this.shouldUpdateSearch = true
        this.$store.state.isLoading = false
      })
    },
  },
  beforeDestroy() {
    this.$store.state.contractorByDefault = {}
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/sass/main";

%margin-left-right{
  margin-left: 15px!important;
  margin-right: 15px!important;
}

.wrapper_block_contractor {
  display: flex;
  @extend %margin-left-right;
  justify-content: space-between;
  margin-bottom: 20px;
  &__producer {
    width: 45%;
  }
  &__default {
    width: 45%;
  }
}

.onMobile {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  margin-bottom: 0 !important;
  margin-left: unset !important;
  margin-right: unset !important;
  row-gap: 15px;

  .wrapper_block_contractor__producer {
    width: 100% !important;
  }
  .wrapper_block_contractor__default {
    width: 100% !important;
  }
}
</style>
