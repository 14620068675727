<template>
  <div id="wrapper_desktop" class="desktop-wrapper">
    <div class="desktop-container">
      <v-overlay
          :z-index="1"
          :absolute="true"
          :value="loading_order"
      >
        <v-progress-circular
            style="margin: auto;"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
        ></v-progress-circular>
      </v-overlay>
      <div
          v-for="tab in $store.state.data_access_user_group_user.ACCESS_USER_GROUP_DESKTOP_ACTION"
          :key="tab.id"
          class="desktop-container-window"
          style="text-decoration: none; color: inherit;"
      >
<!--      <div-->
<!--          v-for="tab in test"-->
<!--          :key="tab.id"-->
<!--          class="desktop-container-window"-->
<!--          style="text-decoration: none; color: inherit;"-->
<!--      >-->
        <div class="desktop-container-window-image" @click.prevent="onTouch(tab)">
          <div class="desktop-container-window-image__total" v-if="loading_total && !tab.counter">
            <v-overlay
                :z-index="0"
                :absolute="true"
                :value="loading_total"
            >
              <v-progress-circular
                  style="margin: auto;"
                  width="3"
                  id="progress_circular_loading_total"
                  color="gray"
                  :indeterminate="true"
              ></v-progress-circular>
            </v-overlay>
          </div>
          <div class="desktop-container-window-image__total" v-if="tab.counter">
            <p>{{ tab.counter }}</p>
          </div>
          <div class="desktop-container-window-image__wrapper">
            <img
                :src="require(`@/assets/svg/${tab.src}`)"
                alt=""
                class="desktop-container-window-image__wrapper__image"
            >
          </div>
        </div>
        <p class="desktop-container-window__name"> {{tab.name}} </p>
      </div>

    </div>
    <add-new-order
        v-if="$store.state.data_new_order.state_v_show"
        @listen-child-state="$store.state.data_new_order.state_v_show = false"
    />
  </div>
</template>

<script>
import AddNewOrder from "../components/AddNewOrder";

export default {
  components: {
    AddNewOrder
  },
  name: "Desktop",
  data: () => ({
    // loader
    loading_order: false,
    loading_total: false,
    shouldShow: false,
    test: [
      {
        'name': 'Закупка серийных позиций',
        'action': "Contractors",
        'sort': "13",
        'counter': null,
        'href': '/contractors/',
        'src': 'contractors_work.svg',
        queryParams: {
        },
      },
      {
        'name': 'Номенклатура',
        'action': "NomenclatureList",
        'sort': "14",
        'counter': null,
        'href': '/nomenclature/',
        'src': 'nomenklatura.svg',
        queryParams: {
        },
      },
      {
        'name': 'Остатки',
        'action': "ListRemains",
        'sort': "15",
        'counter': null,
        'href': '/nomenclatura/',
        'src': 'ostatki.svg',
        queryParams: {
        },
      },
    ]
  }),
  mounted() {
    this.loading_order = true
    this.loading_total = true

    if (Object.keys(this.$store.state.data_access_user_group_user).length){
        this.loading_total = false
        this.loading_order = false
    }
  },
  computed: {
  },
  watch: {
    // Смотрим за get запросом на доступ к действиям
    "$store.state.data_access_user_group_user": {
      handler: function watch() {
        console.log('Initialize check access');
        this.loading_total = false
        this.loading_order = false
      },
      deep:true
    },
    "$route.query": {
      handler: function watch() {
        if (this.shouldShow) {
          this.$store.commit('setStateAddNewOrder', true)
          this.shouldShow = false
        }
      },
      deep:true
    }
  },
  methods: {
    // Изменяем состояние в store
    onTouch(value) {
      function NewObj(value) {
        this.stage = value.queryParams?.ID_STAGE
        this.scenario = value.queryParams?.ID_SCENARIO
        this.subtype_prod = value.queryParams?.XML_ID_PROP
      }

      if (value.href === null) {
        this.shouldShow = true
        let queryData = Object.assign({}, {'querys': new NewObj(value)})
        console.log(queryData)
        this.$store.commit('changeShouldUpdateUrl', true)
        this.$store.dispatch('getUpdatedURL', queryData)
      } else {
        console.log(value)
        let queryData = Object.assign({}, {'querys': new NewObj(value)}, {'path': value.href})
        console.log(queryData)
        this.routerPush(queryData)
      }
    },
    // Обновляем URL
    routerPush(object) {
      this.$store.commit('updateURl_desktop', object)
    },
  }
};

</script>

<style lang="scss">
@import "../assets/sass/main";

@media (max-width: 440px) {
  .desktop-container-window-image__wrapper {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    overflow: hidden;
    //background: lightgrey;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: lighten(#363636, 10%);
    }

    &__image {
      width: 75px !important;
      height: 75px !important;
    }
  }

  .desktop-container-window-image__total {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    background: lightgrey;
    position: absolute;
    top: -10px;
    left: 74px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    p {
      color: red;
      margin-bottom: 0;
      text-decoration: none;
    }
  }
}

@media (max-width: 280px) {
  .desktop-container-window-image__wrapper {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
    overflow: hidden;
    //background: lightgrey;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: lighten(#363636, 10%);
    }

    &__image {
      width: 60px !important;
      height: 60px !important;
    }
  }
}

#progress_circular_list_order {
  width: 7em !important;
  height: 7em !important;
}

#progress_circular_loading_total {
  width: 3em !important;
  height: 3em !important;
}

.desktop-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-top: 20px;

  .desktop-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    row-gap: 20px;
    column-gap: 15px;
    padding: 15px;
    overflow: hidden;

    .desktop-container-window {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      //width: 300px;

      .desktop-container-window__name {
        color: white;
        text-decoration: none;
      }

      .desktop-container-window-image {
        position: relative;

        &:hover .desktop-container-window-image__wrapper {
          background: lighten(#363636, 10%);
        }

        &:hover ~ .desktop-container-window__name {
          color: lightyellow !important;
        }

        &__total {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: lightgrey;
          position: absolute;
          top: -15px;
          left: 145px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          p {
            color: black;
            margin-bottom: 0;
            text-decoration: none;
          }
        }

        &__wrapper {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          overflow: hidden;
          //background: lightgrey;
          border: 1px solid lightgrey;
          display: flex;
          justify-content: center;
          align-items: center;

          &__image {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}

</style>
