<template>
  <div :class="{wrapper_live_search: $store.getters.getModalNomenclatureData.state && !listNomenclature}">
    <!--  В СПИСКЕ НОМЕНКЛАТУРЫ (NOMENCLATURE_LIST)  -->
    <template v-if="listNomenclature">
      <div style="padding-bottom: 15px" v-if="!$store.getters.getModalNomenclatureData.state || listNomenclature">
        <v-select
            v-model="$store.state.selected_type_or_subtype_products"
            :items="$store.state.list_type_products"
            item-text="UF_NAME"
            item-value="UF_XML_ID"
            chips
            label="Выберите тип"
            solo dense hide-details
            return-object
            clearable
            @click:clear="$nextTick(() => { $store.state.selected_type_or_subtype_products = {} })"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
            @change="updateQuery(); updateItems()"
        >
        </v-select>
      </div>
      <div class="custom_group_search_select">
        <div class="v_select_filter">
          <v-autocomplete solo dense hide-details
                          :loading="$store.state.loading_nomenclature"
                          :items="$store.state.listNomenclatureByContractors.list_items"
                          item-text="NAME"
                          clearable
                          return-object
                          label="Введите наименование продукции"
                          @click:clear="$nextTick(() => { prod_data = {} })"
                          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                          v-model="$store.state.dataFromUrl.id_prod"
                          :rules="validate_rules.name_prod"
                          :search-input.sync="nomenclature_search"
                          @change="() => {updateQuery()}"
                          @update:search-input="updateItems()"
          >
          </v-autocomplete>
        </div>
      </div>
    </template>
    <!-- ДЕТАЛЬНАЯ НОМЕНКЛАТУРА + МОДАЛКИ   -->
    <template v-else>
      <div style="padding-bottom: 15px" v-if="!$store.getters.getModalNomenclatureData.state">
        <v-select
            v-model="$store.state.selected_type_or_subtype_products"
            :items="$store.state.list_type_products"
            item-text="UF_NAME"
            item-value="UF_XML_ID"
            chips
            label="Выберите тип"
            solo dense hide-details
            return-object
            clearable
            @click:clear="$nextTick(() => { $store.state.selected_type_or_subtype_products = {} })"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
            @change="updateQuery(); $store.dispatch('setListProductsByTypeAction');"
        >
        </v-select>
      </div>
      <div class="custom_group_search_select" v-if="true">
        <!--  В МОДАЛКЕ КОНТРАГЕНТОВ  -->
        <div class="v_select_filter" v-if="$store.getters.getModalNomenclatureData.state && !detailNomenclature">
          <v-autocomplete solo dense hide-details
                          :loading="$store.state.loading_nomenclature"
                          :items="$store.state.data_nomenclature"
                          clearable
                          return-object
                          label="Введите наименование продукции"
                          @click:clear="$nextTick(() => { prod_data = {} })"
                          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                          v-model="prod_data"
                          :rules="validate_rules.name_prod"
                          :search-input.sync="nomenclature_search"
                          @change="() => { $emit('listen-data-prod', prod_data); updateQuery()}"
                          @update:search-input="updateSearchItem"
          >
          </v-autocomplete>
        </div>
        <!--  В ДЕТАЛЬНОЙ НОМЕНКЛАТУРЕ  -->
        <div class="v_select_filter" v-else-if="$store.getters.getModalNomenclatureData.state && detailNomenclature">
          <v-autocomplete solo dense hide-details
                          :loading="$store.state.state_progress_circular_page"
                          :items="$store.state.data_nomenclature"
                          item-text="NAME"
                          clearable
                          return-object
                          label="Введите наименование продукции"
                          @click:clear="$nextTick(() => { prod_data = {} })"
                          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                          v-model="prod_data"
                          :rules="validate_rules.name_prod"
                          :search-input.sync="nomenclature_search"
                          @change="() => { $emit('listen-data-prod', prod_data); updateQuery()}"
                          @update:search-input="getItems()"
          >
          </v-autocomplete>
        </div>
        <!--  ??? ДОЛГИЙ ЗАПРОС  -->
        <div class="v_select_filter" v-else>
          <v-autocomplete solo dense hide-details
                          :loading="$store.state.loading_nomenclature"
                          :items="$store.state.data_nomenclature"
                          clearable
                          return-object
                          label="Введите наименование продукции"
                          @click:clear="$nextTick(() => { prod_data = {} })"
                          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                          v-model="prod_data"
                          :rules="validate_rules.name_prod"
                          :search-input.sync="nomenclature_search"
                          @change="() => { $emit('listen-data-prod', prod_data); updateQuery()}"
                          @update:search-input="$store.dispatch('setNomenclatureByNameOrArticul', {'nameOrArticul': nomenclature_search, 'selectedTypeProducts': $store.state.selected_type_or_subtype_products})"
          >
          </v-autocomplete>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "LiveSearch",
  props: ['listNomenclature', 'modalBtnProp', 'detailNomenclature'],
  data: () => ({
    prod_data: {},
    nomenclature_search: '',
    validate_rules: {
      name_prod: [
        value => !!value || 'Обязательно к заполнению',
      ],
    },
    requestObj: {},
  }),
  mounted() {
    console.log('mounted LiveSearch')
    this.$store.state.shouldSendRequest = false
    this.initializeQuery()
  },
  watch: {
    // '$store.state.dataFromUrl.id_prod': {
    //   handler() {
    //     this.prod_data = this.$store.state.dataFromUrl.id_prod
    //   }
    // },
    'prod_data': {
      handler() {
        console.log('prod_data')
        console.log(this.prod_data)
      }
    }
  },
  methods:{
    checkIsItemDisabled() {
      if (this.$store.state.loading_nomenclature) {
        return this.$store.state.list_type_products
      } else {
        return false
      }
    },
    updateQuery() {
      if (this.$store.state.shouldSendRequest) {
        let queryObj = {}
        if (Object.keys(this.$route.query).length) {
          for (let key in this.$route.query) {
            Object.assign(queryObj, {[key]: this.$route.query[key]})
          }
        }
        if (this.$store.state.selected_type_or_subtype_products !== null && Object.keys(this.$store.state.selected_type_or_subtype_products).length) {
          Object.assign(queryObj, {'subtype_prod': this.$store.state.selected_type_or_subtype_products.UF_XML_ID})
        } else {
          delete queryObj.subtype_prod
        }

        if (this.$store.state.dataFromUrl.id_prod !== null && Object.keys(this.$store.state.dataFromUrl.id_prod).length) {
          if (this.$store.state.dataFromUrl.id_prod !== null && Object.keys(this.$store.state.dataFromUrl.id_prod).length) {
            console.log('true')
            Object.assign(queryObj, {'id_prod': this.$store.state.dataFromUrl.id_prod.ID})
          } else {
            delete queryObj.id_prod
          }
        } else {
          if (this.prod_data !== null && Object.keys(this.prod_data).length) {
            Object.assign(queryObj, {'id_prod': this.prod_data.ID})
          } else delete queryObj.id_prod
        }

        this.pushRouter(queryObj)
      }
    },
    pushRouter(object) {
      this.$store.commit('updateURL_components', object)
    },
    initializeQuery() {
      if (!this.listNomenclature) {
        if (Object.keys(this.$route.query).includes('subtype_prod')) {
          if (this.$store.state.list_type_products.length) {
            this.$nextTick(() => {
              this.$store.state.selected_type_or_subtype_products = this.$store.state.list_type_products.filter((elem) => this.$route.query['subtype_prod'] === elem.UF_XML_ID)[0]
            })
          } else {
            this.$store.dispatch('setListTypeProductsAction').then(() => {
              this.$nextTick(() => {
                this.$store.state.selected_type_or_subtype_products = this.$store.state.list_type_products.filter((elem) => this.$route.query['subtype_prod'] === elem.UF_XML_ID)[0]
              })
            })
          }
        }

        if (Object.keys(this.$store.state.data_nomenclature_from_plus).length) {
          this.$nextTick(() => { this.prod_data = this.$store.state.data_nomenclature_from_plus; this.$emit('listen-data-prod', this.prod_data) })
        }

        if (Object.keys(this.$route.query).includes('id_prod')) {
          if (this.$store.state.data_nomenclature.length) {
            this.$nextTick(() => {
              this.prod_data = this.$store.state.data_nomenclature.filter((elem) => this.$route.query['id_prod'] === elem.ID)[0]; this.$emit('listen-data-prod', this.prod_data)
            })
          } else {
            this.$store.dispatch('getNomenclatureById', this.$route.query['id_prod']).then(() => {
              console.log('getByID')
              this.prod_data = this.$store.state.data_nomenclature.filter((elem) => this.$route.query['id_prod'] === elem.ID)[0]; this.$emit('listen-data-prod', this.prod_data)
            })
          }
        }
      }
    },
    updateItems() {
      console.log(this.$store.state.shouldSendRequest)
      if (this.$store.state.shouldSendRequest) {
        this.$store.dispatch('getListNomenclature', {
          'selected_type_or_subtype_products': (this.$store.state.selected_type_or_subtype_products !== null && Object.keys(this.$store.state.selected_type_or_subtype_products).length) ? this.$store.state.selected_type_or_subtype_products : '',
          'name': this.nomenclature_search,
          'CUSTOM_CONTRACTOR_ID': !!this.$route.query.contractor_id ? this.$route.query.contractor_id : '',
          'type_contractor':  !!this.$route.query.type_contractor ? this.$route.query.type_contractor : '',
        })
      }
    },
    updateSearchItem() {
      this.$store.dispatch('getListNomenclature', {
        'name': this.nomenclature_search,
        'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company?.CUSTOM_CONTRACTOR_ID,
        'contractor_by_default': this.$store.getters.getModalNomenclatureData.company?.CUSTOM_CONTRACTOR_ID,
        'selected_type_or_subtype_products': this.$store.state.selected_type_or_subtype_products,
        'type_contractor':  !!this.modalBtnProp ? this.modalBtnProp.UF_XML_ID : '',
      })
    },
    getItems() {
      this.$store.dispatch('getListNomenclatureFoLiveSearch', {
        'name': this.nomenclature_search,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-select__selections {
    row-gap: 5px;
    padding: 5px 0;
  }
  ::v-deep .v-chip {
    line-height: 14px !important;
    white-space: unset !important;
    height: 36px !important;
    ::v-deep .v-size--default {
      font-size: 1.1em !important;
    }
  }
  ::v-deep .v-list-item__title {
    white-space: break-spaces;
    line-height: 1.3rem !important;
  }
  ::v-deep .v-list-item__content {
    padding: 5px 0 !important;
  }
  //::v-deep .v-list-item {
  //  min-height: 30px;
  //  height: 30px;
  //  font-size: 0.85em;
  //}
  .v-select-list {
    text-align: left !important;
  }

  .wrapper_live_search {
    display: flex;
    align-items: center;
    .custom_group_search_select {
      padding-bottom: 0;
      width: 100%;
    }
  }

  .newDesign {

  }

  .mainItem {
    margin-left: 10px;
    text-align: left;
  }
  .subItem {
    margin-left: 20px;
    text-align: left;
  }
  .custom_group_search_select {
    padding-bottom: 1em;
  }
</style>
