<template>
  <div>
    <v-form>
      <v-text-field
          solo dense
          hide-details
          clearable
          @click:clear="$nextTick(() => { login = null})"
          label="Login"
          v-model="login"
      ></v-text-field>
      <v-text-field
          solo dense
          hide-details
          clearable
          @click:clear="$nextTick(() => { password = null})"
          label="password"
          v-model="password"
      ></v-text-field>
      <v-btn color="secondary" block @click="Authorization()">Authorization</v-btn>
    </v-form>
    <br>
    <v-btn color="primary" block @click="GetListNomenclature()">GetListNomenclature</v-btn>
    <v-btn color="primary" block @click="$store.dispatch('SetAccessUserGroup')">SetAccessUserGroup</v-btn>
  </div>
</template>

<script>
import Request from "../services/request"

export default {
  name: "TestAuthApi",
  data: () => ({
    login: null,
    password: null,
  }),
  methods:{
    async Authorization(){
      const response = await Request.post(
          process.env.VUE_APP_LOCAL_SERVER+'/local/BackendApi/PurchaseOrder/auth.php',
          {
            'login_user': this.login,
            'password_user': this.password,
          }
      )
      console.log('response', response)
    },
    async GetListNomenclature() {
      const response = await Request.post(
          process.env.VUE_APP_LOCAL_SERVER+'/bitrix/services/main/ajax.php?mode=class&c=nk%3Aproduction.list_nomenclature&action=executeGetListNomenclature',
          {
            post: {
              num_page: 1,
            }
          }
      )
      console.log('response', response)
    },
  }
}
</script>

<style scoped>

</style>