<template>
    <div id="list_specification_found_this_nomenclature">
        <div class="wrap_row"
               v-if="arr_data_list_order.length"
               v-for="order in arr_data_list_order" :id="order.ID_TETHER_NOMENCLATURE" :key="order.ID_TETHER_NOMENCLATURE"
        >
            <div class="wrap_row_onclick_area" style="grid-area: wrap_row_onclick_area">
                <a :href="'/bitrix/admin/iblock_element_edit.php?IBLOCK_ID=2&type=products&ID='+order.ID_TETHER_NOMENCLATURE+'#spec'" target="_blank" class="id_prod">
                    <v-icon
                            class="link_specification"
                            color="primary"
                            elevation="2"
                    >mdi-file-tree
                    </v-icon>
                    {{ order.ID_TETHER_NOMENCLATURE }}
                </a>
                <section class="name_production" @click="show_modal_specification(order)" >{{ order.NAME_SPEC }}</section>
                <section class="articul" @click="show_modal_specification(order)" >{{ order.DATA_PROD.PROPERTY_ARTICUL_VALUE }}</section>
            </div>
        </div>
        <v-alert  v-if="arr_data_list_order.length === 0" outlined type="info" prominent>Не найдено</v-alert>


        <!-- Диалоговое окно с номенклатурой -->
        <v-dialog
                v-model="dialog_specification.state"
                max-width="600px"
                content-class="dialog_nomenclature"
        >
            <v-card id="v_dialog_nomenclature_window" class="dialog_specification">
                <v-card-title>
                    <span class="headline" > {{ dialog_specification.name_spec }}</span>
                    <v-icon
                            class="close_dialog_cross"
                            color="primary"
                            elevation="2"
                            @click="dialog_specification.state = false"
                    >fas fa-times
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <tree-specification class="tree_specification_for_cur_nom" style="grid-area: tree_specification_for_cur_nom"
                                                v-if="!!dialog_specification.nomenclature_data" :nomenclature_data="dialog_specification.nomenclature_data"
                            ></tree-specification>
                            <v-alert v-else
                                     outlined type="error" prominent
                            >
                                Не найдено в массиве данной позиции
                            </v-alert>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import TreeSpecification from "./TreeSpecification";
    export default {
        name: "ListSpecificationFoundThisNomenclature.vue",
        components: {
            TreeSpecification
        },
        props:['id_nomenclature'],
        data: () => ({
            arr_data_list_order: [],
            dialog_specification: {
                nomenclature_data: null,
                name_spec: null,
                state: false,
            },
        }),
        watch:{
            id_nomenclature:{
                handler: function watch() {
                    this.get_list_specification_found_this_nomenclature();
                },
            },
        },
        mounted(){
            console.log('this.id_nomenclature');
            console.log(this.id_nomenclature);
            this.get_list_specification_found_this_nomenclature();
        },
        methods:{
            show_modal_specification(order){
                this.dialog_specification.nomenclature_data = order.DATA_PROD;
                this.dialog_specification.name_spec = order.NAME_SPEC;
                this.dialog_specification.state = true;
            },
            async get_list_specification_found_this_nomenclature(){
                const _this = this;

                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.detail_purchase_order',
                    'executeGetListSpecificationFoundThisNomenclature', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            'post':{'id_nomenclature': _this.id_nomenclature},
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success') {
                            // Если форма успешно отправилась
                            const result = Object.values(response.data).filter(elem => elem.TYPE_SPECIFICATION === 'GLOBAL_SPEC');
                            // const res = result.sort(function(a, b) { return a.TYPE_SPECIFICATION - b.TYPE_SPECIFICATION; });
                            console.log(result);
                            _this.arr_data_list_order = result;


                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/main";

    .wrap_row {
        background: $background_main_order_block;
        display: grid;
        //border: 1px solid;
        padding: 10px 10px;
        font-size: 15px;

        grid-template-areas:
            "wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area"
            "tree_specification_for_cur_nom tree_specification_for_cur_nom tree_specification_for_cur_nom tree_specification_for_cur_nom tree_specification_for_cur_nom"
        ;
        word-break: break-word;
        border-bottom: 2px solid #212121;
        height: auto;
        color: white;

        .wrap_row_onclick_area{
            display: grid;
            grid-template-columns: minmax(9%, auto) 1fr minmax(11%, auto);
            align-items: center;
            grid-column-gap: 1em;
            .id_prod{
                display: inline-flex;
                text-decoration: unset;
                align-items: baseline;
                grid-column-gap: 5px;
            }
            .name_production {
                //position: relative;
                color: lightgrey;
                cursor: pointer;
            }
        }
        section {
            padding: 3px;
            //outline: 1px solid;
            text-align: left;
            display: flex;
            align-items: baseline;
            //justify-content: center;
        }
    }

    .dialog_specification{
        .wrapper_static_header{
            top: 63px!important;
        }
    }
</style>