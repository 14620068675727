<template>
	<div class="specification_card">
		<search-by-drawing-number></search-by-drawing-number>

		<!--    <div>-->
		<!--      <label ><font color="#faebd7">{{$route.params.id}}</font></label>-->
		<!--    </div>-->
	</div>
</template>

<script>
import SearchByDrawingNumber from '@/components/specification/DrawingNumber'

export default {
	name: 'SpecificationSearch',
	components: { SearchByDrawingNumber },
}
</script>

<style lang="scss">
@import 'src/assets/sass/main';
@import 'src/assets/sass/specification';
@import 'src/assets/sass/detailSpecification';
</style>
