<template>
  <div class="wrapper_list_order_for_nomenclature">
        <div class="wrap_row"
               v-if="arr_data_list_order.length"
               v-for="order in arr_data_list_order" :id="order.ID_ORDER" :key="order.ID_ORDER">

          <progress-stage-production
              style="grid-area: progress_stage_of_production"
              :order="order"
          ></progress-stage-production>
            <div class="wrap_row_onclick_area"
                 @click="show_modal_detail_order(order, $event)"
                 style="grid-area: wrap_row_onclick_area">
                <section class="id_order"><span>ЗН</span> <span>{{ order.ID_ORDER }}</span></section>
                <section class="date_create_new_order">от &nbsp; {{ order.DATE_CREATE_NEW_ORDER }}</section>
                <section class="name_production" :data-id-prod="order.ID">{{  order.NAME_PROD }}</section>
                <section class="quantity">{{ order.NUMBER_OF_POSITIONS_FOR_EXECUTION }}</section>
                <section class="arrow_open_close" :class="{'collapse': order.boolean_state_iframe}"></section>
            </div>
        </div>
        <v-alert  v-if="arr_data_list_order.length === 0" outlined type="info" prominent>Не найдено</v-alert>

        <!--<order-for-nomenclature class="order_for_nomenclature" style="grid-area: order_for_nomenclature"-->
                                <!--v-if="!!modal_detail_order.event"-->
                                <!--:order="modal_detail_order.order"-->
                                <!--:event="modal_detail_order.event"-->
        <!--&gt;</order-for-nomenclature>-->

    </div>
</template>

<script>
    import OrderForNomenclature from "../components/OrderForNomenclature";
    import ProgressStageProduction from "../components/ProgressStageProduction";

    export default {
        name: "ListOrderForNomenclature",
        components: {
           OrderForNomenclature,
          ProgressStageProduction,
        },
        props:['id_nomenclature'],
        data: () => ({
            arr_data_list_order: [],
            loading_order: false,
            modal_detail_order: {
                order: null,
                event: null,
            },
        }),
        watch:{
            id_nomenclature:{
                handler: function watch() {
                    this.get_list_order_for_cur_nomenclature();
                },
            },
        },
        mounted(){
            console.log('this.id_nomenclature');
            console.log(this.id_nomenclature);
            this.get_list_order_for_cur_nomenclature();
        },
        methods:{
            show_modal_detail_order(order,event){
                this.modal_detail_order.order = order;
                this.modal_detail_order.event = event;
            },
            async get_list_order_for_cur_nomenclature(){
                const _this = this;

                // this.loading_order = true;
                // console.log('_this.$store.state.token');
                // console.log(_this.$store.state.token);

                _this.arr_data_list_order = [];
                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.list_purchase_order',
                    'executeGetListOrder', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            'post':{
                              'id_prod': _this.id_nomenclature,
                              'num_page': _this.$store.state.purchase_order.count_data.NUM_PAGE,
                              // 'id_order': (!!_this.$route.query.id_order) ? _this.$route.query.id_order : null,
                            },
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success') {
                            // Если форма успешно отправилась
                            console.log(response.data);

                            /* Заполняем массив полученными данными */
                            if (!!response.data.LIST_ORDER) {
                                _this.arr_data_list_order = response.data.LIST_ORDER.filter(elem => elem.STATE_ORDER === 'Открыт');
                            }
                        }

                        // _this.loading_order = false;
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            }
        }
    }
</script>

<style lang="scss">
    @import "../assets/sass/main";
    .wrapper_list_order_for_nomenclature {
      font-size: 1em;
      @media (max-width: 768px) {
        font-size: 0.8em;
      }

      .wrap_row {
        background: $background_main_order_block;
        display: grid;
        //border: 1px solid;
        padding: 10px 10px;
        grid-template-areas:
            "progress_stage_of_production progress_stage_of_production progress_stage_of_production progress_stage_of_production progress_stage_of_production"
            "wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area wrap_row_onclick_area"
            "order_for_nomenclature order_for_nomenclature order_for_nomenclature order_for_nomenclature order_for_nomenclature";
        word-break: break-word;
        border-bottom: 2px solid #212121;
        height: auto;
        color: white;

        .wrap_row_onclick_area {
          display: grid;
          grid-template-columns: auto auto auto auto;
          align-items: center;
          grid-column-gap: 1em;

          .id_order {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 10px;
          }

          .name_production {
            //position: relative;
            color: lightgrey;
            cursor: pointer;
          }
        }

        .treeview_order {

        }

        section {
          padding: 3px;
          //outline: 1px solid;
          text-align: left;
          display: flex;
          align-items: baseline;
          //justify-content: center;

          $font_info_list_order: 100%;

          &.date_create_new_order {
            font-size: $font_info_list_order;
          }

          &.priority {
            font-size: $font_info_list_order;

            &.Низкий {
              color: #009846;
            }

            &.Средний {
              color: #ef7f1a;
            }

            &.Высокий {
              color: #e31e24;
            }
          }

          &.articul {
            font-style: italic;
            font-weight: lighter;
            justify-content: flex-start;
          }

          &.quantity, &.main_status, &.fio_employee {
            display: flex;
            //align-items: center;
            justify-content: center;
            text-align: center;
          }

          &.quantity {
            font-size: 1.5rem;
            //align-items: center;
          }

          &.fio_employee {
            word-break: break-all;
          }

          &.articul {
            //display: grid;
            //grid-template-columns: 15% auto;
            //grid-column-gap: 10%;
            //text-align: left;

            a {
              text-align: center;
            }
          }
        }
      }
    }
</style>