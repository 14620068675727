<template>
  <div class="specification_card__header__name">
    <span class="standard_title" v-show="elementTitle">Наименование:</span>
    <!--      @update:search-input="getItemsFromName()"-->
    <!-- Отключим поиск, так как ломает логику создания-->
    <v-text-field
      v-if="elementView === 'input'"
      label="Наименование нового элемента"
      placeholder="Наименование нового элемента"
      name="NAME_NEWS"
      ref="NameNomenclatureInput"
      @input="handleChange($event)"
      hide-details="auto"
      solo
      dense
      clearable
      :disabled="elementDisabled"
      :value="elementValue"
      :class="elementValue ? 'drawing_hint_success' : 'drawing_hint_error'"
      :hint="elementValue ? 'Наименование' : 'Заполните наименование'"
      :persistent-hint="elementValue && $route.name !== 'SpecificationView'"
    ></v-text-field>
    <v-combobox
      v-if="elementView === 'select'"
      solo
      dense
      ref="myComboBox"
      clearable
      placeholder="Наименование нового элемента"
      class="specification_card__header__name__input"
      item-text="NAME_NEW"
      :menu-props="{ closeOnContentClick: true, bottom: true, offsetY: true }"
      @change=""
      :error="false"
      @click:clear=""
    ></v-combobox>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'SearchByName',
  // props: ['elementView', 'elementDisabled', 'elementValue'],
  props: {
    elementView: { type: String },
    elementDisabled: { type: Boolean },
    elementValue: { type: String },
    elementTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: [
        (value) =>
          /\S/.test(value) && value !== null && !!value
            ? (this.validate = true)
            : (this.validate = false),
      ],
      validate: false,
    }
  },

  methods: {
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
    }),

    handleChange(event) {
      // this.createNewElement({
      // 	name: this.$refs.NameNomenclatureInput.$attrs.name,
      // 	event: event,
      // })
      this.$emit('eventChange', event)
    },
  },
}
</script>

<style scoped></style>
