<template>
    <div id="vue_wrapper_set_performer_page">

        <v-dialog
                :value="true"
                max-width="600px"
                @click:outside="()=> {
                   $emit('listen-child-state');
                   $store.dispatch('ClearCustomAlert');
                }"
        >
            <div class="wrapper_loader_progress-circular" v-show="$store.state.state_progress_circular_page">
                <v-progress-circular
                        indeterminate
                        color="success"
                        size="100"
                        width="10"
                ></v-progress-circular>
            </div>
            <v-card>
                <v-card-title>
                    <span class="headline">Назначить исполнителя <br> на участок - Сварка</span>
                </v-card-title>

                <v-form ref="form" id="form_set_performer">
                    <div class="wrapper_select_block wrapper_page_set_performer" v-if="Object.keys(arr_data_form_order).length !== 0">

                    <!-- Исполнитель для ЕМ -->
                    <div class="wrapper_row wrapper_assign_performer">
                        <div class="fourth_block">
                            <p>Назначим исполнителя ?</p>
                            <v-select :items="arr_data_form_order.ASSIGN_PERFORMER.LIST_PERFORMER"
                                      solo dense hide-details
                                      clearable
                                      @click:clear="$nextTick(() => { form_data.id_performer = 'без исполнителя'})"
                                      item-value="ID" item-text="FULL_NAME"
                                      :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                                      v-model="form_data.id_performer"
                                      label="Не назначен"
                            >
                            </v-select>
                        </div>
                    </div>
                </div>
                </v-form>
                <v-alert v-if="$store.state.custom_alert" id="custom-v-alert"
                         transition="scale-transition"
                         elevation="5"
                         :type="$store.state.custom_alert.type_alert"
                         :value="$store.state.custom_alert.state_alert"
                         @input="$store.state.custom_alert.state_alert = false"
                >
                    <span v-html="$store.state.custom_alert.data_alert"></span>
                </v-alert>

                <v-card-actions>
                      <v-btn
                          id="btn_cancel"
                          text
                          @click="() => {
                                $emit('listen-child-state');
                                $store.dispatch('ClearCustomAlert');
                            }"
                      >
                        Закрыть
                      </v-btn>
                      <v-btn
                          v-if="notSetted"
                          id="btn_take_an_order"
                          color="secondary"
                          @click="action_set_performer_for_order()"
                      >
                        Назначить
                      </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "SetPerformerComponent",
        props: ['order', 'state_show'],
        data: () => ({
            stage_production:{
                count: 1,
            },
            form_data:{
                id_order: null,
                id_stage: null,
                id_performer: 'без исполнителя',
                resolve_time_assemble: null,
            },
            notSetted: true,
            computed_selected_contractor: [],
            arr_data_form_order: [],
            validate_rules:{},
        }),
        mounted() {
            this.get_form_add_order();
            // console.log(this.$root);
        },
        created() {
            /* Проставляем свойства из пропса полученное от родителя */
            this.form_data.id_order = this.order.ID_ORDER;
            this.form_data.id_performer = this.order.ASSIGN_PERFORMER_SVARKA.DATA.ID_PERFORMER;

            const id_store_svarka = this.order.ASSIGN_PERFORMER_SVARKA.STORE_ID;
            this.form_data.id_stage = this.order.FULL_DATA_STAGE_OF_PRODUCTION.filter(elem => elem.ID_STORE_STAGE == id_store_svarka)[0].ID;
        },
        watch:{
            // 'form_data.prod':{
            //     handler: function watch(value) {
            //         console.log('watch form_data.prod')
            //         console.log(value)
            //     },
            //     deep: true,
            // },
        },
        computed:{},
        methods:{
            delete_stage_production(key_production){
                this.stage_production.count -= 1;
                delete this.form_data.stage_order[key_production];
            },
            async action_set_performer_for_order(){
                const _this = this;

                if (this.$refs.form.validate()) {
                    /* Битриксовый AJAX */
                    BX.ajax.runComponentAction('nk:production.update_purchase_order',
                        'executeUpdateOrrAddRecordWorkProgressLog', { // Вызывается без постфикса Action
                            mode: 'class',
                            data: {
                                post: {
                                    id_order: _this.form_data.id_order,
                                    id_performer: _this.form_data.id_performer,
                                    id_stage: _this.form_data.id_stage,
                                },
                                'login': _this.$store.state.login,
                                'token': _this.$store.state.token,
                            },
                        })
                        .then(function (response) {
                            if (response.status === 'success') {
                                // Если форма успешно отправилась
                                console.log(response);
                                /* Вставим в разметку ответ от сервера */

                                if (response.data.status === 'success'){
                                    _this.$store.state.custom_alert.data_alert = response.data.message;
                                    _this.$store.state.custom_alert.type_alert = 'success';
                                    /* Вызываем событие обновление списка ЗН */
                                    _this.$emit('listen-add-update-order')
                                    _this.notSetted = false
                                }
                                else {
                                    _this.$store.state.custom_alert.data_alert = response.data.message;
                                    _this.$store.state.custom_alert.type_alert = 'warning';
                                }
                            }
                        })
                        .catch((response) => {
                            console.log(response);
                            _this.$store.state.custom_alert.data_alert = response.data.message;
                            _this.$store.state.custom_alert.type_alert = 'error';
                        });

                    _this.$store.state.custom_alert.state_alert = true;
                }
                else {
                    document.getElementById('form_add_po').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }
            },
            async get_form_add_order(){
                const _this = this;
                // console.log('_this.$store.state.login token');
                // console.log(_this.$store.state.login);
                // console.log(_this.$store.state.token);

                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.add_purchase_order',
                    'executeGetFormAddOrder', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            'post':{'1': '1',},
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success') {
                            // Если форма успешно отправилась
                            console.log(response.data);
                            _this.arr_data_form_order = response.data;

                            _this.$store.state.state_progress_circular_page = false;
                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/main";
    .v-card {
        background: rgb(74, 74, 74)!important;
        display: grid;
        grid-row-gap: 1em;
        padding: 0 1em;
        .v-card__title {
            padding: unset;
            padding-top: 10px;
            .headline {
                font-size: 1.1em !important;
                color: #f7ee89;
                text-align: center;
                width: 100%;
            }
        }
        #form_set_performer {
            width: 100%;
            .wrapper_page_set_performer {
                width: 100%;
                display: grid;
                grid-row-gap: 1.2em;
                font-size: 16px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }

                .wrapper_row {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-row-gap: 10px;
                    .third_block{
                        .wrapper_list_added_block {
                            box-shadow: 4px 4px 20px 4px rgb(0 0 0 / 20%), 2px 2px 2px 3px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
                            padding: 1em;
                            display: grid;
                            grid-row-gap: 1em;
                            .dynamic_added_block {
                                display: grid;
                                align-items: baseline;
                                font-size: 1em;
                                color: white;
                            }
                            #btn_add_stage_production{
                                display: grid;
                                justify-self: center;
                            }
                        }
                    }
                }

                .wrapper_block_commentary {
                    color: white;

                    header {
                        margin-bottom: 10px;
                    }
                }

                .header {
                    color: $caption_yellow_color;
                    margin-bottom: 15px;
                    font-size: 1.4rem;
                    text-align: center;
                }

                .count {
                    display: grid;
                    justify-content: flex-start;
                }

                p {
                    //text-align: center;
                    color: white;
                    text-align: left;
                    margin-top: 0;
                    margin-bottom: 10px;
                }

                .select2, .block_inputs {
                    //display: none;
                    //width: inherit!important;
                    //height: 35px;
                }

                .block_inputs {
                    flex-wrap: nowrap;
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 10px;
                        //font-size: 1.2rem;
                    }

                    #number_of_positions {
                        height: inherit;
                        //font-size: 1.2rem;
                        text-align: center;
                        padding: 6px;
                        width: 100%;
                        background-color: #fff;
                        border: 1px solid #aaa;
                        border-radius: 4px;
                    }
                }
            }
        }
        .v-card__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            //display: grid;
            //grid-template-columns: 1fr 1fr;
            //justify-items: center;
            //align-items: center;
            padding-bottom: 16px;
            grid-column-gap: 1em;
            button {
                font-family: Roboto;
                color: white;
                //width: 100%;
                max-width: 200px;
                padding: 0 20px;
                display: grid;
                letter-spacing: 1px;
                font-weight: bold;
            }
        }
    }
</style>
