import Logging from './logging'
import Vue from 'vue'

export default class Request {

	static bitrixUrl(component, action){
		const baseBitrixUrl = '/bitrix/services/main/ajax.php?mode=class&c='+component+'&action='+action;
		return process.env.VUE_APP_LOCAL_SERVER + baseBitrixUrl;
	}

	static async request(url, params = {}, method) {
		/*
		 * В таком состоянии fetch из консоли выставлял куки
		 * mode: 'no-cors'
		 * credentials: 'include'
		 * */
        // const myHeaders = new Headers();
        // myHeaders.set('Authorization', 'Basic dXNlcjpwd2Q='); // user:pwd

		let options = {
			method: method, // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			headers: {
				// 'Content-Type': 'application/json'
				// 'Content-Type': 'application/x-www-form-urlencoded',
                // '2sd': 'dXNlcjpwd2Q='
			},
			// redirect: 'follow', // manual, *follow, error
			// referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		}

		if ('GET' === method) {
			if (params !== null) {
                url += '?' + (new URLSearchParams(params)).toString();
			}
        }
        else {
			Object.assign(params, {
				'login': sessionStorage.getItem('login'),
				'token': sessionStorage.getItem('token'),
			})
            // console.log('WAY params')
            // console.log(params)

			options.body = this.bodyFromData(params)
		}

        // console.log('options.body');
        // console.log(options.body);

		return await fetch(url, options)
            .then(response => response.json())
            .then(response => {
				console.log('response fetch', response);
				return response
			})
			.catch((error) => {
                console.error('Error:', error);
                return error
            });
	}
    // static async get ( url, params = null ) {
    //     return this.request( url, params, 'GET' )
    // }
	static async post(url, params) {
        return this.request( url, params, 'POST' );
	}
	static async put(url, params) {
		return this.request( url, params, 'PUT' );
	}

	static bodyFromData(paramBody) {
		if (! Logging.checkValueIsUndefinedOrNull(paramBody)) return new FormData();

		let bodyFormData = new FormData()

		for (const [key, value] of Object.entries(paramBody)) {
			if (typeof value === 'object' && value){
				for (const [key2, value2] of Object.entries(value)){
					bodyFormData.append(`post[${key2}]`, value2);
				}
			}
			else{
				bodyFormData.append(key, value)
			}
		}
		return bodyFormData
	}

}