<template>
  <div>
    <div id="ListProductOnStore">
      <!-- Блок фильтров -->
      <div class="invisible block_filters_universal" v-show="$store.state.state_block_filters">
        <div class="block_filter">
          <live-search
              @listen-data-prod="(data) => { this.nomenclature_data = (!!data) ? data : {} }"
              :listNomenclature="listNomenclature"
          />
          <v-autocomplete
              solo dense
              hide-details
              clearable
              :loading="loading_order"
              @click:clear="$nextTick(() => { data_filters.CONTRACTOR_ID = {}})"
              label="Поиск контрагента"
              v-model="data_filters.CONTRACTOR_ID"
              style="padding-bottom: 1em"
              :search-input.sync="contractor_search"
              @update:search-input="get_list_contractor()"
              @change="() => { getListNomenclature('update'); updateQuery()}"
              :items="list_contractor.DATA"
              return-object
              item-text="PERSON_NAME"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
          ></v-autocomplete>
            <v-autocomplete
                solo dense
                hide-details
                clearable
                label="Тип контрагента"
                :items="$store.state.listTypeContractors"
                :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                item-text="UF_NAME"
                return-object
                v-model="type_contractor"
                @change="getListNomenclature('update'); updateQuery()"
            ></v-autocomplete>
        </div>
      </div>

      <div
          class="wrapper_list_remains"
          :class="{resizeHeight: $store.state.state_progress_circular_page}"
          v-if="$store.state.listNomenclatureByContractors.list_items"
      >
        <NomenclatureHorizontalCard
            class="row_remains opacity-hover"
            :class="[{row_remains_modal: $store.getters.getModalNomenclatureData.state}, $store.getters.getColorBorder(product.PRIORITY_OBJ.color)]"
            v-for="(product, index) in $store.state.listNomenclatureByContractors.list_items"
            :key="index"
            :product="product"
            @openDetailNomenclature="open_dialog_nomenclature"
            :nomenclatureListComponent="nomenclatureListComponent"
        />
      </div>

      <!-- Оверлей -->
      <v-overlay
          :z-index="0"
          :absolute="true"
          :value="$store.state.state_progress_circular_page"
      >
        <v-progress-circular
            style="margin: auto; position: relative; top: 80px"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
            v-if="$store.state.state_progress_circular_page"
        ></v-progress-circular>
      </v-overlay>

      <v-alert
          v-if="!$store.state.listNomenclatureByContractors.list_items.length && !$store.state.state_progress_circular_page"
          type="error"
          text
          class="err-msg"
      >
        Ничего не найдено
      </v-alert>
      <v-btn
          block
          v-if="!$store.state.state_progress_circular_page"
          @click='() => { showMore() }'
          :disabled="$store.state.listNomenclatureByContractors.count_data.RECORDS_TO_END <= 0"
          style="margin: 1em 0"
      >
        Показать ещё {{ $store.state.listNomenclatureByContractors.count_data.RECORDS_TO_END }}
      </v-btn>

      <v-dialog
          v-model="$store.state.dialog_nomenclature_window"
          max-width="600px"
          content-class="dialog_nomenclature"
      >
        <v-card id="v_dialog_nomenclature_window">
          <v-card-title>
            <span class="headline">Номенклатура</span>
            <v-icon
                class="close_dialog_cross"
                color="primary"
                elevation="2"
                @click="$store.state.dialog_nomenclature_window = false"
            >fas fa-times
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <nomenclature
                    v-if="$store.state.dialog_nomenclature_window"
                    :dialog_id_order="dialog_nomenclature_window.id_order"
                />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var type_contractor;

import Nomenclature from "../components/nomenclature/Nomenclature";
import LiveSearch from "../components/LiveSearch";
import NomenclatureHorizontalCard from "../components/nomenclature/NomenclatureHorizontalCard";

export default {
  components: {
    NomenclatureHorizontalCard,
    Nomenclature,
    LiveSearch,
  },
  name: "NomenclatureList",
  data: () => ({
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    nomenclatureListComponent: true,
    queryContractor: false,
    debounceTimeout: null,
    debounceTotal: null,
    debounceContractor: null,
    requestObj: {},
    contractor_search: '',
    list_contractor: {
      COUNT: {
        NUM_PAGE: 1,
      },
      DATA: [],
    },
    data_filters: {
      color: [],
      CONTRACTOR_ID: {},
    },
    contractorByRequest: {},
    shouldUpdateSearch: true,
    objFilters: {
      0: {'color': 'rose', 'text_color': '#FF80AB', 'text': 'Заполнить ФО, НО, КУ'},
      1: {'color': 'red', 'text_color': 'red', 'text': 'Заказать срочно'},
      2: {'color': 'yellow', 'text_color': 'yellow', 'text': 'Заказать планово'},
      3: {'color': 'green', 'text_color': 'green', 'text': 'Товара достаточно'},
    },
    type_contractor: null,
    listNomenclature: true,
    shouldUpdateNomenclature: true,
    loading_order: false,
  }),
  mounted() {
    if (Object.keys(this.$route.query).length) {
      this.$store.state.state_block_filters = true;
    }
    this.get_list_types_contractors().then(() => {
      this.initializeQuery()
    })

  },
  computed: {},
  watch: {
    'data_filters.color': {
      handler: function watch(value) {
        this.requestObj = Object.assign({}, {
          'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID,
          'color': value
        })
        this.$store.dispatch('getListNomenclature', this.requestObj)
      },
    },
    '$store.state.dialog_nomenclature_window': {
      handler() {
        if (!this.$store.state.dialog_nomenclature_window) {
          this.getListNomenclature()
        }
      },
    }
  },
  methods: {
    /* Запрос на контрагента с id URL */
    updateContractorItems(id) {
      return new Promise((resolve, reject) => {
        if (this.shouldUpdateSearch) {
          if (this.debounceContractor) clearTimeout(this.debounceContractor);
          this.debounceContractor = setTimeout(() => {

            this.loading_order = true
            const _this = this;

            /* Запрашиваем список поставщиков  */
            BX.ajax.runComponentAction('nk:production.list_contractors',
                'executeGetListContractors', { // Вызывается без постфикса Action
                  mode: 'class',
                  data: {
                    post: {
                      get_list_contractor: 'Y',
                      num_page: 1,
                      id_contractor: id ? id : ''
                    },
                    'login': _this.$store.state.login,
                    'token': _this.$store.state.token,
                  },
                })
                .then(function (response) {
                  if (response.status === 'success') {
                    // Если форма успешно отправилась
                    console.log('executeGetListContractor');
                    console.log(response.data);
                    if (response.data.log_data.DATA !== null) {
                      if (_this.$route.query.contractor_id) {
                        _this.contractorByRequest = response.data.log_data.DATA ? response.data.log_data.DATA[0] : null
                        _this.list_contractor.DATA.push(response.data.log_data.DATA[0]);
                      } else {
                        _this.list_contractor.DATA.push(...response.data.log_data.DATA);
                      }
                    }
                  }
                  _this.loading_order = false
                  resolve()
                })
                .catch((response) => {
                  console.log(response);
                  _this.loading_order = false
                  reject(response)
                });
          }, 500);
        }
      })
    },
    /* Обновляем URL при изменении фильтров */
    updateQuery() {
      setTimeout(() => {
        let queryObj = {}
        // Другие фильтры из LiveSearch.vue
        if (Object.keys(this.$route.query).length) {
          for (let key in this.$route.query) {
            Object.assign(queryObj, {[key]: this.$route.query[key]})
          }
        }
        // contractor_id
        if (Object.keys(this.data_filters.CONTRACTOR_ID).length) {
          Object.assign(queryObj, {'contractor_id': this.data_filters.CONTRACTOR_ID.CUSTOM_CONTRACTOR_ID})
        } else {
          delete queryObj.contractor_id
        }
        // type_contractor
        if (this.type_contractor !== null && Object.keys(this.type_contractor).length) {
          Object.assign(queryObj, {'type_contractor': this.type_contractor.UF_XML_ID})
        } else {
          delete queryObj.type_contractor
        }
        // Вызываем ф-ию router.push
        this.pushRouter(queryObj)
      }, )
    },
    pushRouter(object) {
      this.$store.commit('updateURL_components', object)
    },

    /* Ф-ия проверки URL на параметры */
    initializeQuery() {
      this.$store.state.shouldSendRequest = false
      this.shouldUpdateNomenclature = false
      Promise.all([
        /* Проверка на тип контрагента */
        new Promise((resolve) => {
          if (Object.keys(this.$route.query).includes('type_contractor')) {
            if (this.$store.state.listTypeContractors.length) {
              this.$nextTick(() => {
                resolve()
                this.type_contractor = this.$store.state.listTypeContractors.filter((elem) => this.$route.query['type_contractor'] === elem.UF_XML_ID)[0]
              })
            } else {
              this.get_list_types_contractors.then(() => {
                this.type_contractor = this.$store.state.listTypeContractors.filter((elem) => this.$route.query['type_contractor'] === elem.UF_XML_ID)[0]
                resolve()
              })
            }
          } else resolve()
        }),
        /* Проверка на контрагента */
        new Promise((resolve) => {
          if (Object.keys(this.$route.query).includes('contractor_id')) {
            this.updateContractorItems(this.$route.query.contractor_id).then(() => {
              this.shouldUpdateSearch = false
              this.data_filters.CONTRACTOR_ID = Object.assign({}, this.contractorByRequest)
              resolve()
            })
          } else {
            this.get_list_contractor().then(() => {
              resolve()
            })
          }
        }),
        /* Проверка на продукцию */
        new Promise((resolve) => {
          if (Object.keys(this.$route.query).includes('id_prod')) {
            this.$store.dispatch('getNomenclatureById', this.$route.query['id_prod']).then(() => {
              this.$store.state.dataFromUrl.id_prod = this.$store.state.data_nomenclature.filter((elem) => this.$route.query['id_prod'] === elem.ID)[0]
              resolve()
            })
          } else {
            resolve()
          }
        }),
        /* Проверка на тип продукции */
        new Promise((resolve) => {
          if (Object.keys(this.$route.query).includes('subtype_prod')) {
            if (this.$store.state.list_type_products.length) {
              this.$nextTick(() => {
                this.$store.state.selected_type_or_subtype_products = this.$store.state.list_type_products.filter((elem) => this.$route.query['subtype_prod'] === elem.UF_XML_ID)[0]
                resolve()
              })
            } else {
              this.$store.dispatch('setListTypeProductsAction').then(() => {
                this.$nextTick(() => {
                  // this.$store.state.selected_type_or_subtype_products = this.$store.state.list_type_products.filter((elem) => this.$route.query['subtype_prod'] === elem.UF_XML_ID)[0]
                  resolve()
                })
              })
            }
          } else {
            resolve()
          }
        })
      ]).finally(() => {
        // После вызываем список номенклатуры с подставленными из URL фильтрами
        this.shouldUpdateNomenclature = true
        this.getListNomenclature()
        setTimeout(() => {
          this.$store.state.shouldSendRequest = true
          this.shouldUpdateSearch = true
        }, 500)
      })
    },

    setColorFilter(color) {
      const arr = this.data_filters.color;
      if (arr.length === 0) {
        arr.push(color);
        return false;
      }
      const findIndex = arr.findIndex((elem) => elem === color);
      if (findIndex !== -1) {
        arr.splice(findIndex, 1);
      } else {
        arr.push(color);
      }
    },

    /* Запрос на типы контрагентов в селектор фильтров */
    async get_list_types_contractors() {
      await this.$store.dispatch('getListTypeContractors')
    },
    /* Ф-ия запроса на список номенклатуры, которая принимает параметры с фильтров */
    getListNomenclature(action) {
      console.log(this.shouldUpdateNomenclature)
      if (this.shouldUpdateNomenclature) {
        setTimeout(() => {
          /* Если мы меняем какой-либо из фильтров сбрасываем NUM_PAGE до 1 */
          if (action === 'update') {
            this.$store.state.listNomenclatureByContractors.count_data.NUM_PAGE = 1
          }

          /* Объект запроса с фильтров */
          this.requestObj = Object.assign({}, {
            'CUSTOM_CONTRACTOR_ID': Object.keys(this.data_filters?.CONTRACTOR_ID).length ? this.data_filters.CONTRACTOR_ID.CUSTOM_CONTRACTOR_ID : '',
            'type_contractor': !!this.type_contractor ? this.type_contractor.UF_XML_ID : '',
            'name': (this.$store.state.dataFromUrl.id_prod !== null && Object.keys(this.$store.state.dataFromUrl.id_prod).length) ? this.$store.state.dataFromUrl.id_prod.NAME : '',
            'selected_type_or_subtype_products': (!!this.$store.state.selected_type_or_subtype_products && Object.keys(this.$store.state.selected_type_or_subtype_products).length) ? this.$store.state.selected_type_or_subtype_products : '',
          })
          this.$store.state.shouldSendRequest = false
          this.$store.dispatch('getListNomenclature', this.requestObj).then(() => {
            this.$store.state.shouldSendRequest = true
          })
        },)
      }
    },
    /* Показать еще */
    showMore() {
      this.$store.state.listNomenclatureByContractors.count_data.NUM_PAGE++
      // this.requestObj = Object.assign({}, {'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID ? this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID : null})
      this.$store.dispatch('getListNomenclature', {})
    },

    /*  Показываем/скрываем кнопку удаления товара из "корзины"  */
    inStorage(product) {
      return this.$store.state.modalNomenclature.totalCard.some(elem => elem.ID === product.ID)
    },
    /* Очищаем предмет из корзины */
    deleteElem(id) {
      this.$store.dispatch('removeModalNomenclatureItem', id)
    },
    /* Общая функция для изменения свойств */
    onChangeProperty(property) {
      this.$store.dispatch('setPropertyItemAnyIblock', property).then(() => {
        this.getListNomenclature()
      })
    },
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    onChangeQuantity(operation, product) {
      const planned_quantity = (!!product.QUANTITY_TO_ORDER) ? product.QUANTITY_TO_ORDER : 0;
      const pieces_in_package = (!!product.HOW_MANY_PIECES_IN_PACKAGE) ? product.HOW_MANY_PIECES_IN_PACKAGE : 0;

      if (!!pieces_in_package) {
        /* Остаток */
        const aliquot = planned_quantity % pieces_in_package;
        /* Вычисляем логику декремента / инкремента от остатков / упаковки */
        const decrement_aliquout = parseInt((!!aliquot) ? aliquot : pieces_in_package);
        const increment_aliquout = pieces_in_package - aliquot;

        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) - parseInt(decrement_aliquout);
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) + parseInt(increment_aliquout);
          this.changeValue(product)
        }
      } else {
        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) - 1
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) + 1
          this.changeValue(product)
        }
      }
    },
    changeValue(product) {
      if (this.debounceTotal) clearTimeout(this.debounceTotal)

      this.debounceTotal = setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('setTotalCard', product)
        })
      }, 500)
    },
    sendEditableAmount(product) {
      const _this = this;
    },
    open_dialog_nomenclature(order_id) {
      this.dialog_nomenclature_window.id_order = order_id;
      this.dialog_nomenclature_window.state = true;
    },
    getTotal(product) {
      return product.total = parseInt(product.perProd) * parseInt(product.counter)
    },
    computed_total_price() {
      return this.$store.state.modalNomenclature.totalCard.reduce((a, b) => {
        return +(a + (b.PURCHASING_PRICE * b.QUANTITY_TO_ORDER)).toFixed(2)
      }, 0)
    },

    //id_contractor: _this.type_contractor !== null ? _this.type_contractor.UF_XML_ID : ''

    get_list_contractor(action) {
      return new Promise((resolve, reject) => {
        if (this.$store.state.debounceTimeout) clearTimeout(this.$store.state.debounceTimeout);
        this.$store.state.debounceTimeout = setTimeout(() => {

          this.loading_order = true
          const _this = this;

          /* Запрашиваем список поставщиков  */
          BX.ajax.runComponentAction('nk:production.list_contractors',
              'executeGetListContractors', { // Вызывается без постфикса Action
                mode: 'class',
                data: {
                  post: {
                    get_list_contractor: 'Y',
                    num_page: _this.list_contractor.COUNT.NUM_PAGE,
                    name: _this.contractor_search ? _this.contractor_search : '',
                  },
                  'login': _this.$store.state.login,
                  'token': _this.$store.state.token,
                },
              })
              .then(function (response) {
                if (response.status === 'success') {
                  // Если форма успешно отправилась
                  console.log('executeGetListContractor');
                  console.log(response.data);
                  if (response.data.log_data !== null) {
                    _this.list_contractor.DATA.push(...response.data.log_data.DATA)
                    _this.list_contractor.COUNT = response.data.log_data.COUNT;
                  } else _this.list_contractor.DATA = []
                }
                _this.loading_order = false
                resolve()
              })
              .catch((response) => {
                console.log(response);
                _this.loading_order = false
                reject(response)
              });
        }, 500);
      })
    },
  },
  beforeDestroy() {
    /* Очищаем список корзины, если закрываем модалку */
    if (this.$store.state.modalNomenclature.totalCard.length) {
      this.$store.dispatch('clearModalNomenclature')
    }

    this.$store.state.dataFromUrl.id_prod = {}
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/main";

.v-application {
  font-family: inherit;

  a {
    color: unset !important;
  }
}


.modal_actions {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;

  .modal_actions_chips {
    padding-left: 10px;
    //max-width: 200px;
    .v-slide-group__wrapper {
      flex-wrap: wrap;
    }
  }
}

::v-deep .v-card__title {
  background: darkgray !important;
  padding: 5px 24px 5px;
}

::v-deep .v-slide-group__content {
  //flex-wrap: nowrap;
}

.wrapper_title {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
  justify-items: left;
  align-items: baseline;
  width: 100%;

  .title {
    font-size: 1.1em !important;
  }

  .subtitle {
    font-size: 0.8em !important;
    width: fit-content;
    color: dimgrey;

    input {
      text-align: center;
    }
  }
}

::v-deep .v-icon.v-icon::after {
  background-color: transparent !important;
}

%howManyPiecesGrid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: end;
  grid-column-gap: 5px;
}

#ListProductOnStore {
  color: white;
  padding-bottom: 1em;
  height: 100%;

  .contractor_filter {
    padding: 10px 2% 10px 2%;

    .block_filter {
      display: flex;


      .block_filter_chips {
        padding-left: 10px;
      }
    }
  }

  .resizeHeight {
    min-height: 140px;
  }

  .wrapper_list_remains {
    display: grid;
    width: 100%;
    position: relative;
    margin-top: 1em;

    @media (max-width: 768px) {
      .row_remains {
        grid-template-columns: 25% 30% auto !important;

        .display {
          background: #FFFFFF !important;
          border-radius: 5px !important;
          height: 90px !important;
        }

        .image {
          width: 100% !important;
          border-radius: 5px !important;
        }
      }
    }

    .row_remains {
      display: grid;
      grid-template-columns: 25% 30% auto;
      grid-column-gap: 0.75em;
      align-items: center;
      background: $background_main_order_block;
      @extend %padding-row-position;
      @extend %margin-row-position;
      @extend %border-universal;
      //border-color: map-get($border-universal-color, 'желтый');
      font-size: 1em;
      position: relative;

      .image {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
        border-radius: 5px;
        background-color: #FFFFFF;
      }

      .display {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 130px;
        max-width: 100%;
      }

      .wrapper_name_articul {
        align-self: start;
        text-align: left;
        display: grid;
        height: 100%;
        align-content: space-around;
        grid-row-gap: 5px;
        color: lightgrey;

        .name_prod {
          font-size: 0.9em;
          cursor: pointer;
        }

        .articul, .drawing_number {
          font-size: 1.1em;
          color: grey;

          span {
            color: lightgrey;
            font-size: 1em;
            opacity: 0.9;
          }
        }
      }

      .wrapper_quantity {
        display: grid;
        grid-template-columns: 25% auto 20%;
        align-items: start;
        word-break: break-word;
        column-gap: 15px;
        transition: all .3s ease;

        &__right {
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: all .3s ease;
          font-size: 0.75em;

          &__total {
            padding: 8px 0;
            font-weight: 500;
            color: lightyellow;
            transform: translateY(30px);
            transition: all .3s ease;
            opacity: 0;
          }

          .showTotal {
            opacity: 1;
          }

          &__per_item {
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
          }
        }

        &__center {
          display: flex;
          flex-direction: column;
          transition: all .3s ease;
          row-gap: 5px;
          font-size: 0.75em;
          //height: 100%;
          .EDITABLE_QUANTITY {
            transform: translateY(30px);
            transition: all .3s ease;
            -moz-appearance: textfield !important;

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            &__append {
              cursor: pointer !important;
              color: #FFFFFF;
            }

            &__prepend {
              cursor: pointer !important;
              color: #FFFFFF;
            }
          }

          &__package {
            height: 30px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            @extend %howManyPiecesGrid;
          }

          &__edit {
            color: white;
            opacity: 0;
            transform: translateY(30px);
            transition: transform .3s ease, opacity .3s ease, color .4s ease-in-out;
            cursor: pointer;

            &:hover {
              color: darkgray;
            }

            ::v-deep .v-icon.v-icon::before {
              background-color: transparent !important;
            }
          }

          &__remains {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            row-gap: 5px;
            margin-left: -28px;

            &__block {
              height: 24px;
              span {
                min-width: 23px;
              }

              .EDITABLE_QUANTITY_REMAINS {
                max-width: 70px;
                -moz-appearance: textfield !important;

                ::v-deep .v-input__control input {
                  color: darkgray !important;
                  max-height: 24px !important;
                  text-indent: 8px;
                }

                ::v-deep input::-webkit-outer-spin-button,
                ::v-deep input::-webkit-inner-spin-button {
                  -webkit-appearance: none !important;
                }

                ::v-deep .v-text-field__suffix {
                  font-size: 12px !important;
                }

                ::v-deep .v-input__prepend-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__append-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__slot {
                  background: $background_main_order_block !important;
                  padding: 0;
                }

                ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
                ::v-deep .v-input__slot {
                  margin: 0;
                  max-height: 26px;
                  min-height: 24px !important;
                  display: flex !important;
                  flex-direction: column;
                  align-items: center !important;
                }

                ::v-deep .v-input__control {
                  min-height: 24px !important;
                  color: white;
                }
              }

              .edit {
                ::v-deep .v-input__slot {
                  background: white !important;
                  padding: 0;
                }

                ::v-deep .v-input__control input {
                  color: black !important;
                  text-indent: 8px;
                  text-align: left;
                }
              }
            }
          }
        }

        &__left {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          font-size: 0.75em;

          .EDITABLE_QUANTITY {
            transform: translateY(30px);
            transition: all .3s ease;
            padding-bottom: 5px;
            -moz-appearance: textfield !important;

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            &__append {
              cursor: pointer !important;
              color: #FFFFFF;
            }

            &__prepend {
              cursor: pointer !important;
              color: #FFFFFF;
            }
          }

          &__remains {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            row-gap: 5px;
            margin-left: -28px;

            &__block {
              display: flex;
              column-gap: 5px;
              justify-content: flex-start;
              align-items: center;
              height: 24px;
              padding-left: 30px;
              span {
                min-width: 23px;
              }

              .EDITABLE_QUANTITY_REMAINS {
                max-width: 70px;
                -moz-appearance: textfield !important;

                //::v-deep .v-text-field input {
                //  color: darkgray !important;
                //}

                ::v-deep .v-input__control input {
                  color: darkgray !important;
                  max-height: 24px !important;
                  text-indent: 8px;
                }

                ::v-deep input::-webkit-outer-spin-button,
                ::v-deep input::-webkit-inner-spin-button {
                  -webkit-appearance: none !important;
                }

                ::v-deep .v-input__prepend-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__append-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__slot {
                  background: $background_main_order_block !important;
                  padding: 0;
                }

                ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
                ::v-deep .v-input__slot {
                  margin: 0;
                  max-height: 26px;
                  min-height: 24px !important;
                  display: flex !important;
                  flex-direction: column;
                  align-items: center !important;
                }

                ::v-deep .v-input__control {
                  min-height: 24px !important;
                  color: white;
                }
              }

              .edit {
                ::v-deep .v-input__slot {
                  background: white !important;
                  padding: 0;
                }

                ::v-deep .v-input__control input {
                  color: black !important;
                  text-indent: 8px;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .card-buttons {
        position: absolute;
        right: -15px;
        top: -5px;

        &__delete {
          background: lightcoral;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.6s ease-in-out;
          cursor: pointer;
        }

        .showCloseImg {
          opacity: 1;
        }
      }

      &:hover {
        .wrapper_quantity {
          align-items: start;

          &__right {
            &__per_item {
              opacity: 1;
              transform: translateY(0px);
            }

            &__total {
              transform: translateY(0px);
            }
          }

          &__center {
            .EDITABLE_QUANTITY {
              transform: translateY(0px);
            }

            &__package {
              opacity: 1;
              transform: translateY(0px);
              @extend %howManyPiecesGrid;
            }

            &__edit {
              opacity: 1;
              transform: translateY(0px);
            }
          }

          &__left {
            .EDITABLE_QUANTITY {
              transform: translateY(0px);
            }

            &__remains {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
      }
    }

    .row_remains_modal {
      grid-template-columns: 20% 30% auto;

      .display {
        height: 110px;
      }
    }
  }

  &:hover {
    .wrapper_quantity {
      align-items: start;

      &__right {
        &__per_item {
          opacity: 1;
          transform: translateY(0px);
        }

        &__total {
          transform: translateY(0px);
        }
      }

      &__center {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__package {
          opacity: 1;
          transform: translateY(0px);
          @extend %howManyPiecesGrid;
        }

        &__edit {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &__left {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__remains {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

.row_remains_modal {
  grid-template-columns: 20% 25% auto;

  .display {
    height: 110px;
  }
}

::v-deep .v-text-field {
  padding-top: 0;
  margin-top: 0;
}

@media (max-width: 768px) {
  ::v-deep .v-text-field__slot {
    font-size: 0.75em !important;

    input {
      padding: 0 !important;
    }
  }
  .row_remains {
    grid-column-gap: 0.7em !important;

    .wrapper_name_articul {
      .articul, .drawing_number {
        font-size: 0.8em !important;;
        color: grey;

        span {
          color: lightgrey;
          font-size: 0.9em !important;
          opacity: 0.9;
        }
      }

      .name_prod {
        font-size: 0.85em !important;
      }
    }

    .wrapper_quantity {
      padding-right: 0 !important;
      word-break: break-all;

      &__titles {
        grid-template-columns: auto 35% !important;
      }

      &__items {
        grid-template-columns: auto 35% !important;;
      }
    }
  }
}

.modal_footer {
  position: sticky;
  bottom: 0;
  background: darkgray;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px 10px;
  font-size: 14px;

  &__top {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__item {
        display: flex;
        column-gap: 5px;

        &__title {
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
        }

        &__count {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
    }

    &__right {
      font-size: 24px;
      color: lightyellow;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal_footer__bottom {
      display: grid;
      grid-row-gap: 5px;
      align-items: center;
      column-gap: 10px;
    }
  }
}
</style>

<style lang="scss">
    .v-icon.v-chip__filter {
      font-size: 1em;
    }
</style>
