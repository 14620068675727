<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    min-width="auto"
    :nudge-right="0"
    :nudge-top="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="date_field"
        :value="computedDateFormattedDatefns"
        append-icon="mdi-calendar"
        readonly
        dense
        hide-details="auto"
        solo
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      class="date_picker"
      v-model="date"
      @input="menu = false"
      no-title
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  data() {
    return {
      menu: false,
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    }
  },
  methods: {
  },
  computed: {
    computedDateFormattedDatefns () {
      return this.date ? format(parseISO(this.date), 'dd.MM.yyyy') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/main';
@import 'src/assets/sass/specification';
</style>
