<template>
    <v-row justify="center" id="vue_wrapper_add_page_order">
        <v-dialog
                v-model="$store.state.data_new_order.state_v_show"
                max-width="600px"
                @click:outside="()=> {
                   $store.dispatch('ClearCustomAlert');
                }"
        >
            <div class="wrapper_loader_progress-circular" v-show="$store.state.state_progress_circular_page">
                <v-progress-circular
                        indeterminate
                        color="success"
                        size="100"
                        width="10"
                ></v-progress-circular>
            </div>
            <v-card>
                <v-card-title>
                    <span class="headline">Новый заказ-наряд</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-form ref="form" id="form_add_po">
                                <div class="wrapper_select_block wrapper_page_add_po" v-if="Object.keys(arr_data_form_order).length !== 0">
                                <div class="wrapper_row">
                                  <div class="first_block">
                                        <p>1. Что будем делать ? *</p>
                                        <live-search @listen-data-prod="(data) => { this.form_data.prod = (!!data) ? data : {} }" :showAll="showAll"></live-search>
                                  </div>
                                </div>
                                <div class="wrapper_row">
                                    <div class="second_block">
                                        <p>2. Сколько штук ? *</p>
                                        <div class="block_inputs">
                                    <!--  :items="form_data.prod.NUMBER_OF_POSITIONS_DESIRED_TO_ASSEMBLE_FOR_AMOUNT_TIME"    -->
                                            <v-combobox
                                                    :items="form_data.prod.NUMBER_OF_POSITIONS_DESIRED_TO_ASSEMBLE_FOR_AMOUNT_TIME"
                                                    item-value="value" item-text="text"
                                                    min="0"
                                                    solo dense hide-details
                                                    hide-selected
                                                    clearable
                                                    :return-object="false"
                                                    :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                                                    :rules="validate_rules.quantity_to_prod_rules"
                                                    hide-details="auto"
                                                    v-model.number="form_data.quantity_to_prod"
                                                    suffix="шт."
                                            ></v-combobox>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrapper_row">
                                    <div class="third_block">
                                        <p>3. Сценарий движения ЗН *</p>
                                        <v-select
                                                class="select_scenario"
                                                :items="arr_data_form_order.SCENARIO_STAGE_ORDER"
                                                item-value="ID_SCENARIO" item-text="NAME_SCENARIO"
                                                solo dense hide-details
                                                hide-selected
                                                clearable
                                                :menu-props="{bottom: true, offsetY: true }"
                                                :rules="validate_rules.scenario_rules"
                                                hide-details="auto"
                                                :return-object="true"
                                                v-model="form_data.result_scenario"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Приоритет для Виолы -->
                                <div class="wrapper_row wrapper_priority" v-if="arr_data_form_order.PRIORITY.VALID_USER_GROUP.CHECK_VALID_USER_GROUP === 'Y'">
                                    <div class="five_block">
                                        <p>4. Приоритет заказ-наряда: *</p>
                                        <v-select :items="arr_data_form_order.PRIORITY.ARR_PRIORITY"
                                                  solo dense hide-details
                                                  item-value="ID" item-text="NAME"
                                                  :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                                                  v-model="form_data.priority"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Исполнитель для ЕМ -->
                                <div class="wrapper_row wrapper_assign_performer"
                                     v-if="arr_data_form_order.ASSIGN_PERFORMER.VALID_USER_GROUP.CHECK_VALID_USER_GROUP === 'Y' && check_store_svarka">
                                    <div class="fourth_block">
                                        <p>5. Назначим сварщика ?</p>
                                        <v-autocomplete
                                            :items="arr_data_form_order.ASSIGN_PERFORMER.LIST_PERFORMER"
                                            solo dense hide-details
                                            clearable
                                            item-value="ID" item-text="FULL_NAME"
                                            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                                            v-model="form_data.id_performer"
                                            label="Не назначен"
                                          >
<!--                                            <template v-slot:prepend-item>-->
<!--                                                <v-list-item ripple @click="form_data.id_performer = null">-->
<!--                                                    <v-list-item-title>-->
<!--                                                        Не назначен-->
<!--                                                    </v-list-item-title>-->
<!--                                                </v-list-item>-->
<!--                                                <v-divider class="mt-1"></v-divider>-->
<!--                                            </template>-->
                                        </v-autocomplete>
                                    </div>
                                </div>

                                <div class="wrapper_block_commentary" v-if="arr_data_form_order.PRIORITY.VALID_USER_GROUP.CHECK_VALID_USER_GROUP === 'Y'">
                                    <header>Комментарии к заказ-наряду:</header>
                                    <v-textarea
                                            clearable solo dense auto-grow
                                            clear-icon="mdi-close-circle"
                                            v-model="form_data.commentary"
                                            rows="3"
                                    ></v-textarea>
                                </div>
                            </div>
                            </v-form>
                            <v-alert v-if="$store.state.custom_alert" id="custom-v-alert"
                                     transition="scale-transition"
                                     dismissible
                                     elevation="5"
                                     :type="$store.state.custom_alert.type_alert"
                                     :value="$store.state.custom_alert.state_alert"
                                     @input="$store.state.custom_alert.state_alert = false"
                            >
                                <span v-html="$store.state.custom_alert.data_alert"></span>
                            </v-alert>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            id="btn_cancel"
                            text
                            @click="() => {
                                $emit('listen-child-state');
                                $store.dispatch('ClearCustomAlert');
                            }"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn
                            :loading="!!$store.state.data_production.MAIN_DATA_PERFORMER_SVARKA === false"
                            id="btn_take_an_order"
                            color="secondary"
                            @click="take_an_order()"
                    >
                        В работу!
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import LiveSearch from "../components/LiveSearch";

    export default {
        name: "AddNewOrder",
        components: {
          LiveSearch,
        },
        data: () => ({
            form_data:{
                prod: {
                    NUMBER_OF_POSITIONS_DESIRED_TO_ASSEMBLE_FOR_AMOUNT_TIME: [],
                },
                quantity_to_prod: null,
                priority: null,
                id_performer: null,
                commentary: '',
                resolve_time_assemble: null,
                result_scenario: null,
            },
            shortNameForURL: {
              prod: 'product',
              quantity_to_prod: 'quantity',
              result_scenario: 'scenario',
              priority: 'priority',
              sybtype_prod: 'sybtype_prod'
            },
            computed_selected_contractor: [],
            // Объект для сбора всех изменений для URL
            queryData: {},
            shouldUpdate: true,
            arr_data_form_order: [],
            validate_rules:{
                quantity_to_prod_rules: [
                    value => !!value || 'Обязательно к заполнению',
                    value => (parseInt(value) >= 0 || parseInt(value) <= 0) || 'Должно быть числом'
                ],
                scenario_rules: [
                  value => !!value || 'Обязательно к заполнению',
                ],
            },
          showAll: true,
        }),
        mounted() {
            // console.log('Na moment mounted query')
            // console.log(this.$route.query)
            /* Общее свойство объекта */
            if (!!this.$store.state.data_new_order.nomenclature_obj) {
              if (Object.keys(this.$store.state.data_new_order.nomenclature_obj).length) {
                  this.form_data.prod = this.$store.state.data_new_order.nomenclature_obj;
              }
            }
            console.log('mounted_add_new_order');
            this.get_form_add_order();
            // this.$store.dispatch('getListProducts', {})
        },
        watch:{
            'form_data':{
                handler: function watch(value) {
                    if (this.shouldUpdate) {
                      console.log('watch form_data.prod')
                      this.updateUrl()
                    }
                },
                deep: true,
            },
        },
        computed: {
          check_store_svarka() {
            let result = false;

            if (!!this.form_data.result_scenario) {
              if (!!this.form_data.result_scenario.DATA_SCENARIO.find(elem => elem.NAME_STAGE === 'Сварка')) {
                result = true;
              }
            }
            /* TODO id_performer - Обнудлить */

            return result;
          },
        },
        methods:{
            // Проверяем изменения селекторов
            updateUrl() {
              let queryObj = {}
              if (Object.keys(this.$route.query).length) {
                for (let key in this.$route.query) {
                  Object.assign(queryObj, {[key]: this.$route.query[key]})
                }
              }
              for (let key in this.form_data) {
                if (key === 'prod') {
                  if ((this.form_data[key] !== null) && (typeof this.form_data[key] !== 'string')) {
                    Object.assign(queryObj, {[this.shortNameForURL[key]]: this.form_data[key].ID})
                  } else {
                    delete queryObj[this.shortNameForURL[key]]
                  }
                } else if (key === 'result_scenario') {
                  if (this.form_data[key] !== null) {
                    Object.assign(queryObj, {[this.shortNameForURL[key]]: this.form_data[key].ID_SCENARIO})
                  } else {
                    delete queryObj[this.shortNameForURL[key]]
                  }
                } else if (key !== 'commentary' && key !== 'resolve_time_assemble' && key !== 'id_performer' && key !== 'nomenclature_search') {
                  if (this.form_data[key] !== null) {
                    Object.assign(queryObj, {[this.shortNameForURL[key]]: this.form_data[key]})
                  } else {
                    delete queryObj[this.shortNameForURL[key]]
                  }
                }
              }
              console.log(queryObj)
              this.pushRouter(queryObj)
            },
            // Вносим изменения в URL
            pushRouter(object) {
              // console.log(object)
              this.$store.commit('updateURL_components', object)
            },
            async take_an_order(){
                const _this = this;

                function isNaN_check(value) {
                    if (Boolean(value)){
                        return parseInt(value)
                    }
                    else {
                        return null;
                    }
                }

                if (this.$refs.form.validate()) {
                    BX.ajax.runComponentAction('nk:production.add_purchase_order',
                        'executeAddNewRecord', { // Вызывается без постфикса Action
                            mode: 'class',
                            data: {
                                post: {
                                    id_prod: isNaN_check(_this.form_data.prod.ID),
                                    number_of_positions: _this.form_data.quantity_to_prod,
                                    id_scenario: _this.form_data.result_scenario.ID_SCENARIO,
                                    id_stage: _this.form_data.result_scenario.DATA_SCENARIO[0].ID,
                                    assign_performer: _this.form_data.id_performer,
                                    priority_choice: _this.form_data.priority,
                                    commentary: _this.form_data.commentary,
                                    check_store_svarka: _this.check_store_svarka,
                                },
                                'login': _this.$store.state.login,
                                'token': _this.$store.state.token,
                            },
                        })
                        .then(function (response) {
                            if (response.status === 'success') {
                                // Если форма успешно отправилась
                                console.log(response);
                                /* Вставим в разметку ответ от сервера */

                                if (!!response.data.id_new_order){
                                    _this.$store.state.custom_alert.data_alert = response.data.message;
                                    _this.$store.state.custom_alert.type_alert = 'success';
                                    /* Вызываем событие обновление списка ЗН */
                                }
                                else {
                                    _this.$store.state.custom_alert.data_alert = response.data.message;
                                    _this.$store.state.custom_alert.type_alert = 'warning';
                                }

                                _this.$emit('listen-add-update-order');
                            }
                        })
                        .catch((response) => {
                            console.log(response);
                            _this.$store.state.custom_alert.data_alert = response.data.message;
                            _this.$store.state.custom_alert.type_alert = 'error';
                        });

                    _this.$store.state.custom_alert.state_alert = true;
                }
                // else {
                //     document.getElementById('form_add_po').scrollIntoView({
                //         behavior: 'smooth',
                //         block: 'start'
                //     })
                // }
            },
            async get_form_add_order(){
                const _this = this;

                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.add_purchase_order',
                    'executeGetFormAddOrder', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            'post':{'1': '1',},
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success') {
                            _this.arr_data_form_order = response.data;

                            _this.$store.state.state_progress_circular_page = false;

                            // Проверяем наличие в URL, чтобы автоматически подставить в селектор
                            if (Object.keys(_this.$route.query).length) {
                                for (let key in _this.$route.query) {
                                    if (key === 'scenario' && _this.arr_data_form_order.SCENARIO_STAGE_ORDER.length) {
                                        for (let i = 0; i < _this.arr_data_form_order.SCENARIO_STAGE_ORDER.length; i++) {
                                            if (_this.arr_data_form_order.SCENARIO_STAGE_ORDER[i].ID_SCENARIO === _this.$route.query.scenario) {
                                              _this.form_data.result_scenario = _this.arr_data_form_order.SCENARIO_STAGE_ORDER[i]
                                            }
                                        }
                                    } else {
                                        for (let fullName in _this.shortNameForURL) {
                                            if (key === _this.shortNameForURL.fullName) {
                                              _this.form_data[fullName] = _this.$route.query[key]
                                            }
                                        }
                                    }
                                }
                            }

                            /* TODO  Выставляем первые значения в select'ы*/
                            if (_this.form_data.priority === null) {
                              _this.form_data.priority = _this.arr_data_form_order.PRIORITY.ARR_PRIORITY[0].ID;
                            }
                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            },
        },
        // Перед уничтожением компонента, смотрим в URL
        beforeDestroy() {
            if (Object.keys(this.$route.query).length) {
              this.form_data.priority = null
              let queryObj = {}
              for (let key in this.$route.query) {
                if (!Object.values(this.shortNameForURL).includes(key)) {
                  Object.assign(queryObj, {[key]: this.$route.query[key]})
                }
              }
              // вызываем метод обновления URL
              this.pushRouter(queryObj)
            }
            // Чистим из стора product
            this.$store.state.data_nomenclature_from_plus = Object.assign({})
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/main";
    .v-card {
        background: rgb(74, 74, 74)!important;
        .headline {
            font-size: 1.1em !important;
            color: #f7ee89;
            text-align: center;
            width: 100%;
        }
        #form_add_po {
            width: 100%;
            .wrapper_page_add_po {
                width: 100%;
                display: grid;
                grid-row-gap: 1.2em;
                font-size: 16px;
                margin-top: 1em;
                @media (max-width: 768px) {
                    font-size: 14px;
                }

                .wrapper_row {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-row-gap: 10px;
                    .third_block{
                        .wrapper_list_added_block {
                            box-shadow: 4px 4px 20px 4px rgb(0 0 0 / 20%), 2px 2px 2px 3px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
                            padding: 1em;
                            display: grid;
                            grid-row-gap: 1em;
                            .dynamic_added_block {
                                display: grid;
                                align-items: baseline;
                                font-size: 1em;
                                color: white;
                            }
                            #btn_add_stage_production{
                                display: grid;
                                justify-self: center;
                            }
                        }
                    }
                }

                .wrapper_block_commentary {
                    color: white;

                    header {
                        margin-bottom: 10px;
                    }
                }

                .header {
                    color: $caption_yellow_color;
                    margin-bottom: 15px;
                    font-size: 1.4rem;
                    text-align: center;
                }

                .count {
                    display: grid;
                    justify-content: flex-start;
                }

                p {
                    //text-align: center;
                    color: white;
                    text-align: left;
                    margin-top: 0;
                    margin-bottom: 10px;
                }

                .select2, .block_inputs {
                    //display: none;
                    //width: inherit!important;
                    //height: 35px;
                }

                .block_inputs {
                    flex-wrap: nowrap;
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 10px;
                        //font-size: 1.2rem;
                    }

                    #number_of_positions {
                        height: inherit;
                        //font-size: 1.2rem;
                        text-align: center;
                        padding: 6px;
                        width: 100%;
                        background-color: #fff;
                        border: 1px solid #aaa;
                        border-radius: 4px;
                    }
                }
            }
        }
        .v-card__actions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            padding: 0 24px 20px;
            grid-column-gap: 1em;
            button {
                font-family: Roboto;
                color: white;
                width: 100%;
                max-width: 200px;
                display: grid;
                letter-spacing: 1px;
                font-weight: bold;
            }
        }
    }
</style>

<style lang="scss">
    .v-slide-group__content {
      width: 100%;
      flex-wrap: wrap;
    }

    .select_scenario{
      .v-select__selection{
        white-space: pre-wrap;
      }
    }

    #vue_wrapper_add_page_order {
        .v-input {
            font-size: 1em;

            .v-input__control {
                min-height: 35px !important;

                .v-text-field__details {
                    /*display: none;*/
                }
            }
        }

        .v-select__slot {
            flex-wrap: wrap;

            .v-select__selection {
                //white-space: normal;
                max-height: 20px;
            }
        }
    }
    .dynamic_added_block {
        .v-input {
            .v-input__control {
                .v-text-field__details {
                    display: none;
                }
            }
        }
    }
</style>
