<template>
  <div class="wrapper_block_nomenclature">

    <div class="nomenclature_custom_group_search_select">
      <live-search
          v-if="!dialog_id_order"
          @listen-data-prod="(data) => { this.nomenclature_data = (!!data) ? data : {} }"
          style="margin-bottom: 1em"
          :detailNomenclature="detailNomenclature"
      ></live-search>
    </div>

    <!-- START. Вкладки -->
    <v-tabs
        v-if="!!nomenclature_data && Object.keys(nomenclature_data).length"
        class="nomenclature_list_tabs"
        v-model="tab"
        dark
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab :href="getUrlQueryParamOnTab+'#tab-0'">Карточка</v-tab>
      <v-tab :href="getUrlQueryParamOnTab+'#tab-1'">Используемость</v-tab>
      <v-tab :href="getUrlQueryParamOnTab+'#tab-2'" v-if="nomenclature_data.PRODUCT_TYPE === 'Покупнина'">Приходы для покупнины</v-tab>
      <v-tab :href="getUrlQueryParamOnTab+'#tab-3'" v-if="nomenclature_data.PRODUCT_TYPE === 'Товар' || nomenclature_data.PRODUCT_TYPE === 'Изделие'">ЗН для изделия</v-tab>
      <v-tab :href="getUrlQueryParamOnTab+'#tab-4'">Спецификация</v-tab>

      <!--
        Содержимое вкладок
      -->
      <v-tab-item :value="getUrlQueryParamOnTab+'tab-0'">
        <nomenclature-card-product :nomenclature_data="nomenclature_data"></nomenclature-card-product>
      </v-tab-item>

      <v-tab-item :value="getUrlQueryParamOnTab+'tab-1'">
          <list-order-for-nomenclature
              v-if="!!nomenclature_data && getStateActiveTabByName('tab-1')"
              :id_nomenclature="nomenclature_data.ID"
          ></list-order-for-nomenclature>
      </v-tab-item>

      <v-tab-item :value="getUrlQueryParamOnTab+'tab-2'">
        tab-2
      </v-tab-item>

      <v-tab-item :value="getUrlQueryParamOnTab+'tab-3'">
        <list-specification-found-this-nomenclature
            v-if="!!nomenclature_data && getStateActiveTabByName('tab-3')" :id_nomenclature="nomenclature_data.ID">
        </list-specification-found-this-nomenclature>
      </v-tab-item>

      <v-tab-item :value="getUrlQueryParamOnTab+'tab-4'">
        <tree-specification v-if="!!nomenclature_data && getStateActiveTabByName('tab-4')" :nomenclature_data="nomenclature_data">
        </tree-specification>
      </v-tab-item>

    </v-tabs>
    <p v-else style="color: darkgrey; font-size: 1.5em">Среди выбранных разделов такой номенклатуры не найдено</p>
    <!-- END. Вкладки -->

    <!-- Оверлей -->
    <v-overlay
        :z-index="0"
        :absolute="true"
        :value="$store.state.loading_nomenclature"
    >
      <v-progress-circular
          style="margin: auto;"
          width="3"
          height="3"
          id="progress_circular_list_order"
          color="green"
          :indeterminate="true"
      ></v-progress-circular>
    </v-overlay>

    <add-new-order
        v-if="$store.state.data_new_order.state_v_show"
        @listen-child-state="$store.state.data_new_order.state_v_show = false"
        @listen-add-update-order=""
    >
    </add-new-order>
  </div>
</template>

<script>
import ListOrderForNomenclature from "../ListOrderForNomenclature";
import ListSpecificationFoundThisNomenclature from "../ListSpecificationFoundThisNomenclature";
import AddNewOrder from "../AddNewOrder";
import TreeSpecification from "../TreeSpecification";
import NomenclatureCardProduct from "./NomenclatureCardProduct";
import LiveSearch from "../LiveSearch";

export default {
  name: "Nomenclature",
  components: {
    ListOrderForNomenclature,
    ListSpecificationFoundThisNomenclature,
    AddNewOrder,
    TreeSpecification,
    NomenclatureCardProduct,
    LiveSearch,
  },
  props: ['dialog_id_order'],
  data: () => ({
    nomenclature_data: {},
    nomenclature_search: '',
    expand_collapse_list_order: true,
    tab: '',
    detailNomenclature: true,
  }),
  watch: {
    /* Следим за приходящим пропсом из другого компонента */
    'dialog_id_order': {
      handler: function watch() {
        console.log('watch parent component - dialog_id_order');
        if (this.dialog_id_order) {
          this.set_cur_data_nomenclature();
        }
      },
      immediate: true
    },
    /* За измеением основного массива с данными, если меняется пишем отдельно  ид продукции для других компонентов */
    'nomenclature_data': {
      handler: function watch(value) {
        console.log('watch nomenclature_data');
        if (this.$route.name === "Nomenclature") {
          const id_prod = this.$route.query.id_prod;
          const hash = this.$route.hash;
          if (!!value) {
            if (!Object.keys(this.$route.hash).length && !this.$route.hash) {
              this.$router.replace({ query: { id_prod: value.ID }, hash: '#tab-0' }).catch(error => this.NavigationDuplicated(error));
            } else {
              this.$router.replace({ query: { id_prod: value.ID }, hash: this.$route.hash }).catch(error => this.NavigationDuplicated(error));
            }
          } else {
            if (!Object.keys(this.$route.hash).length && !this.$route.hash) {
              this.$router.replace({ query: { id_prod: value.ID }, hash: '#tab-0'}).catch(error => this.NavigationDuplicated(error));
            } else {
              this.$router.replace({ query: { id_prod: value.ID }, hash: this.$route.hash}).catch(error => this.NavigationDuplicated(error));
            }
          }
        }
      },
    },
  },
  mounted() {
    this.$store.state.modalNomenclature.state = true

    // if (!Object.keys(this.$route.hash).length && !this.$route.hash) {
    //   if (this.$route.name === "Nomenclature") {
    //     const id_prod = this.$route.query.id_prod;
    //     const hash = '#tab-0';
    //     this.$router.replace({ query: { id_prod: id_prod }, hash: hash }).catch(error => this.NavigationDuplicated(error));
    //   }
    // }
  },
  computed: {
    getUrlQueryParamOnTab(){
      const id_prod = this.$route.query.id_prod;
      return (id_prod) ? '?id_prod='+id_prod : '';
    }
  },
  methods: {
    NavigationDuplicated(error){
      if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error)
      }
    },
    getStateActiveTabByName(name_tab){
      return this.tab.indexOf(name_tab) !== -1;
    },
    set_cur_data_nomenclature() {
      let id_order = null;

      if (!!this.dialog_id_order) {
        console.log('this.dialog_id_order');
        console.log(this.dialog_id_order);

        id_order = this.dialog_id_order;
      } else {
        if (!!this.$route.query.id_prod) {
          console.log('this.$route.query.id_prod');
          console.log(this.$route.query.id_prod);

          id_order = this.$route.query.id_prod;
        }
      }

      if (!!id_order){
        this.$store.dispatch('getNomenclatureById', id_order)
            .then((response) => {
              console.log('WAYA');
              console.log(response);
              // this.$store.state.data_new_order.nomenclature_obj = response;
              this.nomenclature_data = !!response.DATA ? response.DATA[0] : null;
            });
      }
    },
  },
  beforeDestroy() {
    if (this.$route.hash) {
      this.$router.push({
        hash: null,
      })
    }
    this.$store.state.tabContractor = ''
  },
}
</script>

<style lang="scss">
@import "../../assets/sass/main";

/* Страница Номенклатуры */
%margin-left-right {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

/* Стили для вызова в модалке */
.v-card#v_dialog_nomenclature_window {
  background: #363636 !important;
  color: white !important;

  .v-card__title {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #363636;
  }

  .v-card__text {
    padding: unset !important;
  }

  .headline {
    margin: auto;
  }
}

.nomenclature_list_tabs {
  .v-tabs-bar {
    z-index: 1;
    background-color: #2f2e2d !important;

    .v-tab--active {
      background: #1E88E5 !important;
    }

    .v-slide-group__prev {
      display: contents !important;
    }

    .v-slide-group__content {
      display: inline-flex;
      grid-column-gap: 5px;
      flex-wrap: nowrap;

      .v-tabs-slider {
        display: none;
      }

      @media (max-width: 762px) {
        .v-tab {
          font-size: 10px;
        }
      }

      .v-tab {
        max-width: unset;
        text-transform: capitalize;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        padding: unset;
        background: $background_static_tabs;

        :before {
          border-radius: 10px;
        }

        &.v-tab--active {
          border: 1px solid grey;
          border-bottom: unset;
          background: #363636;
        }
      }
    }
  }
  .v-tabs-items {
    background-color: unset !important;
  }
}

.v-item-group {
  margin-top: 5px;
}

.wrapper_block_nomenclature {
  background: #363636 !important;
  width: 100%;
  margin-bottom: 50px;

  .nomenclature_custom_group_search_select {
    //position: sticky;
    top: 5px;
    z-index: 2;
    margin-top: 1em;
    @extend %margin-left-right;

    p {
      text-align: left;
      color: white;
    }

    .v-progress-linear {
      top: calc(100% - 3px);
      border-radius: 10px;
      height: 3px !important;
    }

    /*&:before{*/
    /*    content: url('/local/templates/gidruss/static/svg/лупа2.svg');*/
    /*    position: absolute;*/
    /*    width: 21px;*/
    /*    height: 21px;*/
    /*    bottom: 8px;*/
    /*}*/
  }
}
</style>
