<template>
  <div class="specification_card__real_nomenclature_content__search">
    <v-combobox
        :solo="$store.state.edit_fields"
        dense
        hide-details
        clearable
        hide-no-data
        return-object
        placeholder="Выберите реальную номенклатуру"
        class="specification_card__create_zone__head__inputs__input"
        v-model="selectedRealNomenclature"
        :loading="loading_list"
        :items="realSelectorList"
        item-text="NAME"
        :search-input.sync="realSelectorValue"
        @update:search-input="getRealItems()"
        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
        :disabled="!virtual_nomenclature.name || !Object.keys(virtual_nomenclature.name).length || !$store.state.edit_fields"
        :readonly="!$store.state.edit_fields"
    ></v-combobox>
    <v-btn
        color="primary"
        :loading="loading_list"
        :disabled="!selectedRealNomenclature || !Object.keys(selectedRealNomenclature).length || typeof selectedRealNomenclature === 'string'"
        @click="addRealToVirtual()"
    >
      Добавить
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'SearchByRealNomenclature'
}
</script>
