export class ChildNomenclature {
  constructor(
    DRAWING_NUMBER = null,
    NAME = '',
    TYPE = '',
    NOTE = '',
    QUANTITY_NODES = null,
    QUANTITY_BIND_NOMENCLATURE = 0,
  ) {
    this.DRAWING_NUMBER = DRAWING_NUMBER
    this.NAME = NAME
    this.TYPE = TYPE
    this.NOTE = NOTE
    this.QUANTITY_NODES = QUANTITY_NODES ? QUANTITY_NODES : 1
    this.QUANTITY_BIND_NOMENCLATURE = QUANTITY_BIND_NOMENCLATURE
  }
}
