<template>
  <div class="wrapper_render_info_product" v-if="nomenclature_data && Object.keys(nomenclature_data).length">
    <div class="wrapper_render_info_product__card yellow_border opacity-hover">
      <div class="wrapper_render_info_product__card__left">
        <div class="wrapper_render_info_product__card__left__img" v-viewer="{movable: false, zoomable: false, navbar: false}">
            <img
                :src="srcData"
                :title="titleData"
                :width="widthData"
                :alt="nomenclature_data.NAME"
                @error="replaceByDefault"
            >
        </div>
        <div class="wrapper_render_info_product__card__left__title">
            <span class="wrapper_render_info_product__card__left__title__name">
              {{ nomenclature_data.NOMENCLATURE_NAME ? nomenclature_data.NOMENCLATURE_NAME : nomenclature_data.NAME }}
            </span>
          <span class="wrapper_render_info_product__card__left__title__article">
              {{ nomenclature_data.DRAWING_NUMBER }}
            </span>
        </div>
      </div>
      <div class="wrapper_render_info_product__card__right">
        <div class="wrapper_render_info_product__card__right__count">
          {{ nomenclature_data.QUANTITY_NODES ? nomenclature_data.QUANTITY_NODES : '' }}
        </div>
        <div class="wrapper_render_info_product__card__right__info">
            <span class="wrapper_render_info_product__card__right__info__cost_price">
              {{ nomenclature_data.CATALOG_PURCHASING_PRICE ? nomenclature_data.CATALOG_PURCHASING_PRICE : 0 }} ₽
            </span>
          <div class="wrapper_render_info_product__card__right__info__buttons">
            <v-icon size="40" color="grey lighten-1">
              mdi-format-list-numbered
            </v-icon>
            <!-- ICON SPECIFICATION -->
            <span
                class="wrapper_render_info_product__card__right__info__buttons__countSubItems"
                @click="onShowChilds(nomenclature_data.ID)"
                :class="computed_color_quantity"
            >
                [{{ Object.keys(virtual_list.COUNT).length ? virtual_list.COUNT.CUR_COUNT : 'N' }}]
            </span>
          </div>
        </div>
      </div>
    </div>
    <transition name="list">
      <div class="wrapper_render_info_product__subChilds" v-show="showChilds">
        <div class="wrapper_render_info_product__subChilds__relations" :id="`relationBlock${index}`">
          <div class="relation" :style="`height: ${heightRelation}px`"></div>
        </div>
        <div class="wrapper_render_info_product__subChilds__cards" :id="`cards${index}`">
          <detail-nomneclature-card
              v-for="(item, indexItem) in virtual_list.DATA"
              :key="indexItem"
              :nomenclature_data="item"
              :showChilds="showChilds"
              :index="index"
              :specification_child="specification_child"
              :nomenclature_parent="calculatedID"
              style="font-size: 18px"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import DetailNomneclatureCard from "../nomenclature/DetailNomenclatureCard";

Vue.use(VueViewer)

export default {
  name: "SpecificationCard",
  components: {DetailNomneclatureCard},
  props:['nomenclature_data', 'index', 'force'],
  data: () => ({
    heightRelation: 0,
    heightByDefault: 0,
    showChilds: false,
    virtual_list: {
      COUNT: {},
      DATA: []
    },
    loading_list: false,
    specification_child: true,
  }),
  mounted() {
    if (Object.keys(this.nomenclature_data).length) {
      // Vertical line
      this.$nextTick(() => {
        const _this = this
        const resizeObserver = new ResizeObserver(function() {
          let height = document.getElementById(`relationBlock${_this.index}`).offsetHeight
          const parent = document.getElementById(`cards${_this.index}`);
          const child = parent.lastChild
          _this.heightByDefault = child.offsetHeight
          _this.heightRelation = height - _this.heightByDefault/2
        });
        resizeObserver.observe(document.getElementById(`relationBlock${this.index}`));
      })
    }
    this.getVirtualChilds()
  },
  watch: {
    'nomenclature_data': {
      handler() {
        this.$nextTick(() => {
          const _this = this
          const resizeObserver = new ResizeObserver(function() {
            let height = document.getElementById(`relationBlock${_this.index}`).offsetHeight
            const parent = document.getElementById(`cards${_this.index}`);
            const child = parent.lastChild
            _this.heightByDefault = child.offsetHeight
            _this.heightRelation = height - _this.heightByDefault/2
          });
          resizeObserver.observe(document.getElementById(`relationBlock${this.index}`));
        })
      },
    },
    '$store.state.show_all_childs': {
      handler(v) {
        if (Object.keys(this.virtual_list.COUNT).length && this.virtual_list.COUNT.CUR_COUNT > 0) {
          this.showChilds = !!v;
        }
      }
    }
  },
  computed: {
    calculatedID() {
      return this.nomenclature_data?.NOMENCLATURE_ID ? this.nomenclature_data.NOMENCLATURE_ID : this.nomenclature_data.ID
    },
    computed_color_quantity() {
      if (Object.keys(this.virtual_list.COUNT).length) {
        if (this.virtual_list.COUNT?.CUR_COUNT !== 0) {
          return 'green_lights_quantity';
        } else {
          return 'red_lights_quantity';
        }
      } else {
        return 'gray_lights_quantity'
      }
    },
    srcData() {
      return this.$store.state.VUE_DEV_LOCAL_SERVER+this.nomenclature_data?.PREVIEW_PICTURE?.SRC
    },
    titleData() {
      return this.nomenclature_data?.PREVIEW_PICTURE?.TITLE
    },
    widthData() {
      return this.nomenclature_data?.PREVIEW_PICTURE?.WIDTH
    }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    getVirtualChilds() {
      this.loading_list = true

      var myHeaders = new Headers();
      myHeaders.append("Connection", "keep-alive");
      myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.174 YaBrowser/22.1.3.848 Yowser/2.5 Safari/537.36");
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Origin", this.$store.state.VUE_DEV_LOCAL_SERVER);
      myHeaders.append("Referer", this.$store.state.VUE_DEV_LOCAL_SERVER);
      myHeaders.append("Accept-Language", "ru,en;q=0.9,zh;q=0.8");
      myHeaders.append("Cookie", "BITRIX_SM_GUEST_ID=217156; BITRIX_SM_LAST_VISIT=29.10.2021%2012%3A50%3A39; BITRIX_SM_SALE_UID=0; BITRIX_SM_SOUND_LOGIN_PLAYED=Y; BITRIX_SM_UIDH=6b68bb4d326eed456760a113ae4bad0f; BITRIX_SM_UIDL=gidruss; PHPSESSID=PpTKpCCUJ46f9a3v0eOUtlQl6z3mUklQ");

      var urlencoded = new URLSearchParams();
      urlencoded.append("post[id_virtual_nomenclature]", this.nomenclature_data.NOMENCLATURE_ID ? this.nomenclature_data.NOMENCLATURE_ID : this.nomenclature_data.ID);
      urlencoded.append("token", this.$store.state.token);
      urlencoded.append("sessid", this.$store.state.sessid);
      urlencoded.append("login", this.$store.state.login);
      urlencoded.append("post[num_page]", "1");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(`${this.$store.state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?mode=class&c=nk%3Aproduction.list_nomenclature&action=executeGetBindedNomenclatureByVirtualNomenclature`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.virtual_list.DATA = []
            this.$nextTick(() => {
              this.virtual_list = result.data.log_data
              this.loading_list = false
              if (this.virtual_list.DATA.length) {
                this.resizeConnection()
                if (this.force) {
                  setTimeout(() => {
                    this.showChilds = true
                  }, 400)
                }
              }
            })
          })
          .catch(error => {
            this.loading_list = true
            console.log('error', error)
          });
    },
    onShowChilds() {
      if (Object.keys(this.virtual_list.COUNT).length && this.virtual_list.COUNT.CUR_COUNT > 0) {
        this.showChilds = !this.showChilds
      }
    },
    resizeConnection() {
      if (Object.keys(this.virtual_list.DATA).length) {
        // Vertical line
        this.$nextTick(() => {
          const _this = this
          const resizeObserver = new ResizeObserver(function() {
            let height = document.getElementById(`relationBlock${_this.index}`).offsetHeight
            const parent = document.getElementById(`cards${_this.index}`);
            const child = parent.lastChild
            _this.heightByDefault = child.offsetHeight
            _this.heightRelation = height - _this.heightByDefault/2
          });
          resizeObserver.observe(document.getElementById(`relationBlock${this.index}`));
        })
      }
    },
    toContractor(contractor) {
      if (Object.keys(contractor).length) {
        this.$router.push({
          path: '/spisok-nomenklatury',
          // query: {contractor_id: contractor.CUSTOM_CONTRACTOR_ID}
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/main";

.wrapper_render_info_product {
  width: 100%;
  margin-top: 20px;
  color: white;
  font-size: 16px;
  @media (min-width: 450px) {
    & {
      font-size: 18px;
    }
  }
  @media (min-width: 800px) {
    & {
      font-size: 18px;
    }
  }

  display: flex;
  flex-direction: column;

  &__card {
    display: grid;
    grid-template-columns: 75% auto;
    border: 1px solid #c3cfd9;
    background: $background_main_order_block;
    @extend %margin-row-position;
    padding: 10px 10px;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    transition: all .5s ease-in-out;

    &.active {
      background: #5f5f5f;
    }

    //&:hover {
    //  background: darken(#ffffff, 15%);
    //  border-color: #0d95e8;
    //}

    &__left {
      display: grid;
      grid-template-columns: 13% 80%;
      column-gap: 10px;
      align-items: center;

      &__img {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: 65px;
        max-width: 100%;

        img {
          width: 100%;
          height: 100%;
          //margin: 0 auto;
          object-fit: contain;
          border-radius: 5px;
          background-color: #FFFFFF;
        }
      }
      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        font-size: 1.15em;
        max-width: 100%;
        row-gap: 5px;

        &__firstBlock {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &__article {
            color: #207868;
            font-size: 1.2em;
          }
          &__contractor {
            text-transform: uppercase;
            color: #2c88d9;
            font-size: 0.8em;
          }
        }
        &__secondBlock {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          column-gap: 25px;

          &__name {
            color: #838f9c;
            font-size: 0.8em;
            text-align: left;
            word-break: break-all;
          }
          &__cost {
            font-size: 0.65em;
            color: #207868;
            min-width: 70px;
            padding-top: 4px;
            text-align: right;
          }
        }

        &__name {
          color: burlywood;
          text-align: left;
          word-break: break-word;
          font-size: 0.8em !important;
        }
        &__article {
          color: lightyellow;
          font-size: 0.65em !important;
        }
      }
    }
    &__right {
      display: grid;
      grid-template-columns: 30% auto;
      align-items: end;
      justify-items: end;

      &__count {
        font-size: 40px !important;
        color: lightgrey;
        margin-bottom: 8px;
      }

      &__info {
        display: grid;
        grid-template-rows: auto auto;
        align-content: space-between;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-between;
        height: 100%;

        &__block {
          font-size: 0.7em;
          width: 100%;
          display: grid;
          grid-template-columns: auto 16%;
          column-gap: 10px;
          justify-items: end;
          align-items: center;

          header {
            color: #838f9c;
          }
          span {
            font-size: 1.15em;
          }
        }

        &__cost_price {
          color: #338cda;
          font-size: 0.85em;
          text-align: right;
        }
        &__buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 15px;

          &__countSubItems {
            font-size: 1.5em;
            padding-bottom: 3px;
          }
        }
      }
    }

    .blockChild {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }

  &__subChilds {
    display: grid;
    grid-template-columns: auto 90%;
    padding-top: 2px;

    &__relations {
      display: flex;
      //width: 100%;
      justify-content: center;
      height: 100%;

      .relation {
        width: 4px;
        border-radius: 5px;
        height: 100%;
        background: #c3cfd9;
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      &__wrapper {
        //position: relative;
        //display: flex;
        //align-items: center;
        &__connection {
          //position: absolute;
          ////left: -45px;
          //width: 100%;
          //border-radius: 5px;
          //height: 4px;
          //background: #c3cfd9;
          //z-index: 1;
          //overflow: hidden;
        }
        .child {
          z-index: 3;
          width: 100%;
          grid-template-columns: 65% auto;
          border: 2px dashed #788896;
          background: #e3e6e9;
          transition: all .3s ease-in-out;

          //&:hover {
          //  background: #f6d8dd;
          //  border-color: #d3455b;
          //}
        }
      }
    }
  }
}

.red_lights_quantity {
  color: #d3455b;
}

.green_lights_quantity {
  color: #24912c;
}

.gray_lights_quantity {
  color: darkgrey;
}
</style>
