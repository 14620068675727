<template>
  <div>
    <MetaMaket>
      <template v-slot:info>
        <div class="operation_card-info" style="padding-right: 10px">
          <div
            class="wrapper_render_info_product__card__left__img"
            style="cursor: pointer"
            v-viewer="{ movable: false, zoomable: false, navbar: false }"
          >
            <img
              class="wrapper_render_info_product__card__left__img__image"
              v-if="
                nomenclature_data.PICTURE &&
                Object.keys(nomenclature_data.PICTURE).length
              "
              :src="
                $store.state.VUE_DEV_LOCAL_SERVER +
                nomenclature_data.PICTURE.SRC
              "
              :title="nomenclature_data.PICTURE.TITLE"
              :width="nomenclature_data.PICTURE.WIDTH"
              :alt="nomenclature_data.NAME"
              @error="replaceByDefault"
            />
            <img
              v-else
              class="image"
              src="https://www.seekpng.com/png/detail/75-753538_png-file-svg-cogs-svg.png"
              :alt="nomenclature_data.NAME"
            />
          </div>

          <div
            class="d-flex justify-space-between align-center"
            style="width: 100%"
          >
            <div
              class="operation_card__info"
              :style="`font-size: ${specification_child ? '14px' : '16px'}`"
              style="cursor: pointer"
              @click="show_nomenclature_dialog"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="operation_card__info__block"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="operation_card__info__block__name"
                      v-if="Object.keys($store.state.contractorByDefault)"
                      :style="`font-size: ${
                        specification_child ? '0.95em' : '0.8em'
                      }`"
                    >
                      {{ $store.state.contractorByDefault.PERSON_NAME }}
                    </span>
                    <span class="operation_card__info__block__name">
                      {{ nomenclature_data.NAME }}
                    </span>
                  </div></template
                ><span>
                  {{ nomenclature_data.NAME }}
                </span></v-tooltip
              >
              <span class="internal_article">
                {{
                  nomenclature_data.INTERNAL_ARTICLE ==
                  nomenclature_data.ARTICUL
                    ? nomenclature_data.INTERNAL_ARTICLE
                    : nomenclature_data.INTERNAL_ARTICLE
                    ? nomenclature_data.INTERNAL_ARTICLE
                    : nomenclature_data.ARTICUL
                    ? nomenclature_data.ARTICUL
                    : ''
                }}
                {{
                  nomenclature_data.INTERNAL_ARTICLE ==
                  nomenclature_data.ARTICUL
                    ? ''
                    : nomenclature_data.INTERNAL_ARTICLE
                    ? ' (' + nomenclature_data.ARTICUL + ')'
                    : ''
                }}
              </span>
            </div>
            <div class="real_nom-icon">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <div
                      v-if="nomenclature_data.LINK_TO_CATALOG"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                      @click="goToCatalogOrPanel(nomenclature_data, 'catalog')"
                    >
                      <v-icon
                        :color="hover ? 'grey lighten-5' : 'grey lighten-1 '"
                        >mdi-web</v-icon
                      >
                    </div></v-hover
                  ></template
                >
                <span> Посмотреть на сайте </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <div
                      v-if="nomenclature_data.LINK_TO_CONTROL_PANEL"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        goToCatalogOrPanel(nomenclature_data, 'catalog-edit')
                      "
                    >
                      <v-icon
                        :color="hover ? 'grey lighten-5' : 'grey lighten-1 '"
                        >mdi-file-edit-outline</v-icon
                      >
                    </div></v-hover
                  ></template
                >
                <span> Перейти в редактирование CMS Bitrix </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:related>
        <div
          class="operation_card__right real_width-related"
          style="gap: 40px; justify-content: space-between"
          :class="{ 'justify-end': !nomenclature_data.PURCHASING_PRICE }"
        >
          <span
            class="
              wrapper_render_info_product__card__left__title__secondBlock__cost
            "
            v-if="nomenclature_data.PURCHASING_PRICE"
            :style="`font-size: ${specification_child ? '20px' : '18px'}`"
          >
            {{ nomenclature_data.PURCHASING_PRICE.toLocaleString() }} ₽
          </span>

          <div
            class="wrapper_render_info_product__card__right blockChild"
            v-if="isMobile"
          >
            <div class="wrapper_render_info_product__card__right__info__block">
              <div>Фактические остатки:</div>
              <div
                v-if="isMobile"
                style="border-bottom: 1px dotted #838f9c; width: 100%"
              ></div>
              <span :class="computed_color_fact_quantity">
                {{
                  nomenclature_data.QUANTITY ? nomenclature_data.QUANTITY : 0
                }}
              </span>
            </div>
            <div
              class="
                wrapper_render_info_product__card__right__info__block
                green_lights_quantity
              "
            >
              <div>Не снижаемые остатки:</div>
              <div
                v-if="isMobile"
                style="border-bottom: 1px dotted #838f9c; width: 100%"
              ></div>
              <span>{{
                nomenclature_data.MINIMUM_QUANTITY_GIDRUSS
                  ? nomenclature_data.MINIMUM_QUANTITY_GIDRUSS
                  : 0
              }}</span>
            </div>
            <div class="wrapper_render_info_product__card__right__info__block">
              <div>В упаковке:</div>
              <div
                v-if="isMobile"
                style="border-bottom: 1px dotted #838f9c; width: 100%"
              ></div>
              <span>
                {{
                  nomenclature_data.HOW_MANY_PIECES_IN_PACKAGE
                    ? HOW_MANY_PIECES_IN_PACKAGE
                    : 0
                }}
              </span>
            </div>
            <div class="wrapper_render_info_product__card__right__info__block">
              <div>Критические остатки:</div>
              <div
                v-if="isMobile"
                style="border-bottom: 1px dotted #838f9c; width: 100%"
              ></div>
              <span>{{
                nomenclature_data.CRITICAL_QUANTITY_GIDRUSS
                  ? nomenclature_data.CRITICAL_QUANTITY_GIDRUSS
                  : 0
              }}</span>
            </div>
            <div class="wrapper_render_info_product__card__right__info__block">
              <div>Учетная цена</div>
              <div
                v-if="isMobile"
                style="border-bottom: 1px dotted #838f9c; width: 100%"
              ></div>
              <span
                >{{
                  nomenclature_data.CATALOG_PURCHASING_PRICE
                    ? nomenclature_data.CATALOG_PURCHASING_PRICE
                    : 0
                }}
                ₽</span
              >
            </div>
          </div>
          <div class="wrapper_render_info_product__card__right" v-else>
            <div
              class="blockChild mobile_info_product"
              style="width: 200px; display: flex; flex-direction: column"
            >
              <div
                class="wrapper_render_info_product__card__right__info__block"
              >
                <div>Фактические остатки:</div>
                <span :class="computed_color_fact_quantity">
                  {{
                    nomenclature_data.QUANTITY ? nomenclature_data.QUANTITY : 0
                  }}
                </span>
              </div>
              <div
                class="wrapper_render_info_product__card__right__info__block"
              >
                <div>Не снижаемые остатки:</div>
                <span class="green_lights_quantity">{{
                  nomenclature_data.MINIMUM_QUANTITY_GIDRUSS
                    ? nomenclature_data.MINIMUM_QUANTITY_GIDRUSS
                    : 0
                }}</span>
              </div>
              <div
                class="wrapper_render_info_product__card__right__info__block"
              >
                <div>В упаковке:</div>
                <span>{{
                  nomenclature_data.HOW_MANY_PIECES_IN_PACKAGE
                    ? nomenclature_data.HOW_MANY_PIECES_IN_PACKAGE
                    : 0
                }}</span>
              </div>
              <div
                class="wrapper_render_info_product__card__right__info__block"
              >
                <div>Критические остатки:</div>
                <span>{{
                  nomenclature_data.CRITICAL_QUANTITY_GIDRUSS
                    ? nomenclature_data.CRITICAL_QUANTITY_GIDRUSS
                    : 0
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:edit>
        <div class="operation_card__edit" style="padding-left: 10px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="curly" v-on="on">
                <v-checkbox
                  color="success"
                  title="Номенклатура для выдачи по-умолчанию"
                  v-model="nomenclature_data.IS_DEFAULT_REAL_NOMENCLATURE"
                  @click="setDefaultRealNomenclature(nomenclature_data)"
                  :disabled="getActionPage($route) == 'view'"
                />
              </div>
            </template>
            <span>Номенклатура для выдачи по-умолчанию</span>
          </v-tooltip>

          <v-hover v-slot="{ hover }">
            <div
              class="operation_card__buttons"
              v-if="
                getActionPage($route) === 'edit' ||
                getActionPage($route) === 'create'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    plain
                    :color="hover ? 'grey lighten-5' : 'grey lighten-1 '"
                    @click="
                      setSimpleModal({
                        message: {
                          text: 'Удалить номенклатуру из спецификации?',
                          name: nomenclature_data.NAME,
                        },
                        data_obj: nomenclature_data,
                        component_name: $options.name,
                      })
                    "
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Удалить номенклатуру из спецификации</span>
              </v-tooltip>
            </div>
          </v-hover>
        </div>
        <!-- <div
          class="wrapper_render_info_product__card__selectors"
          v-if="isMobile"
        >
          <detail-contractors-selectors :isMobile="isMobile" />
        </div> -->
      </template>
    </MetaMaket>

    <v-dialog
      v-model="dialog_nomenclature_window.state"
      max-width="600px"
      content-class="dialog_nomenclature"
    >
      <v-card id="v_dialog_nomenclature_window">
        <v-card-title>
          <span class="headline">Номенклатура</span>
          <v-icon
            class="close_dialog_cross"
            color="primary"
            elevation="2"
            @click="dialog_nomenclature_window.state = false"
            >fas fa-times
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <nomenclature
                v-if="dialog_nomenclature_window.state"
                :dialog_id_order="dialog_nomenclature_window.id_order"
              ></nomenclature>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { isMobile } from 'mobile-device-detect'
import DetailContractorsSelectors from './DetailContractorsSelectors'
import MetaMaket from './MetaMaket.vue'

export default {
  name: 'DetailNomenclatureCard',
  components: {
    DetailContractorsSelectors,
    Nomenclature: () => import('./Nomenclature'),
    MetaMaket,
  },
  props: [
    'nomenclature_data',
    'showChilds',
    'index',
    'specification_child',
    'nomenclature_parent',
    'disableAbsolute',
  ],
  data: () => ({
    width: 0,
    isMobile: false,
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
  }),
  created() {
    this.isMobile = isMobile
  },
  mounted() {
    console.log('this.$parent', this.$parent.$options.name)
    this.$nextTick(() => {
      // Horizontal line
      window.addEventListener('resize', this.onResize)
      this.onResize()
    })
  },
  computed: {
    ...mapGetters('specificationStore', ['getActionPage']),
    computed_color_fact_quantity() {
      if (
        parseInt(
          this.nomenclature_data.QUANTITY_PRODUCT_CATALOG_PRODUCT_TABLE,
        ) < parseInt(this.nomenclature_data.CRITICAL_QUANTITY_GIDRUSS)
      ) {
        return 'red_lights_quantity'
      } else {
        return 'green_lights_quantity'
      }
    },
  },
  watch: {
    showChilds: {
      handler() {
        if (this.showChilds) {
          this.$nextTick(() => {
            this.onResize()
          })
        }
      },
    },
    // Следим за модальным окном и если событие кнопки ОК сработало то удаляем номенклатуру, переданную в data_obj
    '$store.state.simpleModal': {
      handler: function (obj) {
        if (
          obj.component_name === this.$options.name &&
          obj.state_accept_event
        ) {
          this.deleteRealNomenclature(obj.data_obj)
          this.hideSimpleModal()
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('specificationStore', [
      'setDefaultRealNomenclature',
      'deleteRealNomenclature',
    ]),
    ...mapActions(['setSimpleModal', 'hideSimpleModal']),

    goToCatalogOrPanel(nom_data, item) {
      if (item === 'catalog') {
        window.open(nom_data.LINK_TO_CATALOG, '_blank')
        return
      }

      if (item === 'catalog-edit') {
        window.open(
          this.$store.state.VUE_DEV_LOCAL_SERVER +
            nom_data.LINK_TO_CONTROL_PANEL,
          '_blank',
        )
        return
      }
    },

    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    onResize() {
      this.$nextTick(() => {
        const elem = document.getElementById(`relationBlock${this.index}`)
        if (elem) {
          this.width = elem.getBoundingClientRect().width / 2 + 12
        } else {
          this.width = 0
        }
      })
    },
    show_nomenclature_dialog() {
      if (this.$parent.$options.name === 'NomenclatureCardProduct') return false

      this.dialog_nomenclature_window.state = true
      this.dialog_nomenclature_window.id_order = this.nomenclature_data.ID
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/main';
@import 'src/assets/sass/detailSpecification';

.operation_card {
  &__buttons {
    height: 100%;

    & button {
      height: 100%;
      padding-left: 10px;
    }
  }
}

.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #888888 !important;
}

.internal_article {
  @extend %internal-article-text;
}

@media (max-width: 768px) {
  .wrapper_render_info_product {
    width: 100%;
    margin-top: 20px;
    color: white;
    font-size: 12px !important;
    display: flex;
    flex-direction: column;

    &__card {
      display: grid;
      grid-template-columns: unset;
      grid-template-areas:
        'leftBlock'
        'info';

      &__left {
        grid-area: leftBlock;
        grid-template-columns: unset !important;
        display: flex;
        grid-template-areas:
          'article img'
          'name img';

        &__img {
          grid-area: img;
          img {
            width: 100% !important;
          }
        }
        &__title {
          &__firstBlock {
            &__article {
              grid-area: article;
            }
            &__contractor {
              display: none;
              grid-area: contractor;
            }
          }
          &__secondBlock {
            &__name {
              grid-area: name;
            }
            &__cost {
              display: none;
              grid-area: cost;
            }
          }
        }
      }
      &__right {
        padding-top: 15px;
        grid-area: info;

        &__info {
          font-size: 1.2em !important;

          &__block {
            justify-items: start !important;
            grid-template-columns: auto 1fr auto !important;
            column-gap: 2px !important;

            div {
              font-size: 1.2em;
            }
            span {
              justify-self: end !important;
            }
            div {
              padding-top: 10px;
            }
          }
          &__cost_price {
          }
          &__buttons {
          }
        }
      }
    }

    .child {
      grid-template-columns: unset !important;
    }
  }
}
.real_width-related {
  width: 348px;
}
.wrapper_render_info_product {
  @media (min-width: 450px) {
    & {
      font-size: 18px;
    }
  }
  @media (min-width: 800px) {
    & {
      font-size: 20px;
    }
  }

  width: 100%;
  margin-top: 20px;
  color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;

  &__card {
    display: flex;
    // grid-template-columns: 75% auto;
    background: #ffffff;
    border: 2px solid #c3cfd9;
    //#d2dbe3
    border-radius: 5px;
    color: black;
    padding: 10px 10px;
    cursor: pointer;
    height: 100%;

    &__left {
      display: flex;
      // grid-template-columns: 20% auto;
      align-items: center;
      column-gap: 10px;

      &__img {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        max-width: 100%;
        width: 96px;

        img {
          // width: 100%;
          // height: 100%;
          width: 96px;
          height: 80px;
          //margin: 0 auto;
          object-fit: contain;
          border-radius: 5px;
          background-color: #ffffff;
        }
      }
      &__title {
        //display: flex;
        //flex-direction: column;
        //align-items: flex-start;
        //justify-content: flex-start;
        display: grid;
        // grid-template-rows: 30% auto;
        justify-items: start;
        align-items: start;
        height: 100%;
        font-size: 1.15em;
        width: 280px;

        &__firstBlock {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &__article {
            color: lightyellow;
            font-size: 1.2em;
          }
          &__contractor {
            text-transform: uppercase;
            color: #2c88d9;
            font-size: 0.8em;
          }
        }
        &__secondBlock {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          column-gap: 25px;

          &__name {
            color: burlywood;
            font-size: 0.8em;
            text-align: left;
            word-break: break-all;
          }
          &__cost {
            font-size: 0.65em;
            color: #24912c;
            width: max-content;
            text-align: right;
          }
        }

        &__name {
          color: #293845;
          text-align: left;
          word-break: break-all;
        }
        &__article {
          color: #84939f;
        }
      }
    }
    &__right {
      // display: grid;
      // // grid-template-columns: 30% auto;
      // align-items: center;
      // justify-items: end;
      // width: 280px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__count {
        font-size: 52px !important;
        color: #293845;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        &__block {
          font-size: 0.7em;
          width: 100%;
          display: flex;
          /* display: grid; */
          /* grid-template-columns: auto 16%; */
          -moz-column-gap: 10px;
          column-gap: 10px;
          /* justify-items: end; */
          align-items: center;
          height: 20px;

          div {
            color: darkgray;
          }
          span {
            font-size: 1.15em;
            color: #ffffff;
          }
        }

        &__cost_price {
          color: #338cda;
          font-size: 1.15em;
          text-align: right;
        }
        &__buttons {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          column-gap: 15px;

          &__countSubItems {
            font-size: 1.6em;
          }

          .braces {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .blockChild {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-right: 36px;
    }
  }

  &__subChilds {
    display: grid;
    grid-template-columns: auto 90%;
    padding-top: 2px;

    &__relations {
      display: flex;
      //width: 100%;
      justify-content: center;

      .relation {
        width: 4px;
        border-radius: 5px;
        height: 100%;
        background: #c3cfd9;
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      row-gap: 2px;

      &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        .child {
          z-index: 3;
          width: 100%;
          display: flex;
          justify-content: space-between;
          // grid-template-columns: 65% auto;
          border: 1px solid #c3cfd9;
          transition: all 0.3s ease-in-out;
          background: $background_main_order_block;
          padding: 10px 10px;
          border-radius: 10px;

          &:hover {
            //background: lighten(darkgray, 5%);
            border-color: #dde09e;
          }
        }
      }
    }
  }
}

.operation_card__info__block__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 250px;
}

.red_lights_quantity {
  color: #d3455b !important;
}

.green_lights_quantity {
  color: #24912c !important;
}

.real_nom-icon {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2;
  padding: 14px;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}

@media screen and (max-width: 848px) {
  .wrapper_render_info_product__card__right {
    display: flex;
    flex-direction: column;
  }

  .real_width-related {
    width: auto !important;
  }

  .wrapper_render_info_product__card__right__info__block {
    display: grid;
    height: auto !important;
    align-items: baseline;
  }
}

@media screen and (max-width: 600px) {
  .wrapper_render_info_product__subChilds__cards__wrapper .child {
    flex-direction: column;
  }

  .real_width-related {
    width: auto;
  }

  .mobile_info_product {
    width: auto !important;
  }

  .operation_card__info__block__name {
    width: 208px;
  }

  // .onMobile {
  //   flex-direction: row;
  //   gap: 10px;
  // }
}
</style>
