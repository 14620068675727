<template>
  <div>
    <SubDetailCard
      style="margin-bottom: 15px"
      v-for="(item, index) in elementValue"
      :key="index"
      :index="index"
      :quantity="false"
      :data_elem="item"
    />
  </div>
</template>

<script>
import SubDetailCard from './SubDetailCard.vue'

export default {
  name: 'Applicability',
  props: {
    elementValue: { type: Array, default: () => [] },
  },
  components: { SubDetailCard },
}
</script>

<style lang="scss" scoped></style>
