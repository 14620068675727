<template>
  <div>
    <div id="wrapper_contractor" :class="{wrapper_contractor_modal: modal}">
      <!-- Блок фильтров -->
      <div id="" class="invisible block_filters_universal" v-show="$store.state.state_block_filters">

        <div class="block_filter">
          <v-text-field
              solo dense
              hide-details
              clearable
              @click:clear="$nextTick(() => { data_filters.name = null})"
              @input="get_list_contractor()"
              label="Поиск по наименованию"
              v-model="data_filters.name"
              style="padding-bottom: 10px"
          ></v-text-field>
          <v-text-field
              solo dense
              hide-details
              clearable
              @click:clear="$nextTick(() => { data_filters.prefix = null})"
              @input="get_list_contractor()"
              label="Поиск по префиксу"
              v-model="data_filters.prefix"
              style="padding-bottom: 10px"
          ></v-text-field>
          <!-- TYPE_CONTRACTORS -->
<!--          <v-autocomplete-->
<!--              solo dense-->
<!--              hide-details-->
<!--              clearable-->
<!--              label="Тип контрагента"-->
<!--              :items="$store.state.listTypeContractors"-->
<!--              item-text="UF_NAME"-->
<!--              return-object-->
<!--              v-model="type_contractor"-->
<!--              @change="get_list_contractor"-->
<!--          ></v-autocomplete>-->
        </div>

        <div id="block_error">Идёт загрузка...</div>
      </div>

      <div class="wrapper_all_row" v-if="list_contractor.DATA !== null || list_contractor.DATA.length">
        <div class="wrapper_grid_table opacity-hover" v-for="(doc, index) in list_contractor.DATA"
             :key="index"
             :class="status_doc(doc, 'border')"
        >
          <v-lazy>
            <div class="wrapper_rows_data">
              <section class="wrapper_name_info">
                <section class="wrapper_name_info__name" style="cursor: pointer" @click="showNomenclature(doc)">{{  doc.COMPANY }}</section>
                <section class="wrapper_name_info__prefix" v-if="doc.PREFIX">
                  <span style="color: darkgray; opacity: 0.9">Префикс артикула:</span> {{ doc.PREFIX }}
                </section>
                <section class="wrapper_name_info__address">
                  {{ doc.ADDRESS ? doc.ADDRESS : doc.COUNTRY ? doc.COUNTRY : doc.CITY }}
                </section>
                <section class="items-wrapper">
                  <section class="first-item">
                    <section class="wrapper_name_info__phone">
                      <span>Телефон: </span>{{ doc.PHONE }}
                    </section>
                  </section>
                  <section class="second-item">
                    <section class="wrapper_name_info__item">
                      <span>email: </span>{{ doc.EMAIL }}
                    </section>
                    <section class="wrapper_name_info__item">
                      <span>ИНН: </span>{{ doc.INN }}
                    </section>
                  </section>
                </section>
              </section>
              <div class="rightBlock">
                <span v-if="dayPassed(doc) > 0">Дней с последней поставки
                  <span
                      :class="status_doc(doc, 'color')"
                  >
                    {{ dayPassed(doc) }}
                  </span>
                </span>
                <div class="wrapper_icons">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          class="wrapper_icons__icon"
                          @click="showForm(doc)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Изменить данные контрагента</span>
                  </v-tooltip>
                  <v-menu
                      open-on-hover
                      left
                      offset-x
                      rounded
                      origin="top right"
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          class="wrapper_icons__icon"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-clipboard-clock-outline
                      </v-icon>
                    </template>
                    <v-list
                        color="#454444"
                    >
                      <v-list-item
                          v-for="(item, index) in doc.ALL_DOCS"
                          :key="index"
                          class="menu-list-item"
                      >
                        <router-link
                            :to="{name: 'Receipt_overhead', query: {id_document: item.ID}}" class="menu-list-item-link"
                        >
                          {{`${item.DATE_MODIFY_VALID} - №${item.ID} - ${item.TOTAL===null ? 0 : item.TOTAL} ₽`}}
                        </router-link>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <template v-if="doc.EXIST_CONTRACTOR_BY_DEFAULT_ID">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="wrapper_icons__icon"
                            @click="showNomenclature(doc, 'contractors_by_default')"
                            :color="status_doc(doc, 'btn')"
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-format-list-bulleted-square
                        </v-icon>
                      </template>
                      <span>Оформить заказ поставщику</span>
                    </v-tooltip>
                  </template>
                  <template v-if="doc.EXIST_PRODUCER_ID">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="wrapper_icons__icon"
                            @click="showNomenclature(doc, 'producer')"
                            :color="status_doc(doc, 'btn')"
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-archive-cog-outline
                        </v-icon>
                      </template>
                      <span>Оформить заказ производителю</span>
                    </v-tooltip>
                  </template>
                </div>
              </div>
            </div>
          </v-lazy>
        </div>

        <v-progress-circular
            style="margin: auto;"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
            v-if="$store.state.state_progress_circular_page"
        ></v-progress-circular>
      </div>

      <!-- Модалки -->

      <v-dialog
          v-model="dialog_contractor"
          max-width="600px"
          class="dialog_contractor"
      >
        <v-card id="v_dialog_nomenclature_window">
          <v-card-title>
            <span class="headline">Изменение контрагента</span>
            <v-icon
                class="close_dialog_cross"
                color="primary"
                elevation="2"
                @click="hideForm()"
            >fas fa-times
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="contractor_wrapper">
                <v-form
                    v-model="valid"
                    class="contractor_form"
                    ref="form"
                >
                  <div class="contractor_form_item">
<!--                    <p>Наименование контрагента</p>-->
                    <v-combobox
                        dark dense hide-details
                        clearable
                        label="Наименование контрагента"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.COMPANY"
                        disabled
                    >
                    </v-combobox>
                  </div>
                  <div class="contractor_form_item wrapper_prefix_article">
                    <!--                    <p>Префикс артикула</p>-->
                    <v-text-field
                        class="active_input"
                        dark dense
                        clearable
                        counter="2"
                        maxlength="2"
                        label="Префикс артикула"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.PREFIX"
                        :disabled="!!oldValues.prefix.value"
                        :error="($store.state.prefixValue === contractorForm.PREFIX) && $store.state.prefixExist"
                        :error-messages="(this.$store.state.prefixValue === this.contractorForm.PREFIX) && this.$store.state.prefixExist ? $store.state.prefixMessage : []"
                        :loading="loader"
                    >
                    </v-text-field>
                  </div>
<!--                  <div class="contractor_form_item">-->
<!--                    <p>Тип контрагента</p>-->
<!--                    <v-autocomplete-->
<!--                        dark dense hide-details-->
<!--                        clearable-->
<!--                        class="active_input"-->
<!--                        label="Тип контрагента"-->
<!--                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"-->
<!--                        v-model="contractorForm.TYPE_CONTRACTORS"-->
<!--                        :items="$store.state.listTypeContractors"-->
<!--                        item-text="UF_NAME"-->
<!--                        return-object-->
<!--                        :disabled="!contractorForm.PREFIX"-->
<!--                        :loading="loader"-->
<!--                    >-->
<!--                    </v-autocomplete>-->
<!--                  </div>-->
<!--                  <div class="contractor_form_item">-->
<!--                    <div class="wrapper_two_column">-->
<!--                      <div class="column">-->
                  <div class="contractor_form_item">
                    <p class="v-label v-label--active v-label--is-disabled theme--dark">
                      Вид контрагента
                    </p>
                    <div class="contractor_form_item__type">
                      <v-checkbox
                          dark dense hide-details
                          v-model="contractorForm.PERSON_TYPE"
                          value="2"
                          label="Юридическое лицо"
                          class="contractor_form_item__type__checkbox"
                          disabled
                      >
                      </v-checkbox>
                      <v-checkbox
                          dark dense hide-details
                          v-model="contractorForm.PERSON_TYPE"
                          value="1"
                          label="Физическое лицо"
                          class="contractor_form_item__type__checkbox"
                          disabled
                      >
                      </v-checkbox>
                    </div>
                  </div>
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="contractor_form_item">
<!--                    <p>ИНН контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="ИНН"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.INN"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>КПП контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="КПП"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.KPP"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>E-Mail контрагента</p>-->
                    <v-text-field
                        class="active_input"
                        dark dense hide-details
                        clearable
                        label="Email"
                        :rules="emailRules"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.EMAIL"
                        :loading="loader"
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Телефон контрагента</p>-->
                    <v-text-field
                        class="active_input"
                        dark dense hide-details
                        clearable
                        label="Номер телефона"
                        :rules="phoneRules"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.PHONE"
                        :loading="loader"
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Страна контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="Страна"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.COUNTRY"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Город контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="Город"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.CITY"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Юридический адресс контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="Юр. адресс"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.ADDRESS"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Физический адресс контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="Физ. адресс"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.ADDRESS"
                        disabled
                    >
                    </v-text-field>
                  </div>
                  <div class="contractor_form_item">
<!--                    <p>Почтовый адресс контрагента</p>-->
                    <v-text-field
                        dark dense hide-details
                        clearable
                        label="Почтовый адресс"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="contractorForm.POST_INDEX"
                        disabled
                    >
                    </v-text-field>
                  </div>
                </v-form>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                id="btn_cancel"
                text
                @click="hideForm()"
            >
              Закрыть
            </v-btn>
            <v-btn
                id="btn_take_an_order"
                color="secondary"
                @click="validateForm"
                :disabled="!valid || hasDifferences"
                :loading="loader"
            >
              Применить изменения
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <ModalNomenclature
          v-if="$store.getters.getModalNomenclatureData.state"
          :modalBtnProp="modalBtnProp"
      />

      <v-alert
          v-if="list_contractor.DATA === null || !list_contractor.DATA.length"
          type="error"
          text
          class="err-msg"
      >
        Ничего не найдено
      </v-alert>
      <v-btn
          @click="list_contractor.COUNT.NUM_PAGE++; get_list_contractor('next_page')"
          :disabled="list_contractor.COUNT.RECORDS_TO_END <= 0"
          :loading="loading_order"
      >
        Показать ещё {{ list_contractor.COUNT.RECORDS_TO_END }}
      </v-btn>
    </div>

    <v-overlay
        :z-index="0"
        :absolute="true"
        :value="loading_order"
    >
      <v-progress-circular
          style="margin: auto;"
          width="3"
          id="progress_circular_list_order"
          color="green"
          :indeterminate="true"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import ModalNomenclature from "../components/nomenclature/ModalNomenclature";
import LiveSearch from "../components/LiveSearch";
export default {
  name: "Contractors",
  components: {
    LiveSearch,
    ModalNomenclature
  },
  props: ['modal'],
  data: () => ({
    loading_order: false,
    currentPage: 0,
    count_data: {},
    data_date_time: {},
    documents: [],
    currentDoc: {},
    type_contractor: null,
    modalBtnProp: null,

    dialog_contractor: false,
    valid: true,
    contractorForm: {
      ID: '',
      COMPANY: '',
      EMAIL: '',
      PHONE: '',
      COUNTRY: '',
      CITY: '',
      ADDRESS: '',
      jurAddress: '',
      physAddress: '',
      POST_INDEX: '',
      INN: '',
      KPP: '',
      PERSON_TYPE: '',
      CUSTOM_CONTRACTOR_ID: '',
      CUSTOM_CONTRACTOR_IBLOCK_ID: '',
      TYPE_CONTRACTORS: '',
      PREFIX: '',
    },
    emailRules: [
      v => (!v || !!/.+@.+\..+/.test(v)) || 'Введите корректный email',
    ],
    phoneRules: [
      v => (!v || (v.toString().length > 5 && v.toString().length < 18)) || 'Введите корректный номер телефона'
    ],
    // prefixRules: [
    //   v => !!v || 'Заполните',
    // ],
    oldValues: {
      email: {
        value: '',
        sended: false,
      },
      phone: {
        value: '',
        sended: false,
      },
      prefix: {
        value: '',
        sended: false,
      },
      type: {
        value: '',
        sended: false,
      },
    },
    prefixGenerated: false,
    typePrefixSended: false,
    loader: false,

    list_contractor: {
      DATA: [],
      COUNT: {
        NUM_PAGE: 1,
      },
    },
    data_update_commentary: null,
    data_filters: {
      name: '',
      prefix: '',
    },
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },

    localdebounceTimeout: null,
    queryObj: {},
    gettedFromUrl: false,
  }),
  mounted() {
    this.initializeQuery()
    this.get_list_contractor()
    this.get_list_types_contractors()
  },
  computed: {
    computed_selected_contractor: {
      get: function () {
        return this.selected_contractor;
      },
      set: function (value) {
        console.log('computed_selected_contractor');
        console.log(value);
        if (!value) {
          this.selected_contractor = {'code': []};
        } else {
          this.selected_contractor = value;
        }
      }
    },
    /* Проверяем изменил ли что-то пользователь или нет */
    hasDifferences() {
      return (this.oldValues.phone.value === this.contractorForm.PHONE) &&
          (this.oldValues.email.value === this.contractorForm.EMAIL) &&
          (this.oldValues.prefix.value === this.contractorForm.PREFIX)
    },
    // notEmptyType() {
    //   return (this.oldValues.type !== null && this.contractorForm.TYPE_CONTRACTORS === null)
    // },
    computedErrorText() {
      return (this.$store.state.prefixValue === this.contractorForm.PREFIX) && this.$store.state.prefixExist ? 'Уже существует' : []
    },
  },
  watch: {
    'data_filters.name': {
      handler() {
        if (!this.gettedFromUrl) {
          if (this.localdebounceTimeout) clearTimeout(this.localdebounceTimeout)
          this.localdebounceTimeout = setTimeout(() => {
            this.updateQuery()
          }, 400)
        }
      }
    },
    'data_filters.prefix': {
      handler() {
        if (!this.gettedFromUrl) {
          if (this.localdebounceTimeout) clearTimeout(this.localdebounceTimeout)
          this.localdebounceTimeout = setTimeout(() => {
            this.updateQuery()
          }, 400)
        }
      }
    },
  },
  methods: {
    updateQuery() {
      if (this.data_filters.name) {
        this.queryObj.name = this.data_filters.name
      } else delete this.queryObj.name

      if (this.data_filters.prefix) {
        this.queryObj.prefix = this.data_filters.prefix
      } else delete this.queryObj.prefix

      this.$router.push({
        path: this.$route.path,
        query: {...this.queryObj}
      })
    },
    initializeQuery() {
      if (Object.keys(this.$route.query).length && Object.keys(this.$route.query).includes('name')) {
        this.gettedFromUrl = true
        this.data_filters.name = this.$route.query.name
      }
      if (Object.keys(this.$route.query).length && Object.keys(this.$route.query).includes('prefix')) {
        this.gettedFromUrl = true
        this.data_filters.prefix = this.$route.query.prefix
      }
      setTimeout(() => {
        this.gettedFromUrl = false
      }, 400)
    },
    setTypeContractor(code_prop, value_prop) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$store.dispatch('setPropertyItemAnyIblock', {
            'id_elem': this.contractorForm.CUSTOM_CONTRACTOR_ID,
            'code_prop': code_prop,
            'value_prop': !!Object.keys(value_prop).length ? value_prop.UF_XML_ID : '',
            'iblock_id': this.contractorForm.CUSTOM_CONTRACTOR_IBLOCK_ID
          }).then(() => {
              this.oldValues.type.sended = true
              if (this.oldValues.phone.sended && this.oldValues.email.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
                this.updateList()
              }
            })
        }, 500)
      })
    },
    setOnlyPrefix() {
      setTimeout(() => {
        const custom_contractor_id = this.contractorForm.CUSTOM_CONTRACTOR_ID;
        const prefix = this.contractorForm.PREFIX;
        this.$store.dispatch('fillPropertyPrefixToContractorsTable', {
          'prefix': this.contractorForm.PREFIX,
          'custom_contractor_id': this.contractorForm.CUSTOM_CONTRACTOR_ID
        })
          .then((response) => {
            console.log(response)
            this.oldValues.type.sended = true
            if (this.oldValues.phone.sended && this.oldValues.email.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
              if (this.$store.state.prefixExist) {
                this.oldValues.prefix.sended = true
                this.loader = false
              } else {
                this.updateList()
              }
            }
          })
          .catch((response) => {
            console.log(response)
          })
      }, 500)
    },
    setPrefixArticulByProduct(code_prop, value_prop) {
      const custom_contractor_id = this.contractorForm.CUSTOM_CONTRACTOR_ID;
      const prefix = this.contractorForm.PREFIX;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$store.dispatch('setPropertyItemAnyIblock', {
            'id_elem': this.contractorForm.CUSTOM_CONTRACTOR_ID,
            'code_prop': code_prop,
            'value_prop': !!Object.keys(value_prop).length ? value_prop.UF_XML_ID : '',
            'iblock_id': this.contractorForm.CUSTOM_CONTRACTOR_IBLOCK_ID
          }).then(() => {
            this.typePrefixSended = true
            this.oldValues.type.sended = true
            this.setOnlyPrefix()
          })
        }, 500)
      })
    },
    /* Метод отправки email */
    setPropertyEmail(code_prop, value_prop) {
      Promise.all([
        setTimeout(() => {
          this.$store.dispatch('setPropertyItemAnyIblock', {
            'id_elem': this.contractorForm.CUSTOM_CONTRACTOR_ID,
            'code_prop': code_prop,
            'value_prop': value_prop,
            'iblock_id': this.contractorForm.CUSTOM_CONTRACTOR_IBLOCK_ID
          })
        }, 500),
        this.$store.dispatch('setPropertyContractorsBitrixIBlock', {
          'id_elem': this.contractorForm.ID,
          'code_prop': code_prop,
          'value_prop': value_prop,
        })
      ]).finally(() => {
        this.oldValues.email.sended = true
        if (this.oldValues.email.sended && this.oldValues.phone.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
          this.updateList()
        }
      })
    },
    /* Метод отправки телефона */
    setPropertyPhone(code_prop, value_prop) {
      Promise.all([
        setTimeout(() => {
          this.$store.dispatch('setPropertyItemAnyIblock', {
            'id_elem': this.contractorForm.CUSTOM_CONTRACTOR_ID,
            'code_prop': code_prop,
            'value_prop': value_prop,
            'iblock_id': this.contractorForm.CUSTOM_CONTRACTOR_IBLOCK_ID
          })
        }, 500),
        this.$store.dispatch('setPropertyContractorsBitrixIBlock', {
          'id_elem': this.contractorForm.ID,
          'code_prop': code_prop,
          'value_prop': value_prop,
        })
      ]).finally(() => {
        this.oldValues.phone.sended = true
        if (this.oldValues.phone.sended && this.oldValues.email.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
          this.updateList()
        }
      })
    },
    status_doc(doc, status) {
      if (status === 'border') {
        return {
          yellow_border: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'yellow',
          green_border: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'green',
          red_border: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'red',
        }
      } else if (status === 'color') {
        return {
          yellow_color: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'yellow',
          green_color: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'green',
          red_color: doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'red',
        }
      } else if (status === 'btn') {
          if (doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'green') {
          return 'green'
        } else if (doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'yellow') {
          return 'yellow'
        } else if (doc?.PRIORITY_OBJ_BY_LAST_DOCUMENT?.color === 'red') {
          return 'red'
        }
      }
    },
    dayPassed(doc) {
      let arr_lastDate = (!!doc.DATE_LAST_DOCUMENT_VALID) ? doc.DATE_LAST_DOCUMENT_VALID.split(/\D+/) : new Date().toLocaleDateString().split(/\D+/)
      const lastDate = new Date(arr_lastDate[2], arr_lastDate[1]-1, arr_lastDate[0])
      let arr_nowDate = new Date().toLocaleDateString().split(/\D+/)
      const nowDate = new Date(arr_nowDate[2], arr_nowDate[1]-1, arr_nowDate[0])
      return nowDate/(1000 * 3600 * 24) - lastDate/(1000 * 3600 * 24)
    },
    showForm(doc) {
      this.dialog_contractor = true
      for (let key in doc) {
        if (Object.keys(this.contractorForm).includes(key)) {
          Object.keys(this.contractorForm).map(property => {
            if (property === key) {
              this.$nextTick(() => { this.contractorForm[property] = doc[key] })
            }
          })
        }
      }
      /* Записываем данные до изменения */
      this.oldValues.email.value = doc.EMAIL
      this.oldValues.phone.value = doc.PHONE
      this.oldValues.prefix.value = doc.PREFIX
      this.oldValues.type.value = doc.TYPE_CONTRACTORS

      // if (!!doc.EMAIL) {
      //   this.oldValues.email.value = doc.EMAIL
      // } else this.oldValues.email.value = ''
      // if (!!doc.PHONE) {
      //   this.oldValues.phone.value = doc.PHONE
      // } else this.oldValues.phone.value = ''
      // this.oldValues.prefix.value = doc.PREFIX
      // this.oldValues.type.value = doc.TYPE_CONTRACTORS
    },
    hideForm() {
      this.dialog_contractor = false
      Object.keys(this.contractorForm).map(property => {
        this.contractorForm[property] = ''
      })
      this.$store.state.prefixExist = false
      this.$store.state.prefixValue = ''
    },
    /* Проверяем валидацию и инициируем запросы */
    validateForm () {
      this.$refs.form.validate()
      if (this.valid) {
        this.callRequests()
      }
    },
    /* Инициируем запросы в зависимости от того, изменились ли данные */
    callRequests() {
      this.oldValues.email.sended = false
      this.oldValues.phone.sended = false
      this.oldValues.prefix.sended = false
      this.oldValues.type.sended = false
      this.prefixGenerated = false
      this.typePrefixSended = false

      let typePrefix = false

      // Если и тип и префикс
      if (this.oldValues.prefix.value !== this.contractorForm.PREFIX &&
          (this.contractorForm.TYPE_CONTRACTORS !== null &&
          this.oldValues.type.value?.UF_XML_ID !== this.contractorForm.TYPE_CONTRACTORS?.UF_XML_ID)
          ) {
        typePrefix = true
        if (!this.loader) this.loader = true
        this.setPrefixArticulByProduct('TYPE_CONTRACTORS', this.contractorForm.TYPE_CONTRACTORS)
      } else {
        this.oldValues.type.sended = true
        this.oldValues.prefix.sended = true
        this.typePrefixSended = true
      }

      // Если только префикс
      if (this.oldValues.prefix.value !== this.contractorForm.PREFIX && !typePrefix) {
        if (!this.loader) this.loader = true
        this.setOnlyPrefix()
      } else this.oldValues.prefix.sended = true

      // Если только тип
      if (this.oldValues.prefix.value === this.contractorForm.PREFIX &&
          (this.contractorForm.TYPE_CONTRACTORS !== null ||
          this.oldValues.type.value?.UF_XML_ID !== this.contractorForm.TYPE_CONTRACTORS?.UF_XML_ID)
      ) {
        if (!this.loader) this.loader = true
        this.setTypeContractor('TYPE_CONTRACTORS', this.contractorForm.TYPE_CONTRACTORS)
      } else this.oldValues.type.sended = true

      // Если email
      if (this.oldValues.email.value !== this.contractorForm.EMAIL) {
        if (!this.loader) this.loader = true
        this.setPropertyEmail('EMAIL', this.contractorForm.EMAIL)
      } else {
        this.oldValues.email.sended = true
      }
      // Если PHONE
      if (this.oldValues.phone.value !== this.contractorForm.PHONE) {
        if (!this.loader) this.loader = true
        this.setPropertyPhone('PHONE', this.contractorForm.PHONE)
      } else this.oldValues.phone.sended = true

      // Генерация префиксов
      if (!this.$store.state.prefixExist) {
        const custom_contractor_id = this.contractorForm.CUSTOM_CONTRACTOR_ID;
        const prefix = this.contractorForm.PREFIX;
        console.log(custom_contractor_id)
        console.log(prefix)
        setTimeout(() => {
          this.$store.dispatch('addToInternalArticlesTable', { custom_contractor_id } ).then(() => {
            this.prefixGenerated = true
            if (this.oldValues.phone.sended && this.oldValues.email.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
              if (this.$store.state.prefixExist) {
                this.oldValues.prefix.sended = true
                this.loader = false
              } else {
                this.updateList()
              }
            }
          })
        }, 500)
      } else {
        this.prefixGenerated = true
        if (this.oldValues.phone.sended && this.oldValues.email.sended && this.oldValues.prefix.sended && this.oldValues.type.sended && this.prefixGenerated && this.typePrefixSended) {
          if (this.$store.state.prefixExist) {
            this.oldValues.prefix.sended = true
            this.loader = false
          } else {
            this.updateList()
          }
        }
      }


      if (
          (this.oldValues.email.value === this.contractorForm.EMAIL) &&
          (this.oldValues.phone.value === this.contractorForm.PHONE) &&
          (this.oldValues.prefix.value === this.contractorForm.PREFIX) &&
          (this.oldValues.type.value?.UF_XML_ID === this.contractorForm.TYPE_CONTRACTORS?.UF_XML_ID)
      ) {
        this.oldValues.email.sended = true
        this.oldValues.phone.sended = true
        this.oldValues.type.sended = true
        this.oldValues.prefix.sended = true
        this.loader = false
      }
    },
    /* Метод Обновления Списка контрагентов */
    updateList() {
      this.loader = false
      this.dialog_contractor = false
      this.$store.state.prefixExist = false
      this.$store.state.prefixValue = ''
      this.list_contractor.COUNT.NUM_PAGE = '1'
      this.get_list_contractor()
    },
    showNomenclature(doc, action) {
      if (action) {
        this.modalBtnProp = this.$store.state.listTypeContractors.filter(elem => {
          return elem.UF_XML_ID === action
        })[0]
      }
      this.$store.commit('setModalNomenclature', {
        state: true,
        company: doc,
      });
    },
    /* Вызов типов поставщиков */
    get_list_types_contractors() {
      this.$store.dispatch('getListTypeContractors')
    },
    async get_list_contractor(action) {
      if (this.$store.state.tabContractor) {
        this.type_contractor = {}
        this.type_contractor.UF_XML_ID = this.$store.state.tabContractor
      }

      if (this.$store.state.debounceTimeout) clearTimeout(this.$store.state.debounceTimeout);
      this.$store.state.debounceTimeout = setTimeout(() => {

        this.loading_order = true
        const _this = this;

        /* Запрашиваем список поставщиков  */
        BX.ajax.runComponentAction('nk:production.list_contractors',
            'executeGetListContractors', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                post: {
                  get_list_contractor: 'Y',
                  num_page: _this.list_contractor.COUNT.NUM_PAGE,
                  name: _this.data_filters.name,
                  prefix: _this.data_filters.prefix,
                  type_contractor: (!!_this.type_contractor) ? _this.type_contractor?.UF_XML_ID : null,
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function (response) {
              if (response.status === 'success') {
                if (action === 'next_page') {
                  if (response.data.log_data !== null) {
                    _this.list_contractor.DATA.push(...response.data.log_data.DATA)
                    _this.list_contractor.COUNT = response.data.log_data.COUNT;
                  }
                } else {
                  // Если форма успешно отправилась
                  console.log('executeGetListContractor');
                  console.log(response.data);
                  if (response.data.log_data.DATA !== null) {
                    _this.list_contractor = response.data.log_data;
                  } else _this.list_contractor.DATA = []

                }
              }
              _this.loading_order = false
            })
            .catch((response) => {
              console.log(response);
              _this.loading_order = false
            });
      }, 500);
    },
  },
}
</script>

<style scoped lang="scss">
@import "../assets/sass/main";

@media (max-width: 768px) {
  body {
    font-size: 14px !important;

    #wrapper_contractor {
      margin-bottom: 10px !important;
      grid-row-gap: 0.5em !important;
      .wrapper_all_row {
        margin-top: 0.5em !important;
        .wrapper_grid_table {
          padding: 10px 10px !important;
          .wrapper_name_info {
            .items-wrapper {
              display: flex !important;
              flex-direction: column !important;
              .first-item {
                .wrapper_name_info__address {
                  font-size: 12px !important;
                  opacity: 0.9;
                }
                .wrapper_name_info__item {
                  font-size: 12px !important;
                  opacity: 0.9;
                }
              }
              .second-item {
                display: none;
                .wrapper_name_info__item {
                  font-size: 12px !important;
                  opacity: 0.9;
                }
              }
            }
            &__name {
              line-height: 12px !important;
              font-size: 12px !important;
              font-weight: 600;
            }
          }
          .date {
            font-size: 12px !important;
          }
          .wrapper_icons {
            display: flex;
            flex-direction: column !important;
            height: 100%;

            &__icon {
              font-size: 24px !important;
            }
          }
        }
      }
    }
  }
}

body {
  font-size: 18px;

  .wrapper_contractor_modal {
    margin-bottom: 0 !important;
  }

  #wrapper_contractor {
    font-size: 14px;
    margin-bottom: 20px;
    display: grid;
    color: white;

    .block_filters_universal {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .block_filter {
        .v-input {
          font-size: 14px;

          .v-text-field__details {
            display: none;
          }
        }

        .v_select_filter {
          margin: 1em 0;
        }
      }
    }

    .wrapper_all_row {
      display: grid;
      margin-top: 1em;

      .wrapper_grid_table {
        display: grid;
        background: $background_main_order_block;
        @extend %margin-row-position;
        padding: 10px 30px;

        &:hover {
          .wrapper_name_info__item {
            opacity: 1 !important;
            transform: translateY(0px) !important;
          }
          .wrapper_name_info__address {
            opacity: 1 !important;
            transform: translateY(0px) !important;
          }
          .wrapper_name_info__phone {
            opacity: 1 !important;
            transform: translateY(0px) !important;
          }
        }

        &.active {
          background: #5f5f5f;
        }

        .wrapper_rows_data {
          display: grid;
          grid-template-columns: 80% auto;
          grid-column-gap: 1em;
          justify-items: center;
          align-items: center;

          .wrapper_name_info {
            width: 100%;
            text-align: left;
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            span {
              font-size: 0.9em;
              color: darkgray;
              opacity: 0.9;
            }

            &__phone {
              font-size: 20px;
              transform: translateY(30px);
              transition: all .3s ease;
              opacity: 0;
            }

            &__item {
              transform: translateY(30px);
              transition: all .3s ease;
              opacity: 0;
            }

            .items-wrapper {
              display: grid;
              grid-template-columns: 1fr 1fr;
              column-gap: 90px;

              .first-item {
                display: flex;
                align-items: center;
              }

              .second-item {
              }

              span {
                font-size: 0.9em;
                color: darkgray;
                opacity: 0.9;
              }
            }

            &__name {
              font-size: 18px;
              color: burlywood;
            }
            &__address {
              transform: translateY(30px);
              transition: all .3s ease;
              opacity: 0;
            }
            &__prefix {
              font-size: 18px;
              color: lightyellow;
            }
          }

          .rightBlock {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;

            .yellow_color {
              color: yellow;
            }
            .green_color {
              color: green;
            }
            .red_color {
              color: #F44336;
            }

            .wrapper_icons {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              padding-left: 30px;

              ::v-deep .v-icon.v-icon::after {
                background-color: unset !important;
              }

              &__icon {
                cursor: pointer;
                color: #E0E0E0;
                font-size: 30px;

                ::v-deep .v-icon.v-icon::after {
                  background-color: unset !important;
                }

                &:hover {
                  color: lighten(#E0E0E0, 30%)
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dialog_contractor {
    font-size: 12px !important;
  }
}

.contractor_wrapper {
  padding: 0 12px;
  .contractor_form {
    .contractor_form_item {
      padding-bottom: 20px;

      .active_input:not(.v-input--is-disabled) ::v-deep {
        ::before{
          border-color: white;
        }
        input[type="number"] {
          -moz-appearance: textfield !important;;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          appearance: none !important;
          -webkit-appearance: none !important;;
          -moz-appearance: none !important;;
        }
        label, input {
          color: white;
        }
      }

      p {
        margin: 0;
        padding: 0 0 5px 0;
        text-align: left;
        font-size: 1em;
      }

      &__type {
        display: flex !important;
        column-gap: 7px;

        &__checkbox {
          ::v-deep label {
          }
          ::v-deep .v-input--selection-controls__input .v-icon {
            color: rgba(255, 255, 255, 0.54);
          }
        }
      }

      .wrapper_two_column{
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 1em;
        align-items: end;
        .wrapper_prefix_article{
          display: grid;
          grid-template-columns: auto 35%;
          grid-column-gap: 1em;
          align-items: end;
          justify-items: end;
          p{
            //color: rgba(255, 255, 255, 0.5);
            color: white;
            font-size: 1.2em;
            padding: unset;
            width: min-content;
          }
          .v-input{
            height: 100%;
            align-content: end;
            display: grid;
            margin-bottom: -13px;
            ::v-deep .v-input__append-inner{
              align-self: center;
            }
            ::v-deep input{
              text-align: center;
              text-transform: uppercase;
              max-height: unset;
              height: 44px;
            }
          }
        }
        .prefix_article ::v-deep{
          //.v-text-field__prefix{
          //  text-align: left;
          //}
        }
      }
    }
  }
}

::v-deep .v-dialog > .v-card > .v-card__actions {
  display: flex !important;
  justify-content: space-between;
  padding: 5px 25px 25px 25px !important;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}



.menu-list-item:hover {
  background: lighten(#454444, 10%);

  .menu-list-item-link {
    color: lightcoral;
  }
}

.v-list {
  padding: 0 !important;
}

.menu-list-item-link {
  color: white;
  text-decoration: none;
  font-size: 0.8em;
}
</style>
