var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MetaMaket',{class:!!_vm.quantity ? _vm.setColor : '',attrs:{"checkCounts":_vm.checkCounts},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"operation_card-info",staticStyle:{"cursor":"pointer"},on:{"click":_vm.openChild}},[_c('div',{staticClass:"operation_card__number"},[_c('div',{staticClass:"operation_card__number__round"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")])]),_c('div',{staticClass:"operation_card__info"},[_c('div',{staticClass:"operation_card__info__block"},[_c('span',{staticClass:"operation_card__info__block__name"},[_vm._v(" "+_vm._s(_vm.data_elem.NAME)+" ")])]),_c('span',{staticClass:"operation_card__info__drawing_number"},[_vm._v(" "+_vm._s(_vm.data_elem.DRAWING_NUMBER)+" ")])])])]},proxy:true},{key:"related",fn:function(){return [_c('div',{staticClass:"operation_card__right"},[_c('div',{staticClass:"operation_card__right_items"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"operation_card__right_nom",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('span',{on:{"click":_vm.openDetail}},[_vm._v(" "+_vm._s(_vm.data_elem.QUANTITY_BIND_NOMENCLATURE ? _vm.data_elem.QUANTITY_BIND_NOMENCLATURE : 0)+" ")])])]}}])},[_c('span',[_vm._v("Количество привязанной номенклатуры")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"operation_card__right_nom"},on),[_c('span',[_vm._v(_vm._s(_vm.data_elem.QUANTITY_PARENTS ? _vm.data_elem.QUANTITY_PARENTS : 0))])])]}}])},[_c('span',[_vm._v("Количество родительских элементов")])])],1)])]},proxy:true},{key:"edit",fn:function(){return [_c('div',{staticClass:"operation_card__edit",class:{ check_color_counts: _vm.checkCounts }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(
              _vm.getActionPage(_vm.$route) === 'edit' ||
              _vm.getActionPage(_vm.$route) === 'create'
            )?_c('div',_vm._g({staticClass:"operation_card__right_counts",on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-text-field',{staticClass:"operation_card__counts-input",staticStyle:{"width":"80px","text-align":"center"},attrs:{"justify-right":"","type":"number","min":"0","solo":"","hide-details":"","placeholder":"0"},on:{"focus":_vm.deactivateTooltip},model:{value:(_vm.data_elem.QUANTITY_NODES),callback:function ($$v) {_vm.$set(_vm.data_elem, "QUANTITY_NODES", _vm._n($$v))},expression:"data_elem.QUANTITY_NODES"}})],1):_vm._e(),(_vm.quantity && _vm.getActionPage(_vm.$route) === 'view')?_c('div',_vm._g({staticClass:"operation_card__right_counts"},on),[_c('span',[_vm._v(_vm._s(_vm.data_elem.QUANTITY_NODES ? _vm.data_elem.QUANTITY_NODES : 0))])]):_vm._e()]}}])},[(_vm.$route.name === 'SpecificationView')?_c('span',[_vm._v("Количество")]):_c('span',[_vm._v("Изменить количество")])]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [(
            _vm.getActionPage(_vm.$route) === 'edit' ||
            _vm.getActionPage(_vm.$route) === 'create'
          )?_c('div',{staticClass:"operation_card__buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":hover ? 'grey lighten-5' : 'grey lighten-1 '},on:{"click":function($event){$event.stopPropagation();return _vm.setSimpleModal({
                    message: {
                      text: 'Удалить номенклатуру из спецификации?',
                      name: _vm.data_elem.NAME,
                    },
                    data_obj: _vm.data_elem,
                    component_name: _vm.$options.name,
                  })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Удалить номенклатуру из спецификации")])])],1):_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }