<template>
  <div>
    <div class="mobile_menu">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <div
          class="
            specification_card__header__title__wrapper__icons
            mobile_menu__icons
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                large
                :color="
                  $route.name === 'SpecificationCreate'
                    ? 'green lighten-1'
                    : 'grey lighten-1'
                "
                @click="goToPageViewOfCreate()"
                v-bind="attrs"
                v-on="on"
                class="yellow_icon_active"
              >
                mdi-plus-circle-outline
              </v-icon>
            </template>
            <span>Создать новую спецификацию</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                large
                :color="'grey lighten-1'"
                @click="goToTree()"
                v-bind="attrs"
                v-on="on"
                :disabled="activeIconEdit"
                class="yellow_icon_active"
              >
                mdi-pine-tree
              </v-icon>
            </template>
            <span>Дерево элементов</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                large
                :color="
                  $route.name === 'SpecificationEdit'
                    ? 'green lighten-1'
                    : 'grey lighten-1'
                "
                @click="
                  goToPageViewOfEdit('SpecificationEdit', $route.params.id)
                "
                v-bind="attrs"
                v-on="on"
                :disabled="activeIconEdit"
                class="yellow_icon_active"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ textEditTooltip }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                large
                color="grey lighten-1"
                v-bind="attrs"
                v-on="on"
                class="yellow_icon_active"
              >
                fa-solid fa-file-pdf
              </v-icon>
            </template>
            <span>Сохранить как документ</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                large
                color="grey lighten-1"
                @click="print_layout_nomenclature = true"
                v-bind="attrs"
                v-on="on"
                class="yellow_icon_active"
              >
                mdi-printer
              </v-icon>
            </template>
            <span>Отправить на печать</span>
          </v-tooltip>
        </div>
      </v-menu>
    </div>
    <div class="specification_card__header__title__wrapper__icons desktop_menu">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            :color="
              $route.name === 'SpecificationCreate'
                ? 'green lighten-1'
                : 'grey lighten-1'
            "
            @click="goToPageViewOfCreate()"
            v-bind="attrs"
            v-on="on"
            class="yellow_icon_active"
          >
            mdi-plus-circle-outline
          </v-icon>
        </template>
        <span v-if="$route.name !== 'SpecificationCreate'"
          >Создать новую спецификацию</span
        >
        <span v-else>Отменить создание нового элемента</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            :color="'grey lighten-1'"
            @click="goToTree()"
            v-bind="attrs"
            v-on="on"
            :disabled="activeIconEdit"
            class="yellow_icon_active"
          >
            mdi-pine-tree
          </v-icon>
        </template>
        <span>Дерево элементов</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            :color="
              $route.name === 'SpecificationEdit'
                ? 'green lighten-1'
                : 'grey lighten-1'
            "
            @click="goToPageViewOfEdit('SpecificationEdit', $route.params.id)"
            v-bind="attrs"
            v-on="on"
            :disabled="activeIconEdit"
            class="yellow_icon_active"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>{{ textEditTooltip }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            color="grey lighten-1"
            v-bind="attrs"
            v-on="on"
            :disabled="activeIconEdit"
            class="yellow_icon_active"
          >
            fa-solid fa-file-pdf
          </v-icon>
        </template>
        <span>Сохранить как документ</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            color="grey lighten-1"
            @click="print_layout_nomenclature = true"
            v-bind="attrs"
            v-on="on"
            :disabled="activeIconEdit"
            class="yellow_icon_active"
          >
            mdi-printer
          </v-icon>
        </template>
        <span>Отправить на печать</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HeaderIcons',
  methods: {
    ...mapMutations('specificationStore', {
      clearForm: 'CLEAR_FORM',
    }),
    goToTree() {
      if (this.$route.params.id) {
        let routeData = this.$router.resolve({
          name: 'TreeVirtualSpecification',
          params: { id_v_prod: this.$route.params.id },
        })
        window.open(routeData.href, '_blank')
      }
    },
    goToPage(page, id) {
      this.clearForm()
      this.$router.push({ name: page, params: { id: id } })
    },
    goToPageViewOfEdit(page, id) {
      if (this.$route.name == 'SpecificationEdit' && this.$route.params.id) {
        this.$router.push({
          name: 'SpecificationView',
          params: { id: this.$route.params.id },
        })
        return
      }
      this.$router.push({
        name: 'SpecificationEdit',
        params: { id: this.$route.params.id },
      })
    },
    goToPageViewOfCreate() {
      if (this.$route.name == 'SpecificationCreate') {
        this.$router.push({
          name: 'SpecificationView',
        })
        return
      }
      this.$router.push({
        name: 'SpecificationCreate',
      })
    },
  },
  computed: {
    ...mapState('specificationStore', [
      'nomenclatureId',
      'concreteNomenclature',
    ]),
    // activeIconCreate() {
    //   return this.$route.name === 'SpecificationCreate'
    // },
    activeIconView() {
      return this.$route.name === 'SpecificationView'
    },
    textEditTooltip() {
      if (this.$route.name === 'SpecificationEdit') {
        return 'Отменить редактирование'
      } else {
        return 'Редактировать спецификацию'
      }
    },
    activeIconEdit() {
      if (
        this.$route.name === ('SpecificationView' && this.$route.params.id) ||
        ('SpecificationEdit' && this.$route.params.id)
      ) {
        return false
      }
      return true
    },
    activeIconCreate() {
      if (this.$route.name === 'SpecificationCreate') {
        return true
      }
      return false
    },
  },
  mounted() {
    console.log(this.$route)
  },
}
</script>

<style scoped lang="scss">
//@import "src/assets/sass/main";
//@import "src/assets/sass/specification";
//@import "src/assets/sass/detailSpecification";
.deactiveIconEdit {
  cursor: pointer !important;
  pointer-events: none !important;
}

.theme--dark.v-icon:focus::after {
  opacity: 0;
}

.mobile_menu {
  display: none;
}

@media screen and (max-width: 600px) {
  .v-menu__content {
    top: 40px !important;
  }
  .mobile_menu {
    display: block;
    &__icons {
      .theme--light.v-icon.v-icon.v-icon--disabled {
        color: #888888 !important;
      }
      background: #363636;
      align-items: inherit;
      // flex-direction: column;
      margin: 0;
      overflow: hidden;

      & button {
        padding: 4px;
      }
    }
  }
  .desktop_menu {
    display: none;
  }
}
</style>
