<template>
  <div class="meta_maket">
    <div class="meta_maket__content">
      <div class="meta_maket-info">
        <slot name="info"></slot>
      </div>
      <div class="d-flex meta_maket__content-right">
        <div class="meta_maket-related">
          <slot name="related"></slot>
        </div>
        <div
          class="meta_maket-edit"
          :class="{ check_color_counts: checkCounts }"
        >
          <slot name="edit"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkCounts: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/detailSpecification';
@import '../../assets/sass/main';

.meta_maket {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  &:hover {
    background: #5e5e5e;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }
  &-info {
    width: 100%;
  }
  &-related {
    display: flex;
    border-left: 1px solid #8f8f8f40;
    padding-left: 14px;
    padding-right: 14px;
  }
  &-edit {
    display: flex;
    border-left: 1px solid #8f8f8f40;
  }
}

@media screen and (max-width: 848px) {
  .meta_maket__content {
    flex-direction: column;
  }
  .meta_maket__content-right {
    border-top: 1px solid #8f8f8f40;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 6px;
  }
  .meta_maket-edit,
  .meta_maket-related {
    border-left: none;
    padding: 0;
  }
}
</style>
