<template>
  <div>
    <div class="specification_card__specification_zone">
      <div
        class="specification_card__specification_zone__header"
        @click="is_show_detail = !is_show_detail"
        style="cursor: pointer; user-select: none"
      >
        <span class="standard_title">
          Детали [ {{ getElementTypeDetail.length }} ]</span
        >
        <v-icon
          large
          color="grey lighten-1"
          @click="is_show_detail = !is_show_detail"
          :class="{ upDownChevron: !is_show_detail }"
        >
          mdi-chevron-up
        </v-icon>
      </div>
      <div
        class="specification_card__specification_zone__content content__child"
        v-show="is_show_detail"
      >
        <SubDetailCard
          v-for="(item, index) in getElementTypeDetail"
          :key="index"
          :index="index"
          :data_elem="item"
        />
      </div>
    </div>

    <div class="specification_card__operation_zone mt-6">
      <div
        class="specification_card__operation_zone__header"
        @click="is_show_operation = !is_show_operation"
        style="cursor: pointer; user-select: none"
      >
        <span class="standard_title">
          Операции [ {{ getElementTypeOperation.length }} ]</span
        >
        <v-icon
          large
          color="grey lighten-1"
          :class="{ upDownChevron: !is_show_operation }"
        >
          mdi-chevron-up
        </v-icon>
      </div>
      <div
        class="specification_card__specification_zone__content content__child"
        v-show="is_show_operation"
      >
        <SubDetailCard
          v-for="(item, index) in getElementTypeOperation"
          :key="index"
          :index="index"
          :data_elem="item"
        />
      </div>
    </div>

    <div class="specification_card__operation_zone mt-4" v-if="mode_view">
      <div class="specification_card__operation_zone__header">
        <span class="standard_title"> Добавление элемента </span>
        <v-icon
          large
          color="grey lighten-1"
          @click="is_show = !is_show"
          :class="{ upDownChevron: !is_show }"
        >
          mdi-chevron-up
        </v-icon>
      </div>
    </div>

    <div v-show="is_show && mode_view">
      <div class="specification_card__create_zone__head__inputs head-inputs">
        <drawing-number
          class="head-inputs__search"
          elementView="select"
          elementPlaceholder="Поиск"
          @eventChange="eventFunctionSelect"
          @eventData="eventDataSelect"
          @eventClear="clearNomenclature"
          :itemsSelect="allNomenclatures"
          ref="localDrawingNumber"
        ></drawing-number>

        <v-text-field
          ref="quantity"
          type="number"
          min="1"
          solo
          dense
          v-model.number="child_nomenclature.QUANTITY_NODES"
          @focus="$event.target.select()"
          hide-details
          placeholder="1"
          style="padding-top: 3px"
        ></v-text-field>
      </div>

      <div
        v-if="state_form_add_child_nomenclature"
        class="
          specification_card__create_zone__wrapper
          specification_card__create_zone__wrapper__notFounded
        "
      >
        <div
          class="specification_card__create_zone__wrapper__notFounded__content"
        >
          <h3
            class="
              specification_card__create_zone__wrapper__notFounded__content__title
            "
          >
            Не найдена номенклатура. Создадим дочерний элемент?
          </h3>
          <div
            class="
              specification_card__create_zone__wrapper__notFounded__content__name
            "
          ></div>
        </div>

        <DrawingNumber
          :elementView="'input'"
          :elementTitle="'Чертежный номер:'"
          :elementPlaceholder="'Чертежный номер'"
          :elementName="'children_drawing_number'"
          @eventChange="(data) => eventDataFunction(data, 'DRAWING_NUMBER')"
        />
        <NameNomenclature
          :elementView="'input'"
          @eventChange="(data) => eventDataFunction(data, 'NAME')"
        />
        <TypeSpecification
          @eventChange="(data) => eventDataFunction(data, 'TYPE')"
        />
        <Note @eventChange="(data) => eventDataFunction(data, 'NOTE')" />
      </div>

      <div class="specification_card__create_zone__button mt-2">
        <v-btn
          color="primary"
          @click="addChild"
          :disabled="!child_nomenclature.NAME"
        >
          {{ addChildText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import DrawingNumber from '@/components/specification/DrawingNumber.vue'
import NameNomenclature from '@/components/specification/NameNomenclature.vue'
import TypeSpecification from './TypeSpecification.vue'
import SubDetailCard from '@/components/specification/SubDetailCard'
import Note from './Note.vue'
import Logging from '../../services/logging'
import { ChildNomenclature } from '@/constructors/ChildNomenclature'
import { StatusNomenclature } from '@/constructors/StatusNomenclature'

export default {
  props: {
    mode_view: { type: Boolean, default: true },
    elementsDetailOperation: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    DrawingNumber,
    NameNomenclature,
    TypeSpecification,
    Note,
    SubDetailCard,
  },
  data() {
    return {
      is_show: true,
      is_show_detail: true,
      is_show_operation: true,
      childNomenclaturesId: [],
      childNomenclaturesObject: [],
      event_data: false,
      child_nomenclature: new ChildNomenclature(),
      state_form_add_child_nomenclature: false,
      activeSearch: true,
    }
  },
  computed: {
    ...mapState('specificationStore', [
      'concreteNomenclature',
      'allNomenclatures',
      'countAllNomenclatures',
      'newElement',
    ]),
    ...mapGetters('specificationStore', [
      'getElementTypeOperation',
      'getElementTypeDetail',
    ]),

    validationСheckChild() {
      if (
        this.child_nomenclature.DRAWING_NUMBER &&
        this.child_nomenclature.NAME &&
        this.child_nomenclature.TYPE
      ) {
        return false
      } else {
        return true
      }
    },

    addChildText() {
      if (this.state_form_add_child_nomenclature) {
        return 'Создать и добавить'
      }
      return 'Добавить'
    },
  },
  methods: {
    ...mapActions('specificationStore', [
      'getConcreteNomenclature',
      'getAllNomenclatures',
      'pushChildNomenclature',
      'deleteChildNomenclature',
    ]),
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
      clearAllNomenclatures: 'CLEAR_ALL_NOMENCLATURES',
      clearStatus: 'CLEAR_STATUS',
      addStatusMessage: 'ADD_STATUS_MESSAGE',
    }),

    async addChild() {
      const response = this.validateAddChild()
      if (response.state) {
        await this.pushChildNomenclature(this.child_nomenclature)
        this.addStatusMessage(
          new StatusNomenclature(response.message, '', 'info'),
        )
        this.clearNomenclature()
        this.$refs.localDrawingNumber.clearFunc()
      } else {
        this.$refs.quantity.focus()
        this.addStatusMessage(
          new StatusNomenclature(response.message, '', 'error'),
        )
      }
    },
    validateAddChild() {
      if (!Object.keys(this.child_nomenclature))
        return new Logging({
          message: 'Не заполнен объект виртуальной номенклатуры',
          state: false,
        })

      if (!this.child_nomenclature.QUANTITY_NODES)
        return new Logging({
          message:
              'Не заполнено кол-во виртуальной номенклатуры - ' +
              (this.child_nomenclature.DRAWING_NUMBER != null
                  ? this.child_nomenclature.DRAWING_NUMBER
                  : ((this.child_nomenclature.NAME).length > 40 ? this.child_nomenclature.NAME.substr(0, 40) + '...' : this.child_nomenclature.NAME)),
          state: false,
        })

      if (!this.child_nomenclature.TYPE)
        return new Logging({
          message:
              'Не заполнен тип виртуальной номенклатуры - ' +
              (this.child_nomenclature.DRAWING_NUMBER != null
                  ? this.child_nomenclature.DRAWING_NUMBER
                  : ((this.child_nomenclature.NAME).length > 40 ? this.child_nomenclature.NAME.substr(0, 40) + '...' : this.child_nomenclature.NAME)),
          state: false,
        })

      //Проверяем, что такой элемент ещё не был добавлен
      const existEntry = this.newElement.CHILDREN.some((elem) => {
        return elem.ID != null && elem.ID === this.child_nomenclature.ID
            || elem.DRAWING_NUMBER != null && elem.DRAWING_NUMBER === this.child_nomenclature.DRAWING_NUMBER
      })

      if (existEntry) {
        return new Logging({
          message:
              'Элемент уже добавлен - ' +
              (this.child_nomenclature.DRAWING_NUMBER != null
                  ? this.child_nomenclature.DRAWING_NUMBER
                  : ((this.child_nomenclature.NAME).length > 40 ? this.child_nomenclature.NAME.substr(0, 40) + '...' : this.child_nomenclature.NAME)),
          state: false,
        })
      }

      // Если все ОК
      return new Logging({
        message:
          'Добавлен элемент:  ' +
            (this.child_nomenclature.DRAWING_NUMBER != null
                ? this.child_nomenclature.DRAWING_NUMBER
                : ((this.child_nomenclature.NAME).length > 40 ? this.child_nomenclature.NAME.substr(0, 40) + '...' : this.child_nomenclature.NAME)),
        state: true,
      })
    },
    clearNomenclature() {
      this.child_nomenclature = new ChildNomenclature()
      this.state_form_add_child_nomenclature = false
    },
    eventDataFunction(data, name) {
      this.child_nomenclature[name] = data
    },

    async eventFunctionSelect(data) {
      if (!data || data.trim() == '') {
        console.log(this.event_data)
        this.clearAllNomenclatures()
        this.event_data = true
        return
      }

      if (this.activeSearch) {
        const response = await this.getAllNomenclatures(data)
        if (!response.state) {
          this.clearNomenclature()
        }
      }
      // по сути костыли из за верхнего туду
      if (!this.allNomenclatures.length && !this.child_nomenclature.ID) {
        this.state_form_add_child_nomenclature = true
      }

      this.event_data = false
    },
    eventDataSelect(data) {
      if (typeof data == 'object' && data !== null) {
        if (!data.QUANTITY_NODES) {
          data.QUANTITY_NODES = 1
        }
        this.child_nomenclature = data
        this.state_form_add_child_nomenclature = false
        this.activeSearch = false
        this.$refs.quantity.focus()
        // this.getConcreteNomenclature(data.ID)
        // this.event_data = data
      } else {
        this.activeSearch = true
        this.clearAllNomenclatures()
        // this.event_data = data
      }
    },
  },
}
</script>

<style lang="scss">
.head-inputs {
  display: grid;
  grid-template-columns: 85% auto;
  column-gap: 20px;

  &__search {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
}
</style>
