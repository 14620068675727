<template>
  <div>
    <div id="treeview_container" v-if="items.length">
      <div class="wrapper_static_header">
        <header id="header_treeview">
          <v-text-field
              class="search_input"
              v-model="search"
              label="Поиск по наименованию"
              dark
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
          ></v-text-field>
<!--          <section>Цена</section>-->
<!--          <section>Кол-во в специф.</section>-->
<!--          <section>Не сниж. остаток (ост. партнеров)</section>-->
<!--          <section>Факт. кол-во</section>-->
          <!--          <section>Хотим</section>-->
          <!--          <section>Потребность</section>-->
          <!--          <section>Записать в заявку</section>-->
          <!--          <a href="/prices/?analysis_of_balances&only_checked_position" target="_blank"-->
          <!--             title="Перейти в анализ остатков">Кол-во в заявку</a>-->
        </header>
        <v-btn
            v-if="!$store.state.show_all_childs"
          text
          class="text-capitalize wrapper_static_header__btn"
          color="yellow lighten-1"
          @click="$store.state.show_all_childs = !$store.state.show_all_childs"
        >
          Показать все аналоги
        </v-btn>
        <v-btn
            v-else
            text
            class="text-capitalize wrapper_static_header__btn"
            color="yellow lighten-1"
            @click="$store.state.show_all_childs = !$store.state.show_all_childs"
        >
          Скрыть все аналоги
        </v-btn>
      </div>
      <v-treeview id="treeview_specification"
                  v-if="loading_order === false"
                  ref="ref_treeview_specification"
                  :open-all="true"

                  :open="initiallyOpen"
                  :items="items"
                  :search="search"
                  :filter="filter"
                  activatable
                  hoverable
                  item-key="ID"
                  item-text="NAME"
                  item-children="CHILDREN"
      >
        <!--                selectable
                        return-object-->
        <template v-slot:prepend="{ item, open }">
          <v-icon class="background_type_product" :class="[item.TYPE, class_icon_composite_node(item)]">
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <!--          <div class="wrapper_row">-->
          <specification-card
              :nomenclature_data="item"
              :index="`${item.ID}`"
          />
          <!--            <a class="item name href_to_nomenclature" @click="open_dialog_nomenclature(item.NOMENCLATURE_ID)">{{
                          item.NOMENCLATURE_NAME
                        }}
                      </a>
                      &lt;!&ndash; Цена &ndash;&gt;
                      <v-text-field
                          v-model="item.NOMENCLATURE_CATALOG_HIGHEST_PRICE"
                          class="item NOMENCLATURE_CATALOG_HIGHEST_PRICE"
                          :disabled="true"
                      ></v-text-field>
                      &lt;!&ndash; Кол - во по спецификации &ndash;&gt;
                      <v-text-field
                          v-model="item.QUANTITY_NODES"
                          class="item QUANTITY_NODES"
                          :disabled="true"
                      ></v-text-field>
                      &lt;!&ndash; Не сниж. остаток  &ndash;&gt;
                      <div class="wrapper_tooltip_min_quantity">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <section class="tooltip_absolute_wrapper"
                                     v-bind="attrs"
                                     v-on="on"
                            ></section>
                          </template>
                          <span>{{ item.NOTE_ON_MINIMUM_BALANCES }}</span>
                        </v-tooltip>
                        <v-text-field
                            v-model="item.MINIMUM_QUANTITY_GIDRUSS"
                            class="item MINIMUM_QUANTITY_GIDRUSS"
                            :disabled="true"
                        >
                          <section slot="append" color="value" v-if="item.REMAINS_OF_PARTNERS">
                            ({{ item.REMAINS_OF_PARTNERS }})
                          </section>
                        </v-text-field>
                      </div>
                      &lt;!&ndash; Факт. кол-во &ndash;&gt;
                      <v-text-field
                          v-model="item.NOMENCLATURE_CATALOG_QUANTITY_store_1"
                          class="item NOMENCLATURE_CATALOG_QUANTITY_store_1"
                          :class="{not_enough_quantity: (calc_suffix(item)['not_enough_quantity']) }"
                          :disabled="true"
                      ></v-text-field>-->
          <!--          </div>-->
        </template>
      </v-treeview>
    </div>
    <v-alert v-if="(items.length === 0) && (loading_order === false)" outlined type="info" prominent>
      {{ !!this.$store.state.system_notification_dialog.body_message ? this.$store.state.system_notification_dialog.body_message : 'Не найдено'}}
    </v-alert>

    <v-progress-circular
        v-if="loading_order"
        id="progress_circular_list_order"
        color="green"
        :indeterminate="true"
    ></v-progress-circular>


    <!-- Диалоговое окно с номенклатурой -->
    <v-dialog
        v-model="dialog_nomenclature_card_product.state"
        max-width="600px"
        content-class="dialog_nomenclature"
    >
      <v-card id="v_dialog_nomenclature_window">
        <v-card-title>
          <span class="headline">Номенклатура</span>
          <v-icon
              class="close_dialog_cross"
              color="primary"
              elevation="2"
              @click="dialog_nomenclature_card_product.state = false"
          >fas fa-times
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <nomenclature-card-product
                  :nomenclature_data="dialog_nomenclature_card_product.data"
              />
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import NomenclatureCardProduct from "../nomenclature/NomenclatureCardProduct";
import SpecificationCard from "./SpecificationCard";
import Request from "@/services/request";
export default {
  components: {
    SpecificationCard,
    NomenclatureCardProduct
  },
  props: [],
  name: "TreeVirtualSpecification",
  data: () => ({
    warning: false,
    loading_order: false,
    parent_editable_value: null,
    initiallyOpen: ['public'],
    files: {
      html: 'mdi-language-html5',
      js: 'mdi-nodejs',
      json: 'mdi-code-json',
      md: 'mdi-language-markdown',
      pdf: 'mdi-file-pdf',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
    },
    items: [],
    search: null,
    caseSensitive: false,
    rules: {
      min_quantity(value, min) {
        return (value >= min) || `Должно быть не меньше - ${min}`;
      },
    },
    dialog_nomenclature_card_product: {
      state: false,
      data: null,
    },
    tree_spec_nomenclature_data: null,
  }),
  mounted() {
    this.get_tree();
  },
  watch: {
  },
  computed: {
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  methods: {
    show_modal_create_order(item) {
      this.$store.dispatch('getNomenclatureById', item.NOMENCLATURE_ID)
          .then((response) => {
            this.$store.state.data_new_order.nomenclature_obj = response;
            this.$store.state.data_new_order.state_v_show = true;
          });
    },
    class_icon_composite_node(item, open) {
      return (item.FLAG_COMPOSITE_NODE === 'Y') ? 'folder' : ''
    },
    open_dialog_nomenclature(id_prod) {
      this.$store.dispatch('getNomenclatureById', id_prod)
          .then((response) => {
            this.dialog_nomenclature_card_product.data = response;
            this.dialog_nomenclature_card_product.state = true;
          });
    },
    /* Рекурсивная ф-я по суммированию лепестков дерева */
    getFiniteValue(obj) {
      const _this = this;
      getProp(obj);

      function getProp(value) {
        for (var key in value) {
          if (typeof (value[key]) === 'object') {
            getProp(value[key]);
          } else {
            if (!!value['ARR_PARENT_QUANTITY_NODES']) {
              // console.log(o['ARR_PARENT_QUANTITY_NODES']);

              value.SUM_ARR_PARENT_QUANTITY_NODES = value.ARR_PARENT_QUANTITY_NODES.reduce(function (a, b) {
                return a * b;
              });

              /* Текущие остатки умножаем на сумму родительских остатков снизу вверх умножаем на необходимое количество штук */
              const res_wanted_quantity = (value.QUANTITY_NODES * value.SUM_ARR_PARENT_QUANTITY_NODES * _this.parent_editable_value);

              value.WANTED_QUANTITY = Number.isInteger(res_wanted_quantity) ? res_wanted_quantity : res_wanted_quantity.toFixed(2);
              /* Из res вычитаем Факт. ост. прибавляем Неснижаемые */
              const check_negation_number = parseInt(res_wanted_quantity - value.NOMENCLATURE_CATALOG_QUANTITY_store_1 + value.MINIMUM_QUANTITY_GIDRUSS);
              value.EDITABLE_NEED_QUANTITY = (check_negation_number < 0) ? 0 : check_negation_number;
            }
            // console.log('Finite value: '+o[prop]);
          }
        }
      }
    },
    calc_tree_quantity(item) {
      this.getFiniteValue(this.items);

      /* Ререндер дерева - по сути */
      this.$refs.ref_treeview_specification.updateAll(false);
      this.$nextTick(() => {
        this.$refs.ref_treeview_specification.updateAll(true);
      });

      // this.items.forEach(function (elem) {
      //     if (elem.parent !== '#') {
      //         console.log(elem.ARR_PARENT_QUANTITY_NODES);
      //
      //         elem.SUM_ARR_PARENT_QUANTITY_NODES = elem.ARR_PARENT_QUANTITY_NODES.reduce(function (a, b) {
      //             return a * b;
      //         });
      //
      //         elem.WANTED_QUANTITY = elem.QUANTITY_NODES * elem.SUM_ARR_PARENT_QUANTITY_NODES * _this.parent_editable_value;
      //     }
      // });
    },
    calc_suffix(item, prop) {
      let obj_res = {
        'not_enough_quantity': false,
        'nomenclature_catalog_quantity_store_1': ' (' + String(item.NOMENCLATURE_CATALOG_QUANTITY_store_1) + ') ',
        'minimum_quantity_gidruss': item.MINIMUM_QUANTITY_GIDRUSS,
      };

      if (parseInt(item.NOMENCLATURE_CATALOG_QUANTITY_store_1) < parseInt(item.MINIMUM_QUANTITY_GIDRUSS)) {
        obj_res.not_enough_quantity = true
      }

      // console.log(obj_res);
      return obj_res;
    },
    async get_tree() {
      // this.loading_order = true;

      const params = {}
      const response = await Request.post(
          `${process.env.VUE_APP_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.GetTreeSpecification&id_parent_virtual_nomenclature=${this.$route.params.id_v_prod}`,
          params,
      )

      console.log('tree', response)
      if (response?.status === 'success') {
        // if (response?.data?.state) {
        //   if (Array.isArray(response?.data.data)) {
        this.items = response.data.data;
        // }
        // }
      }



    },
    push_need_quantity(item) {
      // console.log('push_need_quantity');

      const _this = this;

      if (!!item.EDITABLE_NEED_QUANTITY) {
        if (item.STATE_ADD_NEED_QUANTITY === 'Y') {
          /* Лоадер загрузки */
          item.STATE_PUSH_QUANTITY = true;

          BX.ajax.runComponentAction('nk:production.block_nomenclature',
              'executeUpdatePlannedQuantity', { // Вызывается без постфикса Action
                mode: 'class',
                data: {
                  post: {
                    'id_prod': item.NOMENCLATURE_ID,
                    'planned_quantity': item.EDITABLE_NEED_QUANTITY,
                    'planned_quantity_status': item.STATE_ADD_NEED_QUANTITY,
                    'status': 'add',
                  },
                  'login': _this.$store.state.login,
                  'token': _this.$store.state.token,
                },
              })
              .then(function (response) {
                if (response.status === 'success') {
                  // Если форма успешно отправилась
                  console.log(response.data);

                  /* Обновим остатки с бэка */
                  if (!!response.data) {
                    item.PLANNED_QUANTITY = response.data.PLANNED_QUANTITY;
                    item.STATE_ADD_NEED_QUANTITY = response.data.PLANNED_QUANTITY_STATUS;
                  }
                  item.STATE_PUSH_QUANTITY = false;

                  console.log(item);
                }
              })
              .catch((response) => {
                console.log(response);
                _this.$store.dispatch('setSystemNotificationResult', response);

                item.STATE_ADD_NEED_QUANTITY = 'N';
                item.STATE_PUSH_QUANTITY = false;
              });
        }
      }
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/sass/main";

#treeview_container {
  color: white;
  margin-bottom: 25px;

  %grid_header_plus_row {
    grid-template-columns: 50% 1fr 1fr 1fr 1fr;
    justify-content: flex-end;
    white-space: normal;
    padding-right: 10px;
    grid-column-gap: 1em;
    align-items: center;
    @media (max-width: 768px) {
      padding-right: 5px;
      font-size: 10px;
      grid-template-columns: 50% 1fr 1fr 1fr 1fr;
      /*grid-template-columns: unset;*/
    }
  }

  %default_color_14px {
    font-size: 14px;
    color: grey;
  }

  %white_color_16px {
    font-size: 16px;
    color: white;
  }

  .wrapper_static_header {
    display: grid;
    position: sticky;
    /*top: 99px;*/
    z-index: 1;
    font-size: 0.85em;
    background: dimgrey;

    &__btn {
      width: 300px;
      text-align: left;
      font-size: 1.1em;
    }
    .v-btn:not(.v-btn--round).v-size--default {
      padding: 0 12px !important;
      .v-btn__content {
        text-align: left;
        justify-content: flex-start;
      }
    }

    .search_input {
      font-size: inherit;
      background: dimgrey;

      .v-input__control {
        @media (max-width: 768px) {
          min-height: 44px;
        }

        label {
          font-size: inherit;
        }
      }
    }

    #header_treeview {
      display: grid;
      background: dimgrey;
      align-items: center;
      padding: 10px 20px 10px 10px;
      word-break: break-word;

      section, label {
        color: darkgrey;
      }

      @extend %grid_header_plus_row;
      @media (max-width: 768px) {
        padding: 5px 10px 5px 5px;
      }
    }
  }

  #treeview_specification {
    font-size: 0.9em;
    padding-right: 10px;
    margin-top: 1em;

    .v-icon {
      color: #999999;
    }

    .v-treeview-node__root {
      padding: unset;
      text-align: left;
      color: white;

      .primary--text {
        color: #1976d2 !important;
      }

      &:hover {
        background-color: #1976d214 !important;
      }

      &.v-treeview-node--active {
        .v-treeview-node__content .v-treeview-node__label .wrapper_row .item {
          &.name, input {
            color: white;
          }
        }
      }

      .v-treeview-node__content {
        .v-treeview-node__prepend {
          .v-icon {
            &.background_type_product {
              width: 24px;
              height: 24px;
              background-size: contain;
              background-position: center;

              &.Товар {
                background-image: url("/local/templates/gidruss/static/svg/File_Т.svg");

                &.folder {
                  background-image: url("/local/templates/gidruss/static/svg/Folder_Т.svg");
                }
              }

              &.Изделие {
                background-image: url("/local/templates/gidruss/static/svg/File_И.svg");

                &.folder {
                  background-image: url("/local/templates/gidruss/static/svg/Folder_И.svg");
                }
              }

              &.Покупнина {
                background-image: url("/local/templates/gidruss/static/svg/File_П.svg");

                &.folder {
                  background-image: url("/local/templates/gidruss/static/svg/Folder_П.svg");
                }
              }

              &.Работа {
                background-image: url("/local/templates/gidruss/static/svg/File_Р.svg");

                &.folder {
                  background-image: url("/local/templates/gidruss/static/svg/Folder_Р.svg");
                }
              }
            }

          }
        }

        .v-treeview-node__label {
          white-space: unset !important;
          line-height: 26px !important;

          .wrapper_row {
            display: grid;
            color: grey;
            @extend %grid_header_plus_row;

            .wrapper_tooltip_min_quantity {
              position: relative;

              .tooltip_absolute_wrapper {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
              }
            }

            .item {
              padding: unset;
              margin: unset;

              .v-input__slot {
                margin: unset;

                &::before {
                  border-color: white;
                }
              }

              input {
                font-size: 14px;
                color: grey;
                text-align: center;
              }

              &.MINIMUM_QUANTITY_GIDRUSS {
                .v-input__append-inner {
                  position: absolute;
                  right: -7px;
                  top: 5px;

                  section {
                    color: darkolivegreen;
                  }
                }
              }

              &.quantity_nodes {
                text-align: center;
              }

              &.NOMENCLATURE_CATALOG_QUANTITY_STORE_1 {
                &.not_enough_quantity {
                  .v-text-field__suffix {
                    color: red !important;
                  }
                }
              }

              &.WANTED_QUANTITY.parent_editable_value {
                .v-input__slot {
                  &::after {
                    border-color: #ffff0075;
                    transform: scaleX(1);
                  }

                  input {
                    @extend %white_color_16px;
                  }
                }
              }

              &.EDITABLE_NEED_QUANTITY {
                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  margin: 0;
                }

                /* Для Firefox */
                input[type=number] {
                  -moz-appearance: textfield;
                }

                input {
                  /*text-align: left;*/
                  @extend %white_color_16px;
                }

                &.status_sending_quantity_true {
                  input {
                    @extend %default_color_14px;
                  }
                }

                .v-text-field__suffix {
                  color: dimgrey;
                  font-weight: bold;
                }
              }

              &.STATE_ADD_NEED_QUANTITY {
                justify-self: center;

                .v-messages {
                  display: none;
                }

                .v-input--selection-controls__input {
                  margin: auto;
                }

                .v-input--switch__track {
                  color: rgba(0, 0, 0, 1);
                }
              }

              &.PLANNED_QUANTITY {
                input {
                  @extend %white_color_16px;
                }
              }
            }

            .wrapper_switch {
              .create_purchase_order_cur_nom {
                min-width: unset;
                word-break: break-word;
                white-space: break-spaces;
                font-size: 9px;
                padding: 5px;
                display: grid;
                align-content: end;
                height: 30px;

                span.v-btn__content {
                  flex: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
