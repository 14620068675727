<template>
    <div class="progress_stage_of_production">
        <div class="wrapper_stage"
             v-for="(value, index) in order.FULL_DATA_STAGE_OF_PRODUCTION" :key="index"
             :class="getStatusStage(value)"
        >
            <div class="wrapper_name_stage">
                <section class="name_stage">{{ value.NAME_STAGE }}</section>
                <v-icon color="green darken-2" v-if="value._STATE_WORK_ON_STAGE_DATA._STATE_ACTION_ON_STAGE_CODE === 'completed_work'">
                    mdi-check
                </v-icon>
            </div>

            <span class="span_progress" :class="'progress_'+value.NAME_STAGE"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProgressStageProduction",
        props: ['order'],
        methods:{
            getStatusStage(stage){
              return (stage.STAGE_STATUS === 'cur_stage' || stage.STAGE_STATUS === 'passed_stage') ? 'active' : null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/main";

    .progress_stage_of_production {
        display: inline-flex;
        grid-column-gap: 5px;
        //height: 20px; /* Может быть любой */
        width: 100%;
        position: relative;
        //background: #555;
        -moz-border-radius:  25px;
        -webkit-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 15px;
        //-webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        //-moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        //box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

        .wrapper_stage{
            display: grid;
            justify-items: center;
            grid-row-gap: 5px;
            opacity: 0.3;
            width: inherit;
            &.active{
              opacity: 1;
            }
            .wrapper_name_stage {
                display: inline-flex;
                align-items: center;
                min-height: 21px;
                .name_stage {
                    font-size: 0.9em;
                    @media (max-width: 768px) {
                        font-size: 0.7em;
                    }
                    color: lightgrey;
                }
                .v-icon{
                    font-size: 1.5em;
                }
            }
            .span_progress {
                display: grid;
                height: 2px;
                width: 100%;
                @each $status, $color in $status-colors {
                    &.progress_#{$status} {
                        background: $color;
                    }
                }
            }

        }
    }
</style>