<template>
  <div class="planning">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr
            class="text-left"
            v-for="(signature, index) in elementValue.SIGNATURES"
            :key="index"
          >
            <td>
              {{ signature.ACTION.TEXT }}
            </td>
            <td>
              <PlanningDate
                v-if="
                  signature.ACTION.CODE === 'PLANNED_LAUNCH_DATE' ||
                  signature.ACTION.CODE === 'FACTUAL_LAUNCH_DATE'
                "
              />
              <span v-else>{{ signature.DATE_INFO.DATE }}</span>
            </td>
            <td style="width: 140px">{{ signature.SIGNATORY.SHORT_NAME }}</td>
            <td>
              <v-checkbox
                v-show="signature.ACTION.CODE != 'CREATION_DATE'"
                v-model="signature.IS_SIGNED"
                :disabled="true"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end mt-4">
      <v-btn color="green darken-1" :disabled="true"> Согласовать </v-btn>
    </div>
  </div>
</template>

<script>
import PlanningDate from './PlanningDate.vue'
export default {
  name: 'Planning',
  props: {
    elementValue: Object,
  },
  components: { PlanningDate },
  data() {
    return {
      concreted: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  background-color: transparent !important;
  color: white !important;
}
tbody {
  td {
    font-size: 16px !important;
  }
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
