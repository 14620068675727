export default class Logging {
    //Если удобно пользоваться конструктором
    constructor(request) {
        const {message, detail_message, data, http_response, state, otherData, count} = request;

        this.message = message;
        this.detail_message = detail_message;
        this.data = data;
        this.otherData = otherData;
        this.http_response = http_response;
        this.state = state;
        this.count = count;
    }

    /**
     * @param {any} request Принимает в себя либо один объект реквест либо массив реквестов.
     * @return {array} message, Возвращает строку в виде либо склеенных сообщений либо в виде одного.
     */
    static getMessage (request) {
        if (!Array.isArray(request))
            return request.message

        return request.map(elem => {
            return elem.message
        }).join("<br>")
    }

    /**
     * @param {any} request Принимает в себя либо один объект реквест либо массив реквестов.
     * @return {Boolean} true|false, Возвращает в зависимости от codeResponse - Булево.
     */
    static checkExistErr (request) {
        console.log(request);
        if (!Array.isArray(request))
            return Boolean(request.http_response >= 400)

        return request.some(elem => {
            return elem.http_response >= 400
        });
    }

    static checkValueIsUndefinedOrNull(value) {
        if(typeof value === 'undefined') {
            return false
        } else if(value === null){
            return false;
        }
        return true;
    }
}