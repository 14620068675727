<template>
  <div class="specification_card_block">
    <div class="par_search">
      <div class="search_nom" v-if="!newElement.NAME">
        <drawing-number
          elementView="select"
          elementPlaceholder="Поиск номенклатуры по наименованию или обозначению:"
          @eventChange="eventFunction"
          @eventData="eventDataFunction"
          :itemsSelect="allNomenclatures"
          :btnCreate="btnCreateActive"
          :hintShow="true"
        ></drawing-number>
      </div>
      <div class="search_nom search_result" v-if="newElement.NAME">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <drawing-number
                elementView="input"
                :elementDisabled="true"
                :elementValue="newElement.DRAWING_NUMBER"
              ></drawing-number>
            </div>
          </template>
          <span>{{ newElement.DRAWING_NUMBER }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <NameNomenclature
                elementView="input"
                :elementDisabled="true"
                :elementTitle="false"
                :elementValue="newElement.NAME"
              ></NameNomenclature>
            </div>
          </template>
          <span>{{ newElement.NAME }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              color="grey lighten-1"
              @click="goToView"
              v-bind="attrs"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <span>Назад к поиску</span>
        </v-tooltip>
      </div>
    </div>

    <div class="margin_blocks">
      <show-block
        :nameBlock="
          newElement.DRAWINGS.length ? 'Чертежи' : 'Чертежи отсутствуют'
        "
        :showBlock="!!newElement.DRAWINGS.length"
        :numberBlock="newElement.DRAWINGS"
        @renderDrawing="renderDrawing"
      >
        <Drawings
          :drawings="newElement.DRAWINGS"
          :is_enabled="true"
          :key="componentKey"
        />
      </show-block>

      <show-block
        :nameBlock="
          newElement.CHILDREN.length
            ? 'Спецификация'
            : 'Спецификация не заполнена'
        "
        :showBlock="!!newElement.CHILDREN.length"
        :numberBlockChild="newElement.CHILDREN"
      >
        <div class="show_block-content">
          <ChildElement
            :mode_view="false"
            :elementsDetailOperation="
              newElement.CHILDREN ? newElement.CHILDREN : []
            "
          />
        </div>
      </show-block>

      <show-block
        :nameBlock="
          newElement.REAL_NOMENCLATURE.length
            ? 'Номенклатура'
            : 'Номенклатура не привязана'
        "
        :showBlock="!!newElement.REAL_NOMENCLATURE.length"
        :numberBlock="newElement.REAL_NOMENCLATURE"
      >
        <!-- <div
          class="show_block-content"
          style="display: flex; flex-direction: column; gap: 15px"
        >
          <div
            class="specification_card__real_nomenclature_content__items"
            v-for="(item, indexItem) in newElement.REAL_NOMENCLATURE"
            :key="indexItem"
          >
            <detail-nomenclature-card
              :nomenclature_data="item"
              :index="currentId"
              :specification_child="true"
              :nomenclature_parent="currentId"
              :disableAbsolute="true"
              style="font-size: 18px"
            />
          </div>
        </div> -->
        <div class="show_block-content view_nom">
          <RealNomenclature :editArray="true" />
        </div>
      </show-block>

      <show-block
        :nameBlock="'Параметры'"
        :showBlock="!!newElement.NOTE.length || !!newElement.HASHTAGS.length"
      >
        <div class="show_block-content show_block-content_params">
          <div class="standard_title">
            Тип: <span class="type_check">{{ typeCheck }}</span>
          </div>
          <Note :elementValue="newElement.NOTE" :elementDisabled="true" />
          <Chips
            style="pointer-events: none"
            :elementValue="newElement.HASHTAGS"
            :elementReadonly="true"
            :closeChip="false"
          />
        </div>
      </show-block>

      <show-block
        :nameBlock="
          !!idParrents.length ? 'Применяемость' : 'Применяемость отсутствует'
        "
        :showBlock="!!idParrents.length"
        :numberBlock="idParrents"
      >
        <div class="show_block-content">
          <Applicability :elementValue="idParrents" />
        </div>
      </show-block>

      <show-block
        :nameBlock="idPlanning ? 'Планирование' : 'Планирование не заполнено'"
      >
        <div class="show_block-content">
          <Planning :elementValue="idPlanning" v-if="idPlanning" />
        </div>
      </show-block>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import DrawingNumber from '@/components/specification/DrawingNumber.vue'
import HeaderIcons from '@/components/specification/HeaderIcons'
import SearchByName from '@/components/specification/NameNomenclature'
import Drawings from '@/components/specification/Drawings'
import Note from '@/components/specification/Note'
import RealNomenclature from '@/components/specification/RealNomenclature'
import NameNomenclature from '../../components/specification/NameNomenclature.vue'
import Chips from '../../components/specification/Chips.vue'
import DetailNomenclatureCard from '../../components/nomenclature/DetailNomenclatureCard.vue'
import ChildElement from '../../components/specification/ChildElement.vue'
import ShowBlock from '../../components/specification/ShowBlock.vue'
import Planning from '../../components/specification/Planning.vue'
import Applicability from '../../components/specification/Applicability.vue'

export default {
  name: 'SpecificationView',
  components: {
    RealNomenclature,
    Note,
    Drawings,
    SearchByName,
    HeaderIcons,
    DrawingNumber,
    NameNomenclature,
    Chips,
    DetailNomenclatureCard,
    ChildElement,
    ShowBlock,
    Planning,
    Applicability,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        activeIcons: false,
      },
      currentId: 0,
      btnCreateActive: false,
      componentKey: 0,
    }
  },
  computed: {
    ...mapState('specificationStore', [
      'newElement',
      'allNomenclatures',
      'idPlanning',
      'idParrents',
    ]),
    ...mapGetters('specificationStore', ['typeCheck']),
  },
  methods: {
    ...mapMutations('specificationStore', {
      getIdNomenclature: 'GET_ID_NOMENCLATURE',
      clearForm: 'CLEAR_FORM',
      clearNomenclatures: 'CLEAR_ALL_NOMENCLATURES',
      clearStatus: 'CLEAR_STATUS',
    }),
    ...mapActions('specificationStore', [
      'getConcreteNomenclature',
      'getAllNomenclatures',
      'getAllNomenclaturesDrawingNumber',
    ]),

    eventFunction(data) {
      if (!data || data.trim() == '') {
        this.btnCreateActive = true
        this.clearNomenclatures()
        return
      }
      this.getAllNomenclatures(data).then((res) => {
        this.btnCreateActive = res.state
      })
    },

    eventFunctionDrawing(data) {
      if (!data || data.trim() == '') {
        return
      }
      this.getAllNomenclaturesDrawingNumber(data)
    },

    eventDataFunction(data) {
      if (typeof data == 'object' && data !== null) {
        this.$router.push(`/specification/view/${data.ID}`)
        this.getConcreteNomenclature(data.ID)
      }
    },

    goToView() {
      this.clearNomenclatures()
      this.clearForm()
      this.$router.push({
        name: 'SpecificationView',
      })
    },
    renderDrawing() {
      console.log('rendering')
      this.componentKey += 1
    },
  },
  mounted() {
    this.getConcreteNomenclature(this.$route.params.id).then((res) => {
      if (res.state === false) {
        this.$router.push({ name: 'SpecificationView' })
      }
    })
    window.scrollTo(0, 0)
  },
  // beforeUpdate() {
  //   this.componentKey += 1
  // },
}
</script>

<style lang="scss">
.specification_card__real_nomenclature_content__items {
  position: relative;
  display: flex;
  flex-direction: column !important;
  .wrapper_render_info_product__subChilds__cards__wrapper__connection {
    display: flex;
    align-items: center;
    z-index: 1;
    input {
      width: 1.2em;
      height: 1.2em;
    }
  }
}

.specification_card__header__title {
  display: grid;
  flex-direction: column;
  grid-template-columns: 70% auto;
}
.type_check {
  font-weight: 800;
}
.skeleton {
  max-width: 100%;
}
.selected_nom {
  color: white;
  font-size: 24px;
  padding: 16px;
}
.par_search {
  width: 100%;
  max-width: inherit;
  position: fixed !important;
  top: 50px;
  z-index: 997;
  padding-top: 8px;
  background: #363636;
  height: 64px;
}
.search_nom {
  background: #363636;
  margin-top: 0px !important;
  // padding: 0px 12px;
  & input {
    font-size: 20px;
  }

  & .v-text-field__details {
    display: none;
  }
}

.search_nom input:focus::-webkit-input-placeholder {
  color: transparent;
}

.search_result {
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 2fr 0fr;

  & input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .v-input__append-inner {
    display: none;
  }

  & input:disabled {
    color: rgb(0 0 0 / 70%);
  }

  &-clear {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
