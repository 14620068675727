<template>
    <div class="print_page" id="print_page" v-if="data_detail_order.DETAIL_ORDER">
        <div class="wrapper_heading_info">
            <section class="head_info">
                <section class="block_heading">
                    <section><b>ЗАКАЗ-НАРЯД № {{ data_detail_order.DETAIL_ORDER.ID}}</b></section>
                    <section>от {{ data_detail_order.DETAIL_ORDER.DATE_CREATE_NEW_ORDER }}</section>
                </section>
                <section v-if="!desired_value_value_LIST_STAGE_OF_PRODUCTION('Сварка')" class="block_padding">Не назначен исполнитель на сварку</section>
                <section class="block_stage_of_production" v-if="!!desired_value_value_LIST_STAGE_OF_PRODUCTION('Сварка')">
                    <section class="heading_stage_of_production">
                        <b>СВАРКА:
                            <u>
                                <section>
                                    {{ desired_value_value_LIST_STAGE_OF_PRODUCTION('Сварка')._LOG_FOR_STAGE_DATA.FULL_NAME_PERFORMER }}
                                </section>
                            </u>
                        </b>
                    </section>
                    <section class="info_stage_of_production">
                        <section>Назначен: {{ desired_value_value_LIST_STAGE_OF_PRODUCTION('Сварка')._LOG_FOR_STAGE_DATA.PLANNED_TIME_FOR_WORK}} </section>
                        <section>Норма времени на сварку: {{ data_detail_order.DETAIL_ORDER.NORM_OF_TIME_WELDING}} </section>
                    </section>
                </section>
            </section>
          <img class="qr_code"
               :src="'https://chart.googleapis.com/chart?chs=150x150&cht=qr&chld=L|0&chl='+data_detail_order.CORRECT_URL_QR_CODE" id="qr_code">
        </div>

        <!--            <section class="kolichestvo" style="grid-area: kolichestvo">--><!--</section>-->

        <div class="block_main_info">
            <img class="image_prod" :src="data_detail_order.DETAIL_ORDER.IMG_CUR_SPEC.SRC" :title="data_detail_order.DETAIL_ORDER.IMG_CUR_SPEC.TITLE">
            <section class="name_production"><b>{{ data_detail_order.DETAIL_ORDER.NAME_PROD }}</b></section>
            <section class="number_of_position">{{ data_detail_order.DETAIL_ORDER.NUMBER_OF_POSITIONS_FOR_EXECUTION }}</section>
        </div>

        <div class="wrapper_table">
            <div class="header_block_output_table"><b>Комплектовочная ведомость:</b></div>

            <section class="table_row" v-for="(array_tree, key) in data_detail_order.OUT_INVENTORY_FLAT_ARRAY">
                <div class="nomer">{{ key = key + 1 }}</div>
                <div class="naimenovanie">
                    <section class="nomenclature_name_section"
                             :data-img-href="array_tree.IMG.SRC"
                             :data-name="array_tree.NOMENCLATURE_NAME">
                        {{ array_tree.NOMENCLATURE_NAME }}
                    </section>
                </div>
                <div class="dots"></div>
                <div class="kolichestvo" :class="{not_enough_quantity: array_tree.STATE__NOT_ENOUGH_CATALOG_QUANTITY}">
                    <span>
                        {{ array_tree['SUM_QUANTITY_NODES'] }} шт.
                    </span>
                </div>
            </section>
        </div>

        <div id="page-break-before"></div>
        <div class="signature_table">
            <section class="heading_1">Действие</section>
            <section class="heading_2">Время/Дата</section>
            <section class="heading_3">Подпись</section>
            <section class="heading_4">Фамилия И.О.</section>

            <section class="main_row_1">Принял на склад заготовок</section>
            <section class="main_row_2">Принял на склад пилки</section>
            <section class="main_row_3">Принял на склад токарки</section>
            <section class="main_row_4">Принял на склад сверловки</section>
            <section class="main_row_5">Принял на склад дробеструя</section>
            <section class="main_row_6">Принял на склад сварки</section>
            <section class="main_row_7">Принял на склад продувки</section>
            <section class="main_row_8">Принял на склад полировки</section>
            <section class="main_row_9">Принял на склад покраски</section>
            <section class="main_row_10">Принял на склад изделий</section>
            <section class="main_row_11">Принял на склад готовой продукции</section>

            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
            <section></section>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrintPageOrder",
        props: ['id_order_for_print'],
        data: () => ({
            output: null,
            data_detail_order: [],
        }),
        watch:{
            id_order_for_print:{
                handler: function watch() {
                    this.get_data_prod();
                },
            },
        },
        computed:{},
        mounted() {
            this.get_data_prod();
        },
        methods: {
            desired_value_value_LIST_STAGE_OF_PRODUCTION(value){
                var a = this.data_detail_order.LIST_STAGE_OF_PRODUCTION_STATUS.filter(elem => elem.NAME_STAGE === value)[0];
                console.log('desired_value_value_LIST_STAGE_OF_PRODUCTION');
                console.log(a);
                return a;
            },
            get_data_prod(){
                const _this = this;
                console.log('_this.id_order_for_print');
                console.log(_this.id_order_for_print);
                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.detail_purchase_order',
                    'executeGetOrder', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            'post':{'id_order': _this.id_order_for_print},
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success') {
                            // Если форма успешно отправилась
                            console.log('production.print_purchase_order');
                            console.log(response.data);

                            _this.data_detail_order = response.data;

                            _this.$nextTick(() => {
                                const main_vue_component = _this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
                                main_vue_component.printed_setTimeout(_this, '#print_page');
                            })
                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });

            },
        },
    }
</script>

<style lang="scss">
    @import "../assets/sass/main";

    /* ВСЕ СТИЛИ ПРОГОНЯТЬ ЧЕРЕЗ SCSS - CSS конвертер и отдельный файлом подключать в компонент VueHtmlToPaper
     ТОЛЬКО ОНИ для печати используются */
    /*header {*/
        /*-webkit-print-color-adjust: exact;*/
        /*print-color-adjust: exact;*/
    /*}*/

    $background_heading: lightgrey;
    %padding-head_info {
        padding: 5px 25px !important;
    }

    .print_page {
        //padding: 5% 0;
        display: block;
        .wrapper_heading_info, .block_main_info, .wrapper_table, .signature_table {
            margin: 15px 0;
        }

        .wrapper_heading_info {
            display: grid;
            grid-template-columns: auto 160px;
            grid-column-gap: 20px;
            .head_info {
                display: grid;
                //grid-row-gap: 20px;
                grid-template-rows: auto auto;
                font-size: 20px;

                .block_heading {
                    height: fit-content;
                    color: black;
                    background: $background_heading;
                    @extend %padding-head_info;
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    grid-column-gap: 15px;
                    justify-content: flex-start;
                }
                .block_stage_of_production {
                    display: grid;
                    grid-row-gap: 5px;
                    align-content: baseline;
                    @extend %padding-head_info;
                }
            }
            img.qr_code {
                height: 100%;
                margin-left: auto;
            }
        }
        .block_main_info {
            display: grid;
            grid-template-columns: 20% auto 15%;
            grid-column-gap: 30px;
            justify-items: center;
            align-items: flex-start;
            img {
                height: 100%;
            }
            .name_production {
                font-size: 21px;
            }
            .number_of_position {
                font-size: 5vh;
                margin-left: auto;
            }
        }
        .wrapper_table {
            display: grid;
            //grid-row-gap: 5px;
            font-size: 18px;

            .header_block_output_table {
                background: $background_heading;
                @extend %padding-head_info;
            }

            .table_row {
                padding: 5px 0 5px 25px;
                display: grid;
                grid-template-columns: 4% auto 1fr auto;
                grid-template-areas: "nomer naimenovanie kolichestvo";
                .dots {
                    @extend %dots;
                }
                .kolichestvo {
                    text-align: right;
                }
            }
        }

        .signature_table {
            width: 100%;
            display: grid;
            grid-template-columns: 30% auto auto auto;
            grid-template-rows: repeat(5, auto);
            grid-template-areas: "heading_1 heading_2 heading_3 heading_4" "main_row_1 . . ." "main_row_2 . . ." "main_row_3 . . ." "main_row_4 . . ." "main_row_5 . . .";
            //grid-gap: 10px;
            justify-items: center;
            position: fixed;
            bottom: 0;

            %baseline_elem {
                //justify-self: baseline;
                padding-left: 20px;
            }
            %top_elem {
                background: $background_heading;
                border: unset;
                font-weight: bold;
            }

            section {
                //justify-content: center;
                padding: 8px;
                border: 1px solid lightgrey;
                width: -webkit-fill-available;
                width: -moz-available;
                /*width: fill-available;*/

                &.heading_1 {
                    grid-area: heading_1;
                    @extend %baseline_elem;
                    @extend %top_elem
                }
                &.heading_2 {
                    grid-area: heading_2;
                    @extend %top_elem;
                    text-align: center;
                }
                &.heading_3 {
                    grid-area: heading_3;
                    @extend %top_elem;
                    text-align: center;
                }
                &.heading_4 {
                    grid-area: heading_4;
                    @extend %top_elem;
                    text-align: center;
                }
                &.main_row_1 {
                    grid-area: main_row_1;
                    @extend %baseline_elem;
                }
                &.main_row_2 {
                    grid-area: main_row_2;
                    @extend %baseline_elem;
                }
                &.main_row_3 {
                    grid-area: main_row_3;
                    @extend %baseline_elem;
                }
                &.main_row_4 {
                    grid-area: main_row_4;
                    @extend %baseline_elem;
                }
                &.main_row_5 {
                    grid-area: main_row_5;
                    @extend %baseline_elem;
                }
                &.dot {
                    grid-area: dot;
                }

                //&:nth-child(6n){
                //  border-top: unset;
                //}
            }

        }
    }

</style>