<template>
  <v-dialog
      v-model="$store.state.system_notification_dialog.state_dialog"
      max-width="600px"
  >
    <v-card id="v_dialog_list_po">
      <v-card-title>
        <span class="headline">{{ $store.state.system_notification_dialog.header_message }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row style="display:grid;">
            <span class="html_content_v_dialog" v-html="$store.getters.valid_system_message"></span>
            <v-divider style="border: 1px solid lightgrey;  margin: 10px 0;"></v-divider>
            <span v-html="$store.state.system_notification_dialog.body_sub_message"></span>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            id="close_dialog"
            block
            :color="$store.state.system_notification_dialog.color"
            elevation="2"
            @click="$store.state.system_notification_dialog.state_dialog = false; reload_with_cache()"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SystemNotificationDialog",
  methods: {
    reload_with_cache() {
      if (this.$store.state.system_notification_dialog.shouldUpdatePage) {
        location.reload(true);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

#v_dialog_list_po{
  .headline{
    text-align: center;
    margin: auto;
    word-break: break-word;
  }
  span.html_content_v_dialog{
    font-size: 1.1em;
    width: 100%;
  }
}

</style>