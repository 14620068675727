<template>
  <div class="specification_card__header__name">
    <span class="standard_title">Тип:</span>
    <div class="radio_group">
      <v-radio-group class="radio-buttons" dense dark v-model="radioActive">
        <v-radio
          v-for="(radio, index) in radio_types"
          :key="index"
          :label="radio.text"
          :value="radio.value"
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      radio_types: [
        {
          text: 'Деталь',
          value: 'detail',
        },
        {
          text: 'Операция',
          value: 'operation',
        },
      ],
    }
  },
  mounted() {
    this.$emit('eventChange', this.radioActive)
  },
  computed: {
    radioActive: {
      get() {
        if (this.activeType) {
          return this.activeType
        }
        return 'detail'
      },
      set(value) {
        this.$emit('eventChange', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin: 0 !important;
}
</style>
