<template>
  <div>
    <div id="wrapper_receipt_overhead">
      <!-- Блок фильтров -->
      <div id="" class="invisible block_filters_universal" v-show="$store.state.state_block_filters">

        <div class="block_filter">
          <p>Номер счета:</p>
          <v-text-field v-model.number.trim="data_filters.account_number"
                        label="Номер счета"
                        solo dense
                        @input="updateQuery(); get_list_documents(); get_list_contractor()"
          ></v-text-field>
        </div>

        <div class="block_filter">
          <p>Поставщик:</p>
          <v-autocomplete solo dense
                          :items="list_contractor"
                          item-value="code" item-text="label"
                          return-object
                          clearable
                          @click:clear="$nextTick(() => { selected_contractor = default_selected_contractor})"
                          label="Вcе контрагенты"
                          :menu-props="{closeOnContentClick: true}"
                          v-model="computed_selected_contractor">
          </v-autocomplete>
        </div>

        <div class="block_filter">
          <p>Статус документа:</p>
          <v-select
              solo dense
              clearable
              @click:clear="$nextTick(() => { selected_filter_status_doc = null })"
              label="Все"
              placeholder="Все"
              :menu-props="{closeOnContentClick: true}"
              :items="list_for_filter_status_doc"
              item-value="CODE" item-text="NAME"
              return-object
              v-model="selected_filter_status_doc"
          >
          </v-select>
        </div>
        <div class="container_arrow_open_close_block_filter">
          <v-icon
              class="mdi_icon_arrow"
              @click="$store.state.state_block_filters = !$store.state.state_block_filters"
              color="primary"
              elevation="2"
          >mdi-play
          </v-icon>
        </div>

        <div id="block_error">Идёт загрузка...</div>
      </div>
      <v-dialog
          v-model="resetAll"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title style="display: flex; justify-content: center">
            <span class="headline">Начать приемку заново?</span>
          </v-card-title>
          <v-card-actions style="display: flex; justify-content: space-between">
            <v-btn
                color="green darken-1"
                text
                @click="resetAll = false"
            >
              Нет
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="confirmReset()"
            >
              Да
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-overlay
          :z-index="1"
          :absolute="true"
          :value="loading_order"
      >
        <v-progress-circular
            style="margin: auto;"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
        ></v-progress-circular>
      </v-overlay>

      <div class="wrapper_all_row">
        <div class="wrapper_grid_table" v-for="(doc, key) in documents"
             :data-id-doc="doc.id"
             :data-namecontractor="doc.namecontractor"
             ref="arrBlockDoc"
             :class="status_doc(doc)"
        >
          <v-lazy>
            <div class="wrapper_rows_data">
              <div class="wrapper_grid_row" @click="onShow(doc); state_blur = !state_blur;">
                <div class="wrapper_0">
                  <section class="ACCOUNT_NUMBER">{{ doc.ACCOUNT_NUMBER }}</section>
                  <section class="PLANNED_DATE">{{ doc.PLANNED_DATE }}</section>
                </div>
                <div class="wrapper_1">
                  <section class="namecontractor"> {{ doc.namecontractor }}</section>
                  <div class="wrapper_1_1">
                    <div class="wrapper_1_1_1">
                      <section class="number_of_positions">
                        <span>{{ $store.getters.cutSymbols(doc.items[0].NAME_PROD) }}</span>
                        <span>{{ (doc.numberofpositions > 1) ? '['+doc.numberofpositions+']' : '' }}</span>
                      </section>
                      <section class="total_price small_grey">{{ doc.total_price }}</section>
                    </div>
                    <div class="wrapper_1_1_2">
                      <v-icon
                          class="card_arrow"
                          :class="{ mdi_icon_arrow: true, activeArrow: doc.v_show_state }"
                          color="grey"
                          elevation="2"
                      >mdi-play
                      </v-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="list_products_position" v-show="doc.v_show_state">
                <div class="row_product" v-for="(pos, index) in doc.items"
                     :key="pos.ID"
                     :class="{ active: doc.obj_performance_mark.includes(pos.ID) }"
                >
                  <div class="column column_0">
                    <section>{{ index += 1 }}</section>
                  </div>
                  <div class="column column_1">
                    <a @click="open_dialog_nomenclature(pos.ELEMENT_ID)" class="href_to_nomenclature">
                      {{ pos.NAME_PROD }}
                    </a>
                  </div>
                  <div class="column column_2">
<!--        :value="pos.PRODUCTS_AMOUNT"            -->
                    <v-text-field
                        hide-details
                        class="EDITABLE_QUANTITY"
                        v-model="pos.AMOUNT"
                        :placeholder="pos.DEFAULT_AMOUNT"
                        suffix="шт."
                        :rules="rules.rulesEditableQuantity(pos.DEFAULT_AMOUNT)"
                        @input="setChangedValue(pos)"
                        min="0"
                        type="number"
                        :disabled="documents[key].disableEdit"
                    ></v-text-field>
<!--                    @input="event => setChangedValue(event, pos)"-->
                    <section class="small_grey">{{ pos.PURCHASING_PRICE }} ₽</section>
                  </div>
                  <div class="column column_3">
                    <v-checkbox
                        color="#00a0e3"
                        :value="pos.ID"
                        :disabled="doc.mark_status_disabled_doc"
                        v-model="doc.obj_performance_mark"
                        @change="create_update_position_document_custom_table(doc)"
                    >
                    </v-checkbox>
                  </div>

                </div>
              </div>

              <div class="mini_chat" v-show="doc.v_show_state">
                <v-textarea
                    auto-grow
                    outlined
                    rows="3"
                    clearable
                    @click:clear="doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION = '';"
                    clear-icon="mdi-close-circle"
                    class="commentary_production"
                    :label="doc.FRONT_CUSTOM_STATUS.textarea.text"
                    background-color="#f7ee89"
                    v-if="doc.FRONT_CUSTOM_STATUS.textarea.state"
                    :autofocus="doc.FRONT_CUSTOM_STATUS.textarea.state"
                    v-model="doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION"
                    :loading="((!!data_update_commentary) && data_update_commentary.code == 'send')"
                    :color="doc.FRONT_CUSTOM_STATUS.textarea.color"
                    :messages="doc.FRONT_CUSTOM_STATUS.textarea.messages"
                    @input="update_commentary_production(doc)"
                >
                  {{ doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION }}
                </v-textarea>
              </div>

              <div class="wrapper_button" v-show="doc.v_show_state">
                <v-btn
                    :color="doc.FRONT_CUSTOM_STATUS.button.color"
                    :disabled="!doc.FRONT_CUSTOM_STATUS.button.state || doc.DEFAULT_BITRIX_STATUS !== 'N'"
                    id="viavlena_nedostacha" class=""
                    @click="doc.FRONT_CUSTOM_STATUS.stage_status_counter++; setLogicCustomStatus(doc);"
                >
                  {{ doc.FRONT_CUSTOM_STATUS.button.text }}
                </v-btn>
                <v-btn color="" class=""
                       elevation="2"
                       text
                       :disabled="(!doc.obj_performance_mark.length && (doc.FRONT_CUSTOM_STATUS.stage_status_counter === 0)) || doc.DEFAULT_BITRIX_STATUS !== 'N'"
                       @click="setCurrentDoc(doc)">Сбросить всё
                </v-btn>
                <v-btn :color="doc.ACCEPT_BTN.color" v-show="doc.v_show_state"
                       block
                       id="accept_receipt" class=""
                       :loading="loading_order"
                       :disabled="doc.disabled_state_btn_access_conduct"
                       @click="access_conduct_document(doc)"
                >
                  {{ doc.ACCEPT_BTN.text }}
                </v-btn>
              </div>
            </div>
          </v-lazy>
        </div>
      </div>

      <v-alert
          v-if="!documents.length && !loading_order"
          type="error"
          text
          class="err-msg"
      >
        Ничего не найдено
      </v-alert>
      <v-btn @click="get_list_documents('next_page')" :disabled="count_data.RECORDS_TO_END <= 0" v-if="documents.length && !loading_order">Показать ещё
        {{ count_data.RECORDS_TO_END }}
      </v-btn>

      <!--<div class="blur" :class="{ active_blur: state_blur }"></div>-->

      <!-- Диалоговое окно с номенклатурой -->
      <v-dialog
          v-model="dialog_nomenclature_window.state"
          max-width="600px"
          content-class="dialog_nomenclature"
      >
        <v-card id="v_dialog_nomenclature_window">
          <v-card-title>
            <span class="headline">Номенклатура</span>
            <v-icon
                class="close_dialog_cross"
                color="primary"
                elevation="2"
                @click="dialog_nomenclature_window.state = false"
            >fas fa-times
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <nomenclature :dialog_id_order="dialog_nomenclature_window.id_order"></nomenclature>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Nomenclature from "../components/nomenclature/Nomenclature";
// import variables from "../assets/sass/main";

export default {
  components: {
    Nomenclature,
  },
  name: "ReceiptOverhead",
  data: () => ({
    loading_order: false,
    state_blur: false,
    currentPage: 0,
    count_data: {},
    data_date_time: {},
    documents: [],
    data_filter: [],
    resetAll: false,
    currentDoc: {},
    id_document: '',
    list_contractor: [],

    default_selected_contractor: {
      'code': [],
      'label': 'Все контрагенты',
    },
    selected_contractor: {
      'code': [],
      'label': 'Все контрагенты',
    },
    data_update_commentary: null,
    data_filters: {
      account_number: '',
    },
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    list_for_filter_status_doc: [],
    selected_filter_status_doc: null,
    rules: {
      rulesEditableQuantity: max_val => {
        return [
            v =>
            ((parseInt(v) <= parseInt(max_val)) || `Максимальное кол-во - `+max_val
            &&
            ((parseInt(v) <= 0) || `Не должно быть меньше нуля`))
        ]
      },
    }
  }),
  mounted() {
    // window.addEventListener('scroll', this.scrollDocumentBlock)
    // this.get_list_contractor();
    this.getListStatusDocuments();
    this.initialQuery();
  },
  computed: {
    computed_selected_contractor: {
      get: function () {
        return this.selected_contractor;
      },
      set: function (value) {
        // console.log('computed_selected_contractor');
        // console.log(value);
        if (!value) {
          this.selected_contractor = {'code': []};
        } else {
          this.selected_contractor = value;
        }
        /* Вызываем отфильтрованный список */
        this.get_list_documents(null);
      }
    },
  },
  watch: {
    documents: {
      handler: function (val, oldVal) {
        /* Если все отметки заполнены изменим стейт кнопки проводки документа */
        val.forEach(function (doc) {
          /* Управляем состояние дизейблед элементов если статус поменялся */
          if (doc.DEFAULT_BITRIX_STATUS !== 'Y') {
            if (doc.items.some(elem => parseInt(elem.AMOUNT) !== parseInt(elem.DEFAULT_AMOUNT))) {
              doc.ACCEPT_BTN.color = 'yellow darken-2'
              doc.ACCEPT_BTN.text = 'Принять накладную частично'
            } else {
              doc.ACCEPT_BTN.color = 'secondary'
              doc.ACCEPT_BTN.text = 'Принять накладную'
            }
            if (doc.items.length === doc.obj_performance_mark.length) {
              doc.disabled_state_btn_access_conduct = false;
            } else {
              doc.disabled_state_btn_access_conduct = true;
            }
          }
        });
      },
      deep: true
    },
    selected_filter_status_doc:{
      handler: function (val, oldVal) {
        this.get_list_contractor();
        this.get_list_documents(null);
      }
    }
  },
  methods: {
    initialQuery() {
      if (Object.keys(this.$route.query).includes('id_document')) {
        this.$nextTick(() => {
          this.id_document = this.$route.query['id_document']
          // this.get_list_documents().then(() => {
          //   this.id_document = ''
          // })
        })
      }
    },
    updateQuery() {
      let queryObj = {}
      if (Object.keys(this.$route.query).length) {
        for (let key in this.$route.query) {
          Object.assign(queryObj, {[key]: this.$route.query[key]})
        }
      }
      if (!!this.data_filters?.account_number) {
        Object.assign(queryObj, {'contractor': this.data_filters.account_number})
      } else {
        delete queryObj.contractor
      }
      this.pushRouter(queryObj)
    },
    pushRouter(object) {
      this.$store.commit('updateURL_components', object)
    },
    setChangedValue(doc){
      if (parseInt(doc.AMOUNT) >= parseInt(doc.DEFAULT_AMOUNT)) {
        this.$nextTick(() => {doc.AMOUNT = doc.DEFAULT_AMOUNT})
      } else if (doc.AMOUNT.charAt(0) === '0') {
        this.$nextTick(() => {doc.AMOUNT = parseInt(doc.AMOUNT.slice(1))})
      } else if (parseInt(doc.AMOUNT) <= 0 || Number.isNaN(parseInt(doc.AMOUNT))) {
        this.$nextTick(() => {doc.AMOUNT = 0})
      }
    },
    setCurrentDoc(doc) {
      this.currentDoc = doc
      this.resetAll = true
    },
    confirmReset() {
      this.resetAll = false
      this.clear_marks(this.currentDoc)
      // this.update_commentary_production(this.currentDoc)
      this.$nextTick(() => {this.currentDoc.items.forEach(elem => elem.AMOUNT = elem.DEFAULT_AMOUNT)})
      this.currentDoc.disableEdit = true
    },
    onShow(doc) {
      let status = null
      if (doc.v_show_state === true) {
        status = false
      } else {
        status = true
      }
      this.documents.map(index => index.v_show_state = false)
      doc.v_show_state = status
    },
    open_dialog_nomenclature(order_id) {
      this.dialog_nomenclature_window.id_order = order_id;
      this.dialog_nomenclature_window.state = true;
    },
    status_doc(doc) {
      return {
        active: (doc.v_show_state),
        open: (doc.status_doc.class === 'open' && doc?.BACK_CUSTOM_STATUS !== 'shortage_identified'),
        close: (doc.status_doc.class === 'close' && doc?.BACK_CUSTOM_STATUS !== 'shortage_identified'),
        shortage: (doc.BACK_CUSTOM_STATUS === 'shortage_identified')
      }
    },
    clear_marks(doc) {
      /* Очистим свойство если оно активно */
      if (doc.mark_status_disabled_doc !== true) {
        doc.obj_performance_mark = [];
      }
      /* Вызовем функцию очистки в таблицах */
      this.create_update_position_document_custom_table(doc)

      /* Обнуляем кнопку "недостатча" и поле коментария */
      doc.FRONT_CUSTOM_STATUS.stage_status_counter = 0
      // doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION = ''
      this.setLogicCustomStatus(doc)
    },
    async create_update_position_document_custom_table(doc) {
      const _this = this;
      // console.log(doc.id);
      // console.log(doc.obj_performance_mark);
      /* Создаем новую запись  */
      BX.ajax.runComponentAction('nk:production.receipt_overhead',
          'executeCreate_update_obj_performance_mark', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                // id: doc.id,
                id_document: doc.id,
                obj_performance_mark: doc.obj_performance_mark,
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            console.log('WAYA');
            console.log(response);
            if (response.data.state === true) {
              // console.log(response);
              // const response_data = response.data;
              // const vue_doc = _this.documents;
              // console.log(response_data);
              // response_data.forEach((elem) => vue_doc.push(new Destruct_arr(elem)));
            } else {
              this.$store.dispatch('setSystemNotificationResult', response.data);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    async update_commentary_production(doc) {
      const _this = this;

      if (_this.$store.state.debounceTimeout)
        clearTimeout(_this.$store.state.debounceTimeout);

      _this.$store.state.debounceTimeout = setTimeout(() => {
        console.log('executeUpdateCommentaryProduction');
        if (doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION === null) {
          doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION = ''
        } else if (!doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION.includes(_this.$store.state.userFio)) {
          doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION = `${_this.$store.state.userFio}: ${doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION}`
        }

        _this.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('send');
        // console.log(obj_data);
        BX.ajax.runComponentAction('nk:production.receipt_overhead',
            'executeUpdateCommentaryProduction', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                post: {
                  id_document: doc.id,
                  commentary_production: doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION,
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function (response) {
              if (response.status === 'success') {
                console.log(response);
                // response.data.obj = JSON.parse(response.data.obj);
                // console.table('executeUpdateCommentaryProduction - success');
                // console.table(response.data);

                setTimeout(() => {
                  _this.$nextTick(() => {
                    _this.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('success');
                    if (doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION !== null && doc.COMMENTARY_DATA.COMMENTARY_PRODUCTION.length) {
                      doc.FRONT_CUSTOM_STATUS.textarea.messages = 'Информация о недостачи передана в отдел снабжения';
                    } else doc.FRONT_CUSTOM_STATUS.textarea.messages = null;

                    // console.log('TIMEOUT');
                  })
                }, 1000);
              }
            })
            .catch((response) => {
              _this.data_update_commentary = _this.$store.getters.getObjectMessageColorForCode('error');
              console.log(response);
            });

      }, 1000);
    },
    async get_list_documents(next_page) {
      this.loading_order = true
      const _this = this;

      if (!!next_page) {
        this.currentPage += 1;
      } else {
        this.currentPage = 1;
      }
      console.log(_this.currentPage);
      console.log(_this.selected_contractor);
      console.log(_this.selected_filter_status_doc);

      return new Promise((resolve, reject) => {
        /* Запрашиваем новый список документов с увеличенной инкрементно currentPage */
        BX.ajax.runComponentAction('nk:production.receipt_overhead',
            'executeGetPageReceipt', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                post: {
                  iNumPage: _this.currentPage,
                  id_contractor: _this.selected_contractor.code,
                  selected_filter_status_doc: (!!_this.selected_filter_status_doc) ? _this.selected_filter_status_doc.CODE : null,
                  account_number: _this.data_filters.account_number,
                  id_document: _this.id_document ? _this.id_document : null
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function (response) {
              if (response.status === 'success') {
                console.log('executeGetPageReceipt');
                console.log(response.data);

                // массив документов
                if (!!response.data.ARR_DOC) {
                  console.log(response.data.ARR_DOC);
                  if (!!next_page) {
                    response.data.ARR_DOC.forEach((elem) => _this.documents.push(new _this.constructDocumentsArr(elem, new _this.default_front_custom_status, true, new _this.accept_btn())));
                  } else {
                    _this.documents = [];
                    response.data.ARR_DOC.forEach((elem) => _this.documents.push(new _this.constructDocumentsArr(elem, new _this.default_front_custom_status, true, new _this.accept_btn())));
                  }
                } else {
                  _this.documents = [];
                }

                // Данные по количество элементов
                if (!!response.data.COUNT_DATA) {
                  // console.log(response.data.COUNT_DATA);
                  _this.count_data = response.data.COUNT_DATA;
                } else {
                  _this.count_data = {};
                }

                // Данные по фильтрации даты
                if (!!response.data.DATA_DATE_TIME) {
                  console.log(response.data.DATA_DATE_TIME);
                  _this.data_date_time = response.data.DATA_DATE_TIME;
                } else {
                  _this.count_data = {};
                }
                _this.loading_order = false
                _this.id_document = ''
                resolve()
              }
            })
            .catch((response) => {
              console.log(response);
              _this.loading_order = false
              _this.id_document = ''
              resolve()
            });
      })
    },
    async access_conduct_document(doc) {
      const _this = this;

      BX.ajax.runComponentAction('nk:production.receipt_overhead',
          'executeConductDoc', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_document: doc.id,
                items: doc.items,
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            console.log(response);
            /* Вставим в разметку ответ от сервера */
            if (response.data.state === true) {
              doc.DEFAULT_BITRIX_STATUS = 'Y';
              doc.disabled_state_btn_access_conduct = true;
              doc.mark_status_disabled_doc = true;
              doc.status_doc = {text: 'принят', class: 'close'};
            }
            _this.$store.dispatch('setSystemNotificationResult', response.data);
            _this.$store.commit('set_system_notification_update', true)

          })
          .catch((response) => {
            console.log(response);
            _this.$store.dispatch('setSystemNotificationResult', response);
            _this.$store.commit('set_system_notification_update', true)
          });
    },
    async get_list_contractor() {
      const _this = this;
      /* Запрашиваем список поставщиков  */
      BX.ajax.runComponentAction('nk:production.receipt_overhead',
          'executeGetListContractor', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                get_list_contractor: 'Y',
                selected_filter_status_doc:  (!!_this.selected_filter_status_doc) ? _this.selected_filter_status_doc.CODE : null,
                account_number: _this.data_filters.account_number,
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            if (response.status === 'success') {
              // Если форма успешно отправилась
              console.log('executeGetListContractor');
              console.log(response.data);

              const response_data = response.data;
              // console.log(response_data);

              /* В начало вставим запись всех */
              _this.list_contractor = [];
              _this.list_contractor.push({'code': null, 'label': 'Все контрагенты'});
              /* Переберем запрос */
              response_data.forEach((elem) => _this.list_contractor.push(new _this.constructListContractor(elem)));
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },

    async getListStatusDocuments(){
      const _this = this;
      /* Запрашиваем список поставщиков  */
      BX.ajax.runComponentAction('nk:production.receipt_overhead',
          'executeGetListStatusDocument', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                getListStatusDocuments: 'Y',
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            if (response.status === 'success') {
              // Если форма успешно отправилась
              console.log('executeGetListContractor');
              console.log(response.data);
              _this.list_for_filter_status_doc = response.data.log_data;
              _this.selected_filter_status_doc = _this.list_for_filter_status_doc.filter((elem) => elem.CODE === 'N')[0];
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },

    setLogicCustomStatus(doc) {
      switch (doc.FRONT_CUSTOM_STATUS.stage_status_counter) {
        case 0:
          doc.FRONT_CUSTOM_STATUS = new this.default_front_custom_status;
          break;
        case 1:
          // this.setStatusShortageIdentified(doc);
          doc.FRONT_CUSTOM_STATUS.button.color = 'primary';
          // doc.FRONT_CUSTOM_STATUS.button.text = 'Уведомить отдел снабжения';
          doc.FRONT_CUSTOM_STATUS.button.state = false;
          doc.FRONT_CUSTOM_STATUS.textarea.text = 'Описание недостачи';
          doc.FRONT_CUSTOM_STATUS.textarea.state = true;
          doc.FRONT_CUSTOM_STATUS.textarea.color = 'primary';
          break;
        case 2:
          doc.FRONT_CUSTOM_STATUS.button.color = 'primary';
          doc.FRONT_CUSTOM_STATUS.button.text = 'Информация передана';
          doc.FRONT_CUSTOM_STATUS.button.state = true;
          doc.FRONT_CUSTOM_STATUS.textarea.text = '';
          doc.FRONT_CUSTOM_STATUS.textarea.messages = 'Информация о недостачи передана в отдел снабжения';
          doc.FRONT_CUSTOM_STATUS.textarea.state = true;
          doc.FRONT_CUSTOM_STATUS.textarea.color = 'primary';
          break;
        case 3:
          break;
        case 4:
          break;
      }
      doc.disableEdit = false
    },
    async setStatusShortageIdentified(doc) {
      console.log('setStatusShortageIdentified');
      const _this = this;

      BX.ajax.runComponentAction('nk:production.receipt_overhead',
          'executeSetStatusShortageIdentified', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              post: {
                id_document: doc.id,
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            console.log(response);
            if (response.data.state) {
              // Если форма успешно отправилась
              console.log(response.data);
            } else {
              _this.$store.dispatch('setSystemNotificationResult', response.data)
            }
            doc.BACK_CUSTOM_STATUS = response.data.log_data;
          })
          .catch((response) => {
            console.log(response);
            _this.$store.dispatch('setSystemNotificationResult', response)
          });
    },

    /* Не используется */
    getStateInvisibleDocument(elem) {
      /* Координаты конкретного блока */
      const coord = elem.getBoundingClientRect();

      const x = window.pageXOffset || window.scrollX || (elem && elem.scrollLeft) || (document.body && document.body.scrollLeft) || 0;
      const y = window.pageYOffset || window.scrollY || (elem && elem.scrollTop) || (document.body && document.body.scrollTop) || 0;
      /* Расчет отступов, когда скролл выше блока */
      const offsetTop = coord.top - y;
      // const offsetTop = (screen.height >= coord.height) ? (screen.height - coord.top - coord.height) : 0;

      /* Расчет отступов, когда скролл ниже блока */
      const offsetBottom = (coord.top < 0) ? (coord.height - Math.abs(coord.top)) : 0;
      /* В результат пишется отрицательное значение из двух вычисленных */
      const res = (offsetBottom < 0) ? offsetBottom : offsetTop;



      const console_obj = {
        'name': elem.getAttribute('data-namecontractor'),
        'y': y,
        'top': coord.top,
        'height': coord.height,
        'offBottom': offsetBottom,
        'offTop': offsetTop,
        'res': res,
      }
      console.log(console_obj);

      return (res < 0);
    },
    /* Не используется */
    scrollDocumentBlock() {
      if (this.$refs.arrBlockDoc.length !== 0) {

          this.$refs.arrBlockDoc.forEach((elem) => {
            if (elem.classList.contains('active')) {

              const id_doc = elem.getAttribute('data-id-doc');
              const doc = this.documents.filter((elem) => elem.id === id_doc)[0];

              const stateInvisibleDoc = this.getStateInvisibleDocument(elem);
              if (stateInvisibleDoc) {
                doc.FRONT_CUSTOM_STATUS.stage_status_counter = 0;
                this.setLogicCustomStatus(doc);

                const console_obj = {
                  'doc_id': doc.id,
                  'stage_status_counter': doc.FRONT_CUSTOM_STATUS.stage_status_counter,
                }
                // console.log(console_obj);
              }

            }
          })
      }
    },

    /* Конструкторы */
    default_front_custom_status(){
        this.stage_status_counter = 0;
        this.button = {
          color: 'rgba(205, 92, 92, 0.73)',
          text: 'Выявлена недостача',
          state: true,
        };
        this.textarea = {
          text: '',
          messages: '',
          state: false,
          color: 'primary'
        };
    },

    accept_btn() {
        this.color = 'secondary'
        this.text = 'Принять накладную'
    },

    constructDocumentsArr
    ({
       ID,
       datecreate,
       items,
       CONTRACTOR_NAME,
       numberofpositions,
       v_show_state,
       total_price,
       COMMENTARY_DATA,
       PLANNED_DATE,
       ACCOUNT_NUMBER,
       COMMENTARY,
       STATUS,
       obj_performance_mark,
       disabled_state_btn_access_conduct,
       CUSTOM_STATUS,
     }, default_front_custom_status,
        disableEdit,
        accept_btn,
    ) {
      this.id = ID;
      this.datecreate = datecreate;

      this.items = items;
      this.namecontractor = CONTRACTOR_NAME;
      this.numberofpositions = numberofpositions;
      this.v_show_state = false;
      this.total_price = total_price;
      this.COMMENTARY_DATA = COMMENTARY_DATA;
      this.PLANNED_DATE = PLANNED_DATE;
      this.ACCOUNT_NUMBER = ACCOUNT_NUMBER;
      /* В зависимости от отметки сгенерируем новое свойство в каждом объекте документа - status_doc */
      this.DEFAULT_BITRIX_STATUS = STATUS;

      this.BACK_CUSTOM_STATUS = CUSTOM_STATUS;
      this.FRONT_CUSTOM_STATUS = default_front_custom_status;
      this.disableEdit = disableEdit;
      this.ACCEPT_BTN = accept_btn;


      this.status_doc = (STATUS === 'N') ?
          this.status_doc = {text: 'не принят', class: 'open'}
          :
          this.status_doc = {text: 'принят', class: 'close'};
      /* Если текущее значение - массив вернем его, если нет то создадим пустой массив, для множественного выбора значений */
      this.obj_performance_mark = Array.isArray(obj_performance_mark) ? obj_performance_mark : [];
      this.mark_status_disabled_doc = (STATUS === 'Y') ? true : false;
      this.disabled_state_btn_access_conduct = (STATUS === 'Y') ? true : false;
    },

    constructListContractor({ID_CONTRACTOR, NAME_CONTRACTOR, COUNT_CONTRACTOR_DOCS}) {
      this.code = ID_CONTRACTOR;
      this.label = NAME_CONTRACTOR;
      this.COUNT_CONTRACTOR_DOCS = COUNT_CONTRACTOR_DOCS;
    }
  },
};


</script>

<style lang="scss">
@import "../assets/sass/main";

body {
  font-size: 18px;

  #wrapper_receipt_overhead {
    font-size: 18px;
    /*margin: 2em 0;*/
    margin-bottom: 20px;
    //padding: 0 15px;
    display: grid;
    grid-row-gap: 1em;
    color: white;

    //header.wrapper_grid_table{
    //  .wrapper_grid_row{
    //    align-items: baseline;
    //    text-align: center;
    //  }
    //}

    .block_filters_universal {
      font-size: 18px;

      .block_filter {
        /*margin: 1em 0;*/
        .v-input {
          font-size: 18px;

          .v-text-field__details {
            display: none;
          }
        }

        .v_select_filter {
          margin: 1em 0;
        }
      }
    }

    header.heading_page {
      color: #f7ee89;
      font-size: 1.25em;
      text-align: left;
      background: dimgrey;
      padding: 16px 20px;
      border-radius: 10px;
      line-height: 1.5;

      span {
        color: lightgrey;
      }
    }

    .wrapper_all_row {
      display: grid;
      margin-top: 1em;

      .wrapper_grid_table {
        display: grid;
        background: $background_main_order_block;
        @extend %padding-row-position;
        @extend %margin-row-position;
        @extend %border-universal;

        &.open {
          border-color: map-get($border-universal-color, 'серый');
        }

        &.close {
          border-color: map-get($border-universal-color, 'зеленый');
        }

        &.shortage {
          border-color: map-get($border-universal-color, 'желтый');
        }

        &.active {
          background: #5f5f5f;
        }

        .wrapper_rows_data {
          display: grid;
          grid-column-gap: 1em;
          justify-items: center;
          align-items: center;

          %grid-row-gap-05 {
            grid-column-gap: 0.5em;
            grid-row-gap: 0.5em;
            //align-items: baseline;
            display: grid;
            height: 100%;
          }

          .wrapper_grid_row {
            display: grid;
            grid-template-columns: 23% auto;
            grid-column-gap: 1em;
            @extend %grid-row-gap-05;
            width: 100%;

            .wrapper_0 {
              @extend %grid-row-gap-05;
            }

            .wrapper_1 {
              @extend %grid-row-gap-05;
            }

            .wrapper_1_1 {
              @extend %grid-row-gap-05;
              grid-template-columns: 1fr auto;
            }

            .wrapper_1_1_1 {
              @extend %grid-row-gap-05;
              grid-template-columns: 1fr 33%;
              align-items: end;
              justify-items: end;
              width: 100%;
            }
            .wrapper_1_1_2{
              display: grid;
              align-items: end;

              .card_arrow {
              }
              .activeArrow {
                transform: translateY(10px) rotate(90deg);
              }
            }

            @at-root .small_grey {
              font-size: 0.85em;
              color: #999999;
            }

            .number_of_positions {
              justify-self: left;
              width: 100%;
              text-align: left;
              word-break: break-word;
            }
            .total_price{
              height: 100%;
              display: grid;
              align-content: end;
            }

            .ACCOUNT_NUMBER {
              color: #f7ee89;
              font-size: 1.1em;
              word-break: break-all;
            }

            .namecontractor {
              word-break: break-word;
              text-align: left;
              color: burlywood;
            }

            .upd_number {
              font-size: 0.8em;
              word-break: break-word;
            }

            .datecreate {
              font-size: 0.8em;
            }

            @at-root .column_3 {
              .v-input--selection-controls {
                margin: unset;
                padding: unset;

                &.v-input--is-label-active {
                  background: white;

                  &.v-input--is-disabled {
                    background: unset;
                  }
                }

                .v-input__slot {
                  margin: unset;

                  .v-input--selection-controls__input {
                    margin: unset;
                  }
                }

                .v-icon {
                  color: #00a0e3 !important;
                  font-size: 36px;
                }

                .v-messages {
                  display: none;
                }
              }
            }
          }
        }

        %universal_block_step {
          display: grid;
          margin: 1em 0;
          background: $background_detail_page;
          border-radius: 10px;
        }

        .list_products_position {
          width: 100%;
          @extend %universal_block_step;

          .row_product {
            display: grid;
            grid-template-columns: 5% 50% 25% auto;
            align-items: center;
            @extend %padding-row-position;


            &:hover {
              @extend %hover-background-light;

              .EDITABLE_QUANTITY {
                color: black !important;

                ::before{
                  border-color: black !important;
                }
              }
            }

            &.active {
              opacity: 0.2;
            }

            .column {
              display: grid;
              text-align: center;

              &.column_1 {
                text-align: left;
                margin-left: 1em;
                word-break: break-word;
              }

              &.column_2{
                .EDITABLE_QUANTITY{
                  font-size: 1em;
                  margin-top: 0;
                  padding-top: 0;
                  padding-bottom: 5px;
                }
              }

              &.column_3 {
                justify-content: center;
                margin-left: 16px;
              }
            }
          }
        }

        .mini_chat {
          display: grid;
          width: 100%;
          grid-template-columns: auto;
          grid-column-gap: 2.5em;

          .v-textarea {
            .v-label--active {
              transform: translateY(-35px) scale(0.75);
              z-index: 0;
            }

            &.commentary_production {
              margin-top: 1.5em;
            }
          }
        }

        .wrapper_button {
          @extend %universal_block_step;
          background: unset;
          grid-template-columns: auto;
          grid-column-gap: 2em;
          grid-row-gap: 10px;
          width: 100%;
          @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            //grid-template-columns: auto;
          }
        }

        button {
          font-size: 0.8em;
          color: white;
          white-space: normal;
          line-height: inherit;
          padding: 5px;

          &#accept_receipt {
            margin-bottom: 1em;
          }
        }
      }
    }

    .v-card__title.headline {
      word-break: break-word;
    }

    .btn_ok_dialog {
      color: white;
      display: grid;
      width: 100%;
    }

    @media (max-width: 500px) {
      & {
        font-size: 14px;
      }
    }

  }
}


</style>
