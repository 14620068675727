<template>
  <div
    class="wrapper_render_info_product"
    v-if="nomenclature_data && Object.keys(nomenclature_data).length"
  >
    <!--      <div class="wrapper_block_contractor">
        <div class="wrapper_block_contractor__producer">
        &lt;!&ndash; <p>Производитель:</p> &ndash;&gt;
          <v-autocomplete
              label="Производитель"
              hide-details
              dense
              dark
              :items="listContractors.DATA"
              :loading="loading"
              :disabled="PRODUCER !== null && !!Object.keys(PRODUCER).length"
              clearable
              item-text="PERSON_NAME"
              v-model="PRODUCER"
              return-object
              :search-input.sync="contractor_search_producer"
              @update:search-input="updateContractor('producer')"
              @change="() => {changeContractorBlock({
            'id_elem': $store.state.NomenclatureModal.ID, 'code_prop':'PRODUCER', 'value_prop': PRODUCER ? PRODUCER.CUSTOM_CONTRACTOR_ID : null
            })}"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
          >
            <template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>
          </v-autocomplete>
        </div>
        <div class="wrapper_block_contractor__default">
          &lt;!&ndash; <p>Поставщик по умолчанию:</p> &ndash;&gt;
          <v-autocomplete
              label="Поставщик по умолчанию"
              hide-details
              dense
              dark
              :items="listContractors.DATA"
              :loading="loading"
              :disabled="CUSTOM_CONTRACTOR !== null && !!Object.keys(CUSTOM_CONTRACTOR).length"
              clearable
              item-text="PERSON_NAME"
              return-object
              v-model="CUSTOM_CONTRACTOR"
              :search-input.sync="contractor_search_custom_contractor"
              @update:search-input="updateContractor('default')"
              @change="() => {changeContractorBlock({
            'id_elem': $store.state.NomenclatureModal.ID, 'code_prop':'CONTRACTORS', 'value_prop': CUSTOM_CONTRACTOR ? CUSTOM_CONTRACTOR.CUSTOM_CONTRACTOR_ID : null
            })}"
              :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
          >
            <template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>
          </v-autocomplete>
        </div>
      </div>-->

    <detail-contractors-selectors v-if="!checkMobileParent" />

    <detail-nomenclature-card
      v-if="nomenclature_data !== null && Object.keys(nomenclature_data).length"
      :nomenclature_data="nomenclature_data"
      :showChilds="showChilds"
      style="margin: 0 15px"
    />

    <div class="wrapper_block_buttons">
      <v-btn color="primary" @click.prevent="addNewOrder()">
        Создать заказ-наряд
      </v-btn>
      <v-btn
        color="primary"
        :disabled="Object.keys(nomenclature_data).length === 0"
        @click="$store.state.data_take_product_on_store.state_v_show = true"
        :loading="
          $store.state.printing_file || $store.state.loading_nomenclature
        "
        id="printing_label"
      >
        Принять на склад
      </v-btn>
      <v-btn
        color="secondary"
        :disabled="Object.keys(nomenclature_data).length === 0"
        @click="print_layout_nomenclature = true"
        :loading="
          $store.state.printing_file || $store.state.loading_nomenclature
        "
        id="printing_label"
      >
        Печать этикетки
      </v-btn>
      <v-btn
        color="secondary"
        :disabled="Object.keys(nomenclature_data).length === 0"
        @click="print_bar_code_nomenclature = true"
        :loading="
          $store.state.printing_file || $store.state.loading_nomenclature
        "
        id="printing_barcode_label"
      >
        Печать штрихкода
      </v-btn>
      <v-btn
        color="secondary"
        :disabled="true"
        id="actual_availability"
        class=""
        >Фактическое наличие</v-btn
      >
    </div>

    <!-- Компонент печати ЗН -->
    <layout-nomenclature
      v-show="false"
      v-if="print_layout_nomenclature && !!nomenclature_data"
      :detail-nomenclature="nomenclature_data"
      :id_prod_from_parent="nomenclature_data.ID"
    >
    </layout-nomenclature>

    <!-- Компонент печати штрих-кода ЗН-->
    <layout-bar-code
        v-show="false"
        v-if="print_bar_code_nomenclature && !!nomenclature_data"
        :detail-nomenclature="nomenclature_data"
        :id_prod_from_parent="nomenclature_data.ID"
    >
    </layout-bar-code>

    <take-to-store
      v-if="!!nomenclature_data"
      :nomenclature_data="nomenclature_data"
    ></take-to-store>
    <!--      <add-new-order-->
    <!--          v-if="$store.state.data_new_order.state_v_show"-->
    <!--          @listen-child-state="$store.state.data_new_order.state_v_show = false"-->
    <!--      />-->
  </div>
</template>

<script>
import ListFiles from '../ListFiles'
import LayoutNomenclature from './LayoutNomenclature'
import LayoutBarCode from './LayoutBarCode'
import TakeToStore from '../TakeToStore'
import DetailNomenclatureCard from './DetailNomenclatureCard'
import DetailContractorsSelectors from './DetailContractorsSelectors'

import { isMobile } from 'mobile-device-detect'

export default {
  name: 'NomenclatureCardProduct',
  components: {
    DetailContractorsSelectors,
    DetailNomenclatureCard,
    ListFiles,
    LayoutNomenclature,
    TakeToStore,
    LayoutBarCode
  },
  props: ['nomenclature_data'],
  data: () => ({
    print_layout_nomenclature: null,
    print_bar_code_nomenclature: false,
    listContractors: {
      DATA: [],
      COUNT: {
        NUM_PAGE: 1,
      },
    },
    debounceTimeout: null,
    debounceTimeoutSearch: null,
    checkMobileParent: false,
    /* newData */
    heightRelation: 0,
    heightByDefault: 0,
    showChilds: false,
  }),
  created() {
    this.checkMobileParent = isMobile
  },
  mounted() {
    this.$store.state.NomenclatureModal = this.nomenclature_data
  },
  watch: {
    '$store.state.printing_file': {
      handler: function watch() {
        console.log('watch $store.state.printing_file')
        console.log(this.$store.state.printing_file)
        if (this.$store.state.printing_file === false) {
          this.print_layout_nomenclature = false
          this.print_bar_code_nomenclature = false
        }
      },
    },
    nomenclature_data: {
      handler() {
        this.$store.state.NomenclatureModal = this.nomenclature_data
      },
    },
  },
  computed: {},
  methods: {
    /* OLD FUNCTIONS */
    closeSave() {
      this.$store.state.dialog_nomenclature_window = false
    },
    addNewOrder() {
      this.$store.state.data_nomenclature_from_plus = Object.assign(
        {},
        this.nomenclature_data,
      )
      this.$store.state.data_new_order.state_v_show = true
    },
  },
  beforeDestroy() {
    this.$store.state.NomenclatureModal = null
  },
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

@import '../../assets/sass/main';

%margin-left-right {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.wrapper_render_info_product {
  width: 100%;
  margin-top: 20px;
  color: white;
  font-size: 16px;
  @media (min-width: 450px) {
    & {
      font-size: 18px;
    }
  }
  @media (min-width: 800px) {
    & {
      font-size: 20px;
    }
  }

  display: flex;
  flex-direction: column;

  &__card {
    display: grid;
    grid-template-columns: 75% auto;
    background: #ffffff;
    border: 2px solid #c3cfd9;
    //#d2dbe3
    border-radius: 5px;
    color: black;
    padding: 10px 15px;
    cursor: pointer;

    &__left {
      display: grid;
      grid-template-columns: 20% auto;
      align-items: center;

      &__img {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 80px;
        max-width: 100%;

        img {
          width: 80%;
          height: 100%;
          margin: 0 auto;
          object-fit: contain;
          border-radius: 5px;
          background-color: #ffffff;
        }
      }
      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        font-size: 1.15em;

        &__firstBlock {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &__article {
            color: #207868;
            font-size: 1.2em;
          }
          &__contractor {
            text-transform: uppercase;
            color: #2c88d9;
            font-size: 0.8em;
          }
        }
        &__secondBlock {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          column-gap: 25px;

          &__name {
            color: #838f9c;
            font-size: 0.8em;
            text-align: left;
            word-break: break-all;
          }
          &__cost {
            font-size: 0.65em;
            color: #207868;
            min-width: 70px;
            padding-top: 4px;
            text-align: right;
          }
        }

        &__name {
          color: #293845;
          text-align: left;
          word-break: break-all;
        }
        &__article {
          color: #84939f;
        }
      }
    }
    &__right {
      display: grid;
      grid-template-columns: 30% auto;
      align-items: center;
      justify-items: end;

      &__count {
        font-size: 52px !important;
        color: #293845;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        &__block {
          font-size: 0.7em;
          width: 100%;
          display: grid;
          grid-template-columns: auto 16%;
          column-gap: 10px;
          justify-items: end;
          align-items: center;

          header {
            color: #838f9c;
          }
          span {
            font-size: 1.15em;
          }
        }

        &__cost_price {
          color: #338cda;
          font-size: 1.15em;
          text-align: right;
        }
        &__buttons {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          column-gap: 15px;

          &__countSubItems {
            font-size: 1.6em;
          }
        }
      }
    }

    .blockChild {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }

  //display: grid;
  //grid-template-rows: repeat(5, auto);
  //grid-row-gap: 20px;
  /* OLD CSS-STYLES */
  .name_prod {
    @extend %margin-left-right;
    color: $caption_yellow_color;
  }

  .wrapper_block_tech_info {
    @extend %margin-left-right;
    display: grid;
    /*grid-template-columns: 1fr 1fr;*/
    /*justify-items: center;*/
    font-size: 0.9em;
    grid-row-gap: 5px;

    .articul,
    .drawing_number,
    .name,
    .type_product {
      display: grid;
      grid-template-columns: 36% 1fr;
      justify-items: baseline;
      grid-column-gap: 1em;
      text-align: left;

      header {
        color: $light_grey_header;
      }

      span {
        font-weight: 500;
      }
    }
  }

  .wrapper_block_contractor {
    display: flex;
    @extend %margin-left-right;
    justify-content: space-between;
    margin-bottom: 20px;
    &__producer {
      width: 45%;
    }
    &__default {
      width: 45%;
    }
  }

  .wrapper_block_image_info {
    display: grid;
    grid-column-gap: 1em;
    /*grid-template-columns: 1fr 20%;*/
    padding: 20px 15px;
    border-top: 2px solid $border_section_nomenclature;
    border-bottom: 2px solid $border_section_nomenclature;
    background: $background_main_order_block;

    .preview_prod {
      img {
        width: auto;
        height: auto;
        /*min-height: 225px;*/
        border-radius: 10px;
        max-width: 300px;
        max-height: 100px;
      }
    }
    .block_icons {
      width: 100%;
      justify-content: center;
      margin: 0 0 1em 0;
      display: flex;
    }
  }

  .wrapper_full_with_border {
    border-bottom: 2px solid $border_section_nomenclature;
    padding-bottom: 20px;

    .wrapper_block_quantity_info {
      @extend %margin-left-right;
      display: grid;
      grid-row-gap: 5px;
      font-size: 0.9em;
      text-align: left;

      section {
        display: grid;
        grid-template-columns: 1fr auto;

        header {
          color: $light_grey_header;
        }

        span {
          font-weight: 500;

          &.red_lights_quantity {
            color: $red_lights_quantity;
          }

          &.green_lights_quantity {
            color: $green_lights_quantity;
          }
        }

        &.fact_quanitity {
          span#fact_quanitity_value {
            font-size: 1.4em;
          }
        }
      }
    }
  }

  .wrapper_list_order_for_nomenclature {
    display: grid;
    background: #454444;
    header {
      display: grid;
      grid-template-columns: 1fr auto;
      padding: 1em;
      border-bottom: 2px solid black;
      label {
        cursor: pointer;
      }
      .v-icon {
        color: #5a9edf;
        font-size: 1.5em;
        transition: 0.8s all;
        &.collapse {
          transform: rotate(180deg);
        }
      }
    }
  }

  .wrapper_block_buttons {
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 16px;
    @extend %margin-left-right;
    margin: 20px 0;

    button {
      background: $button_color;
      border-color: $button_color;
      margin-left: unset;
    }
  }
}
</style>
