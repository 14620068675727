<template>
    <v-dialog
        v-model="$store.state.data_take_product_on_store.state_v_show"
        max-width="600px"
        @click:outside="()=> { $store.dispatch('ClearCustomAlert'); }"
        content-class="take_to_store_container"
    >
      <!-- Оверлей -->
      <v-overlay
          :z-index="0"
          :absolute="true"
          :value="$store.state.loading_nomenclature"
      >
        <v-progress-circular
            style="margin: auto;"
            width="3"
            height="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
            v-if="loading_data"
        ></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>
          <span class="headline">Перемещение со склада <br>"Промежуточный" на склад <br> "Заготовка"</span>
          <v-icon
              class="close_dialog_cross"
              color="primary"
              elevation="2"
              @click="$store.state.data_take_product_on_store.state_v_show = false"
          >fas fa-times
          </v-icon>
        </v-card-title>
        <v-card-text>

          <div class="inline-flex image_name">
            <img v-if="!!local_nomenclature_data.PICTURE"
                 :src="$store.state.VUE_DEV_LOCAL_SERVER+local_nomenclature_data.PICTURE.SRC"
                 :alt="local_nomenclature_data.PICTURE.TITLE"
            >
            <section class="name">{{ local_nomenclature_data.NAME }}</section>
          </div>

          <div class="inline-flex grid_quantity editable_block">
            <v-text-field
                v-if="!!local_nomenclature_data"
                class="item EDITABLE_QUANTITY_ON_PROM_STORE"
                v-model.number="quantity"
                type="number"
                :rules="rulesEditableQuantity"
                label="Введите кол-во"
                :disabled="local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE <= 0"
                :loading="loading_data"
            >
            </v-text-field>
            <v-btn
                block
                color="primary"
                @click="TakeToStore()"
                :disabled="local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE <= 0"
            >
              Принять
            </v-btn>
          </div>
          <div class="inline-flex grid_quantity">
            <section class="QUANTITY_PRODUCT_ON_PROM_STORE">{{ local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE }}</section>
            <section class="text_helper">Еще не принято <br><span>"Промежуточный склад"</span></section>
          </div>
          <div class="inline-flex grid_quantity">
            <section class="QUANTITY_PRODUCT_ON_ZAGOTOVKA_STORE">{{ local_nomenclature_data.QUANTITY_PRODUCT_ON_ZAGOTOVKA_STORE }}</section>
            <section class="text_helper">Уже принято <br><span>"Заготовок склад"</span></section>
          </div>

        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "TakeToStore",
        components: {},
        props:['nomenclature_data'],
        data: () => ({
          loading_data: false,
          local_nomenclature_data: [],
          quantity: null,
        }),
        watch:{
            /* За измеением основного массива с данными, если меняется пишем отдельно  ид продукции для других компонентов */
            'nomenclature_data':{
                handler: function watch(value) {
                    console.log('watch nomenclature_data');
                    this.local_nomenclature_data = this.nomenclature_data;
                },
            },
        },
        mounted(){
          this.local_nomenclature_data = this.nomenclature_data;
        },
        computed:{
          rulesEditableQuantity () {
            const rules = []
            if (this.local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE) {
              const rule =
                  v => (v <= this.local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE) ||
                      `Максимальное доступное кол-во для перемещения - ${this.local_nomenclature_data.QUANTITY_PRODUCT_ON_PROM_STORE}`
              rules.push(rule)
            }
            const rule = value => (parseInt(value) > 0) || 'Должно быть положительным числом';
            rules.push(rule)

            return rules
          },
        },
        methods:{
            async TakeToStore(){
              const _this = this;

              _this.loading_data = true;

              console.log('TakeToStore');

              /* Битриксовый AJAX */
              BX.ajax.runComponentAction('nk:production.block_nomenclature',
                  'executeTakeToStore', { // Вызывается без постфикса Action
                    mode: 'class',
                    data: {
                      post:{
                        id_prod: _this.local_nomenclature_data.ID,
                        quantity: _this.quantity,
                      },
                      'login': _this.$store.state.login,
                      'token': _this.$store.state.token,
                    },
                  })
                  .then(function(response) {
                    if (response.status === 'success') {
                      console.log('executeTakeToStore');
                      console.log(response.data);

                      /* Счетчик обновления вызовов TakeToStore */
                      _this.$store.dispatch('setCounterTakeToStore');

                      _this.getNomenclature().then(() => {
                            _this.$store.dispatch('setSystemNotificationResult', response.data)
                            _this.loading_data = false;
                      });
                    }
                  })
                  .catch((response) => {
                    console.log(response);
                    _this.loading_data = false;
                  });

            },
            async getNomenclature(){
                const _this = this;

                /* Битриксовый AJAX */
                BX.ajax.runComponentAction('nk:production.block_nomenclature',
                    'executeGetProductById', { // Вызывается без постфикса Action
                        mode: 'class',
                        data: {
                            post:{
                                'id_prod': _this.local_nomenclature_data.ID,
                            },
                            'login': _this.$store.state.login,
                            'token': _this.$store.state.token,
                        },
                    })
                    .then(function(response) {
                        if (response.status === 'success'){
                            console.log('getNomenclature');
                            console.log(response.data);

                            _this.local_nomenclature_data = response.data;
                        }
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../assets/sass/main";

    .take_to_store_container {
      .v-card {
        background: rgb(74, 74, 74) !important;

        .headline {
          font-size: 1.1em !important;
          color: #f7ee89;
          text-align: center;
          width: 100%;
        }

        .v-card__text {
          font-size: 1em;
          grid-row-gap: 1.5em;
          display: grid;
          color: white;
          .image_name {
            grid-template-columns: 1fr 2fr;
            display: grid;
            img{
              width: 100%;
              max-width: 100px;
              margin: auto;
            }
          }
          .grid_quantity{
            grid-template-columns: 50% 50%;
            display: grid;
            .QUANTITY_PRODUCT_ON_ZAGOTOVKA_STORE, .QUANTITY_PRODUCT_ON_PROM_STORE{
              font-size: 1.5em;
            }
            .text_helper{
              font-size: 0.9em;
              span{
                color: darkgrey;
              }
            }
          }
        }
      }
    }

</style>
<style lang="scss">

.take_to_store_container {
  font-size: 16px;
}
.EDITABLE_QUANTITY_ON_PROM_STORE{
  font-size: 1.5em;
  color: white!important;
  ::before{
    border-color: white!important;
    color: inherit!important;
  }
  input{
    margin-top: 8px;
    color: inherit!important;
    text-align: center;
    font-size: 1.2em;
  }
  label{
    color: inherit!important;
  }
  .v-messages{
    text-align: center;
    color: inherit!important;
  }
}

</style>