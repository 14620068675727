export class StatusNomenclature {
    // case 'error': return 'error_message'
    // case 'critical': return 'critical_message'
    // case 'warning': return 'warning_message'
    // case 'info': return 'info_message'
    constructor(detail_text = '', short_text = '', level_importance = 'info', arr_errors = []) {
        this.detail_text = detail_text
        this.short_text = short_text
        this.level_importance = level_importance
        this.left = false
        this.right = true
        this.time = new Date().toLocaleTimeString()
        this.arr_errors = this.parseArrErrors(arr_errors)

        switch (level_importance) {
            case "error":
                this.color = 'lighten-2 red';
                this.left = true;
                break;
            case "critical":
                this.color = 'lighten-2 red';
                this.left = true;
                break;
            case "warning":
                this.color = 'orange';
                this.left = true;
                break;
            case "info":
                this.color = 'primary';
                break;
            default:
                this.color = 'primary';
        }
    }

    parseArrErrors(arr_errors){
        if (! arr_errors.length) return [];

        return arr_errors.map((elem) => elem.detail_text)
    }
}