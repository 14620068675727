import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.min.css";
// import "@mdi/font/scss/materialdesignicons.scss";
import "./assets/sass/main.scss";
import VueHtmlToPaper from "vue-html-to-paper";
import VueQrcodeReader from "vue-qrcode-reader";
export const VERSION = require( './../package.json').version;
export const NAME = require( './../package.json').name;
Vue.prototype.$VERSION = VERSION;
Vue.prototype.$NAME = NAME;
console.log(NAME, "v"+VERSION);
console.log("_____________________________");

Vue.use(VueQrcodeReader);


Vue.config.devtools = true;

/* Завели переменную чтобы стили подгружались и под dev и под prod */
// console.log(process.env.BASE_URL);
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  /* Для прод сборки всегда конкретный адрес до файла где лежат стили для печати, потому что на вкладках nomenclature сбивается путь
   * прим prod: 'http://gidruss-ru/purchase_order/print_layout.css'
   * прим dev: '/print_layout.css'
   * */
  styles: ["/print_style.css", "/print_layout.css", "/print_barcode.css"]
};
Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

new Vue({
  axios,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
