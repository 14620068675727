import Request from '../../services/request'
import Logging from '../../services/logging'
import { StatusNomenclature } from '@/constructors/StatusNomenclature'

export default {
  namespaced: true,
  state: {
    VUE_DEV_LOCAL_SERVER: process.env.VUE_APP_LOCAL_SERVER
      ? process.env.VUE_APP_LOCAL_SERVER
      : '',

    newElement: {
      NAME: '',
      DRAWING_NUMBER: '',
      TYPE: 'detail',
      NOTE: '',
      DRAWINGS: [],
      HASHTAGS: [],
      REAL_NOMENCLATURE: [],
      CHILDREN: [],
    },
    idPlanning: '',
    idParrents: [],
    status_nomenclature: [],

    allHashtags: [],
    allNomenclatures: [],
    viewActiveNomenclatureId: '',

    realNomenclature: {
      id_virtual_nomenclature: '',
      id_real_nomenclature: '',
      listRealNomenclature: [],
    },

    confirmActive: false,
  },
  getters: {
    validationСheckEdit(state) {
      if (state.newElement.NAME && state.newElement.DRAWING_NUMBER) {
        return false
      } else {
        return true
      }
    },
    typeCheck(state) {
      switch (state.newElement.TYPE) {
        case 'detail':
          return 'Деталь'
        case 'operation':
          return 'Операция'
      }
    },
    getElementTypeDetail(state) {
      if (!state.newElement.CHILDREN.length) return []

      return state.newElement.CHILDREN.filter((item) => {
        return item.TYPE === 'detail'
      })
    },
    getElementTypeOperation(state) {
      if (!state.newElement.CHILDREN.length) return []

      return state.newElement.CHILDREN.filter((item) => {
        return item.TYPE === 'operation'
      })
    },
    getActionPage: (state) => (route) => {
      if (route.path.match('edit')) {
        return 'edit'
      } else if (route.path.match('view')) {
        return 'view'
      } else if (route.path.match('create')) {
        return 'create'
      } else {
        return null
      }
    },
  },
  actions: {
    async createNew({ state, commit, dispatch }) {
      state.confirmActive = true
      const params = {
        post: JSON.stringify(state.newElement, null, 2),
      }
      const response = await Request.post(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.VirtualNomenclature`,
        params,
      )
      dispatch('setDetailTextResponse', response.data)
      return response
    },

    async getConcreteNomenclature({ state, commit, dispatch }, id) {
      if (!id) return false

      const response = await Request.request(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.VirtualNomenclature&id_virtual_nomenclature=${id}`,
        null,
        'GET',
      )
      let data = response.data
      if (data.state) {
        commit('GET_CONCRETE_NOMENCLATURE', response.data.data)
        dispatch('getPlanning', id)
        dispatch('getParents', id)
      }
      dispatch('setDetailTextResponse', response.data)

      return data
    },

    async updateConcreteNomenclature({ state, commit, dispatch }, id) {
      state.confirmActive = true
      state.newElement.ID = id
      const params = {
        post: JSON.stringify(state.newElement, null, 2),
      }
      const response = await Request.put(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.VirtualNomenclature`,
        params,
      )

      dispatch('setDetailTextResponse', response.data)

      return response
    },

    async getAllNomenclatures({ state, commit, dispatch }, name) {
      const response = await Request.post(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SearchVirtualNomenclature&search=${name}`,
      )
      commit('GET_ALL_NOMENCLATURES', response.data)

      dispatch('setDetailTextResponse', response.data)

      return response.data
    },

    async getAllHashtags({ state, commit }) {
      const response = await Request.request(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SpecificationController.GetAllDataHashtags`,
        null,
        'GET',
      )
      commit('GET_ALL_HASHTAGS', response.data)
    },

    async getRealNomenclature({ state, commit }, name) {
      const { data } = await Request.post(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.SearchNomenclature&search=${name}`,
      )
      commit('GET_REAL_NOMENCLATURE', data.data)
    },
    async getChildNomenclature({ state, commit }, id) {
      const response = await Request.post(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.GetNestedElementsSpecification&id_virtual_nomenclature=${id}`,
      )
      commit('GET_CHILD_NOMENCLATURE', response.data.data)
    },

    async setRealNomenclatureForVirtual(
      { state, commit },
      { id_real_nomenclature, id_virtual_nomenclature },
    ) {
      const response = await Request.post(
        state.VUE_DEV_LOCAL_SERVER +
          '/bitrix/services/main/ajax.php?mode=class&c=nk:production.list_nomenclature&action=executeSetDefaultRealNomenclatureForVirtual',
        {
          post: {
            id_virtual_nomenclature: id_virtual_nomenclature,
            id_real_nomenclature: id_real_nomenclature,
          },
        },
      )
      console.log('setRealNomenclatureForVirtual', response)
      return response
    },

    async getPlanning({ state, commit }, id) {
      const response = await Request.request(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf:elementconstruction.api.SignatureController.Signature&id=${id}`,
        null,
        'GET',
      )
      commit('GET_PLANNING', response.data)
    },

    async getParents({ state, commit }, id) {
      const response = await Request.request(
        `${state.VUE_DEV_LOCAL_SERVER}/bitrix/services/main/ajax.php?action=yf%3Aelementconstruction.api.SpecificationController.GetParentsVirtualNomenclature&id_virtual_nomenclature=${id}`,
        null,
        'GET',
      )
      commit('GET_PARENTS', response.data)
    },

    async setDefaultRealNomenclature({ state, commit }, obj) {
      commit('SET_DEFAULT_REAL_NOMENCLATURE', obj)
    },

    async pushRealNomenclature({ state, commit }, obj) {
      const checkExistEntry = () => {
        console.log(state)
        if (!state.newElement.REAL_NOMENCLATURE.length) return false

        return state.newElement.REAL_NOMENCLATURE.some((elem) => {
          return elem.ID === obj.ID
        })
      }
      if (checkExistEntry()) {
        commit(
          'ADD_STATUS_MESSAGE',
          new StatusNomenclature(
            'Вы уже привязали эту номенклатуру - ' + obj['NAME'],
          ),
        )
      } else {
        commit('PUSH_REAL_NOMENCLATURE', obj)
        commit(
          'ADD_STATUS_MESSAGE',
          new StatusNomenclature(
            'Успешно привязано номенклатура - ' + obj['NAME'],
          ),
        )
      }
    },
    async deleteRealNomenclature({ state, commit }, obj) {
      commit('REMOVE_REAL_NOMENCLATURE', obj.ID)
      commit(
        'ADD_STATUS_MESSAGE',
        new StatusNomenclature(
          obj.NAME + ' [' + obj.ARTICUL + '] ',
          'Удалена номенклатура',
        ),
      )
    },
    async pushChildNomenclature({ state, commit }, obj) {
      commit('PUSH_CHILD_NOMENCLATURE', obj)
    },
    async deleteChildNomenclature({ state, commit, dispatch }, obj) {
      commit('DELETE_CHILD_NOMENCLATURE', obj.ID)
      commit(
        'ADD_STATUS_MESSAGE',
        new StatusNomenclature(
          obj.NAME + ' [' + obj.DRAWING_NUMBER + '] ',
          'Удалён виртуальный элемент',
        ),
      )
    },
    async clearFormNomenclature({ commit }) {
      commit('CLEAR_FORM')
    },
    setDetailTextResponse({ commit }, responseData) {
      const { detail_text, short_text, level_importance } =
        responseData.detail_message
      if (short_text) {
        commit(
          'ADD_STATUS_MESSAGE',
          new StatusNomenclature(
            detail_text,
            short_text,
            level_importance,
            responseData.errors,
          ),
        )
      }
    },
  },
  mutations: {
    CREATE_NEW_ELEMENT(state, payload) {
      if (payload.name === 'DRAWINGS') {
        state.newElement.DRAWINGS.push(payload.event)
        return
      }
      state.newElement[payload.name] = payload.event
    },

    /**
     * ADD_STATUS_MESSAGE
     *
     * @returns void
     * @param state
     * @param StatusNomenclature = new StatusNomenclature()
     */
    ADD_STATUS_MESSAGE(state, StatusNomenclature = new StatusNomenclature()) {
      state.status_nomenclature.push(StatusNomenclature)

      state.status_nomenclature.map((elem) => {
        elem.last_item = false
      })
      state.status_nomenclature[state.status_nomenclature.length - 1][
        'last_item'
      ] = true
    },
    GET_CONCRETE_NOMENCLATURE(state, data) {
      state.newElement.NAME = data.NAME
      state.newElement.DRAWING_NUMBER = data.DRAWING_NUMBER
      state.newElement.TYPE = data.TYPE
      state.newElement.DRAWINGS = data.DRAWINGS
      state.newElement.NOTE = data.NOTE !== 'null' ? data.NOTE : ''
      state.newElement.HASHTAGS = data.HASHTAGS
      state.newElement.REAL_NOMENCLATURE = data.REAL_NOMENCLATURE
      state.newElement.CHILDREN = data.CHILDREN
      state.viewActiveNomenclatureId = data.ID
    },
    GET_ALL_NOMENCLATURES(state, payload) {
      state.allNomenclatures = payload.data
    },
    GET_ALL_HASHTAGS(state, payload) {
      state.allHashtags = payload.data.map((hash) => hash.NAME)
    },
    GET_PLANNING(state, payload) {
      state.idPlanning = payload.data
    },
    GET_PARENTS(state, payload) {
      // if (!payload.data.PARENTS) return false
      // if (!payload.data.PARENTS.length) return false
      // if (!payload.data.PARENTS[0].length) return false

      state.idParrents = payload.data.PARENTS
    },
    // GET_PAGES(state, pages) {
    //   console.log(state.newElement.DRAWINGS[1])
    //   state.newElement.DRAWINGS[pages.index].count_pages = pages.countPages
    // },
    CLEAR_ALL_NOMENCLATURES(state) {
      state.allNomenclatures = []
    },
    CLEAR_FORM(state) {
      state.newElement.NAME = ''
      state.newElement.DRAWING_NUMBER = ''
      state.newElement.TYPE = 'detail'
      state.newElement.NOTE = ''
      state.newElement.DRAWINGS = []
      state.newElement.REAL_NOMENCLATURE = []
      state.newElement.CHILDREN = []
      state.newElement.HASHTAGS = []
      state.viewActiveNomenclatureId = ''
      state.idParrents = []
      state.idPlanning = ''
    },
    CLEAR_STATUS(state) {
      state.status_nomenclature = []
    },
    REMOVE_CHIP(state, chip) {
      state.newElement.HASHTAGS.splice(
        state.newElement.HASHTAGS.indexOf(chip),
        1,
      )
    },
    REMOVED_DRAWING(state, payload) {
      let dr = state.newElement.DRAWINGS.filter(
        (d) =>
          // d.ORIGINAL_NAME !== payload.name ? payload.name : payload.ORIGINAL_NAME,
          d.ORIGINAL_NAME !==
          (payload.name !== undefined ? payload.name : payload.ORIGINAL_NAME),
      )
      state.newElement.DRAWINGS = dr
      console.log(dr)
    },
    GET_CHILD_NOMENCLATURE(state, child) {
      state.newElement.CHILDREN = child
    },
    SET_DEFAULT_REAL_NOMENCLATURE(state, obj) {
      const valueCheckbox = obj.IS_DEFAULT_REAL_NOMENCLATURE

      state.newElement.REAL_NOMENCLATURE.map((elem) => {
        elem.IS_DEFAULT_REAL_NOMENCLATURE = false
      })

      state.newElement.REAL_NOMENCLATURE.map((elem) => {
        if (elem.ID === obj.ID) {
          elem.IS_DEFAULT_REAL_NOMENCLATURE = valueCheckbox
        }
      })
    },
    PUSH_REAL_NOMENCLATURE(state, data) {
      state.newElement.REAL_NOMENCLATURE.push(data)
    },
    PUSH_CHILD_NOMENCLATURE(state, data) {
      state.newElement.CHILDREN.push(data)
    },
    DELETE_CHILD_NOMENCLATURE(state, id) {
      state.newElement.CHILDREN = state.newElement.CHILDREN.filter((elem) => {
        return elem.ID !== id
      })
    },

    HANDLE_SELECTED_REAL_NOMENCLATUE(state, data) {
      state.realNomenclature.id_virtual_nomenclature = data.virtual_id
      state.realNomenclature.id_real_nomenclature = data.real_id.ID
    },
    GET_REAL_NOMENCLATURE(state, data) {
      state.realNomenclature.listRealNomenclature = data
    },
    REMOVE_REAL_NOMENCLATURE(state, id) {
      state.newElement.REAL_NOMENCLATURE =
        state.newElement.REAL_NOMENCLATURE.filter((elem) => {
          return elem.ID !== id
        })
    },
    CLEAR_REAL_NOMENCLATURE(state) {
      state.newElement.REAL_NOMENCLATURE = []
    },
    CONFIRM_ACTIVE(state) {
      state.confirmActive = false
    },
    CONFIRM_DEACTIVE(state) {
      state.confirmActive = true
    },
  },
}
