<template>
  <v-card class="wrapper">
    <div data-dz-image class="small_preview">
      <img class="small_preview_img" data-dz-thumbnail alt="">
    </div>
    <div class="menu">
      <img class="closeSmall" data-dz-remove :src="require('../../assets/svg/close.svg')" alt="close">
      <span id="name" data-dz-name></span>
      <span id="size" data-dz-size></span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "layoutSmallDropzone"
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/main";

#name {
  font-size: 0.8rem !important;
  overflow-wrap: break-word;
  word-break: break-all;
}
#size {
  font-size: 0.8rem !important;
}

.small_preview {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 80px;
  max-width: 100%;
  .small_preview_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    background-color: #FFFFFF;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: $background_main_order_block;
  box-shadow: unset !important;

  ::v-deep .dz-image-preview {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .menu {
      opacity: 1;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .menu {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .4s ease-in-out;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
}
</style>
