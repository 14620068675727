var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specification_card__dropzone"},[[_c('div',{staticClass:"text-center show_block-content"},[(!_vm.drawings.length)?_c('div',{staticStyle:{"color":"darkgrey"}},[_vm._v("Чертежей нет")]):_c('v-dialog',{staticStyle:{"z-index":"997"},attrs:{"width":"900","content-class":"drawing-card_dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-item-group',{staticClass:"items_group",model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.drawings),function(drawing,index){return _c('v-item',{key:drawing.ID ? drawing.ID : drawing.UUID,staticStyle:{"width":"200px","height":"282px","position":"relative","overflow":"hidden"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',_vm._g(_vm._b({on:{"click":toggle}},'div',attrs,false),on),[_vm._l((2),function(i){return _c('pdf',{key:i,attrs:{"page":i,"src":drawing.DATA_URL
                      ? drawing.DATA_URL
                      : _vm.$store.state.VUE_DEV_LOCAL_SERVER + drawing.SRC}})}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pdf-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(drawing.ORIGINAL_NAME)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(drawing.ORIGINAL_NAME))])]),(!_vm.is_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pdf-basket",on:{"click":function($event){$event.stopPropagation();return _vm.removedFile(drawing)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ff6666"}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить чертеж")])]):_vm._e()],2)]}}],null,true)})}),1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-carousel',{staticClass:"carousel-dialog_pdf",staticStyle:{"max-width":"900px"},attrs:{"continuous":false,"cycle":_vm.cycle,"show-arrows":false,"hide-delimiter-background":"","delimiter-icon":"mdi-minus"},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.drawings),function(drawing,index){return _c('v-carousel-item',{key:index,staticClass:"carousel-dialog"},[_c('v-card-title',{staticClass:"dialog-pdf_title"},[_c('div',{staticClass:"dialog-pdf_title-name"},[_vm._v(" "+_vm._s(drawing.ORIGINAL_NAME)+" ")]),_c('v-icon',{staticClass:"drawing-icon_close",attrs:{"color":"black","large":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close-circle")])],1),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll",value:(true),expression:"true"}],staticStyle:{"height":"100%","overflow":"scroll","padding-bottom":"104px"},style:({
                  cursor: _vm.dragPdf ? 'grabbing' : 'grab',
                }),on:{"dragscrollmove":function($event){_vm.dragPdf = true},"dragscrollend":function($event){_vm.dragPdf = false}}},_vm._l((drawing.PAGE_COUNT),function(i){return _c('pdf',{key:i,staticStyle:{"margin-top":"48px","transform-origin":"0 0"},style:({
                    transform: ("scale(" + _vm.drawingScale + ")"),
                  }),attrs:{"page":i,"src":drawing.DATA_URL
                      ? drawing.DATA_URL
                      : _vm.$store.state.VUE_DEV_LOCAL_SERVER + drawing.SRC}})}),1)],1)}),1),_c('v-card-actions',{staticClass:"justify-center",staticStyle:{"margin-bottom":"28px"}},[_c('v-btn',{on:{"click":_vm.prev}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-btn',{on:{"click":_vm.zoomIn}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{on:{"click":_vm.zoomOut}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)],1)],1),(!_vm.is_enabled)?_c('div',{staticClass:"specification_card__dropzone__wrapper",staticStyle:{"margin-top":"12px"}},[_c('vue-dropzone',{ref:"myVueDropzone",class:{ dz_height: _vm.drawings.length },attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-file-added":_vm.uploadedData,"vdropzone-success":_vm.vsuccess}},[_c('h3',{staticClass:"dropzone-custom-title"},[_c('v-icon',{staticStyle:{"transform":"rotate(45deg)"},attrs:{"size":"150","color":"grey lighten-1"}},[_vm._v(" mdi-paperclip ")])],1),_c('div',{staticClass:"subtitle",staticStyle:{"color":"darkgrey"}},[_vm._v(" Для вставки чертежа перетащите файл в зону или нажмите на скрепку ")])])],1):_vm._e()],_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error_drop"},[_vm._v("Неправильный формат чертежа")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.success),expression:"success"}],staticClass:"info_drop"},[_vm._v("Успешно загружено")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }