<template>
  <div class="commodity">
    <span>Товарная позиция с артикулом: </span>
    <v-checkbox color="success" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.commodity {
  display: flex;
  color: white;
  align-items: center;
  gap: 20px;
}

.commodity .v-input--selection-controls__input .v-icon {
  color: #d1d1d1;
}
</style>
