<template>
  <div class="specification_card_block">
    <div class="par_search">
      <div class="search_nom search_result">
        <drawing-number
          elementView="input"
          :elementValue="newElement.DRAWING_NUMBER"
          :elementPlaceholder="'Чертёжный номер элемента'"
          class="nameEdit"
          @eventChange="
            (data) => eventDataFunctionUpdate(data, 'DRAWING_NUMBER')
          "
        ></drawing-number>
        <NameNomenclature
          :elementView="'input'"
          :elementValue="newElement.NAME"
          :elementTitle="false"
          class="nameEdit"
          @eventChange="(data) => eventDataFunctionUpdate(data, 'NAME')"
        />
      </div>
    </div>

    <div class="margin_blocks">
      <show-block
        :nameBlock="
          newElement.DRAWINGS.length ? 'Чертежи' : 'Чертежи отсутствуют'
        "
        :showBlock="!!newElement.DRAWINGS.length"
        :numberBlock="newElement.DRAWINGS"
      >
        <Drawings
          :drawings="newElement.DRAWINGS"
          :is_enabled="false"
          @eventChange="(data) => eventDataFunctionUpdate(data, 'DRAWINGS')"
        />
      </show-block>

      <show-block
        :nameBlock="
          newElement.CHILDREN.length
            ? 'Спецификация'
            : 'Спецификация не заполнена'
        "
        :showBlock="!!newElement.CHILDREN.length"
        :numberBlockChild="newElement.CHILDREN"
      >
        <div class="show_block-content">
          <ChildElement :elementsDetailOperation="newElement.CHILDREN" />
        </div>
      </show-block>

      <show-block
        :nameBlock="
          newElement.REAL_NOMENCLATURE.length
            ? 'Номенклатура'
            : 'Номенклатура не привязана'
        "
        :showBlock="!!newElement.REAL_NOMENCLATURE.length"
        :numberBlock="newElement.REAL_NOMENCLATURE"
      >
        <div class="show_block-content view_nom">
          <RealNomenclature :editArray="true" />
        </div>
      </show-block>

      <show-block
        :nameBlock="
          newElement.NOTE.length || newElement.HASHTAGS.length
            ? 'Параметры'
            : 'Параметры не заполнены'
        "
        :showBlock="!!newElement.NOTE.length || !!newElement.HASHTAGS.length"
      >
        <div class="show_block-content show_block-content_params">
          <TypeSpecification
            :activeType="newElement.TYPE"
            @eventChange="(data) => eventDataFunctionUpdate(data, 'TYPE')"
          />
          <CommodityItem />
          <Note
            :elementValue="newElement.NOTE"
            @eventChange="(data) => eventDataFunctionUpdate(data, 'NOTE')"
          />
          <Chips
            :elementValue="newElement.HASHTAGS"
            :elementChips="allHashtags"
          />
        </div>
      </show-block>

      <!-- <show-block
        :nameBlock="idPlanning ? 'Планирование' : 'Планирование не заполнено'"
        :showBlock="true"
      >
        <div class="show_block-content">
          <Planning :elementValue="idPlanning" v-if="idPlanning" />
        </div>
      </show-block> -->
    </div>

    <v-dialog dark v-model="modalEdit" max-width="600">
      <v-card>
        <v-card-title
          style="display: flex; justify-content: center; align-items: center"
        >
          <span class="text-h7" style="word-break: break-word"
            >Покинуть текущую страницу?
            <p>Все текущие изменения будут утеряны</p></span
          >
        </v-card-title>
        <v-card-actions>
          <v-btn block color="grey" @click="modalEdit = false"> Отмена </v-btn>
          <v-btn block color="primary" @click="confirmationTransition()"
            >Ок</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import DrawingNumber from '@/components/specification/DrawingNumber.vue'
import NameNomenclature from '../../components/specification/NameNomenclature.vue'
import Note from '../../components/specification/Note.vue'
import Drawings from '../../components/specification/Drawings.vue'
import Chips from '../../components/specification/Chips.vue'
import RealNomenclature from '../../components/specification/RealNomenclature.vue'
import TypeSpecification from '../../components/specification/TypeSpecification.vue'
import ChildElement from '../../components/specification/ChildElement.vue'
import ShowBlock from '../../components/specification/ShowBlock.vue'
import Planning from '../../components/specification/Planning.vue'
import CommodityItem from '../../components/specification/CommodityItem.vue'

export default {
  name: 'SpecificationEdit',
  beforeRouteLeave(to, from, next) {
    if (this.confirmActive) {
      next()
      return
    }
    this.nextPage = next
    this.modalEdit = true
  },
  components: {
    DrawingNumber,
    NameNomenclature,
    Note,
    Drawings,
    Chips,
    RealNomenclature,
    TypeSpecification,
    ChildElement,
    ShowBlock,
    Planning,
    CommodityItem,
  },
  data() {
    return {
      componentKey: 0,
      modalEdit: false,
      nextPage: '',
    }
  },
  computed: {
    ...mapState('specificationStore', [
      'allNomenclatures',
      'newElement',
      'allHashtags',
      'confirmActive',
      'IdPlanning',
    ]),
    ...mapGetters('specificationStore', ['validationСheckEdit']),
  },
  methods: {
    ...mapMutations('specificationStore', {
      clearConcrete: 'CLEAR_CONCRETE',
      createNewElement: 'CREATE_NEW_ELEMENT',
      confirmActivated: 'CONFIRM_ACTIVE',
      confirmDeactivated: 'CONFIRM_DEACTIVE',
    }),
    ...mapActions(['setSimpleModal']),
    ...mapActions('specificationStore', [
      'getConcreteNomenclature',
      'getAllNomenclatures',
      'getAllHashtags',
    ]),

    eventClearFunction() {
      this.$router.push({
        name: 'SpecificationView',
      })
      this.clearConcrete()
    },

    backToView() {
      this.$router.push(`/specification/view/${this.$route.params.id}`)
    },

    activeBeSearch() {
      this.activeSearch = !this.activeSearch
    },

    eventFunction(data) {
      if (!data || data.trim() == '') {
        return
      }
      this.getAllNomenclatures(data)
    },

    eventDataFunctionUpdate(data, name) {
      this.createNewElement({
        name: name,
        event: data,
      })
    },

    confirmationTransition() {
      this.modalEdit = false
      this.nextPage()
    },
  },
  mounted() {
    this.componentKey += 1
    this.getConcreteNomenclature(this.$route.params.id).then((res) => {
      if (res.state === false) {
        this.confirmDeactivated()
        this.$router.push({ name: 'SpecificationView' })
      }
    })
    this.getAllHashtags()
    this.confirmActivated()
  },
}
</script>

<style lang="scss">
// @import 'src/assets/sass/main';
// @import 'src/assets/sass/specification';
// @import 'src/assets/sass/detailSpecification';

.search_nom .v-text-field__details {
  display: block !important;
}

.search_edit {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 4px;
}

.search_nom {
  .specification_card__header__title {
    span {
      display: none !important;
      padding: 0;
    }
  }
}

.search_result {
  align-items: start;
}

.btns-create {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  background: #cb0605;
}

.v-card__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  padding: 16px 24px !important;
  .v-btn {
    max-width: 50px;
  }
}

.view_nom {
  & .v-text-field__details {
    display: none;
  }
}
</style>
