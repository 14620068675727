<template>
  <div class="real">
    <div>
      <div
        class="specification_card__real_nomenclature_content__title"
        v-if="realNomenclatureArr.REAL_NOMENCLATURE.length"
      >
        <span
          class="specification_card__real_nomenclature_content__title__text"
        >
          Привязка к номенклатуре:
        </span>
      </div>

      <div
        class="specification_card__real_nomenclature_content__items"
        v-if="realNomenclatureArr.REAL_NOMENCLATURE.length"
      >
        <detail-nomenclature-card
          v-for="(item, indexItem) in realNomenclatureArr.REAL_NOMENCLATURE"
          :key="item.ID"
          :nomenclature_data="item"
          :index="currentId"
          :specification_child="true"
          :nomenclature_parent="currentId"
          :disableAbsolute="true"
          style="font-size: 18px"
        />
      </div>
      <div
        v-if="$route.name !== 'SpecificationView'"
        class="specification_card__real_nomenclature_content__search"
      >
        <DrawingNumber
          elementView="select"
          class="btn_real"
          :elementPlaceholder="'Поиск номенклатуры'"
          @eventChange="eventFunction"
          @eventData="eventFunctionData"
          ref="localDrawingNumber"
          :itemsSelect="realNomenclature.listRealNomenclature"
        />
        <v-btn
          color="primary"
          :disabled="activeBtn"
          @click="addRealNomenclature"
        >
          Добавить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import DetailNomenclatureCard from '../nomenclature/DetailNomenclatureCard.vue'
import SearchByRealNomenclature from './SearchByRealNomenclature.vue'
import DrawingNumber from './DrawingNumber.vue'

export default {
  name: 'RealNomenclature',
  components: {
    SearchByRealNomenclature,
    DetailNomenclatureCard,
    DrawingNumber,
  },
  props: {
    is_enabled: Boolean,
    currentId: 0,
    realNomenclatureList: Array,
    editArray: Boolean,
    elementTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRealNomenclature: {},
      activeBtn: true,
    }
  },
  computed: {
    ...mapState('specificationStore', {
      realNomenclatureArr: 'newElement',
      realNomenclature: 'realNomenclature',
    }),
  },
  methods: {
    ...mapActions('specificationStore', [
      'getConcreteNomenclature',
      'setDefaultRealNomenclature',
      'pushRealNomenclature',
      'getRealNomenclature',
    ]),
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
    }),
    async addRealNomenclature() {
      if (this.editArray) {
      }

      const makeUniq = (arr) => [...new Set(arr)]

      await this.pushRealNomenclature(this.selectRealNomenclature)
      this.$refs.localDrawingNumber.clearFunc()
    },

    eventFunction(data) {
      this.getRealNomenclature(data)
    },
    eventFunctionData(data) {
      if (typeof data !== 'object' || data === null) {
        this.activeBtn = true
      } else {
        this.activeBtn = false
        this.selectRealNomenclature = data
      }
    },
  },
  watch: {},
}
</script>

<style lang="scss">
.btn_real {
  .standard_title {
    padding: 0;
  }
}

.operation_card__buttons {
  .curly {
    column-gap: 10px !important;
  }
}

@media screen and (max-width: 600px) {
  .specification_card__real_nomenclature_content__search {
    display: flex !important;
    flex-direction: column;

    & button {
      width: max-content;
      display: flex;
      margin-left: auto;
      margin-top: 8px;
    }
  }
}
</style>

<!-- width: max-content;
display: flex;
margin-left: auto;
margin-top: 8px; -->
