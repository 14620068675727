<template>
  <div class="list-purchase-order">
    <!-- Блок фильтров -->
    <div id="" class="invisible block_filters_universal"
         v-show="$store.state.state_block_filters && !!state_visible_all_row"
         v-if="Object.keys($store.state.data_production).length !== 0"
    >

      <div class="block_filter">
        <p>Текстовый поиск:</p>
        <live-search @listen-data-prod="(data) => { this.data_filters.id_prod = (!!data) ? data.ID : null }" style="margin-bottom: 1em"></live-search>
      </div>

      <div class="block_filter">
        <p>Категория продукции:</p>
        <v-autocomplete :items="$store.state.data_production.ARR_FILTER_LIST_CATEGORY"
                        :loading="!$store.state.data_production.ARR_FILTER_LIST_CATEGORY"
                        solo dense clearable
                        item-value="id" item-text="text"
                        label="Все"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        v-model="data_filters.category">
        </v-autocomplete>
      </div>

      <div class="block_filter">
        <p>Этап производства:</p>
        <v-autocomplete class="" name="arr_filter_stage_of_production" id="arr_filter_stage_of_production"
                        solo dense clearable
                        label="Все"
                        item-value="ID" item-text="DESCRIPTION"
                        :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                        :items="$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION"
                        :loading="!$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION"
                        v-model="data_filters.stage_of_production"
        >
        </v-autocomplete>
      </div>

      <div class="block_filter wrapper_open_closed_order">
        <p>Открыт / Закрыт:</p>
        <v-select class="" name="arr_filter_open_closed_order" id="arr_filter_open_closed_order"
                  solo dense clearable
                  :label="$store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_SEE_OPEN_CLOSED_ORDER.CHECK_VALID_USER_GROUP === 'Y' ? 'Все' : 'Открыт'"
                  :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true }"
                  item-value="text" item-text="text"
                  :items="$store.state.data_production.STATE_ORDER_ARR"
                  :loading="!$store.state.data_production.STATE_ORDER_ARR"
                  v-model="data_filters.state_order"
                  v-if="Object.keys($store.getters.getDataDeniedFunctional).length !==0"
                  :disabled="$store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_SEE_OPEN_CLOSED_ORDER.CHECK_VALID_USER_GROUP !== 'Y'"
        >
        </v-select>
      </div>

      <div class="block_filter wrapper_filter_commentary">
        <p>Номер счета:</p>
        <v-text-field
            v-model="data_filters.commentary"
            label="Введите номер счета"
            solo dense hide-details
        ></v-text-field>
      </div>

      <!--      <div class="block_filter wrapper_filter_commentary">
              <hr>
              <p>Поиск по закрепленной группе:</p>
              <v-select class="" name="arr_filter_open_closed_order" id="arr_filter_open_closed_order"
                        solo dense clearable
                        @click:clear="clear_filter('group_user')"
                        label="Все"
                        :menu-props="{closeOnContentClick: true}"
                        item-value="ID" item-text="NAME"
                        :items="$store.state.data_access_user_group_user.LIST_GROUP_USERS"
                        v-model="data_filters.group_user"
                        v-if="Object.keys($store.getters.getDataDeniedFunctional).length !==0"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="data_filters.group_user = 'Все'">
                    <v-list-item-title>
                      Все
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </div>-->
<!--      <div class="container_arrow_open_close_block_filter">-->
<!--        <v-icon-->
<!--            class="mdi_icon_arrow"-->
<!--            @click="$store.state.state_block_filters = !$store.state.state_block_filters"-->
<!--            color="primary"-->
<!--            elevation="2"-->
<!--        >mdi-play-->
<!--        </v-icon>-->
<!--      </div>-->

      <div id="block_error">Идёт загрузка...</div>
<!--      <div class="container_arrow_open_close_block_filter">-->
<!--        <v-icon-->
<!--            class="mdi_icon_arrow"-->
<!--            @click="$store.state.state_block_filters = !$store.state.state_block_filters"-->
<!--            color="primary"-->
<!--            elevation="2"-->
<!--        >mdi-play-->
<!--        </v-icon>-->
<!--      </div>-->
    </div>
    <v-skeleton-loader
        v-else
        v-show="$store.state.state_block_filters"
        class="mx-auto"
        type="card"
    ></v-skeleton-loader>

    <div class="wrapper_rows_grid_table" id="wrapper_rows_grid_table">

      <div class="wrapper_iframe_add_page">
        <div id="layer" v-if="state_iframe_v_show"></div>

        <!-- Добавление нового ЗН -->
        <add-new-order
            v-if="$store.state.data_new_order.state_v_show"
            @listen-child-state="$store.state.data_new_order.state_v_show = false"
            @listen-add-update-order="get_list_order('first_load')"
        >
        </add-new-order>
      </div>

      <div class="wrap_row"
           v-for="order in $store.state.purchase_order.list_purchase_order"
           :id="order.ID_ORDER"
           :key="order.ID_ORDER"
           :class="statusOrder(order)"
      >
<!--        v-show="state_visible_all_row || order.boolean_state_iframe === true"-->
<!-- Удалил с карточек ЗН blur :class="{blur_row: !order.EXIST_AMOUNT_SPECIFICATION && order.boolean_state_iframe === false}" -->
        <div class="wrapper_toggle_detail_order" style="display: contents" @click="toggle_detail_order(order)">
          <progress-stage-production
              style="grid-area: progress_stage_of_production"
              :order="order"
          ></progress-stage-production>

<!--          <div class="wrapper_id_date">-->
            <section
                class="id_order"
                style="grid-area: order_id"
            >
              № {{ order.ID_ORDER }}
            </section>
            <section class="date_create_new_order" style="grid-area: order_date">от
              {{ order.DATE_CREATE_NEW_ORDER }}
            </section>
<!--          </div>-->

<!--          <div class="wrapper_performer_priority" style="grid-area: performer_priority">-->
<!--            <div class="performer_wrapper">-->
<!--              <section>Сварка:</section>-->
<!--              <section>{{ order.ASSIGN_PERFORMER_SVARKA.DATA.FULL_NAME_PERFORMER }}</section>-->
<!--            </div>-->
<!--            <span class="priority" :class="order.PRIORITY"> #{{ order.PRIORITY }}</span>-->
<!--          </div>-->

          <div class="order-image-wrapper" style="grid-area: order_image">
            <img class="order-image-wrapper__img" :src="$store.state.VUE_DEV_LOCAL_SERVER+order.IMG.SRC" :title="order.IMG.TITLE">
          </div>

<!--          <div class="wrapper_name_articul">-->
            <section class="name_prod" :data-id-prod="order.ID" style="grid-area: order_name">{{ order.NAME_PROD }}</section>
            <section class="articul" style="grid-area: order_articul">{{ order.ARTICUL }}</section>
<!--          </div>-->
          <section class="quantity" style="grid-area: quantity">{{ order.NUMBER_OF_POSITIONS_FOR_EXECUTION }}</section>

<!--          <section class="arrow_open_close" :class="{'collapse': order.boolean_state_iframe}"-->
<!--                   :style="'grid-area: arrow_open_close;'+'background-image: url('+$store.state.VUE_DEV_LOCAL_SERVER+'/local/templates/gidruss/static/svg/Стрелка_заказ_наряды.svg);'"-->
<!--          ></section>-->
          <!--<div class="preview_prod" style="grid-area: preview_prod">-->
          <!--<img :src="$store.state.VUE_DEV_LOCAL_SERVER+order.IMG.SRC" :title="order.IMG.TITLE">-->
          <!--</div>-->
        </div>

<!--        <v-btn-->
<!--            v-if="$store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_VISIBLE_PRINT_BUTTON.CHECK_VALID_USER_GROUP === 'Y'"-->
<!--            :loading="print_detail_order === order.ID_ORDER"-->
<!--            icon-->
<!--            color="primary"-->
<!--            class="button_print_detail_order"-->
<!--            style="grid-area: print_detail_order"-->
<!--        >-->
<!--          <v-icon class="print_detail_order"-->
<!--                  :style="'background-image: url('+$store.state.VUE_DEV_LOCAL_SERVER+'/local/templates/gidruss/static/svg/Принтер.svg)'"-->
<!--                  @click="print_detail_order = order.ID_ORDER"></v-icon>-->
<!--        </v-btn>-->


        <!-- Верстка Компонента с назначением исполнителя -->
        <div class="wrapper_assign_performer" style="grid-area: wrap_assign_performer;"
             v-if="!order.ASSIGN_PERFORMER_SVARKA.STATUS && !!order.ASSIGN_PERFORMER_SVARKA.DATA">
          <section class="block_svarka_name">Участок: СВАРКА</section>
          <div class="wrapper_block_norma_vremeni">
            <div class="norma_vremeni_one_piece">
              <section class="norma_vremeni_one_piece_desc">
                Норма времени: 1 изделие
              </section>
              <section class="dots"></section>
              <section class="norma_vremeni_one_piece_amount">

              </section>
            </div>
            <div class="norma_vremeni_svarka">
              <section class="norma_vremeni_svarka_desc">
                Норма времени: сварка
              </section>
              <section class="dots"></section>
              <section class="norma_vremeni_svarka_amount">
                {{ order.NORM_OF_TIME_WELDING.ONE_PIECE }}
              </section>
            </div>
            <div class="norma_vremeni_full_order">
              <section class="norma_vremeni_full_order_desc">
                Норма времени: весь заказ
              </section>
              <section class="dots"></section>
              <section class="norma_vremeni_full_order_amount">
                {{ order.NORM_OF_TIME_WELDING.FULL_ORDER }}
              </section>
            </div>
          </div>

          <v-btn color="secondary" class="btn_assign_svarka"
                 v-if="Object.keys($store.getters.getDataDeniedFunctional).length !==0"
                 :disabled="$store.getters.getDataDeniedFunctional.ASSIGN_PERFORMER.VALID_USER_GROUP.CHECK_VALID_USER_GROUP !== 'Y'"
                 @click="$store.state.performer_for_order.id_order = order.ID_ORDER"
          >Назначить исполнителя
          </v-btn>
        </div>

        <!-- Сам компонент -->
        <set-performer
            :order="order"
            v-if="$store.state.performer_for_order.id_order === order.ID_ORDER"
            class="wrap_assign_performer"
            style="grid-area: wrap_assign_performer"
            @listen-add-update-order="get_list_order('first_load')"
            @listen-child-state="$store.state.performer_for_order.id_order = null"
        ></set-performer>


        <detail-page-order
            style="grid-area: detail_page_order"
            class="iframe_detail_page"
            v-if="cur_id_detail_order === order.ID_ORDER"
            :prop_id_order="cur_id_detail_order"
            :order="order"
            v-show="order.boolean_state_iframe"
            @listen-remove-order="remove_order(order)"
            @listen-add-update-order="get_list_order('first_load')"
        >
        </detail-page-order>


        <!--/*Блок для ЕМ*/-->


      </div>

      <v-overlay
          :z-index="0"
          :absolute="true"
          :value="loading_order"
      >
        <v-progress-circular
            style="margin: auto;"
            width="3"
            id="progress_circular_list_order"
            color="green"
            :indeterminate="true"
        ></v-progress-circular>
      </v-overlay>


      <!--      <v-btn @click="pagination_filtered('next_page')" :disabled="count_data.RECORDS_TO_END <= 0">-->
      <!--        Показать ещё {{ count_data.RECORDS_TO_END }}-->
      <!--      </v-btn>-->

      <v-alert
          v-if="$store.state.purchase_order.list_purchase_order === null"
          type="error"
          text
          class="err-msg"
      >
        Ничего не найдено
      </v-alert>
      <v-btn
          block style="margin-bottom: 10px;"
          v-else-if="!loading_order && !!state_visible_all_row"
          @click='() => { $store.state.purchase_order.count_data.NUM_PAGE++; this.get_list_order(); }'
          :disabled="$store.state.purchase_order.count_data.RECORDS_TO_END <= 0"
      >
        Показать ещё {{ $store.state.purchase_order.count_data.RECORDS_TO_END }}
      </v-btn>
    </div>

    <!-- Компонент печати ЗН-->
    <print-page-order
        v-show="false"
        v-if="print_detail_order"
        :id_order_for_print="print_detail_order"
    >
    </print-page-order>


    <!-- Диалоговое окно с номенклатурой -->
    <v-dialog
        v-model="dialog_nomenclature_window.state"
        max-width="600px"
        content-class="dialog_nomenclature"
    >
      <v-card id="v_dialog_nomenclature_window">
        <v-card-title>
          <span class="headline">Номенклатура</span>
          <v-icon
              class="close_dialog_cross"
              color="primary"
              elevation="2"
              @click="dialog_nomenclature_window.state = false"
          >fas fa-times
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <nomenclature :dialog_id_order="dialog_nomenclature_window.id_order"></nomenclature>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "../components/HelloWorld.vue";

import DetailPageOrder from "../components/DetailPageOrder";
import AddNewOrder from "../components/AddNewOrder";
import SetPerformer from "../components/SetPerformer";
import CustomFooter from "../components/CustomFooter";
import PrintPageOrder from "../components/PrintPageOrder";
import Nomenclature from "../components/nomenclature/Nomenclature";
import ProgressStageProduction from "../components/ProgressStageProduction";
import LiveSearch from "../components/LiveSearch";

export default {
  components: {
    DetailPageOrder,
    AddNewOrder,
    SetPerformer,
    CustomFooter,
    PrintPageOrder,
    Nomenclature,
    ProgressStageProduction,
    LiveSearch,
  },
  name: "List_purchase_order",
  data: () => ({
    loading_order: false,
    cur_id_detail_order: null,
    // state_block_filters: false,
    state_visible_all_row: true,
    currentPage: 1,
    count_data: {},
    computed_arr_data_order: [],
    queryFilter: {},
    data_filters: {
      nomenclature_search: '',
      category: null,
      id_prod: null,
      stage_of_production: null,
      state_order: null,
      commentary: null,
      // group_user: null
    },
    shortNameForURL: {
      category: 'category',
      stage_of_production: 'stage',
      state_order: 'state'
    },
    print_detail_order: null,
    state_dialog_window: false,
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
  }),
  mounted() {
    // Проверяем URL на query
    this.initialValue()

    /* Проверяем на пустоту массива списка ЗН, если пустой запрашиваем */
    if (
        (this.$store.state.purchase_order.list_purchase_order === null)
        ||
        (this.$store.state.purchase_order.list_purchase_order.length === 0)
        ||
        (Object.keys(this.$store.state.data_access_user_group_user).length !== 0)
    ) {
      this.get_list_order('first_load');
    }
    //   /* Проверяем на пустоту массива Общих данных, если пустой запрашиваем */
    // if ((this.$store.state.data_production.length === 0) && (this.$store.state.try_set_data_production_state === false)){
    //       const main_vue_component = this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
    //       main_vue_component.get_data_production();
    //   }
  },
  created() {
  },
  filters: {},
  watch: {
    '$store.state.data_take_product_on_store.counter_call': {
      handler: function watch() {
        this.get_list_order('first_load');
      }
    },
    '$store.state.printing_file': {
      handler: function watch() {
        console.log('watch $store.state.printing_file');
        if (this.$store.state.printing_file === false) {
          this.print_detail_order = ''
        }
      },
    },
    // cur_id_detail_order:{
    //     handler: function watch() {
    //         this.toggle_detail_order(order)
    //     },
    // },
    /* Смотрим за фильтрами чтобы отфильтровать отпагинировать */
    data_filters: {
      handler: function watch() {
        // При изменении вызываем цепочку для изменения URL
        this.filtersToURL()
        this.get_list_order('first_load');
      },
      deep: true
    },
    /* Смотрим на ограничением, которое тригерит watch на пагинацию фильтрацию */
    '$store.state.data_production': {
      handler: function watch() {
        console.log('watch $store.state.data_production');
      },
      deep: true
    },
      '$store.getters.getDataDeniedFunctional': {
      handler: function watch() {
        console.log('watch $store.getters.getDataDeniedFunctional');
        if (this.$store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_SEE_OPEN_CLOSED_ORDER.CHECK_VALID_USER_GROUP !== 'Y') {
          // this.data_filters.state_order = 'Открыт';
          // console.log('AGA');
          // console.log(this.data_filters.state_order);
        }
      },
      deep: true
    },
  },
  computed: {
    state_iframe_v_show() {
      // window.scrollTo(0, 0);
      return this.$store.state.data_new_order.state_v_show;
    },
  },
  methods: {
    // Проверяем URL на query
    initialValue() {
      if (Object.keys(this.$route.query).length) {
        for (let key in this.$route.query) {
          for (let fullName in this.shortNameForURL) {
            if (key === this.shortNameForURL[fullName]) {
              this.data_filters[fullName] = this.$route.query[key];
              this.$store.state.state_block_filters = true;
            }
          }
          if (this.$route.query.scenario) {
            this.$store.state.data_new_order.state_v_show = true;
          }
        }
      }
      this.$store.commit('changeShouldUpdateUrl', false)
    },
    filtersToURL() {
      if (Object.keys(this.data_filters).length) {
        let queryObj = {}
        if (Object.keys(this.$route.query).length) {
          for (let key in this.$route.query) {
            this.queryFilter = Object.assign(queryObj, {[key]: this.$route.query[key]})
          }
        }
        for (let key in this.data_filters) {
          if (key !== 'id_prod' && key !== 'commentary' && key !== 'nomenclature_search') {
            if (this.data_filters[key] !== null) {
              this.queryFilter = Object.assign(queryObj, {[this.shortNameForURL[key]]: this.data_filters[key]})
            } else {
              delete this.queryFilter[this.shortNameForURL[key]]
            }
          }
        }
        console.log(this.queryFilter)
        this.$store.commit('changeShouldUpdateUrl', true)
        this.pushRouter()
      }
    },
    // Изменяем URL
    pushRouter() {
      this.$store.commit('updateURL_components', this.queryFilter)
    },
    statusOrder(order) {
      return {
        red_border: (order.EXIST_AMOUNT_SPECIFICATION === 0),
        yellow_border: (order.EXIST_AMOUNT_SPECIFICATION === 1),
        green_border: (order.STATE_ORDER === 'Закрыт'),
      }
    },
    open_dialog_nomenclature(order_id) {
      this.dialog_nomenclature_window.id_order = order_id;
      this.dialog_nomenclature_window.state = true;
    },
    check_incoming_url_parameters() {
      if (!!this.$route.query.id_order) {
        const cur_obj_po = this.$store.state.purchase_order.list_purchase_order.filter(elem => elem.ID_ORDER === this.$route.query.id_order)[0];
        if (!!cur_obj_po) {
          this.$nextTick(() => {
            this.cur_id_detail_order = cur_obj_po.ID_ORDER;
            cur_obj_po.boolean_state_iframe = true;
            this.state_visible_all_row = false;
            // document.getElementById(cur_obj_po.ID_ORDER).scrollIntoView({
            //     behavior: 'instant',
            //     block: 'start'
            // })
          });
        }
      }
    },
    remove_order(order) {
      this.state_visible_all_row = true;
      order.boolean_state_iframe = false;
      this.get_list_order('first_load');
    },
    // clear_filter(name_data_vmodel){
    //     this.$nextTick(() => {
    //         this.data_filters[name_data_vmodel] = 'Все';
    //         console.log( this.data_filters[name_data_vmodel] );
    //     });
    // },
    toggle_detail_order(order) {
      if (!!order) {
        this.cur_id_detail_order = order.ID_ORDER;
        order.boolean_state_iframe = !order.boolean_state_iframe;
        this.state_visible_all_row = !this.state_visible_all_row

        // console.log(order.ID_ORDER)
        // document.getElementById(order.ID_ORDER).scrollIntoView({
        //   behavior: 'instant',
        //   block: 'start',
        // })
        // window.scrollBy(0, -50);

        if (order.boolean_state_iframe) {
            this.$router.replace({
              query: {
                id_order: order.ID_ORDER
              }
            });
        } else {
            this.$router.replace({ query:{} }).then(() => {
              this.get_list_order('first_load');
            })
        }
      }
    },

    // pagination_filtered(){
    //     /* Сортировка */
    //     this.$store.state.purchase_order.list_purchase_order.sort( (a, b) =>
    //         b.EXIST_AMOUNT_SPECIFICATION - a.EXIST_AMOUNT_SPECIFICATION
    //         ||
    //         new Date(b.DATE_CREATE_NEW_ORDER_FRONT_SORT) - new Date(a.DATE_CREATE_NEW_ORDER_FRONT_SORT)
    //         ||
    //         this.comparePriority(a.PRIORITY) - this.comparePriority(b.PRIORITY)
    //     );
    // },
    load_detail_order_iframe(order) {
      console.log(order.ID_ORDER);
      this.id_order_dynamic_test[order.ID_ORDER] = true;
    },
    async get_list_order(first_load = null) {
      console.log('YAY 2 get_list_order');

      const _this = this;
      _this.loading_order = true;
      // console.log('_this.$store.state.token');
      // console.log(_this.$store.state.token);

      if (!!first_load) {
        _this.$store.state.purchase_order.count_data.NUM_PAGE = 1;
      }
      /* Битриксовый AJAX */
      BX.ajax.runComponentAction('nk:production.list_purchase_order',
          'executeGetListOrder', { // Вызывается без постфикса Action
            mode: 'class',
            data: {
              'post': {
                'num_page': _this.$store.state.purchase_order.count_data.NUM_PAGE,
                'id_order': (!!_this.$route.query.id_order) ? _this.$route.query.id_order : null,
                'category': _this.data_filters.category,
                'id_prod': (!!_this.data_filters.id_prod) ? _this.data_filters.id_prod : null,
                'stage_of_production': _this.data_filters.stage_of_production,
                'state_order': _this.data_filters.state_order,
                'commentary': _this.data_filters.commentary,
              },
              'login': _this.$store.state.login,
              'token': _this.$store.state.token,
            },
          })
          .then(function (response) {
            console.log('then get_list_order response');
            console.log(response);
            if (response.status === 'success') {
              // Если форма успешно отправилась
              // console.log('response.data');
              // console.log(response.data);

              _this.$store.dispatch('SetListPurcahseOrder', response)
                  .then(() => {
                    /* QR код по параметру id_order - как вариант без пагинации весь список ЗН отображаем, включаем нужный*/
                    _this.check_incoming_url_parameters();
                  });
            }

            _this.loading_order = false;
            _this.state_visible_all_row = true;
          })
          .catch((response) => {
            console.log(response);
          });
    },

    // async get_once_order(id_order){
    //     /* Битриксовый AJAX */
    //     BX.ajax.runComponentAction('nk:production.list_purchase_order',
    //         'executeGetListOrder', { // Вызывается без постфикса Action
    //             mode: 'class',
    //             data: {
    //                 post:{
    //                     iNumPage: 0,
    //                     id: {'ID': id_order}
    //                 }
    //             },
    //         })
    //         .then(function(response) {
    //             if (response.status === 'success') {
    //                 // Если форма успешно отправилась
    //                 console.log(response.data);
    //                 response.data.LIST_ORDER.forEach((elem) => app.$data.arr_data_list_order.unshift(elem) );
    //             }
    //         })
    //         .catch((response) => {
    //             console.log(response);
    //         });
    // },

  }
};
</script>

<style lang="scss">
@import "../assets/sass/main";
@import '/~vuetify/src/styles/main';

.list-purchase-order {
  .v-skeleton-loader__image {
    background: lightgrey !important;
  }
}

html {
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

#progress_circular_list_order {
  width: 7em !important;
  height: 7em !important;
}

.block_filters_header {
  display: flex;
  justify-content: space-between;

  &__btn:hover {
    background: lighten(rgb(169,169,169), 20%);
  }
}

/* Главная страница */
.wrapper_rows_grid_table {
  display: grid;
  margin-top: 10px;
  //grid-row-gap: 20px;
  .wrapper_iframe_add_page {
    display: grid;
    position: relative;
    justify-items: center;

    iframe.iframe_add_page {
      z-index: 99999;
      position: absolute;
      //margin-top: 5vh;
      min-width: 45vw;
    }
  }

  @media (max-width: 768px) and (min-width: 150px) {
    .wrap_row {
      font-size: 14px !important;
      grid-template-columns: 15% 1fr 1fr 15% !important;

      .performer_wrapper {
        display: flex !important;
        flex-direction: column !important;
      }
    }
  }

  //.unready {
  //  border-color: red !important;
  //}

  .wrap_row {
    background: $background_main_order_block;
    display: grid;
    padding: 10px 10px;
    margin: 0 5px 10px 5px;
    //border: 1px solid #dde09e;
    border-radius: 10px;
    column-gap: 10px;
    grid-template-columns: 10% 1fr 1fr 10%;
    //grid-template-columns: 10% 21% 1fr 10%;
    grid-template-rows: auto;
    //performer_priority
    grid-template-areas:
              "progress_stage_of_production   progress_stage_of_production   progress_stage_of_production   progress_stage_of_production"
              "order_id   order_name   order_name   order_name"
              "order_date                   order_articul                   quantity                   order_image"
              "wrap_assign_performer          wrap_assign_performer          wrap_assign_performer          wrap_assign_performer"
              "detail_page_order              detail_page_order              detail_page_order              detail_page_order";
    //grid-template-areas:
    //          "progress_stage_of_production   progress_stage_of_production   progress_stage_of_production   progress_stage_of_production"
    //          "order-image                    order_id_date                  performer_priority             quantity"
    //          "name_articul                   name_articul                   name_articul                   print_detail_order"
    //          "name_articul                   name_articul                   name_articul                   arrow_open_close"
    //          "wrap_assign_performer          wrap_assign_performer          wrap_assign_performer          wrap_assign_performer"
    //          "detail_page_order              detail_page_order              detail_page_order              detail_page_order";
    word-break: break-word;
    //border-bottom: 4px solid #212121;
    height: auto;
    //grid-column-gap: 0.5em;
    color: white;
    position: relative;

    &.blur_row {
      opacity: 0.2;
    }

    //.wrapper_id_date {
    //  display: flex;
    //  justify-content: space-between;
    //  padding: 0;
    //  column-gap: 0.5em;
    //}

    .order-image-wrapper {

      &__img {
        width: 40px;
        //min-height: 40px;
        height: auto;
        margin: 0 auto;
        -o-object-fit: contain;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .id_order {
      display: grid;
      align-items: baseline;
      color: lightgrey;
      font-size: 1em;
    }

    .date_create_new_order {
      font-size: 0.9em;
      color: darkgrey;
    }

    .button_print_detail_order {
      width: 100%;
      height: 100%;

      .print_detail_order {
        background-size: contain;
        height: 32px;
        width: 32px;
        background-position: center;
      }
    }


    .preview_prod {
      align-items: center;
      align-self: center;
      text-align: center;
      background: white;

      img {
        width: -moz-available;
        width: -webkit-fill-available;
        width: auto;
        max-height: 100px;
        max-width: 100%;
      }
    }


      .name_prod {
        text-decoration: unset;
        //position: relative;
        color: white;
        cursor: pointer;
        font-size: 1em;
      }

      .articul {
        /*font-style: italic;*/
        font-weight: lighter;
        color: lightgrey;
        justify-content: flex-start;
        font-size: 1em;
      }
    //.wrapper_name_articul {
    //  display: grid;
    //}

    .wrapper_performer_priority {
      font-size: 0.9em;
      display: grid;
      justify-items: baseline;

      .performer_wrapper {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 5px;
      }

      .priority {
        &.Низкий {
          color: #009846;
        }

        &.Средний {
          color: #ef7f1a;
        }

        &.Высокий {
          color: #e31e24;
        }
      }
    }

    section {
      padding: unset;
      /*padding: 3px;*/
      //outline: 1px solid;
      text-align: left;
      display: flex;
      //align-items: flex-end;
      align-items: center;
      flex-wrap: wrap;
      //justify-content: center;

      &.arrow_open_close {
        @extend %arrow_open_close;
        padding: 5px;
        margin: 15px 0;

        &.collapse {
          transform: rotate(180deg);
        }
      }

      &.list_status_create, &.list_status_plan, &.list_status_fact {
        text-align: left;
      }

      &.quantity, &.main_status, &.fio_employee {
        display: flex;
        //align-items: center;
        justify-content: center;
        text-align: center;
      }

      &.quantity {
        font-size: 1.5rem;
        //align-items: center;
      }

      &.fio_employee {
        word-break: break-all;
      }
    }

    .wrap_row_onclick_area {
      display: contents;
    }

    .wrapper_assign_performer {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-row-gap: 10px;
      position: relative;
      background: $background_detail_page;
      padding: 10px;
      margin: 10px;
      @extend %round_block_detail_page;

      .block_svarka_name {
        padding-top: 0px;
        padding-bottom: 0px;
        color: $caption_yellow_color;
      }

      button.btn_assign_svarka {
        width: fit-content;
        margin-right: auto;
      }

      @media (min-width: 768px) {
        .btn_assign_svarka {
          font-size: 13px !important;
        }
      }

      .wrapper_block_norma_vremeni {
        display: grid;
        grid-template-rows: auto;
        font-size: 13px;
        //color: rgba(211, 211, 211, 0.8);
        color: rgba(211, 211, 211, 0.8);

        div {
          display: grid;
          grid-template-columns: auto 1fr auto;
          align-items: baseline;

          .dots {
            border-bottom: 1px dotted rgba(211, 211, 211, 0.8);
          }
        }
      }
    }

  }
}
</style>
