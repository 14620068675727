<template>
  <div class="specification_card__comment_zone">
    <show-block :nameBlock="'Примечание (мотив разработки)'" :showBlock="true">
      <div class="specification_card__comment_zone__content">
        <v-textarea
          solo
          dense
          hide-details
          clearable
          @input="handleChange($event)"
          rows="3"
          row-height="25"
          :disabled="elementDisabled"
          :value="elementValue"
          placeholder="Примечание"
        >
        </v-textarea>
      </div>
    </show-block>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import ShowBlock from './ShowBlock.vue'

export default {
  name: 'Note',
  props: {
    elementDisabled: Boolean,
    elementValue: String,
  },
  methods: {
    ...mapMutations('specificationStore', {
      createNewElement: 'CREATE_NEW_ELEMENT',
    }),
    handleChange(event) {
      this.$emit('eventChange', event)
    },
  },
  components: { ShowBlock },
}
</script>

<style scoped></style>
