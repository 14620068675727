var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specification_card_block"},[_c('div',{staticClass:"par_search"},[(!_vm.newElement.NAME)?_c('div',{staticClass:"search_nom"},[_c('drawing-number',{attrs:{"elementView":"select","elementPlaceholder":"Поиск номенклатуры по наименованию или обозначению:","itemsSelect":_vm.allNomenclatures,"btnCreate":_vm.btnCreateActive,"hintShow":true},on:{"eventChange":_vm.eventFunction,"eventData":_vm.eventDataFunction}})],1):_vm._e(),(_vm.newElement.NAME)?_c('div',{staticClass:"search_nom search_result"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('drawing-number',{attrs:{"elementView":"input","elementDisabled":true,"elementValue":_vm.newElement.DRAWING_NUMBER}})],1)]}}],null,false,2366691885)},[_c('span',[_vm._v(_vm._s(_vm.newElement.DRAWING_NUMBER))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('NameNomenclature',{attrs:{"elementView":"input","elementDisabled":true,"elementTitle":false,"elementValue":_vm.newElement.NAME}})],1)]}}],null,false,1075578005)},[_c('span',[_vm._v(_vm._s(_vm.newElement.NAME))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"grey lighten-1"},on:{"click":_vm.goToView}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,false,3995943792)},[_c('span',[_vm._v("Назад к поиску")])])],1):_vm._e()]),_c('div',{staticClass:"margin_blocks"},[_c('show-block',{attrs:{"nameBlock":_vm.newElement.DRAWINGS.length ? 'Чертежи' : 'Чертежи отсутствуют',"showBlock":!!_vm.newElement.DRAWINGS.length,"numberBlock":_vm.newElement.DRAWINGS},on:{"renderDrawing":_vm.renderDrawing}},[_c('Drawings',{key:_vm.componentKey,attrs:{"drawings":_vm.newElement.DRAWINGS,"is_enabled":true}})],1),_c('show-block',{attrs:{"nameBlock":_vm.newElement.CHILDREN.length
          ? 'Спецификация'
          : 'Спецификация не заполнена',"showBlock":!!_vm.newElement.CHILDREN.length,"numberBlockChild":_vm.newElement.CHILDREN}},[_c('div',{staticClass:"show_block-content"},[_c('ChildElement',{attrs:{"mode_view":false,"elementsDetailOperation":_vm.newElement.CHILDREN ? _vm.newElement.CHILDREN : []}})],1)]),_c('show-block',{attrs:{"nameBlock":_vm.newElement.REAL_NOMENCLATURE.length
          ? 'Номенклатура'
          : 'Номенклатура не привязана',"showBlock":!!_vm.newElement.REAL_NOMENCLATURE.length,"numberBlock":_vm.newElement.REAL_NOMENCLATURE}},[_c('div',{staticClass:"show_block-content view_nom"},[_c('RealNomenclature',{attrs:{"editArray":true}})],1)]),_c('show-block',{attrs:{"nameBlock":'Параметры',"showBlock":!!_vm.newElement.NOTE.length || !!_vm.newElement.HASHTAGS.length}},[_c('div',{staticClass:"show_block-content show_block-content_params"},[_c('div',{staticClass:"standard_title"},[_vm._v(" Тип: "),_c('span',{staticClass:"type_check"},[_vm._v(_vm._s(_vm.typeCheck))])]),_c('Note',{attrs:{"elementValue":_vm.newElement.NOTE,"elementDisabled":true}}),_c('Chips',{staticStyle:{"pointer-events":"none"},attrs:{"elementValue":_vm.newElement.HASHTAGS,"elementReadonly":true,"closeChip":false}})],1)]),_c('show-block',{attrs:{"nameBlock":!!_vm.idParrents.length ? 'Применяемость' : 'Применяемость отсутствует',"showBlock":!!_vm.idParrents.length,"numberBlock":_vm.idParrents}},[_c('div',{staticClass:"show_block-content"},[_c('Applicability',{attrs:{"elementValue":_vm.idParrents}})],1)]),_c('show-block',{attrs:{"nameBlock":_vm.idPlanning ? 'Планирование' : 'Планирование не заполнено'}},[_c('div',{staticClass:"show_block-content"},[(_vm.idPlanning)?_c('Planning',{attrs:{"elementValue":_vm.idPlanning}}):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }