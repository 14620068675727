import { render, staticRenderFns } from "./SpecificationCard.vue?vue&type=template&id=32f9efc0&scoped=true&"
import script from "./SpecificationCard.vue?vue&type=script&lang=js&"
export * from "./SpecificationCard.vue?vue&type=script&lang=js&"
import style0 from "./SpecificationCard.vue?vue&type=style&index=0&id=32f9efc0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f9efc0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
