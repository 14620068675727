<template>
    <div>
        <section class="block_icons" style="grid-area: block_icons">
            <section v-for="doc_assembly in ALL_DOC.PROD_DOCUMENTS_ASSEMBLY">
                <i v-if="doc_assembly.TYPE === 'ТЛ'" @click="modal_set_current_file($store.state.VUE_DEV_LOCAL_SERVER+doc_assembly['SRC'])"
                         class="TL_icon":title="doc_assembly['ORIGINAL_NAME']">
                </i>
                <i v-else-if="doc_assembly.TYPE === 'КВ'" @click="modal_set_current_file($store.state.VUE_DEV_LOCAL_SERVER+doc_assembly['SRC'])"
                         class="KB_icon" :title="doc_assembly['ORIGINAL_NAME']">
                </i>
            </section>
            <i class="RCH_folder_icon"
                 @click="open_dialog_choosen_file(ALL_DOC.PROD_DOCUMENTS_PREFORM)"></i>
            <i class="tree_icon"
                 @click="open_dialog_choosen_file(ALL_DOC.PROD_DOCUMENTS_WELDING)"></i>
        </section>

        <!-- Диалоговое окно c файлами -->
        <v-dialog
                v-model="dialog_choose_files.state"
                max-width="600px"
        >
            <v-card id="v_dialog_files">
                <v-card-title>
                    <span class="headline">Файлы</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="wrapper_doc">
                            <section class="wrap_doc_row" v-for="data in dialog_choose_files.data" >
                                <i @click="modal_set_current_file($store.state.VUE_DEV_LOCAL_SERVER+data.SRC)"
                                   :title="data['ORIGINAL_NAME']">
                                </i>
                                <span>{{ data.ORIGINAL_NAME }}</span>
                            </section>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_current_file.state"
            content-class="dialog_current_file"
        >
            <div id="wrapper_open_image">
                <img class="dynamic_open_image" :src="dialog_current_file.data">
            </div>
            <v-card-actions class="card_actions">
                <v-btn color="secondary"
                       block
                       elevation="2"
                       id="printing_doc"
                       @click="print_current_file()"
                       :loading="$store.state.printing_file === true"
                >
                    Печать
                </v-btn>
                <v-btn
                        id="close_dialog"
                        block
                        elevation="2"
                        @click="dialog_current_file.state = false"
                >
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "ListFiles",
        props: ['ALL_DOC'],
        data: () => ({
            dialog_current_file:{
                state: false,
                data: null,
            },
            dialog_choose_files:{
                state: false,
                data: [],
            }
        }),
        watch:{
            'dialog_current_file.state':{
                handler: function watch() {
                    if (this.dialog_current_file.state === false){
                        this.dialog_current_file.data = null;
                    }
                },
            },
        },
        methods:{
            modal_set_current_file(src){
                this.dialog_current_file.state = true;
                this.dialog_current_file.data = src;
            },
            print_current_file(){
                const main_vue_component = this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
                main_vue_component.printed_setTimeout(this, '#wrapper_open_image');
            },
            open_dialog_choosen_file(obj_files){
                // console.log('open_dialog_choosen_file = obj_files');
                // console.log(obj_files);
                this.dialog_choose_files.state = !this.dialog_choose_files.state;
                this.dialog_choose_files.data = obj_files;
            },
        }
    }
</script>

<style lang="scss">
    .block_icons {
        //display: grid;
        //grid-template-columns: auto;
        //grid-row-gap: 20px;
        //grid-column-gap: 15px;
        //justify-items: center;

        /*display: flex;*/
        /*flex-wrap: wrap;*/
        display: grid;
        grid-row-gap: 5px;
        flex-wrap: wrap;
        justify-items: center;
        height: 100%;
        align-content: center;
        i,a{
            height: auto;
            max-height: 100px;
            min-height: 40px;
            width: auto;
            max-width: 120px;
            min-width: 50px;
            margin: 5px 5px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: block;

            &.KB_icon {
                min-height: 40px;
                background-image: url('../assets/svg/КВ_pdf.svg');
            }
            &.TL_icon {
                min-height: 40px;
                background-image: url('../assets/svg/ТЛ_pdf.svg');
            }
            &.tree_icon {
                background-image: url('../assets/svg/structure_tree1.svg');
                cursor: pointer;
            }
            &.RCH_folder_icon {
                background-image: url('../assets/svg/РЧ_folder.svg');
                cursor: pointer;
            }

            @at-root .wrapper_doc{
                display: grid!important;
                grid-column-gap: 1em;
                /*color: white;*/
                .wrap_doc_row {
                    display: grid;
                    //grid-row-gap: 15px;
                    justify-content: center;
                    justify-items: center;
                    i,a {
                        cursor: pointer;
                        height: auto;
                        max-height: 100px;
                        min-height: 60px;
                        width: auto;
                        max-width: 120px;
                        min-width: 60px;
                        margin: 5px 5px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;

                        background-image: url('../assets/svg/pdf.svg');
                    }
                }
            }

        }

    }

    .dialog_current_file{
        margin: 10px;
        #wrapper_open_image{
            .dynamic_open_image{
                height: auto;
                max-height: 800px;
                max-width: 100%;
            }
        }
        .card_actions{
            display: grid!important;
            grid-template-columns: 1fr 1fr;
        }
    }

</style>