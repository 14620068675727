<template>
<!--  <div >-->
    <table id="wrapper_layout_bar_code" style="page-break-after:always;">
      <tbody class="LayoutBarCode">
      <tr>
        <td v-for="(row, index) in arr_data_order" :key="index">
          <!--        v-for="nomenclature in arr_data_order" v-if="arr_data_order.length !== 0"-->
          <div
              class="LayoutBarCode_wrapper"
              style="page-break-inside: avoid;"
          >
            <div class="LayoutBarCode_company_date">
              <img class="LayoutBarCode_company" :src="'https://gidruss.ru/local/templates/gidruss/static/i/logotop2.png'">
              <span class="LayoutBarCode_date">{{ row.CUR_DATE }}</span>
            </div>
            <v-divider class="LayoutBarCode_divider"></v-divider>
            <img :src="$store.state.VUE_DEV_LOCAL_SERVER + row.PICTURE.SRC" class="LayoutBarCode_picture"/>
            <section class="LayoutBarCode_name">{{ row.NAME }}</section>
            <section class="LayoutBarCode_internal-article">Артикул: {{ getArticle(row) }}</section>
<!--            <section class="" style="word-break: break-word;">{{ NAME_DATA(row) }}</section>-->
                <!--                                <section class="">Чертежный номер</section>-->
                <!--                                <section class="" v-if="nomenclature['DRAWING_NUMBER']">{{ nomenclature['DRAWING_NUMBER'] }}</section>-->
                <!--                                <section class="" v-else>000000</section>-->
              <!--                <section class="LayoutBarCode_Block_WrapperQrCode_CurDate">{{ column['CUR_DATE'] }}</section>-->
            <barcode v-if="row.BARCODE" class="LayoutBarCode_barcode" v-bind:value="row.BARCODE">
              Ошибка отрисовки штрих-кода
            </barcode>
            <span v-else class="LayoutBarCode_barcode_error">Не назначен штрихкод</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
<!--  </div>-->
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
    name: "LayoutBarCode",
    components: {
      'barcode': VueBarcode
    },
    props: ['id_prod_from_parent', 'detailNomenclature'],
    data: () => ({
        arr_data_order: [],
    }),
    mounted() {
      if (this.detailNomenclature && Object.keys(this.detailNomenclature).length) {
        this.arr_data_order.push(this.detailNomenclature)
        setTimeout(() => {
          this.print()
        }, 500)
      } else {
        this.loadData()
      }
    },
    computed: {},
    methods:{
      getArticle(data) {
        return data?.ARTICUL ? data.ARTICUL : ''
      },
      NAME_DATA(data) {
        return data?.NAME ? data.NAME : ''
      },
      refactorDataToTables(data) {
        const arr = [[[],[]],[[],[]]]
        const refactorArr = []
        let row = []
        const column = []

        for (let i = 0; i < data.length; i++) {
          if ((i+1) % 2 !== 0) {
            row.push(data[i])
          } else {
            row.push(data[i])
            this.arr_data_order.push(row)
            row = []
          }
        }
        this.print()
      },
      loadData(){
        const _this = this;
        console.log(this.id_prod_from_parent)
        BX.ajax.runComponentAction('nk:production.print_layout',
            'executeGetDataProd', { // Вызывается без постфикса Action
              mode: 'class',
              data: {
                'post':  {
                  'id_prod': (!!_this.id_prod_from_parent) ? _this.id_prod_from_parent : _this.$route.query.id_prod,
                },
                'login': _this.$store.state.login,
                'token': _this.$store.state.token,
              },
            })
            .then(function(response) {
              console.log('then loadData response');
              console.log(response);
              if (response.status === 'success') {


                // TODO поменять местами
                // _this.refactorDataToTables(response.data)
                _this.arr_data_order = response.data;
                // console.log('TUTUTU', _this.arr_data_order)

                // _this.arr_data_order = response.data;
                // console.log(_this.arr_data_order)
                _this.print();
              }
            })
            .catch((response) => {
              console.log(response);
            });
      },
      print(){
        this.$nextTick(() => {

          const main_vue_component = this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
          main_vue_component.printed_setTimeout(this, '#wrapper_layout_bar_code');

          // w.close();
          //     // console.log(this.arr_data_order);
          //     this.$htmlToPaper('print_layout_prod', null, () => {
          //         console.log('Printing completed or was cancelled!');
          //         this.$emit('printing-completed')
          //     });
        })
      }
    }
}
</script>

<style lang="scss">
    //@import "../../public/print_layout.css";

    /* TODO стиди неактуальны смотри в файле Print_layout.css*/
    /* Стили для печатной формы Этикеток продукции */
    @media print {
      @page {
        size: auto;
        margin: 0;
        padding: 0;
      }
      header {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      .LayoutBarCode_wrapper{
        background: white;
        display: grid;
        padding: 15px 15px 0 15px;
        max-width: 270px;
        max-height: 453px;
        width: 270px;
        height: 453px;
      }
      .LayoutBarCode_company_date{
        display: inline-flex;
        grid-column-gap: 1em;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center;
      }
      .LayoutBarCode_company{
        max-width: 50%;
      }
      .LayoutBarCode_date{
        font-weight: bold;
        font-size: 14px;
      }
      .LayoutBarCode_divider{
        border-width: 1px;
        border-color: black!important;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
        height: 0px;
      }
      .LayoutBarCode_picture{
        margin: auto;
        height: 200px;
        max-height: 200px;
      }
      .LayoutBarCode_name{
        text-align: center;
      }
      .LayoutBarCode_internal-article{
        font-size: 1em;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
      }
      .LayoutBarCode_barcode_error{
        text-align: center;
        text-decoration: underline;
      }
      .vue-barcode-element{
        max-height: 125px;
        width: 100%;
      }
    }
</style>
