<template>
  <div>
    <v-dialog
        v-model="$store.getters.getModalNomenclatureData.state"
        max-width="850px"
        class="dialog_contractor"
    >
      <v-card id="v_dialog_nomenclature_window">
        <v-card-title style="display: flex; flex-direction: column">
          <div class="wrapper_title">
            <span class="title">{{ $store.getters.getModalNomenclatureData.company.COMPANY }}</span>
            <v-text-field
                hide-details
                class="subtitle"
                :value="$store.getters.getModalNomenclatureData.company.EMAIL"
                placeholder="не заполнен email"
                type="text"
                readonly
            ></v-text-field>
          </div>
          <v-icon
              class="close_dialog_cross"
              color="primary"
              elevation="2"
              @click="$store.commit('setModalNomenclature', {state: false})"
          >fas fa-times
          </v-icon>
          <div class="modal_actions">
              <LiveSearch
                  @listen-data-prod="(data) => { this.nomenclature_data = (!!data) ? data : {} }"
                  :modalBtnProp="modalBtnProp"
                  style="width: 100%;"
                  v-if="$store.getters.getModalNomenclatureData.state"
              />
<!--              <v-autocomplete-->
<!--                  solo dense-->
<!--                  hide-details-->
<!--                  clearable-->
<!--                  label="Тип контрагента"-->
<!--                  :items="$store.state.listTypeContractors"-->
<!--                  item-text="UF_NAME"-->
<!--                  return-object-->
<!--                  v-model="type_contractor"-->
<!--              ></v-autocomplete>-->
            <v-chip-group
                class="modal_actions_chips"
                multiple
            >
              <v-chip
                  v-for="(item, index) in objFilters"
                  :key="index"
                  filter
                  outlined
                  :color="item.text_color"
                  text-color="white"
                  @change="setColorFilter(item.color)"
              >
                {{ item.text }}
              </v-chip>
            </v-chip-group>
          </div>
          <div class="wrapper_footer">
            <v-btn
                class="text-capitalize"
                color="primary"
                title="Распечатать всю номенклатуру по этому контрагенту"
                @click="printAllTetherProduction()"
                :loading="$store.state.state_progress_circular_page"
            >
              Пакетная печать
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <simple-modal/>
            <div id="ListProductOnStore">
              <div
                  class="wrapper_list_remains"
                  :class="{resizeHeight: $store.state.state_progress_circular_page}"
                  v-if="$store.state.listNomenclatureByContractors.list_items">
                <NomenclatureHorizontalCard
                    class="row_remains opacity-hover"
                    :class="[{row_remains_modal: $store.getters.getModalNomenclatureData.state}, $store.getters.getColorBorder(product.PRIORITY_OBJ.color), {modalView: modalView}]"
                    v-for="product in $store.state.listNomenclatureByContractors.list_items"
                    :key="product.ID"
                    :product="product"
                    :modalView="modalView"
                    @openDetailNomenclature="open_dialog_nomenclature"
                />
              </div>

              <!-- Оверлей -->
              <v-overlay
                  :z-index="0"
                  :absolute="true"
                  :value="$store.state.state_progress_circular_page"
              >
                <v-progress-circular
                    style="margin: auto; position: relative; top: 80px"
                    width="3"
                    id="progress_circular_list_order"
                    color="green"
                    :indeterminate="true"
                    v-if="$store.state.state_progress_circular_page"
                ></v-progress-circular>
              </v-overlay>

              <v-alert
                  v-if="!$store.state.listNomenclatureByContractors.list_items.length && !$store.state.state_progress_circular_page"
                  type="error"
                  text
                  class="err-msg"
              >
                Ничего не найдено
              </v-alert>
              <v-btn
                  block
                  v-if="!$store.state.state_progress_circular_page"
                  @click='() => { showMore() }'
                  :disabled="$store.state.listNomenclatureByContractors.count_data.RECORDS_TO_END <= 0"
                  style="margin: 1em 0"
              >
                Показать ещё {{ $store.state.listNomenclatureByContractors.count_data.RECORDS_TO_END }}
              </v-btn>

              <!-- Диалоговое окно с номенклатурой -->
              <v-dialog
                  v-model="dialog_nomenclature_window.state"
                  max-width="600px"
                  content-class="dialog_nomenclature"
              >
                <v-card id="v_dialog_nomenclature_window">
                  <v-card-title>
                    <span class="headline">Номенклатура</span>
                    <v-icon
                        class="close_dialog_cross"
                        color="primary"
                        elevation="2"
                        @click="dialog_nomenclature_window.state = false"
                    >fas fa-times
                    </v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <nomenclature :dialog_id_order="dialog_nomenclature_window.id_order"></nomenclature>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-container>
        </v-card-text>
        <div class="modal_footer"
             v-show="!!$store.state.modalNomenclature.totalCard.length && $store.state.listNomenclatureByContractors.list_items.length !== 0">
          <div class="modal_footer__top">
            <div class="modal_footer__top__left">
              <div class="modal_footer__top__left__item">
                <span class="modal_footer__top__left__item__title">Количество позиций:</span>
                <span class="modal_footer__top__left__item__count">{{
                    $store.state.modalNomenclature.totalCard.length
                  }}</span>
              </div>
              <div class="modal_footer__top__left__item">
                <span class="modal_footer__top__left__item__title">Количество мест:</span>
                <span class="modal_footer__top__left__item__count">{{
                    $store.state.modalNomenclature.totalCard.length
                  }}</span>
              </div>
              <div class="modal_footer__top__left__item">
                <span class="modal_footer__top__left__item__title">Масса:</span>
                <span class="modal_footer__top__left__item__count">{{
                    $store.state.modalNomenclature.totalCard.length
                  }}</span>
              </div>
            </div>
            <div class="modal_footer__top__right">
                <span>
                  <v-icon color="yellow lighten-5">
                    mdi-sigma
                  </v-icon>
                  {{ computed_total_price() }} ₽
                </span>
            </div>
            <div class="modal_footer__bottom">
              <v-btn
                  class="text-capitalize"
                  color="primary"
                  @click="$store.dispatch('copyToClipboard')"
              >
                Скопировать заявку
                <v-icon right>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-btn
                  class="text-capitalize"
                  color="primary"
                  @click="createBitrixDocument()"
                  :disabled="disableBtn"
              >
                Создать счет в 1С
                <v-icon right>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Nomenclature from "./Nomenclature";
import LiveSearch from "../LiveSearch";
import NomenclatureHorizontalCard from "./NomenclatureHorizontalCard";
import SimpleModal from "../ModalWindow/SimpleModal";

export default {
  components: {
    SimpleModal,
    NomenclatureHorizontalCard,
    Nomenclature,
    LiveSearch,
  },
  name: "ModalNomenclature",
  props: ['color', 'modalBtnProp'],
  data: () => ({
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    debounceTimeout: null,
    debounceTotal: null,
    requestObj: {},
    data_filters: {
      color: [],
    },
    objFilters: {
      0: {'color': 'rose', 'text_color': '#FF80AB', 'text': 'Заполнить ФО, НО, КУ'},
      1: {'color': 'red', 'text_color': 'red', 'text': 'Заказать срочно'},
      2: {'color': 'yellow', 'text_color': 'yellow', 'text': 'Заказать планово'},
      3: {'color': 'green', 'text_color': 'green', 'text': 'Товара достаточно'},
    },
    modalView: true,
    type_contractor: null,
    disableBtn: false,
  }),
  mounted() {
    this.initializeQuery()
    if (Object.keys(this.$route.query).includes('subtype_prod')) {
      this.$store.state.state_block_filters = true;
    }
    this.type_contractor = this.$store.getters.getModalNomenclatureData.company.TYPE_CONTRACTORS
    this.getListNomenclature()
  },
  computed: {},
  watch: {
    'data_filters.color': {
      handler: function watch(value) {
        console.log('watch color');
        console.log(value);
        this.requestObj = Object.assign({}, {
          'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID,
          'color': value
        })
        this.$store.dispatch('getListNomenclature', this.requestObj)
      },
    },
    'type_contractor': {
      handler: function watch(value) {
        console.log('watch type');
        console.log(value);
        // this.requestObj = Object.assign({}, {
        //   'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID,
        //   'color': value
        // })
        this.getListNomenclature()
        // this.$store.dispatch('getListNomenclature', this.requestObj)
      },
      deeper: true
    },
  },
  methods: {
    createBitrixDocument() {
      this.disableBtn = true
      this.$store.dispatch('queryToFillDocumentWithNomenclatureBy1c').then(() => {
        setTimeout(() => {
          this.disableBtn = false
        }, 60000)
      })
    },
    printAllTetherProduction(){
      //?id_prod=12213&id_prod=41
      const obj = this.$store.state.listNomenclatureByContractors.list_items.map((elem) => elem.ID);
      let routeData = this.$router.resolve({name: 'LayoutNomenclature', query: { id_prod: obj }});
      window.open(routeData.href, '_blank');
    },
    setColorFilter(color) {
      const arr = this.data_filters.color;
      if (arr.length === 0) {
        arr.push(color);
        return false;
      }

      const findIndex = arr.findIndex((elem) => elem === color);
      if (findIndex !== -1) {
        arr.splice(findIndex, 1);
      } else {
        arr.push(color);
      }
    },
    getListNomenclature() {
      console.log('get list')
      // console.log(this.type_contractor.UF_XML_ID)
      console.log(this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID)
      console.log(this.modalBtnProp)

      this.requestObj = Object.assign({}, {
        'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID,
        'type_contractor':  !!this.modalBtnProp ? this.modalBtnProp.UF_XML_ID : '',
        'contractor_by_default': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID
      })
      // this.type_contractor = Object.assign({}, )
      console.log('this.requestObj');
      console.log(this.requestObj);
      this.$store.dispatch('getListNomenclature', this.requestObj)
    },
    showMore() {
      this.$store.state.listNomenclatureByContractors.count_data.NUM_PAGE++
      this.requestObj = Object.assign({}, {'CUSTOM_CONTRACTOR_ID': this.$store.getters.getModalNomenclatureData.company.CUSTOM_CONTRACTOR_ID})
      this.$store.dispatch('getListNomenclature', this.requestObj)
    },

    /*  Показываем/скрываем кнопку удаления товара из "корзины"  */
    inStorage(product) {
      return this.$store.state.modalNomenclature.totalCard.some(elem => elem.ID === product.ID)
    },
    /* Очищаем предмет из корзины */
    deleteElem(id) {
      this.$store.dispatch('removeModalNomenclatureItem', id)
    },
    /* Общая функция для изменения свойств */
    initializeQuery() {
      if (Object.keys(this.$route.query).includes('stage')) {
        if (this.$store.state.data_production?.ARR_FILTER_STAGE_OF_PRODUCTION?.length) {
          this.$nextTick(() => {
            this.$store.state.listNomenclatureByContractors.data_filters.stage_of_production = this.$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION.filter((elem) => this.$route.query['stage'] === elem.ID)[0]
          })
        } else {
          this.$store.dispatch("SetDataProduction").then(() => {
            this.$nextTick(() => {
              this.$store.state.listNomenclatureByContractors.data_filters.stage_of_production = this.$store.state.data_production.ARR_FILTER_STAGE_OF_PRODUCTION.filter((elem) => this.$route.query['stage'] === elem.ID)[0]
            })
          })
        }
      } else if (Object.keys(this.$route.query).length === 0) {
        // this.$store.dispatch("getListProducts");
      }
    },
    updateQuery() {
      let queryObj = {}
      if (Object.keys(this.$route.query).length) {
        for (let key in this.$route.query) {
          Object.assign(queryObj, {[key]: this.$route.query[key]})
        }
      }
      if (!!this.$store.state.listNomenclatureByContractors?.data_filters.stage_of_production?.ID) {
        Object.assign(queryObj, {'stage': this.$store.state.listNomenclatureByContractors.data_filters.stage_of_production.ID})
      } else {
        delete queryObj.stage
      }
      console.log(queryObj)
      this.pushRouter(queryObj)
    },
    pushRouter(object) {
      this.$store.commit('updateURL_components', object)
    },
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    onChangeQuantity(operation, product) {
      const planned_quantity = (!!product.QUANTITY_TO_ORDER) ? product.QUANTITY_TO_ORDER : 0;
      const pieces_in_package = (!!product.HOW_MANY_PIECES_IN_PACKAGE) ? product.HOW_MANY_PIECES_IN_PACKAGE : 0;

      if (!!pieces_in_package) {
        /* Остаток */
        const aliquot = planned_quantity % pieces_in_package;
        /* Вычисляем логику декремента / инкремента от остатков / упаковки */
        const decrement_aliquout = parseInt((!!aliquot) ? aliquot : pieces_in_package);
        const increment_aliquout = pieces_in_package - aliquot;

        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) - parseInt(decrement_aliquout);
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) + parseInt(increment_aliquout);
          this.changeValue(product)
        }
      } else {
        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) - 1
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) + 1
          this.changeValue(product)
        }
      }
    },
    changeValue(product) {
      console.log('changed!')

      if (this.debounceTotal) clearTimeout(this.debounceTotal)

      this.debounceTotal = setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('setTotalCard', product)
        })
      }, 500)
    },
    sendEditableAmount(product) {
      const _this = this;
    },
    open_dialog_nomenclature(order_id) {
      console.log(order_id)
      this.dialog_nomenclature_window.id_order = order_id;
      this.dialog_nomenclature_window.state = true;
    },
    getTotal(product) {
      return product.total = parseInt(product.perProd) * parseInt(product.counter)
    },
    computed_total_price() {
      return this.$store.state.modalNomenclature.totalCard.reduce((a, b) => {
        return +(a + (b.PURCHASING_PRICE * b.QUANTITY_TO_ORDER)).toFixed(2)
      }, 0)
    },
  },
  beforeDestroy() {
    /* Очищаем список корзины, если закрываем модалку */
    if (this.$store.state.modalNomenclature.totalCard.length) {
      this.$store.dispatch('clearModalNomenclature')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/main";

.v-application {
  font-family: inherit;

  a {
    color: unset !important;
  }
}


.modal_actions {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  align-items: center;
  padding-top: 10px;

  .modal_actions_chips {
    padding-left: 10px;
    //max-width: 200px;
    .v-slide-group__wrapper {
      flex-wrap: wrap;
    }
  }
}
.wrapper_footer{
  display: grid;
  justify-items: left;
  width: 100%;
}

::v-deep .v-card__title {
  background: darkgray !important;
  padding: 5px 24px 5px;
}

::v-deep .v-slide-group__content {
  //flex-wrap: nowrap;
}

.wrapper_title {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
  justify-items: left;
  align-items: baseline;
  width: 100%;

  .title {
    font-size: 1.1em !important;
  }

  .subtitle {
    font-size: 0.8em !important;
    width: fit-content;
    color: dimgrey;

    input {
      text-align: center;
    }
  }
}

::v-deep .v-icon.v-icon::after {
  background-color: transparent !important;
}

%howManyPiecesGrid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: end;
  grid-column-gap: 5px;
}

#ListProductOnStore {
  color: white;

  .contractor_filter {
    padding: 10px 2% 10px 2%;

    .block_filter {
      display: flex;


      .block_filter_chips {
        padding-left: 10px;
      }
    }
  }

  .resizeHeight {
    min-height: 140px;
  }

  .wrapper_list_remains {
    display: grid;
    width: 100%;
    position: relative;
    //margin-top: 1em;

    @media (max-width: 768px) {
      .row_remains {
        grid-template-columns: 25% 30% auto !important;

        .display {
          background: #FFFFFF !important;
          border-radius: 5px !important;
          height: 90px !important;
        }

        .image {
          width: 100% !important;
          border-radius: 5px !important;
        }
      }
    }

    .row_remains {
      display: grid;
      grid-template-columns: 30% 30% auto;
      grid-column-gap: 1em;
      align-items: center;
      background: $background_main_order_block;
      @extend %padding-row-position;
      @extend %margin-row-position;
      @extend %border-universal;
      //border-color: map-get($border-universal-color, 'желтый');
      font-size: 0.9em;
      position: relative;

      .image {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
        border-radius: 5px;
        background-color: #FFFFFF;
      }

      .display {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 130px;
        max-width: 100%;
      }

      .wrapper_name_articul {
        align-self: start;
        text-align: left;
        display: grid;
        height: 100%;
        align-content: space-around;
        grid-row-gap: 5px;
        color: lightgrey;

        .name_prod {
          font-size: 1.3em;
          cursor: pointer;
        }

        .articul, .drawing_number {
          font-size: 1.6em;
          color: grey;

          span {
            color: lightgrey;
            font-size: 1em;
            opacity: 0.9;
          }
        }
      }

      .wrapper_quantity {
        display: grid;
        grid-template-columns: 25% auto 25%;
        align-items: start;
        word-break: break-word;
        column-gap: 25px;
        transition: all .3s ease;

        &__right {
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: all .3s ease;

          &__total {
            padding: 8px 0;
            font-size: 18px;
            font-weight: 500;
            color: lightyellow;
            transform: translateY(30px);
            transition: all .3s ease;
            opacity: 0;
          }

          .showTotal {
            opacity: 1;
          }

          &__per_item {
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
          }
        }

        &__center {
          display: flex;
          flex-direction: column;
          transition: all .3s ease;
          row-gap: 5px;
          //height: 100%;
          .EDITABLE_QUANTITY {
            transform: translateY(30px);
            transition: all .3s ease;
            -moz-appearance: textfield !important;

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            &__append {
              cursor: pointer !important;
              color: #FFFFFF;
            }

            &__prepend {
              cursor: pointer !important;
              color: #FFFFFF;
            }
          }

          &__package {
            height: 30px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            @extend %howManyPiecesGrid;
          }

          &__edit {
            color: white;
            opacity: 0;
            transform: translateY(30px);
            transition: transform .3s ease, opacity .3s ease, color .4s ease-in-out;
            cursor: pointer;

            &:hover {
              color: darkgray;
            }

            ::v-deep .v-icon.v-icon::before {
              background-color: transparent !important;
            }
          }

          &__remains {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            row-gap: 5px;
            margin-left: -28px;

            &__block {
              height: 24px;
              span {
                min-width: 23px;
              }

              .EDITABLE_QUANTITY_REMAINS {
                max-width: 40px;
                -moz-appearance: textfield !important;

                ::v-deep .v-input__control input {
                  color: darkgray !important;
                  max-height: 24px !important;
                  text-indent: 8px;
                }

                ::v-deep input::-webkit-outer-spin-button,
                ::v-deep input::-webkit-inner-spin-button {
                  -webkit-appearance: none !important;
                }

                ::v-deep .v-text-field__suffix {
                  font-size: 12px !important;
                }

                ::v-deep .v-input__prepend-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__append-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__slot {
                  background: $background_main_order_block !important;
                  padding: 0;
                }

                ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
                ::v-deep .v-input__slot {
                  margin: 0;
                  max-height: 26px;
                  min-height: 24px !important;
                  display: flex !important;
                  flex-direction: column;
                  align-items: center !important;
                }

                ::v-deep .v-input__control {
                  min-height: 24px !important;
                  color: white;
                }
              }

              .edit {
                ::v-deep .v-input__slot {
                  background: white !important;
                  padding: 0;
                }

                ::v-deep .v-input__control input {
                  color: black !important;
                  text-indent: 8px;
                  text-align: left;
                }
              }
            }
          }
        }

        &__left {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

          .EDITABLE_QUANTITY {
            transform: translateY(30px);
            transition: all .3s ease;
            padding-bottom: 5px;
            -moz-appearance: textfield !important;

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            &__append {
              cursor: pointer !important;
              color: #FFFFFF;
            }

            &__prepend {
              cursor: pointer !important;
              color: #FFFFFF;
            }
          }

          &__remains {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            opacity: 0;
            transform: translateY(30px);
            transition: all .3s ease;
            row-gap: 5px;
            margin-left: -28px;

            &__block {
              display: flex;
              column-gap: 5px;
              justify-content: flex-start;
              align-items: center;
              height: 24px;
              padding-left: 30px;
              span {
                min-width: 23px;
              }

              .EDITABLE_QUANTITY_REMAINS {
                max-width: 70px;
                -moz-appearance: textfield !important;

                //::v-deep .v-text-field input {
                //  color: darkgray !important;
                //}

                ::v-deep .v-input__control input {
                  color: darkgray !important;
                  max-height: 24px !important;
                  text-indent: 8px;
                }

                ::v-deep input::-webkit-outer-spin-button,
                ::v-deep input::-webkit-inner-spin-button {
                  -webkit-appearance: none !important;
                }

                ::v-deep .v-input__prepend-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__append-inner {
                  align-self: center !important;
                }

                ::v-deep .v-input__slot {
                  background: $background_main_order_block !important;
                  padding: 0;
                }

                ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
                ::v-deep .v-input__slot {
                  margin: 0;
                  max-height: 26px;
                  min-height: 24px !important;
                  display: flex !important;
                  flex-direction: column;
                  align-items: center !important;
                }

                ::v-deep .v-input__control {
                  min-height: 24px !important;
                  color: white;
                }
              }

              .edit {
                ::v-deep .v-input__slot {
                  background: white !important;
                  padding: 0;
                }

                ::v-deep .v-input__control input {
                  color: black !important;
                  text-indent: 8px;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .card-buttons {
        position: absolute;
        right: -15px;
        top: -5px;

        &__delete {
          background: lightcoral;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.6s ease-in-out;
          cursor: pointer;
        }

        .showCloseImg {
          opacity: 1;
        }
      }

      &:hover {
        .wrapper_quantity {
          align-items: start;

          &__right {
            &__per_item {
              opacity: 1;
              transform: translateY(0px);
            }

            &__total {
              transform: translateY(0px);
            }
          }

          &__center {
            .EDITABLE_QUANTITY {
              transform: translateY(0px);
            }

            &__package {
              opacity: 1;
              transform: translateY(0px);
              @extend %howManyPiecesGrid;
            }

            &__edit {
              opacity: 1;
              transform: translateY(0px);
            }
          }

          &__left {
            .EDITABLE_QUANTITY {
              transform: translateY(0px);
            }

            &__remains {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
      }
    }

    .row_remains_modal {
      grid-template-columns: 20% 30% auto;

      .display {
        height: 110px;
      }
    }
  }

  &:hover {
    .wrapper_quantity {
      align-items: start;

      &__right {
        &__per_item {
          opacity: 1;
          transform: translateY(0px);
        }

        &__total {
          transform: translateY(0px);
        }
      }

      &__center {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__package {
          opacity: 1;
          transform: translateY(0px);
          @extend %howManyPiecesGrid;
        }

        &__edit {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &__left {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__remains {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

.row_remains_modal {
  grid-template-columns: 20% 25% auto;

  .display {
    height: 110px;
  }
}

::v-deep .v-text-field {
  padding-top: 0;
  margin-top: 0;
}

@media (max-width: 768px) {
  ::v-deep .v-text-field__slot {
    font-size: 0.75em !important;

    input {
      padding: 0 !important;
    }
  }
  .row_remains {
    grid-column-gap: 0.7em !important;

    .wrapper_name_articul {
      .articul, .drawing_number {
        font-size: 0.8em !important;;
        color: grey;

        span {
          color: lightgrey;
          font-size: 0.9em !important;
          opacity: 0.9;
        }
      }

      .name_prod {
        font-size: 0.85em !important;
      }
    }

    .wrapper_quantity {
      padding-right: 0 !important;
      word-break: break-all;

      &__titles {
        grid-template-columns: auto 35% !important;
      }

      &__items {
        grid-template-columns: auto 35% !important;;
      }
    }
  }
}

.modal_footer {
  position: sticky;
  bottom: 0;
  background: darkgray;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px 10px;
  font-size: 14px;

  &__top {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__item {
        display: flex;
        column-gap: 5px;

        &__title {
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
        }

        &__count {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
    }

    &__right {
      font-size: 24px;
      color: lightyellow;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal_footer__bottom {
      display: grid;
      grid-row-gap: 5px;
      align-items: center;
      column-gap: 10px;
    }
  }
}

.modalView {
  font-size: 1.4em !important;
}
</style>

<style lang="scss">
.v-icon.v-chip__filter {
  font-size: 1em;
}
</style>
