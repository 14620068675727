<template>
  <div
    class="wrapper_relative_menu_footer"
    id="vue_wrapper_relative_menu_footer"
  >
    <div class="menu_footer_not" v-if="$route.name == 'SpecificationCreate'">
      <StatusLogger></StatusLogger>

      <div class="btns-create">
        <div class="btn-create">
          <v-btn color="red darken-1" text @click="backToView">Отмена</v-btn>
        </div>
        <div class="btn-create">
          <v-btn
            color="green darken-1"
            @click="postNewElement"
            :disabled="validationСheckEdit"
          >
            Создать
          </v-btn>
        </div>
      </div>
    </div>

    <div class="menu_footer_not" v-if="$route.name == 'SpecificationView'">
      <StatusLogger></StatusLogger>
    </div>

    <div
      class="menu_footer_not"
      v-if="$route.name == 'SpecificationEdit'"
      style="height: auto"
    >
      <StatusLogger></StatusLogger>
      <div class="btns-create">
        <div class="btn-create">
          <!-- <v-btn color="red darken-1" text @click="">Удалить</v-btn> -->
          <v-btn color="red darken-1" text @click="backToViewFromEdit"
            >Отмена</v-btn
          >
        </div>
        <div class="btn-create">
          <v-btn
            color="green darken-1"
            @click="updateNomenclature"
            :disabled="validationСheckEdit"
          >
            Обновить данные
          </v-btn>
        </div>
      </div>
    </div>

    <div
      class="menu_footer"
      v-if="
        $route.name !== 'SpecificationEdit' &&
        $route.name !== 'SpecificationView' &&
        $route.name !== 'SpecificationCreate'
      "
    >
      <v-btn
        id="btn_filter"
        text
        @click="open_close_block_filter"
        color="primary"
      >
        <v-icon color="blue" large
          >mdi-{{ this.icons_state ? 'filter-off' : 'filter' }}</v-icon
        >
      </v-btn>
      <v-btn
        id="btn_add"
        v-if="Object.keys($store.getters.getDataDeniedFunctional).length !== 0"
        color="primary"
        text
        @click="open_frame_add_new_order"
        :disabled="
          $store.getters.getDataDeniedFunctional.ACCESS_USER_GROUP_ADD_NEW_ORDER
            .CHECK_VALID_USER_GROUP !== 'Y' ||
          $route.name === 'Receipt_overhead' ||
          $route.name === 'NomenclatureList'
        "
      >
        <v-icon color="blue" large>mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn
        v-if="Object.keys($store.getters.getDataDeniedFunctional).length !== 0"
        id="btn_find_nomenclature"
        text
        @click="$router.push('/qr_code')"
      >
        <v-icon color="blue" large>mdi-qrcode-scan</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import StatusLogger from '@/components/specification/StatusLogger'
import { StatusNomenclature } from '@/constructors/StatusNomenclature'

export default {
  name: 'CustomFooter',
  props: [],
  components: {
    StatusLogger,
  },
  data: () => ({
    modal_expand_collapse_logout: false,
    icons_state: true,
  }),
  watch: {
    '$store.state.state_block_filters': {
      handler: function watch() {
        this.icons_state = this.$store.state.state_block_filters !== false
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('specificationStore', [
      'viewActiveNomenclatureId',
      'status_nomenclature',
      'status_nomenclature_create',
    ]),
    ...mapGetters('specificationStore', ['validationСheckEdit']),

    activeIconCreate() {
      if (this.$route.name === 'SpecificationCreate') {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions('specificationStore', [
      'createNew',
      'updateConcreteNomenclature',
    ]),
    ...mapMutations('specificationStore', {
      clearForm: 'CLEAR_FORM',
      clearStatus: 'CLEAR_STATUS',
      addStatusMessage: 'ADD_STATUS_MESSAGE',
    }),

    goToPage(page, id) {
      this.clearForm()
      this.$router.push({ name: page, params: { id: id } })
    },

    backToView() {
      this.clearForm()
      this.$router.push(`/specification/view`)
    },

    backToViewFromEdit() {
      this.$router.push(`/specification/view/${this.viewActiveNomenclatureId}`)
    },

    async postNewElement() {
      const response = await this.createNew()
      let data = response.data
      if (data.state) {
        this.$router.push(`/specification/view/${data.data.ID}`)
        this.clearForm()
        setTimeout(() => {}, 3000)
      }
    },

    async updateNomenclature() {
      const response = await this.updateConcreteNomenclature(
        this.$route.params.id,
      )
      if (response.data.state) {
        this.clearForm()
        this.$router.push({
          name: 'SpecificationView',
          params: { id: this.$route.params.id },
        })
      }
    },
    /* Footer */
    open_frame_add_new_order() {
      this.$store.state.data_new_order.state_v_show = true
    },
    open_close_block_filter() {
      this.$store.state.state_block_filters =
        !this.$store.state.state_block_filters
      // scroll(top);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/main';

#vue_wrapper_relative_menu_footer {
  @extend %padding_20_percent_media_768;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 777;

  #modal_expand_collapse_logout {
    margin-right: unset !important;
    max-width: 30%;
    background: unset;
    border: unset;
    button {
      background: white;
    }
  }
  .menu_footer_not {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    // grid-template-columns: 70% 30%;
    align-items: center;
    padding: 0.65em 36px;
    background: lightgrey;
    height: 75px;
  }
  .menu_footer {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0.7em 0;
    background: lightgrey;

    button {
      @media (min-width: 768px) {
        height: 2.5em;
      }
      height: 2.2em;
    }

    span.v-btn__content {
      height: 100%;
      img {
        height: 100%;
        max-height: 2.5em;
        min-height: 2.2em;
      }
    }
  }
}

.theme--light.v-icon:focus::after {
  opacity: 0;
}
.btn-create {
  display: flex;
  justify-content: flex-end;
}
.btns-create {
  display: flex;
  justify-content: space-around;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .menu_footer_not {
    flex-direction: column;
    gap: 12px;
  }
}
</style>
